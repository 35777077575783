import { defineStore } from "pinia";
import { computed, ref } from "vue";

interface BetaConfiguration {
  name: string;
  routes: string[];
}

/**
 * Array of current beta configurations. Add and remove as required.
 */
const betaConfigurations: BetaConfiguration[] = [
  {
    name: "rooms",
    routes: ["/settings/integrations/microsoft"],
  },
  {
    name: "panels",
    routes: [],
  },
];

const useBetaStore = defineStore("beta", {
  state: () => {
    const betas = ref(betaConfigurations.map((beta) => beta.name));
    const routes = computed(() =>
      betaConfigurations
        .filter((beta) => betas.value.includes(beta.name))
        .flatMap((beta) => beta.routes)
    );

    return {
      disabled: {
        betas,
        routes,
      },
      isLoaded: false,
    };
  },
  actions: {
    load(betas: string[]) {
      this.disabled.betas = betaConfigurations
        .filter((configs) => !betas.includes(configs.name))
        .map((beta) => beta.name);
      this.isLoaded = true;
    },
  },
});

export default useBetaStore;
