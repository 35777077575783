<script setup lang="ts">
import { ElDialog } from "element-plus";

export interface Props {
  visible?: boolean;
  title: string;
}

withDefaults(defineProps<Props>(), {
  visible: true,
});

const emit = defineEmits<{
  (e: "before-close", done: () => void): void;
  (e: "close"): void;
}>();
</script>

<template>
  <el-dialog
    :model-value="visible"
    append-to-body
    :before-close="(done: () => void) => emit('before-close', done)"
    @close="emit('close')"
  >
    <template #header>
      <h3>{{ title }}</h3>
    </template>

    <slot name="default" />

    <template #footer>
      <div class="dialog-actions">
        <slot name="actions" />
      </div>
    </template>
  </el-dialog>
</template>

<style scoped lang="css">
.dialog-actions {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media only screen and (min-width: 480px) {
  .dialog-actions {
    flex-direction: row;
    justify-content: right;
  }
}
</style>
