import type {
  NavItem,
  SettingsNavItem,
  TabNavItem,
} from "@/components/layout/navigation/types";
import useAccountStore from "@/stores/account";
import useAuthStore from "@/stores/auth";
import useBetaStore from "@/stores/beta";
import { watch } from "vue";
import { useRoute, useRouter, type RouteLocationNormalized } from "vue-router";
import { useAuth } from "@/composables/auth";
import { PLAN_FEATURE } from "@/stores/constants";

interface RouteGuard {
  watch: () => unknown;
  isDenied: (to: RouteLocationNormalized) => boolean;
  redirect: { name: string; params?: { id: string } };
}

export function useIsRouteActive(
  navItem: NavItem | TabNavItem | SettingsNavItem
) {
  const route = useRoute();
  return route.matched.find((match) => match.name === navItem.route);
}

export const useRouterGuards = () => {
  const authStore = useAuthStore();
  const betaStore = useBetaStore();
  const accountStore = useAccountStore();
  const router = useRouter();
  const route = useRoute();
  const { isSuperAdmin } = useAuth();

  /** array of routes and variables to check */
  const routeGuards: RouteGuard[] = [
    {
      watch: () => betaStore.disabled.routes,
      isDenied: (to) =>
        (authStore.authUser ?? false) &&
        betaStore.isLoaded &&
        betaStore.disabled.routes.includes(to.name?.toString() ?? ""),
      redirect: { name: "home" },
    },
    {
      watch: () => accountStore.subscription.isExpired,
      isDenied: (to) => {
        const allowedRoutes = accountStore.subscription.isFreeTrial
          ? ["/settings/account", "/settings/spaces"]
          : ["/settings/account"];
        const isAllowedRoute = allowedRoutes.some((path) =>
          to.path.includes(path)
        );

        return (
          (authStore.authUser ?? false) &&
          !isAllowedRoute &&
          !isSuperAdmin.value &&
          accountStore.isOutsideGracePeriod
        );
      },
      redirect: accountStore.subscription.isFreeTrial
        ? { name: "change-plan" }
        : { name: "account-settings-details", params: { id: "update" } },
    },
    {
      watch: () => accountStore.isOnProvisionedPlan,
      isDenied: (to) => {
        const disallowedRoutes = [
          "/settings/account/plans",
          "/settings/account/order",
        ];
        const isAllowedRoute = !disallowedRoutes.some((path) =>
          to.path.includes(path)
        );

        return accountStore.isOnProvisionedPlan && !isAllowedRoute;
      },
      redirect: { name: "account-settings-details" },
    },
    {
      watch: () => accountStore.isFeatureBundled(PLAN_FEATURE.AZURE_AD),
      isDenied: (to) => {
        const disallowedRoutes = [
          "/settings/integrations/azure-manage",
          "/settings/integrations/azure-setup",
        ];
        const isAllowedRoute = !disallowedRoutes.some((path) =>
          to.path.includes(path)
        );

        return (
          !accountStore.isFeatureBundled(PLAN_FEATURE.AZURE_AD) &&
          !isAllowedRoute
        );
      },
      redirect: { name: "integrations" },
    },
  ];

  /**
   * add router guards to the router.beforeEach function.
   */
  const beforeEach = (to: RouteLocationNormalized) => {
    const redirect = routeGuards.find((guard) => guard.isDenied(to))?.redirect;
    if (redirect) {
      return redirect;
    }
  };

  /**
   * used to initialise route watches in the authenticated layout.
   */
  const initialiseWatches = () => {
    routeGuards.forEach((guard) => {
      watch(guard.watch, () => {
        if (guard.isDenied(route)) {
          router.push(guard.redirect);
        }
      });
    });
  };

  return {
    initialiseWatches,
    beforeEach,
  };
};
