import axios from "axios";
import { useQuery } from "@tanstack/vue-query";
import { tags, type MaybeRef } from "@/api/common";
import { useLocationGroups } from "@/api/locationGroups";
import { useAuth } from "@/composables/auth";
import useApiStore from "@/stores/api";
import { endpoints } from "@/endpoints";
import { queryKeys } from "@/api/queryKeys";
import { unref, computed } from "vue";

import type {
  GetLocationApiResponse,
  Location,
  LocationApiResult,
  LocationsApiResponse,
} from ".";

export const mapLocation = (apiLocation: LocationApiResult) => {
  const location = <Location>{
    numericId: Number(apiLocation.id),
    id: apiLocation.pk,
    name: apiLocation.name,
    timeZone: apiLocation.time_zone,
    address: apiLocation.address,
    latlng: apiLocation.latlng
      ? [Number(apiLocation.latlng[0]), Number(apiLocation.latlng[1])]
      : null,
    hasSmsSubscription: Boolean(Number(apiLocation.has_sms_subscription)),
    order: Number(apiLocation.order),
    settings: JSON.parse(apiLocation.settings),
  };

  location.settings.catering = Boolean(Number(location.settings.catering));
  location.settings.deliveries = Boolean(Number(location.settings.deliveries));
  location.settings.inductions = Boolean(Number(location.settings.inductions));

  return location;
};

export const useGetLocation = (locationId: MaybeRef<string>) => {
  const apiStore = useApiStore();

  return useQuery({
    queryKey: queryKeys[tags.platform.locations].byId(locationId).queryKey,
    queryFn: async () => {
      const result = await axios.get<GetLocationApiResponse>(
        apiStore.getEndpoint(
          "platform",
          endpoints.locations.get(unref(locationId))
        )
      );
      return result.data;
    },
    select: (data) => mapLocation(data.location),
    enabled: computed(() => Boolean(unref(locationId))),
  });
};

export const useGetLocations = () => {
  const apiStore = useApiStore();
  const { isGroupAdmin } = useAuth();
  const { data: locationGroupsData } = useLocationGroups();

  return useQuery({
    queryKey: queryKeys[tags.platform.locations].list().queryKey,
    queryFn: async () => {
      const result = await axios.get<LocationsApiResponse>(
        apiStore.getEndpoint("platform", endpoints.locations.list)
      );
      const locations = result.data;
      if (isGroupAdmin.value) {
        const groupAdminLocationIds =
          locationGroupsData.value?.flatMap(({ locations }) =>
            locations.map(({ id }) => id)
          ) ?? [];

        locations.list = locations.list.filter(({ pk }) =>
          groupAdminLocationIds.includes(pk)
        );
      }

      return locations;
    },
    select: (data) => data.list.map(mapLocation),
    enabled: !isGroupAdmin.value || Boolean(locationGroupsData.value?.length),
  });
};
