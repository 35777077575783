<script setup lang="ts">
import { ElMenu, ElMenuItem, ElBadge } from "element-plus";
import { computed, ref, watch, type Ref } from "vue";
import { useRoute } from "vue-router";
import { useCssVar, useElementSize } from "@vueuse/core";
import CoreButton from "@/components/common/CoreButton/CoreButton.vue";
import CoreSlideTransition from "@/components/common/CoreSlideTransition/CoreSlideTransition.vue";
import { useIsRouteActive } from "@/composables/route";
import { config } from "@/config/envConfig";
import useAuthStore from "@/stores/auth";
import { useI18n } from "vue-i18n";
import type { NavItem, SettingsNavItem, TabNavItem } from "./types";
import { useLayoutStore } from "@/stores/layout";
import { useAuth } from "@/composables/auth";

export interface Props {
  navItems: NavItem[];
}
defineProps<Props>();

const { t } = useI18n();

const route = useRoute();
const authStore = useAuthStore();
const { isSuperAdmin } = useAuth();

const navColor = useCssVar("--so-color-secondary");

const layoutStore = useLayoutStore();
const menu = ref(null);
const { height: menuHeight } = useElementSize(menu);
watch(menuHeight, () => (layoutStore.navbarHeight = menuHeight.value));

const menuVisible = ref(false);
const selectedSubmenuTitles = ref<string[]>([]);
const tabNavItems: Ref<TabNavItem[] | null> = ref(null);
const subNavItems: Ref<SettingsNavItem[] | null> = ref(null);

const navItemHasSubmenu = (navItem: NavItem | TabNavItem) =>
  "subNavItems" in navItem || "tabNavItems" in navItem;

const submenuTitleClick = () => {
  tabNavItems.value ? (tabNavItems.value = null) : (subNavItems.value = null);
  selectedSubmenuTitles.value.pop();
};

const navItemClick = async (navItem: NavItem | SettingsNavItem) => {
  if ("address" in navItem) {
    window.open(navItem.address, "_blank");
    if (navItem.address === config.WHATS_NEW_LINK)
      await authStore.updateWhatsNewViewedAt();
  }

  const selectedSubNavItems =
    "subNavItems" in navItem ? navItem?.subNavItems : null;
  const selectedTabNavItems =
    "tabNavItems" in navItem ? navItem?.tabNavItems : null;

  if (selectedSubNavItems) {
    selectedSubmenuTitles.value.push(navItem.titleKey);
    subNavItems.value = [
      {
        titleKey: "nav.location",
        isGroupTitle: true,
      },
      ...selectedSubNavItems.group1,
      {
        titleKey: "nav.organization",
        isGroupTitle: true,
      },
      ...selectedSubNavItems.group2,
    ];
    return;
  }
  if (selectedTabNavItems) {
    selectedSubmenuTitles.value.push(navItem.titleKey);
    tabNavItems.value = selectedTabNavItems;
    return;
  }

  menuVisible.value = false;
};

const submenuTitle = computed(() =>
  t(selectedSubmenuTitles.value?.at(-1) ?? "")
);
</script>

<template>
  <div
    ref="menu"
    class="menu"
  >
    <img
      src="@theme/branding/brand_primary_logo.svg"
      alt=""
    />
    <div class="flex-grow" />
    <CoreButton
      :icon="['far', menuVisible ? 'xmark-large' : 'bars']"
      icon-size="xl"
      text
      @click="menuVisible = !menuVisible"
    />
  </div>
  <CoreSlideTransition>
    <div v-if="menuVisible">
      <el-menu
        :active-text-color="navColor"
        class="mobile-nav-menu"
        :default-active="route.name?.toString()"
        router
        :collapse="false"
        mode="vertical"
      >
        <el-menu-item
          v-if="tabNavItems || subNavItems"
          class="menu-item"
          index=""
          :route="{ name: route.name?.toString() }"
          @click="submenuTitleClick"
        >
          <font-awesome-icon
            class="nav-icon"
            :icon="['fal', 'arrow-left']"
            size="xl"
          />
          <template #title>
            {{ submenuTitle }}
          </template>
        </el-menu-item>
        <template
          v-for="(navItem, index) in tabNavItems ?? subNavItems ?? navItems"
        >
          <h4
            v-if="navItem.isGroupTitle"
            :key="navItem.titleKey"
            class="sub-group-title"
          >
            {{ t(navItem.titleKey) }}
          </h4>
          <el-menu-item
            v-else
            :key="index"
            :class="[
              'menu-item',
              {
                'icon-item': 'icon' in navItem,
                'is-active': useIsRouteActive(navItem),
                hidden: isSuperAdmin && navItem.route === 'account-settings',
              },
            ]"
            :index="navItem.route"
            :route="
              navItemHasSubmenu(navItem)
                ? { name: route.name?.toString() }
                : { name: navItem.route }
            "
            :disabled="navItem.disabled"
            @click="navItemClick(navItem)"
          >
            <el-badge
              v-if="'icon' in navItem"
              :hidden="!navItem.showDotBadge"
              is-dot
              class="nav-icon"
            >
              <font-awesome-icon
                :icon="navItem.icon"
                size="lg"
              />
            </el-badge>
            <template #title>
              {{ t(navItem.titleKey) }}
              <font-awesome-icon
                v-if="navItemHasSubmenu(navItem)"
                class="submenu-header-chevron"
                :icon="['fal', 'chevron-right']"
                size="xl"
              />
            </template>
          </el-menu-item>
        </template>
      </el-menu>
    </div>
  </CoreSlideTransition>
</template>

<style lang="scss" scoped>
.flex-grow {
  flex-grow: 1;
}

.menu {
  z-index: 1;
  display: flex;
  align-items: center;
  min-height: 62px;
  padding: 0 var(--so-menu-level-padding);
  background-color: var(--so-color-nav-bg);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);
}

.mobile-nav-menu {
  height: 100vh;
  background-color: var(--so-fill-color-light);
  border-right: 0;
}

.menu-item {
  border-bottom: 1px solid var(--so-border-color-lighter);
  &.is-active {
    font-weight: var(--font-bold);
  }
}

.nav-icon {
  margin-right: 15px;

  & svg {
    width: 18.38px;
    height: 21px;
  }
}

.icon-item {
  background-color: var(--so-color-nav-bg);
}

.submenu-header-chevron {
  margin-left: auto;
}

.sub-group-title {
  font-size: 12px;
  margin-top: 15px;
  padding: 0 var(--so-menu-base-level-padding);
  color: var(--so-text-color-secondary);
  text-transform: uppercase;
}

.hidden {
  display: none;
}
</style>
