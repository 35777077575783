import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const useLayoutStore = defineStore("layout", () => {
  const navbarHeight = ref<number>(0);
  const bannerHeight = ref<number>(0);

  const headerHeight = computed<number>(
    () => navbarHeight.value + bannerHeight.value
  );

  return { navbarHeight, bannerHeight, headerHeight };
});
