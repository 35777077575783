<script setup lang="ts">
import { useCssVar } from "@vueuse/core";
import { computed, onMounted, reactive, ref, watch } from "vue";
import {
  type FormInstance,
  ElForm,
  ElFormItem,
  ElInput,
  type FormRules,
} from "element-plus";
import CoreButton from "@/components/common/CoreButton/CoreButton.vue";
import { useI18n } from "vue-i18n";

export interface Props {
  isLoading: boolean;
  error?: string;
}

const props = defineProps<Props>();
const input = ref<HTMLInputElement>();
const buttonColour = useCssVar("--so-color-secondary");
const form = ref<FormInstance>();
const code = ref("");
const { t } = useI18n();
const emit = defineEmits<{
  submit: [code: string];
  goBack: [];
}>();

const localError = ref(props.error);

onMounted(() => input.value?.focus());

watch(
  () => props.error,
  (error) => {
    localError.value = error;
    if (error) {
      form.value?.validateField("code", () => null);
    }
  }
);

const formRules = computed<FormRules>(() => ({
  code: [
    {
      required: true,
      max: 6,
      message: () => t("multiFactorDialog.errors.InvalidParameterException"),
      trigger: ["change", "blur"],
    },
    {
      validator: (_rule, _value, callback) => {
        if (localError.value) {
          callback(localError.value);
        }
        callback();
      },
    },
  ],
}));

const submit = async () => {
  try {
    localError.value = "";
    await form.value?.validate();
  } catch (err) {
    return;
  }
  emit("submit", code.value);
};
const model = reactive({ code });
</script>
<template>
  <div>
    <el-form
      ref="form"
      hide-required-asterisk
      :model="model"
      :rules="formRules"
      label-position="top"
      @submit.prevent="submit"
    >
      <el-form-item
        prop="code"
        :label="$t('forms.login.mfa.subtitle')"
      >
        <el-input
          ref="input"
          v-model="code"
          class="login-input"
          :disabled="isLoading"
          type="number"
          auto-complete="off"
          :placeholder="t('forms.login.mfa.placeholder')"
          @blur="localError = ''"
        />
      </el-form-item>
      <div class="action-items">
        <CoreButton
          :loading="isLoading"
          class="login-button"
          :color="buttonColour"
          @click="submit"
        >
          {{ t("forms.login.mfa.verify") }}
        </CoreButton>
        <CoreButton
          plain
          type="info"
          class="login-button"
          @click="emit('goBack')"
        >
          {{ t("forms.login.backAction") }}
        </CoreButton>
      </div>
    </el-form>
  </div>
</template>
<style lang="css" scoped>
.login-input :deep(.so-input__inner) {
  min-width: 360px;
}
.action-items {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}
</style>
