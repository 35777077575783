import { BREAKPOINT } from "@/stores/constants";
import { useEventListener } from "@vueuse/core";
import { debounce } from "lodash";
import { computed, ref, watch } from "vue";

export function useScreenType(
  callback?: (type: "mobile" | "tablet" | "desktop") => void
) {
  const { windowWidth } = useScreenSize();
  const isMobile = computed(() => windowWidth.value <= BREAKPOINT.MOBILE);
  const isTablet = computed(
    () =>
      windowWidth.value > BREAKPOINT.MOBILE &&
      windowWidth.value <= BREAKPOINT.TABLET
  );
  const isDesktop = computed(() => windowWidth.value > BREAKPOINT.TABLET);
  const screenType = computed(() => {
    if (isMobile.value) return "mobile";
    if (isTablet.value) return "tablet";
    return "desktop";
  });

  watch(screenType, (newScreenType) => callback?.(newScreenType));

  return { isMobile, isTablet, isDesktop, screenType };
}

export function useScreenSize() {
  const windowWidth = ref(document.body.clientWidth);
  const windowHeight = ref(document.body.clientHeight);

  const resizeFn = debounce(() => {
    windowWidth.value = document.body.clientWidth;
    windowHeight.value = document.body.clientHeight;
  }, 200);

  useEventListener(window, "resize", resizeFn);
  return { windowWidth, windowHeight };
}
