/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineStore } from "pinia";
import type { GlobalError, RootState } from "./types";
import { withScope, captureException } from "@sentry/vue";
import { i18n } from "@/config";

export const useRootStore = defineStore("root", {
  state: (): RootState => ({
    loading: true,
    redirectLoading: false,
    currentAlert: null,
    hasUnsavedFormChanges: false,
  }),
  actions: {
    setHasUnsavedFormChanges: function (payload: boolean) {
      this.hasUnsavedFormChanges = payload;
    },
    setLoading: function (payload: boolean) {
      this.loading = payload;
    },
    setRedirectLoading: function (payload: boolean) {
      this.redirectLoading = payload;
    },
    setAlert: function (payload?: GlobalError) {
      const { t } = i18n.global;
      const payloadObj: GlobalError = {
        type: "error",
        showClose: true,
        message: {
          title: t("errors.unexpected"),
        },
        isNotification: false,
        ...payload,
      };
      this.currentAlert = payloadObj;
    },
    clearAlert: function (payload: any) {
      if (payload === this.currentAlert) {
        this.currentAlert = null;
      }
    },
    reset: function () {
      this.$reset();
    },
    async handleException({ error, extra, shouldDisplayAlert = true }: any) {
      withScope((scope: any) => {
        // If extra is defined, ensure that it is an object with at least one key
        if (typeof extra === "object" && Object.keys(extra).length) {
          // Necessary due to setExtra() overriding extra data in beforesend()
          // eslint-disable-next-line no-underscore-dangle
          Object.assign(scope._extra, extra);
        }
        if (error?.response) {
          // Necessary due to setExtra() overriding extra data in beforesend()
          // eslint-disable-next-line no-underscore-dangle
          Object.assign(scope._extra, {
            "Response Request": error.response?.request,
            "Response Data": error.response?.data,
          });
        }
        delete error.handledGlobally;
        captureException(error);
      });

      if (shouldDisplayAlert) {
        this.setAlert();
      }
    },
  },
});
