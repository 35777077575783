import { createRouter, createWebHistory } from "vue-router";
import useAuthStore from "@/stores/auth";
import { useRootStore } from "@/stores";
import { useAuth } from "@/composables/auth";
import { setCookie } from "tiny-cookie";
import { useRouterGuards } from "@/composables/route";
import useApprovalsStore from "@/stores/approvals";
import { STATUS, REVIEW_TYPE } from "@/stores/constants";
import { redirects } from "./redirects";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    ...redirects,
    {
      path: "/visitors",
      name: "visitors",
      component: () => import("../pages/visitors/index.vue"),
      redirect: { name: "visitors-timeline" },
      children: [
        {
          path: ":id?",
          name: "visitors-timeline",
          component: () => import("../pages/visitors/timeline.vue"),
        },
        {
          path: "remembered/:id?",
          name: "visitors-remembered",
          component: () => import("../pages/visitors/remembered.vue"),
        },
        {
          path: "preregistration/:id?",
          name: "visitors-preregistration",
          component: () => import("../pages/visitors/preregistration.vue"),
        },
      ],
    },
    {
      path: "/employees",
      name: "employees",
      component: () => import("../pages/employees/index.vue"),
      redirect: { name: "employees-timeline" },
      children: [
        {
          path: ":id?",
          name: "employees-directory",
          component: () => import("../pages/employees/directory.vue"),
        },
        {
          path: "timeline/:id?",
          name: "employees-timeline",
          component: () => import("../pages/employees/timeline.vue"),
        },
        {
          path: "archive",
          name: "employees-archive",
          component: () => import("../pages/employees/archive.vue"),
        },
      ],
    },
    {
      path: "/spaces",
      name: "spaces",
      component: () => import("../pages/spaces/index.vue"),
      redirect: { name: "spaces-bookings" },
      children: [
        {
          path: "bookings/:id?",
          name: "spaces-bookings",
          component: () => import("../pages/spaces/bookings/index.vue"),
        },
        {
          path: "resources",
          name: "spaces-resources",
          component: () => import("../pages/spaces/resources/index.vue"),
        },
      ],
    },
    {
      path: "/deliveries/:id?",
      name: "deliveries",
      meta: {
        allLocationsDisabled: true,
      },
      component: () => import("../pages/deliveries/index.vue"),
    },
    {
      path: "/evacuation",
      name: "evacuation",
      meta: {
        allLocationsDisabled: true,
        groupLocationsDisabled: true,
      },
      component: () => import("@/pages/evacuation/index.vue"),
    },
    {
      path: "/settings",
      name: "settings",
      meta: {
        allLocationsDisabled: true,
        singleLocationsDisabled: true,
      },
      children: [
        {
          path: "visitor",
          name: "visitor-settings",
          component: () => import("../pages/settings/visitors/index.vue"),
          redirect: { name: "visitors-welcome-screen-settings" },
          children: [
            {
              path: "",
              name: "visitors-welcome-screen-settings",
              component: () =>
                import("../pages/settings/visitors/welcomeScreen/index.vue"),
            },
            {
              path: "branding-and-badges",
              name: "visitors-branding-and-badges-settings",
              component: () =>
                import("../pages/settings/visitors/brandingAndBadges.vue"),
            },
            {
              path: "categories",
              name: "visitors-categories-settings",
              component: () =>
                import("../pages/settings/visitors/categories.vue"),
            },
            {
              path: "fields",
              name: "visitors-fields-settings",
              component: () =>
                import("../pages/settings/visitors/fields/fields.vue"),
            },
            {
              path: "advanced-settings",
              name: "visitors-advanced-settings",
              component: () =>
                import("../pages/settings/visitors/advanced.vue"),
            },
            {
              path: "catering",
              name: "visitors-catering-settings",
              component: () =>
                import("../pages/settings/visitors/catering.vue"),
            },
          ],
        },
        {
          path: "employee",
          name: "employee-settings",
          component: () => import("../pages/settings/employees/index.vue"),
          redirect: { name: "employee-general-settings" },
          children: [
            {
              path: "",
              name: "employee-general-settings",
              component: () =>
                import("../pages/settings/employees/general.vue"),
            },
            {
              path: "pocket-app",
              name: "employee-pocket-settings",
              component: () =>
                import("../pages/settings/employees/pocketApp.vue"),
            },
            {
              path: "profile-fields",
              name: "employee-profile-settings",
              component: () =>
                import("../pages/settings/employees/profileFields.vue"),
            },
            {
              path: "sign-in-questions",
              name: "employee-sign-in-settings",
              component: () =>
                import("../pages/settings/employees/signInQuestions.vue"),
            },
            {
              path: "sign-out-messages",
              name: "employee-sign-out-settings",
              component: () =>
                import("../pages/settings/employees/signOutMessages.vue"),
            },
          ],
        },
        {
          path: "spaces",
          name: "spaces-settings",
          component: () => import("../pages/settings/spaces/index.vue"),
          redirect: { name: "spaces-spaces-settings" },
          children: [
            {
              path: ":id?",
              name: "spaces-spaces-settings",
              component: () => import("../pages/settings/spaces/spaces.vue"),
            },
            {
              path: "resources/:id?",
              name: "spaces-resources-settings",
              component: () =>
                import("../pages/settings/spaces/resources/index.vue"),
            },
            {
              path: "categories/:id?",
              name: "spaces-categories-settings",
              component: () =>
                import("../pages/settings/spaces/categories.vue"),
            },
          ],
        },
        {
          path: "deliveries",
          name: "deliveries-settings",
          component: () => import("../pages/settings/deliveries/index.vue"),
          redirect: { name: "deliveries-setup-settings" },
          children: [
            {
              path: "",
              name: "deliveries-setup-settings",
              component: () => import("../pages/settings/deliveries/setup.vue"),
            },
            {
              path: "delivery-instructions",
              name: "deliveries-instruction-settings",
              component: () =>
                import("../pages/settings/deliveries/deliveryInstructions.vue"),
            },
          ],
        },
        {
          path: "devices/:id?",
          name: "devices",
          component: () => import("../pages/settings/devices/index.vue"),
        },
        {
          path: "locations/:id?",
          name: "locations",
          component: () => import("../pages/settings/locations/index.vue"),
          beforeEnter: (to, from, next) => {
            const { isGroupAdmin } = useAuth();
            if (isGroupAdmin.value) next({ name: "home" });
            next();
          },
        },
        {
          path: "agreements-library",
          name: "agreements-library",
          component: () => import("../pages/settings/agreements/index.vue"),
        },
        {
          path: "inductions",
          name: "inductions",
          component: () => import("../pages/settings/inductions/index.vue"),
          children: [
            {
              path: ":id?",
              name: "edit-inductions",
              component: () => import("../pages/settings/inductions/index.vue"),
            },
          ],
        },
        {
          path: "integrations",
          name: "integrations",
          component: () => import("../pages/settings/integrations/index.vue"),
          beforeEnter: (to, from, next) => {
            const { isGroupAdmin } = useAuth();
            if (isGroupAdmin.value) next({ name: "home" });
            next();
          },
          children: [
            {
              path: "azure-manage",
              name: "integrations-azure-manage",
              component: () =>
                import("../pages/settings/integrations/azure/index.vue"),
            },
            {
              path: "azure-manage/locations",
              name: "integrations-azure-manage-locations",
              component: () =>
                import("../pages/settings/integrations/azure/locations.vue"),
            },
            {
              path: "azure-manage/fields",
              name: "integrations-azure-manage-fields",
              component: () =>
                import("../pages/settings/integrations/azure/fields.vue"),
            },
            {
              path: "azure-manage/issues",
              name: "integrations-azure-manage-issues",
              component: () =>
                import("../pages/settings/integrations/azure/issues.vue"),
            },
            {
              path: "azure-setup",
              name: "integrations-azure-setup",
              component: () =>
                import("../pages/settings/integrations/azure/setup.vue"),
            },
            {
              path: "teams-manage",
              name: "integrations-teams-manage",
              component: () =>
                import("../pages/settings/integrations/teams/index.vue"),
            },
            {
              path: "slack-manage",
              name: "slack-manage",
              component: () =>
                import("../pages/settings/integrations/slack.vue"),
            },
            {
              path: "microsoft",
              name: "ms365-manage",
              component: () =>
                import("../pages/settings/integrations/microsoft365.vue"),
            },
            {
              path: "rooms",
              name: "rooms-settings",
              component: () =>
                import("../pages/settings/spaces/rooms/index.vue"),
            },
          ],
        },
        {
          path: "account",
          name: "account-settings",
          component: () => import("../pages/settings/account/index.vue"),
          beforeEnter: (to, from, next) => {
            const { isGroupAdmin, isSuperAdmin } = useAuth();
            if (isGroupAdmin.value || isSuperAdmin.value)
              next({ name: "home" });
            next();
          },
          children: [
            {
              path: ":id?",
              name: "account-settings-details",
              component: () =>
                import("../pages/settings/account/SubscriptionDetails.vue"),
            },
            {
              path: "plans",
              name: "change-plan",
              component: () =>
                import("../pages/settings/account/ChangePlan.vue"),
            },
            {
              path: "addons",
              name: "purchase-addons",
              component: () =>
                import("../pages/settings/account/AddToPurchase.vue"),
            },
            {
              path: "order",
              name: "payment-details",
              component: () => import("../pages/settings/account/Order.vue"),
            },
            {
              path: "downgrade",
              name: "downgrade",
              component: () =>
                import(
                  "../pages/settings/account/hubspotForms/DowngradePlan.vue"
                ),
            },
            {
              path: "cancel",
              name: "cancel",
              component: () =>
                import(
                  "../pages/settings/account/hubspotForms/CancelSubscriptionForm.vue"
                ),
            },
            {
              path: "cancelAddon",
              name: "cancelAddon",
              component: () =>
                import(
                  "../pages/settings/account/hubspotForms/CancelAddonForm.vue"
                ),
            },
            {
              path: "requestAddons",
              name: "requestAddons",
              component: () =>
                import(
                  "../pages/settings/account/hubspotForms/RequestAddonsForm.vue"
                ),
            },
            {
              path: "requestLocations",
              name: "requestLocations",
              component: () =>
                import(
                  "../pages/settings/account/hubspotForms/RequestLocationsForm.vue"
                ),
            },
            {
              path: "requestResources",
              name: "requestResources",
              component: () =>
                import(
                  "../pages/settings/account/hubspotForms/RequestResourcesForm.vue"
                ),
            },
          ],
        },
        {
          path: "security",
          name: "security",
          component: () => import("../pages/settings/security/index.vue"),
        },
      ],
    },
    {
      path: "/user-review",
      name: "user-review",
      meta: {
        authlessApproval: true,
        allowAnonymous: true,
        type: REVIEW_TYPE.EMPLOYEE,
      },
      children: [
        {
          path: "reject/:reviewerPk/:movementPk",
          name: "user-review-reject",
          component: () => import("../pages/approvals/index.vue"),
          meta: {
            status: STATUS.REJECT,
          },
          props: {
            type: REVIEW_TYPE.EMPLOYEE,
            status: STATUS.REJECT,
          },
        },
        {
          path: "approve/:reviewerPk/:movementPk",
          name: "user-review-approve",
          component: () => import("../pages/approvals/index.vue"),
          meta: {
            status: STATUS.APPROVE,
          },
          props: {
            type: REVIEW_TYPE.EMPLOYEE,
            status: STATUS.APPROVE,
          },
        },
      ],
    },
    {
      path: "/review",
      name: "review",
      meta: {
        authlessApproval: true,
        allowAnonymous: true,
        type: REVIEW_TYPE.VISIT,
      },
      children: [
        {
          path: "reject/:reviewerPk/:movementPk",
          name: "review-reject",
          component: () => import("../pages/approvals/index.vue"),
          meta: {
            status: STATUS.REJECT,
          },
          props: {
            type: REVIEW_TYPE.VISIT,
            status: STATUS.REJECT,
          },
        },
        {
          path: "approve/:reviewerPk/:movementPk",
          name: "review-approve",
          component: () => import("../pages/approvals/index.vue"),
          meta: {
            status: STATUS.APPROVE,
          },
          props: {
            type: REVIEW_TYPE.VISIT,
            status: STATUS.APPROVE,
          },
        },
      ],
    },
    {
      path: "/evacuation",
      name: "evacuation",
      meta: {
        allLocationsDisabled: true,
        groupLocationsDisabled: true,
      },
      component: () => import("../pages/evacuation/index.vue"),
    },
    {
      path: "/login",
      name: "login",
      component: () => import("../pages/login/index.vue"),
      meta: {
        allowAnonymous: true,
      },
    },
    {
      path: "/logout",
      name: "logout",
      beforeEnter: async (to, from, next) => {
        const authStore = useAuthStore();

        await authStore.logout();

        return next();
      },
      component: () => import("../pages/login/index.vue"),
    },
    {
      path: "/forgot",
      name: "forgot",
      component: () => import("../pages/forgot-password/index.vue"),
      meta: {
        allowAnonymous: true,
      },
    },
    {
      path: "/register",
      name: "register",
      component: () => import("../pages/register.vue"),
      meta: {
        allowAnonymous: true,
      },
    },
    {
      path: "/auth",
      name: "auth-landing",
      component: () => import("../pages/auth/index.vue"),
      meta: {
        allowAnonymous: true,
      },
    },
    {
      path: "/mfa",
      name: "mfa-setup",
      component: () => import("../pages/mfa-setup/index.vue"),
      meta: {
        hideNav: true,
      },
    },
    {
      path: "/",
      name: "home",
      component: () => import("../pages/index.vue"),
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: { name: "home" },
    },
  ],
});

router.beforeEach(async (to, from) => {
  const authStore = useAuthStore();
  const { isLocationManager, isSuperAdmin } = useAuth();
  const rootStore = useRootStore();
  const routerGuards = useRouterGuards();
  const approvalsStore = useApprovalsStore();

  rootStore.currentAlert = null;

  const isAttemptingSuperAdmin =
    from.query["admin"] || to.redirectedFrom?.query["admin"];

  /*
  If the user is attempting to super admin a company and they're not a superadmin then log them out and store the details
  (companyPk, Id & region) of the company they're attempting to access in a cookie named "superadmin-query".

  This cookies' details are then applied on the next successful superadmin login and the company is loaded for them.
  */
  if (isAttemptingSuperAdmin) {
    if (isSuperAdmin.value) {
      if (from.query["admin"]) {
        await authStore.setSuperAdminCookiesFromQuery(from.query);
      } else if (to.redirectedFrom?.query["admin"]) {
        await authStore.setSuperAdminCookiesFromQuery(to.redirectedFrom.query);
      }
    } else {
      // User is not a superadmin - store the company request in a cookie and log them out
      await authStore.logout();
      setCookie("superadmin-query", JSON.stringify(to.redirectedFrom?.query));
    }
  }

  if (to.meta.authlessApproval) {
    return await approvalsStore.handleAuthless(to);
  }

  if (to.meta.allowAnonymous && authStore.authUser) {
    return { name: "home" };
  }

  if (!authStore.authUser) {
    await authStore.checkLogin({ to });

    if (["home"].includes((to.name ?? "") as string)) {
      return { name: "login" };
    }
  }

  if (
    to.fullPath.match("/settings") &&
    !to.fullPath.match("/settings/devices") &&
    isLocationManager.value
  ) {
    return { name: "home" };
  }

  return routerGuards.beforeEach(to);
});

router.onError((error, to) => {
  if (
    error.message.includes("Failed to fetch dynamically imported module") ||
    error.message.includes("Importing a module script failed")
  ) {
    window.location.href = to.fullPath;
  }
});

export default router;
