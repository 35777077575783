import { nextTick } from "vue";
import { createI18n, type Locale, type VueI18n, type Composer } from "vue-i18n";
import axios from "axios";
import type { MessageSchema } from "@/locales/schema";
import dayjs from "dayjs";
import dateLocales from "./i18n/dateLocales";
import elementPlusLocales from "./i18n/elementPlusLocales";
import { config } from "./envConfig";

export const localeMap: { [key: string]: string } = {
  en: "en-US",
  cs: "cs-CZ",
  cn: "zh-CN",
  da: "da-DK",
  de: "de-DE",
  es: "es-US",
  fr: "fr-FR",
  it: "it-IT",
  ko: "ko-KR",
  nb: "nb-NO",
  nl: "nl-NL",
  pt: "pt-BR",
  sv: "sv-SE",
  zh: "zh-TW",
};

const i18n = createI18n<[MessageSchema]>({});
type I18nType = typeof i18n;

export function setLocale(i18n: I18nType, locale: Locale): void {
  if (i18n.mode === "legacy") {
    (i18n.global as unknown as VueI18n).locale = locale;
  } else {
    (i18n.global as unknown as Composer).locale.value = locale;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setupI18n(options: any) {
  const i18n = createI18n<[MessageSchema]>(options);
  setI18nLanguage(i18n, options.locale ?? "en");
  return i18n;
}

export function setI18nLanguage(i18n: I18nType, locale: Locale): void {
  setLocale(i18n, locale);
  axios.defaults.headers.common["Accept-Language"] = locale;
  document.querySelector("html")?.setAttribute("lang", locale);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getResourceMessages = (r: any) => r.default || r;

export type Language = { code: string; description: string };
export const SUPPORTED_LOCALES = config.SUPPORTED_LANGUAGES;

export async function loadDateTimeLocale(lang: string) {
  const lowerLocale = lang.toLowerCase();
  const mappedLocale =
    Object.keys(localeMap).find(
      (item) => localeMap[item].toLocaleLowerCase() === lowerLocale
    ) ?? lowerLocale;

  if (mappedLocale in dateLocales) {
    await dateLocales[mappedLocale]();
    dayjs.locale(mappedLocale);
  }
}

export async function loadElementPlusLocale(lang: string) {
  const lowerLocale = lang.toLowerCase();
  const languageCodeOnly = lowerLocale.split("-")[0];
  if (lowerLocale in elementPlusLocales) {
    return await elementPlusLocales[lowerLocale]();
  } else if (languageCodeOnly in elementPlusLocales) {
    return await elementPlusLocales[languageCodeOnly]();
  }
}

export async function loadLocaleMessages(i18n: I18nType, locale: Locale) {
  if (
    SUPPORTED_LOCALES.some((supportedLocale) => supportedLocale.code === locale)
  ) {
    // load locale messages
    const messages = await import(
      /* @vite-ignore */ `../locales/${locale}.json`
    ).then(getResourceMessages);

    // set locale and locale message
    i18n.global.setLocaleMessage(locale, messages);
    setI18nLanguage(i18n, locale);
  }

  return nextTick();
}
