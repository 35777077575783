<script setup lang="ts">
import { useColourMode } from "@/composables/colourTheme";

export interface Props {
  defaultImage: string;
  darkModeImage?: string;
  alt: string;
}
defineProps<Props>();

const { isDark } = useColourMode();
</script>

<template>
  <img
    :src="isDark ? darkModeImage ?? defaultImage : defaultImage"
    :alt="alt"
  />
</template>
