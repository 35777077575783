import { computed } from "vue";
import type { Employee } from "@/api/employees";
import useAuthStore from "@/stores/auth";

const SUPER_ADMIN = "Super Admin";
const COMPANY_ADMIN = "Company Admin";
const LOCATION_MANAGER = "Location Manager";

export const useAuth = () => {
  const authStore = useAuthStore();

  const isSuperAdmin = computed(() =>
    (authStore.authUser?.assignedRoles ?? []).includes(SUPER_ADMIN)
  );

  const isCompanyAdmin = computed(() =>
    (authStore.authUser?.assignedRoles ?? []).includes(COMPANY_ADMIN)
  );

  const isLocationManager = computed(() =>
    (authStore.authUser?.assignedRoles ?? []).includes(LOCATION_MANAGER)
  );

  const groupAdminPks = computed(() => {
    if (!isCompanyAdmin.value) {
      return [];
    }
    return authStore.authUser?.settings.lg_admin_location_group_pks ?? [];
  });

  const isGroupAdmin = computed(() => Boolean(groupAdminPks.value.length));

  const employeeIsGroupAdmin = (employee: Employee) =>
    Boolean(employee.settings.lg_admin_location_group_pks.length);

  const authUser = computed(() => authStore.authUser);

  return {
    isSuperAdmin,
    isCompanyAdmin,
    isLocationManager,
    isGroupAdmin,
    groupAdminPks,
    authUser,
    employeeIsGroupAdmin,
  };
};
