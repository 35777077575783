import { config } from "@/config/envConfig";
import type { SubNavItems, TabNavItem } from "./types";

export const visitorsTabs: TabNavItem[] = [
  {
    route: "visitors-timeline",
    titleKey: "visitors.tabs.timeline",
  },
  {
    route: "visitors-remembered",
    titleKey: "visitors.tabs.rememberedVisitors",
  },
  {
    route: "visitors-preregistration",
    titleKey: "visitors.tabs.preregistration",
  },
];

export const employeesTabs: TabNavItem[] = [
  {
    route: "employees-timeline",
    titleKey: "employees.tabs.timeline",
  },
  {
    route: "employees-directory",
    titleKey: "employees.tabs.directory",
  },
  {
    route: "employees-archive",
    titleKey: "employees.tabs.archive",
  },
];

const spacesTabsUnfiltered: TabNavItem[] = [
  {
    route: "spaces-bookings",
    titleKey: "spaces.tabs.bookings",
  },
  { route: "spaces-resources", titleKey: "spaces.tabs.resources" },
];

export const spacesTabs: TabNavItem[] = spacesTabsUnfiltered.filter(
  (tab) => !(tab.route === "spaces-resources" && config.HIDE_RESOURCES_TAB)
);

export const spacesSettingsTabs: TabNavItem[] = [
  {
    route: "spaces-spaces-settings",
    titleKey: "nav.settingsItems.configureSpaces.tabs.spaces",
  },
  {
    route: "spaces-resources-settings",
    titleKey: "nav.settingsItems.configureSpaces.tabs.resources",
  },
  {
    route: "spaces-categories-settings",
    titleKey: "nav.settingsItems.configureSpaces.tabs.categories",
  },
];

export const employeeSettingsTabs: TabNavItem[] = [
  {
    route: "employee-general-settings",
    titleKey: "nav.settingsItems.employeesSettings.tabs.general",
  },
  {
    route: "employee-pocket-settings",
    titleKey: "nav.settingsItems.employeesSettings.tabs.pocket-app",
  },
  {
    route: "employee-profile-settings",
    titleKey: "nav.settingsItems.employeesSettings.tabs.profile-fields",
  },
  {
    route: "employee-sign-in-settings",
    titleKey: "nav.settingsItems.employeesSettings.tabs.sign-in-questions",
  },
  {
    route: "employee-sign-out-settings",
    titleKey: "nav.settingsItems.employeesSettings.tabs.sign-out-messages",
  },
];

export const deliveriesSettingsTabs: TabNavItem[] = [
  {
    route: "deliveries-setup-settings",
    titleKey: "nav.settingsItems.deliveriesSettings.tabs.setup",
  },
  {
    route: "deliveries-instruction-settings",
    titleKey: "nav.settingsItems.deliveriesSettings.tabs.delivery-instructions",
  },
];

export const visitorSettingsTabs: TabNavItem[] = [
  {
    route: "visitors-welcome-screen-settings",
    titleKey: "nav.settingsItems.visitorSettings.tabs.welcome-screen",
  },
  {
    route: "visitors-branding-and-badges-settings",
    titleKey: "nav.settingsItems.visitorSettings.tabs.branding-and-badges",
  },
  {
    route: "visitors-categories-settings",
    titleKey: "nav.settingsItems.visitorSettings.tabs.categories",
  },
  {
    route: "visitors-fields-settings",
    titleKey: "nav.settingsItems.visitorSettings.tabs.fields",
  },
  {
    route: "visitors-advanced-settings",
    titleKey: "nav.settingsItems.visitorSettings.tabs.advanced",
  },
  {
    route: "visitors-catering-settings",
    titleKey: "nav.settingsItems.visitorSettings.tabs.catering",
  },
];

export const settingsPages: SubNavItems = {
  group1: [
    {
      titleKey: "nav.settingsItems.visitorSettings.name",
      bodyKey: "nav.settingsItems.visitorSettings.description",
      route: "visitor-settings",
      tabNavItems: visitorSettingsTabs,
      icon: ["fal", "arrow-right-to-bracket"],
    },
    {
      titleKey: "nav.settingsItems.employeesSettings.name",
      bodyKey: "nav.settingsItems.employeesSettings.description",
      route: "employee-settings",
      tabNavItems: employeeSettingsTabs,
      icon: ["fal", "users"],
    },
    {
      titleKey: "nav.settingsItems.configureSpaces.name",
      bodyKey: "nav.settingsItems.configureSpaces.description",
      route: "spaces-settings",
      tabNavItems: spacesSettingsTabs,
      icon: ["fal", "chair-office"],
    },
    {
      titleKey: "nav.settingsItems.deliveriesSettings.name",
      bodyKey: "nav.settingsItems.deliveriesSettings.description",
      route: "deliveries-settings",
      tabNavItems: deliveriesSettingsTabs,
      icon: ["fal", "box"],
    },
    {
      titleKey: "nav.settingsItems.devices.name",
      bodyKey: "nav.settingsItems.devices.description",
      route: "devices",
      icon: ["fal", "tablet"],
    },
  ],
  group2: [
    {
      titleKey: "nav.settingsItems.agreementsLibrary.name",
      bodyKey: "nav.settingsItems.agreementsLibrary.description",
      route: "agreements-library",
      icon: ["fal", "signature"],
    },
    {
      titleKey: "nav.settingsItems.locations.name",
      bodyKey: "nav.settingsItems.locations.description",
      route: "locations",
      icon: ["fal", "location-dot"],
    },
    {
      titleKey: "nav.settingsItems.inductions.name",
      bodyKey: "nav.settingsItems.inductions.description",
      route: "inductions",
      icon: ["fal", "file-check"],
    },
    {
      titleKey: "nav.settingsItems.integrations.name",
      bodyKey: "nav.settingsItems.integrations.description",
      route: "integrations",
      icon: ["fal", "diagram-next"],
    },
    {
      titleKey: "nav.settingsItems.securitySettings.name",
      bodyKey: "nav.settingsItems.securitySettings.description",
      route: "security",
      icon: ["fal", "shield-check"],
    },
    {
      titleKey: "nav.settingsItems.accountSettings.name",
      bodyKey: "nav.settingsItems.accountSettings.description",
      route: "account-settings",
      icon: ["fal", "circle-user"],
    },
  ],
};

export const groupAdminSettingsPages: SubNavItems = {
  group1: [
    {
      titleKey: "nav.settingsItems.visitorSettings.name",
      bodyKey: "nav.settingsItems.visitorSettings.description",
      route: "visitor-settings",
      tabNavItems: visitorSettingsTabs,
    },
    {
      titleKey: "nav.settingsItems.employeesSettings.name",
      bodyKey: "nav.settingsItems.employeesSettings.description",
      route: "employee-settings",
      tabNavItems: employeeSettingsTabs,
    },
    {
      titleKey: "nav.settingsItems.configureSpaces.name",
      bodyKey: "nav.settingsItems.configureSpaces.description",
      route: "spaces-settings",
      tabNavItems: spacesSettingsTabs,
    },
    {
      titleKey: "nav.settingsItems.deliveriesSettings.name",
      bodyKey: "nav.settingsItems.deliveriesSettings.description",
      route: "deliveries-settings",
      tabNavItems: deliveriesSettingsTabs,
    },
    {
      titleKey: "nav.settingsItems.devices.name",
      bodyKey: "nav.settingsItems.devices.description",
      route: "devices",
    },
  ],
  group2: [
    {
      titleKey: "nav.settingsItems.agreementsLibrary.name",
      bodyKey: "nav.settingsItems.agreementsLibrary.description",
      route: "agreements-library",
    },
    {
      titleKey: "nav.settingsItems.inductions.name",
      bodyKey: "nav.settingsItems.inductions.description",
      route: "inductions",
    },
  ],
};

export const locationManagerSettingsPages: SubNavItems = {
  group1: [
    {
      titleKey: "nav.settingsItems.devices.name",
      bodyKey: "nav.settingsItems.devices.description",
      route: "devices",
    },
  ],
  group2: [],
};
