import { onMounted, ref, watch, type Ref } from "vue";
import { UserManager } from "oidc-client-ts";
import { useLocalStorage, useScriptTag } from "@vueuse/core";
import { config } from "@/config/envConfig";
import useAuthStore from "@/stores/auth";
import { useI18n } from "vue-i18n";

export const useSso = (username: Ref<string | undefined>) => {
  const { t } = useI18n();
  const isLoading = ref(false);
  const authStore = useAuthStore();
  const error = ref<string>();
  let googleClient: google.accounts.oauth2.TokenClient | undefined;
  const googleReady = ref(false);

  useScriptTag("https://accounts.google.com/gsi/client", () => {
    googleReady.value = true;
  });

  const azureOIDCClient = new UserManager({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    client_id: config.MICROSOFT_SSO_CLIENT_ID!,
    authority: "https://login.microsoftonline.com/common/v2.0/",
    redirect_uri: `${window.location.origin}/login`,
    scope: "profile email openid User.Read",
    prompt: "select_account",
  });

  const ssoProvider = useLocalStorage<string | undefined>(
    "ssoProvider",
    undefined
  );

  const azureLogin = async () => {
    ssoProvider.value = "azure";
    await azureOIDCClient.signinRedirect({ login_hint: username.value });
  };

  const googleLogin = async () => {
    ssoProvider.value = "google";
    googleClient?.requestAccessToken({
      login_hint: username.value,
      scope:
        "openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
    });
  };

  const handleUnexpectedError = () => {
    isLoading.value = false;
    username.value = undefined;
    error.value = t("errors.forbidden");
  };

  const handleUserMismatchError = () => {
    isLoading.value = false;
    username.value = undefined;
    error.value = t("forms.login.ssoUserMismatch", { user: username.value });
  };

  const setupGoogle = async (email: string) => {
    googleClient = google?.accounts?.oauth2?.initTokenClient({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      client_id: config.GOOGLE_SSO_CLIENT_ID!,
      scope:
        "openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
      error_callback(error) {
        if (error.type === "popup_closed") return;
        handleUnexpectedError();
      },
      callback: async (response) => {
        isLoading.value = true;
        await handleSignIn(email, response.access_token);
      },
    });
  };

  watch(
    [username, googleReady],
    async ([_username, _googleReady]) => {
      if (_username && _googleReady) await setupGoogle(_username);
    },
    { immediate: true }
  );

  const handleMicrosoftRedirect = async () => {
    const args = new URLSearchParams(location.search);
    if (!args.has("state")) return;
    isLoading.value = true;
    await azureOIDCClient.removeUser();
    const oidcUser = await azureOIDCClient.signinCallback();
    if (!oidcUser) return;
    history.replaceState(history.state, "", document.location.pathname);
    const email = username.value;
    const tokenEmail =
      oidcUser.profile.email ?? oidcUser.profile.preferred_username;
    if (
      !email ||
      !tokenEmail ||
      !oidcUser.id_token ||
      email.toLowerCase() !== tokenEmail?.toLowerCase()
    ) {
      handleUserMismatchError();
      return;
    }
    await handleSignIn(email, oidcUser.id_token);
  };

  const handleSignIn = async (email: string, token: string) => {
    const user = await authStore.login({ email });
    try {
      await authStore.ssoLogin({
        user,
        challengeResponse: JSON.stringify({
          token,
          provider: ssoProvider.value,
        }),
      });
    } catch (err) {
      handleUserMismatchError();
      return;
    }
  };
  onMounted(async () => {
    await handleMicrosoftRedirect();
  });

  return { azureLogin, googleLogin, isLoading, error };
};
