export const endpoints = {
  region: (email: string) => `/auth/regions/${email}`,
  user: (id: string) => `/api/users/${id}`,
  getCompany: (id: string) => `/api/companies/${id}`,
  updateCustomField: (id: string) => `/api/companyCustomFields/${id}`,
  deleteCustomField: (id: string) => `/api/companyCustomFields/${id}`,
  deliveryNotification: (pk: string) => `/deliveries/notification/${pk}`,
  generateQrCode: (pk: string) => `/visits/contactless/qrcode/${pk}`,
  updateAddon: (pk: string) => `/account/customer/me/addon/${pk}`,
  bundles: (companyPk: string, plan: string) =>
    `/account/customer/${companyPk}/addon?bundlesPlan=${plan}`,
  profitwellCustomer: "/account/customer/me",
  users: "/api/users",
  getCompanies: "/companies/globalCompanies",
  addCustomField: "/api/companyCustomFields",
  orderVisitorFields: "/api/companyCustomFields/orders",
  setupGuideComplete: "/api/setup/setCompleted",
  getCustomFields: "/api/companyCustomFields",
  getWhatsNew: "/crm/whats-new",
  acceptOffer: "/account/customer/me/plan-offer/accept",
  addOnList: (companyPk: string) => `/account/customer/${companyPk}/addon`,
  checkout: "/account/checkout",
  checkoutUpdate: "/account/customer/me/subscription",
  customer: (companyPk: string) => `/account/customer/${companyPk}`,
  getOffer: "/account/customer/me/plan-offer",
  migrate: "/account/migrate/checkout",
  migratePreview: "/account/migrate/preview",
  preview: "/account/subscription/preview",
  resourceUsage: "/account/customer/me/resources/usage",
  smsPacks: "/account/customer/me/sms/pack",
  transaction: "/account/transaction",
  updateAccountSettings: "/account/customer/me/settings",
  updateCard: "/account/customer/me",
  plans: "/account/plan",
  getRegions: "/auth/regions",
  visits: {
    csv: "/api/visits/csv",
    signOutSelected: "/api/visits/signOutSelected",
    anonymize: "/api/visits/anonymize",
    update: (pk: string) => `/api/visits/${pk}`,
    contactTrace: (id: string) => `/api/trace/visit/${id}`,
    trends: "/api/visits/trends",
  },
  visitors: {
    list: "/api/visitors",
    csv: "/api/visitors/csv",
    signOutAll: "/api/visitors/signOutAll",
    anonymize: "/api/visitors/anonymize",
    create: "/api/visitors",
    update: (id: string) => `/api/visitors/${id}`,
    delete: (id: string) => `/api/visitors/${id}`,
    reviewApprove: (visitPk: string) => `/api/review-visit/approve/${visitPk}`,
    reviewReject: (visitPk: string) => `/api/review-visit/reject/${visitPk}`,
  },
  preregistrations: {
    get: (id: string) => `/api/preregistrations/${id}`,
    create: "/api/preregistrations",
    list: "/api/preregistrations",
    testInvite: "/notifications/sendTestPreregistrationEmail",
    update: (id: string) => `/api/preregistrations/${id}`,
    delete: (id: string) => `/api/preregistrations/${id}`,
  },
  employees: {
    movementTrends: "/api/users/movements/trends",
    csvMovements: "/api/employees/timeline/csv",
    signOutSelected: "/api/users/signOutSelected",
    getMovement: (pk?: string) => `/api/users/movements/${pk}`,
    updateMovement: (pk: string) => `/api/users/movements/${pk}`,
    list: "/api/users",
    csv: "/api/employees/csv",
    importEmployees: "/api/users/import",
    downloadCSVTemplate: "/api/users/template",
    signOutAll: "/api/users/signOutAll",
    delete: "/api/users/deleteEmployees",
    archive: "/api/users/archive",
    restore: "/api/users/restoreEmployees",
    anonymize: "/api/users/anonymize",
    photo: "/mm/employeePhoto",
    customFields: "/api/companyCustomFields",
    get: (pk: string) => `/api/users/${pk}`,
    create: (pk: string) => `/users/${pk}`,
    update: (pk: string) => `/users/${pk}`,
    updateStatus: (id: string) => `/api/users/changeStatus/${id}`,
    deletePhoto: (pk: string, key: string) =>
      `/mm/employeePhoto?user_id=${pk}&key=${key}`,
    reviewApprove: (movementPk: string) =>
      `/api/review-user/approve/${movementPk}`,
    reviewReject: (movementPk: string) =>
      `/api/review-user/reject/${movementPk}`,
    contactTrace: (id: string) => `/api/trace/movement/${id}`,
  },
  evacuation: {
    list: "/api/evacuation",
    csv: "/api/evacuation/csv",
    signOutAll: "/api/evacuation/signOutAll",
  },
  deliveries: {
    get: (pk: string) => `/deliveries/${pk}`,
    list: "/deliveries",
    add: (pk: string) => `/deliveries/${pk}`,
    update: (pk: string) => `/deliveries/${pk}`,
    delete: (pk: string) => `/deliveries/${pk}`,
  },
  visitorCategories: {
    create: "/api/visitorCategory",
    get: (id: string) => `/api/visitorCategory/${id}`,
    list: "/api/visitorCategory",
    update: (id: string) => `/api/visitorCategory/${id}`,
    delete: (id: number) => `/api/visitorCategory/${id}`,
    unlink: "/api/visitorCategory/unlinkAll",
  },
  visitorFields: {
    create: "/api/companyCustomFields",
    get: (id: string) => `/api/visitor-fields/${id}`,
    list: "/api/visitor-fields",
    update: (id: string) => `/api/companyCustomFields/${id}`,
    delete: (id: string) => `/api/companyCustomFields/${id}`,
  },
  locations: {
    list: "/api/locations",
    get: (pk: string) => `/api/locations/${pk}`,
    update: (pk: string) => `/api/locations/${pk}`,
    delete: (pk: string) => `/api/locations/${pk}`,
  },
  locationGroups: {
    list: "/api/location-groups",
    get: (pk?: string) => `/api/location-groups/${pk}`,
    upsert: (pk: string) => `/api/location-groups/${pk}`,
    delete: (pk: string) => `/api/location-groups/${pk}`,
    agreements: (pk: string) => `/api/locationGroups/${pk}/agreements`,
    inductions: (pk: string) => `/api/locationGroups/${pk}/inductions`,
  },
  visitorAgreement: {
    get: "/api/visitorAgreement",
    getDefault: "/api/defaultVisitorAgreement",
    update: "/api/company/updateVisitorAgreement",
    uploadPdf: "/api/company/upload-pdf",
  },
  agreements: {
    list: "/api/agreements",
    create: "/api/agreements",
    createPdf: "/api/agreements/pdf",
    get: (pk: string) => `/api/agreements/${pk}`,
    update: (pk: string) => `/api/agreements/${pk}`,
    updatePdf: (pk: string) => `/api/agreements/pdf/${pk}`,
    signedAgreement: (pk: string) => `/api/visits/${pk}/signedAgreement`,
  },
  resources: {
    list: "/api/resources",
    create: "/api/resources",
    update: (pk: string) => `/api/resources/${pk}`,
    delete: (pk: string) => `/api/resources/${pk}`,
    listScreensavers: "/api/screensavers",
    createScreensavers: "/api/screensavers",
    reorderScreensavers: "/api/screensavers/reorder",
    updateScreensaver: (id: string) => `/api/screensavers/${id}`,
    deleteScreensaver: (id: string) => `/api/screensavers/${id}`,
  },
  statuses: {
    create: "/api/statuses/",
    list: "/api/statuses/",
    delete: (id: string) => `/api/statuses/${id}`,
    update: (id: string) => `/api/statuses/${id}`,
  },
  authUser: {
    get: "/auth/user",
    update: (pk: string) => `/auth/users/${pk}`,
    updateRole: (pk: string) => `/auth/users/${pk}/role`,
    resendInvite: "/auth/users/resend",
  },
  company: {
    get: (pk = "me") => `/companies/${pk}`,
    create: (pk: string) => `/companies/${pk}`,
    features: (pk = "me") => `/companies/${pk}/features`,
  },
  deviceLogos: {
    list: "/api/gadget-logos",
    update: (id: string) => `/api/gadget-logos/${id}`,
    create: "/api/gadget-logos",
    delete: (id: string) => `/api/gadget-logos/${id}`,
  },
  devices: {
    get: (id: string) => `/api/gadgets/${id}`,
    list: "/api/gadgets",
    upsert: (id: string) => `/devices/${id}`,
  },
  movements: {
    get: "/companies/me/movement-fields",
    order: "/companies/me/movement-fields/order",
    upsert: (pk: string) => `/companies/me/movement-fields/${pk}`,
    delete: (pk: string) => `/companies/me/movement-fields/${pk}`,
  },
  integrations: {
    list: "/swack/integrations",
    sync: "/integrations/sync",
    syncTrigger: "/integrations/syncTrigger",
    groups: "/integrations/azure/groups",
    groupIdCheck: "/integrations/azure/groupidcheck",
    config: "/integrations/config/me",
    locations: "/integrations/azure/locations",
    locationsTrigger: "/integrations/azure/locationsTrigger",
    windowsSyncAgent: "/download/ad-sync-agent/windows",
  },
  slack: {
    install: "/swack/oauth/authorization-code",
    uninstall: "/swack",
    getChannels: "/swack/channels",
    patchAssignment: (channelId: string) => `/swack/channels/${channelId}`,
  },
  teams: {
    associate: "/swack/teams/associate",
    tenant: "/swack/teams",
    getTeams: "/swack/teams",
    getAssignments: "/swack/teams/assignments/list",
    patchAssignment(locationPk: string) {
      return `/swack/teams/assign/${locationPk}`;
    },
    deleteAssignment(locationPk: string, channelId: string) {
      return `/swack/teams/assign/${locationPk}/${channelId}`;
    },
    uninstall: "/swack/teams/integration",
  },
  hubspot: {
    form: (portalId: string, formGuid: string) =>
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
  },
};
