import { config } from "@/config/envConfig";
import useApiStore from "@/stores/api";
import useAuthStore from "@/stores/auth";
import { formatFullName } from "@/utils/string";
import { watch, ref, computed } from "vue";
import { useLanguage } from "./lang";

const intercomReady = ref(false);
const intercomBooted = ref(false);

export const useIntercom = () => {
  const authStore = useAuthStore();
  const apiStore = useApiStore();

  watch(
    () => [authStore.authUser, intercomReady.value],
    ([user, ready]) => {
      if (!ready) return;
      bootIntercom(user);
    }
  );
  const { selectedLanguage } = useLanguage();

  const intercomSupported = computed(() => {
    const intercomUnsupportedLocales =
      config.INTERCOM_UNSUPPORTED_LOCALES.toLowerCase().split(",");

    const unsupported = intercomUnsupportedLocales.find(
      (code) => code === selectedLanguage.value?.code.toLowerCase()
    );

    return unsupported === undefined;
  });

  watch(selectedLanguage, () => {
    if (intercomSupported.value && !intercomBooted.value) {
      bootIntercom(authStore.authUser);
      return;
    }

    if (!intercomSupported.value && intercomBooted.value) {
      window.Intercom("shutdown");
      intercomBooted.value = false;
    }
  });

  const setup = () => {
    const APP_ID = config.INTERCOM_ID;
    if (!APP_ID) return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const w = window as any;
    const ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      const d = document;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const i: any = function () {
        // eslint-disable-next-line prefer-rest-params
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args: unknown) {
        i.q.push(args);
      };
      w.Intercom = i;
      const l = function () {
        const s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/" + APP_ID;
        const x = d.getElementsByTagName("script")[0];
        x.parentNode?.insertBefore(s, x);
        s.onload = () => (intercomReady.value = true);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  };

  const bootAnonymousIntercom = () => {
    if (window.Intercom && config.INTERCOM_ID) {
      if (intercomBooted.value) window.Intercom("shutdown");
      window.Intercom("boot", {
        app_id: config.INTERCOM_ID,
      });
      intercomBooted.value = true;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const bootUserIntercom = (user: any) => {
    const regionCode = apiStore.regionCode ? `${apiStore.regionCode} ` : "";

    let settings: Record<string, unknown> = {
      app_id: config.INTERCOM_ID,
    };

    settings = {
      ...settings,
      user_id: `${regionCode.toUpperCase()}${user.id}`,
      email: user.email,
      name: formatFullName(user.firstName, user.lastName),
      swipedon_role: user.roles ?? "",
      user_hash: user.hash,
      company: {
        id: `${regionCode.toUpperCase()}${user.companyId}`,
      },
    };

    window.Intercom(intercomBooted.value ? "update" : "boot", settings);
    intercomBooted.value = true;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const bootIntercom = (user?: any) => {
    if (intercomReady.value && config.INTERCOM_ID) {
      if (!intercomSupported.value) return;

      if (!user) {
        bootAnonymousIntercom();
        return;
      }
      bootUserIntercom(user);
    }
  };

  const showIntercom = () => window.Intercom && window.Intercom("show");

  const toggleIntercom = (visible: boolean) => {
    window.Intercom("update", { hide_default_launcher: !visible });
    window.Intercom("hide");
  };

  return { setup, showIntercom, toggleIntercom };
};
