import { useQuery } from "@tanstack/vue-query";
import { authKeys } from "./keys";
import { ApiClient } from "../zoolander";
import { computed, type Ref } from "vue";
import { endpoints } from "@/endpoints";
import useApiStore from "@/stores/api";
import axios from "axios";

export const useSsoConfig = (
  email: Ref<string | undefined>,
  enabled: Ref<boolean>
) => {
  const defaultResponse = { microsoft: false, google: false };
  return useQuery({
    queryKey: authKeys.sso(email).queryKey,
    queryFn: async () => {
      if (email.value === undefined) return defaultResponse;
      try {
        const response = await new ApiClient({
          withCredentials: false,
        }).getSSOConfiguration({
          email: email.value,
        });
        return response.data;
      } catch (err) {
        return defaultResponse;
      }
    },
    enabled: computed(() => email.value !== undefined && enabled.value),
    staleTime: 1000 * 60,
  });
};

export const useRegionConfig = (email: Ref<string | undefined>) => {
  const apiStore = useApiStore();
  return useQuery({
    queryKey: authKeys.regionConfig(email).queryKey,
    queryFn: async () => {
      const { data: response } = await axios.get(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        apiStore.getEndpoint("rest", endpoints.region(email.value!)),
        {
          withCredentials: false,
        }
      );

      apiStore.setRegionConfig(response.data);
      return response.data;
    },
    enabled: computed(() => email.value !== undefined),
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};
