<script setup lang="ts">
import CoreButton from "@/components/common/CoreButton/CoreButton.vue";
import CoreSlideTransition from "@/components/common/CoreSlideTransition/CoreSlideTransition.vue";
import { useLanguage } from "@/composables/lang";
import { useI18n } from "vue-i18n";
import { ElRadio, ElRadioGroup } from "element-plus";
import { SUPPORTED_LOCALES, type Language } from "@/config/i18n";

export interface Props {
  open: boolean;
}
const { t } = useI18n();
defineProps<Props>();
const emit = defineEmits<{
  (e: "toggle"): void;
}>();

const { changeLanguage, selectedLanguage, isBrowserLanguage } = useLanguage();
const languageDescription = (language: Language) =>
  isBrowserLanguage(language.code)
    ? `Browser (${language.description})`
    : language.description;
</script>
<template>
  <div class="container">
    <CoreButton
      text
      type="primary"
      :icon="['fal', open ? 'chevron-down' : 'chevron-right']"
      @click="emit('toggle')"
    >
      {{ t("nav.profile.buttons.language") }}
    </CoreButton>

    <span v-if="selectedLanguage">{{
      languageDescription(selectedLanguage)
    }}</span>
  </div>
  <CoreSlideTransition>
    <div
      v-if="open"
      class="language-select"
    >
      <el-radio-group
        v-for="language in SUPPORTED_LOCALES"
        :key="language.code"
        :model-value="selectedLanguage?.code"
      >
        <el-radio
          :label="language.code"
          size="large"
          @change="changeLanguage(language.code)"
          >{{ languageDescription(language) }}</el-radio
        >
      </el-radio-group>
    </div>
  </CoreSlideTransition>
</template>

<style scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.language-select {
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
}
</style>
