<script setup lang="ts">
import { useCssVar } from "@vueuse/core";
import { computed, reactive, ref } from "vue";
import {
  type FormInstance,
  ElForm,
  ElFormItem,
  ElInput,
  type FormRules,
} from "element-plus";
import CoreButton from "@/components/common/CoreButton/CoreButton.vue";
import { useI18n } from "vue-i18n";

export interface Props {
  isLoading: boolean;
  error?: string;
}

defineProps<Props>();
const buttonColour = useCssVar("--so-color-secondary");
const form = ref<FormInstance>();
const username = ref("");

const { t } = useI18n();
const emit = defineEmits<{
  submit: [username: string];
}>();

const formRules = computed<FormRules>(() => ({
  username: [
    {
      required: true,
      message: t("forms.login.email.required"),
      trigger: "blur",
    },
    {
      type: "email",
      message: t("forms.login.email.invalid"),
      trigger: "blur",
    },
  ],
}));

const submit = async () => {
  try {
    await form.value?.validate();
    emit("submit", username.value);
  } catch (err) {
    return;
  }
};
const model = reactive({ username });
</script>
<template>
  <el-form
    ref="form"
    hide-required-asterisk
    :model="model"
    :rules="formRules"
    label-position="top"
    class="form"
    @submit.prevent="submit"
  >
    <el-form-item
      :error="error"
      prop="username"
      :label="$t('forms.login.email.label')"
    >
      <el-input
        v-model="username"
        class="login-input"
        :disabled="isLoading"
        type="text"
        auto-complete="off"
      />
    </el-form-item>
    <el-form-item>
      <CoreButton
        :loading="isLoading"
        class="login-button"
        :color="buttonColour"
        @click="submit"
      >
        {{ $t("forms.login.next") }}
      </CoreButton>
    </el-form-item>
  </el-form>
</template>
<style lang="css" scoped>
.login-input :deep(.so-input__inner) {
  min-width: 360px;
}
.form {
  width: 100%;
  max-width: 400px;
}
</style>
