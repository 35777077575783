<script setup lang="ts">
import { ElPopover, type Options } from "element-plus";
import { computed, ref, type CSSProperties } from "vue";
import { useI18n } from "vue-i18n";
import CoreAvatar from "@/components/common/CoreAvatar/CoreAvatar.vue";
import CoreButton from "@/components/common/CoreButton/CoreButton.vue";
import CoreLink from "@/components/common/CoreLink/CoreLink.vue";
import DarkModeToggle from "@/components/widgets/DarkModeToggle.vue";
import useAuthStore from "@/stores/auth";
import ChangeLanguageForm from "./ChangeLanguageForm.vue";
import useApiStore from "@/stores/api";
import { useWindowSize } from "@vueuse/core";
import { useRouter } from "vue-router";
import { debounce } from "lodash";

const defaultPopperStyle = (): CSSProperties => ({
  maxHeight: "auto",
  overflowY: "auto",
});

const { t } = useI18n();
const authStore = useAuthStore();
const apiStore = useApiStore();
const router = useRouter();

const showPopover = ref(false);
const changeLanguageExpanded = ref(false);
const popperStyle = ref<CSSProperties>(defaultPopperStyle());
const { height: windowHeight } = useWindowSize();

const mfaOptions = computed(() => [
  { label: t("nav.profile.mutliFactorAuth.options.off"), code: "NOMFA" },
  {
    label: t("nav.profile.mutliFactorAuth.options.totp"),
    code: "SOFTWARE_TOKEN_MFA",
  },
]);

const profileImage = computed(() =>
  authStore.authUser?.photoKey
    ? apiStore.getEndpoint("platform", `/mm/${authStore.authUser?.photoKey}`)
    : undefined
);

const calculatePopoverHeight = debounce((popper: HTMLElement) => {
  const { height, y } = popper.getBoundingClientRect();

  if (height + y > windowHeight.value || height + y < windowHeight.value) {
    popperStyle.value = {
      maxHeight: `${windowHeight.value - y}px`,
      overflowY: "scroll",
    };
  }
}, 100);

const popperOptions = ref<Partial<Options>>({
  modifiers: [
    {
      name: "resize",
      enabled: true,
      phase: "main",
      effect: (arg) => {
        const resizeObserver = new ResizeObserver(() =>
          calculatePopoverHeight(arg.state.elements.popper)
        );

        resizeObserver.observe(arg.state.elements.popper);

        return () => {
          resizeObserver.disconnect();
          popperStyle.value = defaultPopperStyle();
        };
      },
      fn: (arg) => {
        calculatePopoverHeight(arg.state.elements.popper);
      },
    },
  ],
});

const logout = async () => {
  await authStore.logout();
  router.push({ name: "login" });
};

const hide = () => {
  changeLanguageExpanded.value = false;
  showPopover.value = false;
};

const findMfaCode = (optionToFind: string) => {
  return mfaOptions.value.find(
    (optionToSearch) => optionToSearch.code === optionToFind
  );
};
</script>

<template>
  <el-popover
    v-model:visible="showPopover"
    placement="bottom-end"
    :width="300"
    trigger="click"
    :persistent="false"
    :hide-after="0"
    :show-arrow="false"
    popper-class="profile-popover"
    :popper-style="popperStyle"
    :popper-options="popperOptions"
    @before-leave="hide"
  >
    <template #reference>
      <div class="popover-trigger">
        <CoreAvatar
          size="small"
          :src="profileImage"
        />
        <font-awesome-icon :icon="['fal', 'chevron-down']" />
      </div>
    </template>

    <div class="popover-container">
      <div class="profile">
        <CoreAvatar
          class="avatar"
          :src="profileImage"
        />
        <div class="profile-details">
          <strong class="username break--line-word">{{
            authStore.userName
          }}</strong>
          <span class="username break--line-word">
            {{ authStore.authUser?.companyName }}
          </span>
          <CoreLink
            class="edit-profile-link"
            :underline="false"
            type="primary"
            :route="{
              name: 'employees-directory',
              params: { id: authStore.authUser?.pk },
            }"
            @click="showPopover = false"
          >
            {{ t("nav.profile.viewProfile") }}
          </CoreLink>
        </div>
      </div>

      <div class="popover-section">
        <DarkModeToggle />
      </div>
      <div class="popover-section">
        <ChangeLanguageForm
          :open="changeLanguageExpanded"
          @toggle="changeLanguageExpanded = !changeLanguageExpanded"
        />
      </div>
      <div class="popover-section">
        <h4>{{ t("nav.profile.securityHeader") }}</h4>
        <CoreButton
          text
          type="primary"
          @click="authStore.toggleChangePasswordDialogVisible"
        >
          {{ t("nav.profile.buttons.changePassword") }}
        </CoreButton>

        <div class="container">
          <CoreButton
            text
            type="primary"
            @click="authStore.toggleMfaDialogVisible"
          >
            {{ t("nav.profile.buttons.mfa") }}</CoreButton
          >
          <span v-if="authStore.mfaPreference">
            {{ findMfaCode(authStore.mfaPreference)?.label }}
          </span>
        </div>
      </div>
      <CoreButton
        plain
        class="logout-button"
        type="primary"
        :icon="['fal', 'power-off']"
        @click="logout"
      >
        {{ t("nav.logOut") }}
      </CoreButton>
    </div>
  </el-popover>
</template>

<style scoped lang="scss">
.popover-trigger {
  display: flex;
  align-items: center;
  color: var(--so-menu-text-color);
  justify-content: space-between;
  margin: 0 30px 0 20px;
  width: 70px;

  &:hover {
    cursor: pointer;
  }

  &:hover svg {
    color: var(--so-color-primary);
  }
}

.popover-container {
  padding: 10px 15px;
}

.profile {
  align-items: center;
  display: flex;
  margin-bottom: 15px;
}

.avatar {
  flex-shrink: 0;
}

.username {
  text-align: left;
}

.profile-details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 20px;
}

:deep(.edit-profile-link) {
  justify-content: flex-start;
}

:deep(.edit-profile-link.is-disabled) {
  text-decoration: line-through;
  cursor: default;
  opacity: 0.5;
}

:deep(.edit-profile-link.is-disabled):hover {
  opacity: 0.6;
}

.popover-section {
  border-bottom: 1px solid var(--so-border-color);
  border-top: 1px solid var(--so-border-color);
  padding: 7px 0;
}

.popover-section + .popover-section {
  border-top-width: 0;
}

.logout-button {
  margin-top: 20px;
  width: 100%;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

h4 {
  height: 23px;
  font-size: 0.75rem;
  color: var(--so-text-color-secondary);
  text-transform: uppercase;
}
</style>

<style lang="scss">
.profile-popover.so-popover::-webkit-scrollbar {
  display: none;
}
</style>
