import { createQueryKeys } from "@lukemorales/query-key-factory";
import { tags, type DeepMaybeRef, type MaybeRef } from "@/api/common";

import type { PreregistrationsParams } from "@/api/preregistrations";
import type { ScreensaversParams } from "@/api/screensavers";
import type { VisitorCategoriesParams } from "@/api/visitorCategories";
import type { VisitorFieldsParams } from "@/api/visitorFields";
import type { EmployeeMovementTrendsParams } from "../employeeMovements";
import type { VisitTrendsParams } from "../visits";
import type { EvacuationsParam } from "../evacuation";
import type { EmployeeArchiveParams } from "../employeeArchive";
import type { EmployeeParams } from "../employees";
import type { DeviceParams } from "../devices";
import type { VisitorsParams } from "../visitors";

export const preregistrationsKeys = createQueryKeys(
  tags.platform.preregistrations,
  {
    list: (apiArgs?: DeepMaybeRef<PreregistrationsParams>) => [{ apiArgs }],
    byId: (preregistrationId: MaybeRef<string>) => [{ preregistrationId }],
  }
);

export const agreementsKeys = createQueryKeys(tags.platform.agreements, {
  list: () => [{}],
  byId: (agreementId: MaybeRef<string>) => [{ agreementId }],
  byPdfPath: (pdfPath: MaybeRef<string | undefined>) => [{ pdfPath }],
});

export const visitorFieldsKeys = createQueryKeys(tags.platform.visitorFields, {
  list: (apiArgs?: DeepMaybeRef<VisitorFieldsParams>) => [{ apiArgs }],
  byId: (visitorFieldId: MaybeRef<string>) => [{ visitorFieldId }],
});

export const visitorCategoriesKeys = createQueryKeys(
  tags.platform.visitorCategories,
  {
    list: (apiArgs?: DeepMaybeRef<VisitorCategoriesParams>) => [{ apiArgs }],
  }
);

export const screensaversKeys = createQueryKeys(tags.platform.screensavers, {
  list: (apiArgs?: DeepMaybeRef<ScreensaversParams>) => [{ apiArgs }],
});

export const employeeMovementsKeys = createQueryKeys(
  tags.platform.employeeMovements,
  {
    trends: (apiArgs?: DeepMaybeRef<EmployeeMovementTrendsParams>) => [
      { apiArgs },
    ],
  }
);

export const evacuationKeys = createQueryKeys(tags.platform.evacuation, {
  list: (
    apiArgs?: DeepMaybeRef<EvacuationsParam>,
    selectedLocationPk?: MaybeRef<string | undefined>,
    selectedLocationGroupPk?: MaybeRef<string | undefined>
  ) => [{ apiArgs }, selectedLocationPk, selectedLocationGroupPk],
});

export const employeeArchiveKeys = createQueryKeys(
  tags.platform.employeeArchive,
  {
    list: (apiArgs?: DeepMaybeRef<EmployeeArchiveParams>) => [{ apiArgs }],
  }
);

export const employeesKeys = createQueryKeys(tags.platform.employees, {
  list: (apiArgs?: DeepMaybeRef<EmployeeParams>) => [{ apiArgs }],
  byId: (employeeId: MaybeRef<string>) => [{ employeeId }],
});

export const visitorsKeys = createQueryKeys(tags.platform.visitors, {
  list: (apiArgs?: DeepMaybeRef<VisitorsParams>) => [{ apiArgs }],
  byId: (visitorId: MaybeRef<string>) => [{ visitorId }],
});

export const visitsKeys = createQueryKeys(tags.platform.visits, {
  trends: (apiArgs?: DeepMaybeRef<VisitTrendsParams>) => [{ apiArgs }],
});

export const devicesKeys = createQueryKeys(tags.platform.devices, {
  list: (apiArgs?: DeepMaybeRef<DeviceParams>) => [{ apiArgs }],
  byId: (deviceId: MaybeRef<string>) => [{ deviceId }],
});

export const deviceLogoKeys = createQueryKeys(tags.platform.deviceLogos, {
  list: (apiArgs?: DeepMaybeRef<{ locationGroupId?: string }>) => [{ apiArgs }],
});

export const locationKeys = createQueryKeys(tags.platform.locations, {
  byId: (locationPk: MaybeRef<string>) => [{ locationPk }],
  list: () => [{}],
});

export const locationGroupKeys = createQueryKeys(tags.platform.locationGroups, {
  byId: (locationGroupPk: MaybeRef<string>) => [{ locationGroupPk }],
  all: [{}],
});

export const companyKeys = createQueryKeys(tags.platform.company, {
  byId: (companyId: MaybeRef<number | undefined>) => [{ companyId }],
  byLocationGroupId: (locationGroupId: MaybeRef<string | undefined>) => [
    { locationGroupId },
  ],
});
