/* tslint:disable */
/* eslint-disable */

import handlers from "@/config/axios/globalHandler";
import useApiStore from "@/stores/api";
import type { ServiceType } from "@/stores/api/types";
import axios, { AxiosError } from "axios";
import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CancelToken,
} from "axios";
import type { ListTransactionResponsePagedApiResponse } from "./transaction";
import { endpoints } from "@/endpoints";
import type { Exception } from "@sentry/vue";
import type { GetBundlesResponse, GetCustomerResponse } from "./customer";
import type { GetCompanyResponse } from "./companies";
import type { GetPlansResponse } from "./plans";
import type { OrderPreviewRequest, OrderPreviewResponse } from "./billing";
import { useAuth } from "@/composables/auth";
export class Client {
  private instance: AxiosInstance;
  private baseUrl: string;
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined =
    undefined;

  constructor(baseUrl?: string, instance?: AxiosInstance) {
    this.instance = instance ? instance : axios.create();
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "";
  }

  /**
   * Get Customer record
   * @return Success
   */
  getCustomer(): Promise<GetCustomerResponse> {
    const { isSuperAdmin, authUser } = useAuth();
    const companyPk =
      isSuperAdmin.value && authUser.value?.companyPk
        ? authUser.value.companyPk
        : "me";

    const url = this.baseUrl + endpoints.customer(companyPk);

    const request: AxiosRequestConfig = {
      method: "GET",
      url,
    };

    return this.processRequest<GetCustomerResponse>(request);
  }

  getBundles(plan: string): Promise<GetBundlesResponse> {
    const { isSuperAdmin, authUser } = useAuth();
    const companyPk =
      isSuperAdmin.value && authUser.value?.companyPk
        ? authUser.value.companyPk
        : "me";

    const url = this.baseUrl + endpoints.bundles(companyPk, plan);

    const request: AxiosRequestConfig = {
      method: "GET",
      url,
    };

    return this.processRequest<GetBundlesResponse>(request);
  }

  getPlans(): Promise<GetPlansResponse> {
    const url = this.baseUrl + endpoints.plans;

    const request: AxiosRequestConfig = {
      method: "GET",
      url,
    };

    return this.processRequest<GetPlansResponse>(request);
  }

  getOrderPreview(payload: OrderPreviewRequest): Promise<OrderPreviewResponse> {
    const url = this.baseUrl + endpoints.preview;

    const request: AxiosRequestConfig = {
      method: "POST",
      url,
      data: payload,
    };

    return this.processRequest<OrderPreviewResponse>(request);
  }

  /**
   * Get Company record
   * @return Success
   */
  getCompany(): Promise<GetCompanyResponse> {
    const url = this.baseUrl + endpoints.company.get();

    const request: AxiosRequestConfig = {
      method: "GET",
      url,
    };

    return this.processRequest<GetCompanyResponse>(request);
  }

  /**
   * Get List of Account Transactions
   * @param page (optional)
   * @param sort (optional)
   * @return Success
   */
  listTransactions(
    options?: {
      page?: number | undefined;
      sort?: string | undefined;
    },
    cancelToken?: CancelToken | undefined
  ): Promise<ListTransactionResponsePagedApiResponse> {
    let url_ = this.baseUrl + endpoints.transaction;

    let params = "";

    if (options?.page === null)
      throw new Error("The parameter 'page' cannot be null.");
    else if (options?.page !== undefined)
      params += "page=" + encodeURIComponent("" + options?.page) + "&";

    if (options?.sort === null)
      throw new Error("The parameter 'sort' cannot be null.");
    else if (options?.sort !== undefined && options?.sort.length > 0)
      params += "sort=" + encodeURIComponent("" + options?.sort) + "&";

    if (params.length > 0 && params[0] !== "?") params = `?${params}`;

    url_ += params;

    url_ = url_.replace(/[?&]$/, "");

    const request: AxiosRequestConfig = {
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
      cancelToken,
    };

    return this.processRequest<ListTransactionResponsePagedApiResponse>(
      request
    );
  }

  protected processRequest<T>(request: AxiosRequestConfig): Promise<T> {
    return this.instance
      .request(request)
      .catch((error: Exception) => {
        if (isAxiosError(error) && error.response) {
          return error.response;
        } else {
          throw error;
        }
      })
      .then((response: AxiosResponse) => {
        return this.processResponse<T>(response);
      });
  }

  protected processResponse<T>(response: AxiosResponse): Promise<T> {
    const status = response.status;

    if (status === 200) {
      const result = JSON.parse(response.data);
      return Promise.resolve<T>(result);
    } else if (status !== 200 && status !== 204) {
      handleError(response);
    }
    return Promise.resolve<T>(null as any);
  }
}

function isAxiosError(obj: any | undefined): obj is AxiosError {
  return obj && obj.isAxiosError === true;
}

export class ApiException extends Error {
  override message: string;
  status: number;
  response: string;
  headers: { [key: string]: any };

  constructor(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any }
  ) {
    super();

    this.message = message;
    this.status = status;
    this.response = response;
    this.headers = headers;
  }

  protected isApiException = true;

  static isApiException(obj: any): obj is ApiException {
    return obj.isApiException === true;
  }
}

function handleError(response: AxiosResponse) {
  const _responseText = response.data;

  const _headers: any = {};

  if (response.headers && typeof response.headers === "object") {
    for (const k in response.headers) {
      if (response.headers.hasOwnProperty(k)) {
        _headers[k] = response.headers[k];
      }
    }
  }

  throw new ApiException(
    "An unexpected server error occurred.",
    response.status,
    _responseText,
    _headers
  );
}

const restAxiosInstance = axios.create({
  transformResponse: (data) => data,
});
restAxiosInstance.defaults.headers.common.accept =
  "application/vnd.swipedon+json; version=2";
restAxiosInstance.interceptors.request.use(handlers?.preRequest);
restAxiosInstance.interceptors.response.use(undefined, handlers?.errorResponse);

export class ApiClient extends Client {
  constructor(service: ServiceType = "rest") {
    const store = useApiStore();
    const endpoint = store.getEndpoint(service);
    super(endpoint, restAxiosInstance);
  }
}
