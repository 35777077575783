import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  tags,
  type DeepMaybeRef,
  type QueryStringParams,
  type MaybeRef,
} from "../common";
import type {
  ListAvailableSpaceResourcesArgs,
  ListSpaceResourcesArgs,
  ListSpaceResourcesWithBookingsArgs,
} from "../spaceResources";
import type { ListBookingsArgs } from "../bookings";
import type { VisitorInductionsFilters } from "@/api/visitors";
import type { BookingsInsightsParams } from "../insights/types";
import type { ListPlansArgs } from "../plans/types";
import type { ListRoomsArgs } from "../rooms";
import type { GetCurrentInOutCountsRequest } from "../movements/types";

export const spacesKeys = createQueryKeys(tags.zoolander.spaces, {
  list: (
    filters?: DeepMaybeRef<QueryStringParams>,
    selectedLocationGroupPk?: MaybeRef<string | undefined>
  ) => [{ filters }, selectedLocationGroupPk],
  byId: (spaceId: MaybeRef<string | undefined>, expand?: MaybeRef<string>) => [
    { spaceId },
    { expand },
  ],
});

export const spaceFeaturesKeys = createQueryKeys(tags.zoolander.features, {
  list: (filters?: DeepMaybeRef<QueryStringParams>) => [{ filters }],
  byId: (featureId: MaybeRef<string>) => [{ featureId }],
});

export const spaceResourcesKeys = createQueryKeys(
  tags.zoolander.spaceResources,
  {
    count: (
      filters?: DeepMaybeRef<{
        spaceId?: string;
        spaceZoneId?: string;
      }>
    ) => [{ filters }],
    byId: (spaceId: MaybeRef<string | undefined>) => [{ spaceId }],
    list: (filters: DeepMaybeRef<ListSpaceResourcesArgs>) => [{ filters }],
    listInfinite: (filters: DeepMaybeRef<ListSpaceResourcesArgs>) => [
      { filters },
    ],
    listWithBookings: (
      filters: DeepMaybeRef<ListSpaceResourcesWithBookingsArgs>
    ) => [{ filters }],
    available: (filters: DeepMaybeRef<ListAvailableSpaceResourcesArgs>) => [
      { filters },
    ],
    features: (spaceResourceId: MaybeRef<string | undefined>) => [
      { spaceResourceId },
    ],
    zones: (spaceResourceId: MaybeRef<string | undefined>) => [
      { spaceResourceId },
    ],
    mapImage: (spaceResourceId: MaybeRef<string | undefined>) => [
      { spaceResourceId },
    ],
  }
);

export const spaceResourceRestrictionsKeys = createQueryKeys(
  tags.zoolander.spaceResourceRestrictions,
  {
    byId: (spaceResourceId: MaybeRef<string | undefined>) => [
      { spaceResourceId },
    ],
  }
);

export const inductionsKeys = createQueryKeys(tags.zoolander.inductions, {
  list: (filters?: DeepMaybeRef<QueryStringParams>) => [{ filters }],
  byId: (inductionid: MaybeRef<string>) => [{ inductionid }],
});

export const visitorInductionsKeys = createQueryKeys(
  tags.zoolander.visitorInductions,
  {
    list: (filters?: DeepMaybeRef<VisitorInductionsFilters>) => [{ filters }],
  }
);

export const bookingKeys = createQueryKeys(tags.zoolander.bookings, {
  byId: (bookingId: MaybeRef<string>) => [{ bookingId }],
  count: (spaceId: MaybeRef<string>) => [{ spaceId }],
  list: (filters: DeepMaybeRef<ListBookingsArgs>) => [{ filters }],
});

export const spaceCategoryKeys = createQueryKeys(
  tags.zoolander.spaceCategories,
  {
    byId: (
      spaceCategoryId: MaybeRef<string>,
      locationGroupId?: MaybeRef<string | undefined>
    ) => [spaceCategoryId, locationGroupId],
    list: (filters?: DeepMaybeRef<QueryStringParams>) => [{ filters }],
    listInfinite: () => [{}],
  }
);

export const roomCategoryKeys = createQueryKeys(tags.zoolander.roomCategories, {
  byId: (
    spaceCategoryId: MaybeRef<string>,
    locationGroupId?: MaybeRef<string | undefined>
  ) => [spaceCategoryId, locationGroupId],
});

export const visitorBrandingKeys = createQueryKeys(tags.platform.branding, {
  list: (locationGroupPk: MaybeRef<string | undefined>) => [
    { locationGroupPk },
  ],
});

export const spaceZonesKeys = createQueryKeys(tags.zoolander.zones, {
  byId: (zoneId: MaybeRef<string>) => [{ zoneId }],
  list: (spaceId: MaybeRef<string>) => [{ spaceId }],
});

export const subscriptionKeys = createQueryKeys(tags.zoolander.subscription, {
  get: () => [{}],
});

export const planKeys = createQueryKeys(tags.zoolander.plan, {
  byId: (planId: MaybeRef<string>) => [{ planId }],
  list: (filter: MaybeRef<ListPlansArgs>) => [{ filter }],
});

export const insightsKeys = createQueryKeys(tags.zoolander.insights, {
  bookings: (filters?: DeepMaybeRef<BookingsInsightsParams>) => [{ filters }],
});

export const preregistrationInductionsKeys = createQueryKeys(
  tags.zoolander.preregistrationInductions,
  {
    listInduction: (preregistrationId: MaybeRef<string>) => [
      { preregistrationId },
    ],
  }
);

export const roomKeys = createQueryKeys(tags.zoolander.rooms, {
  list: (filters: DeepMaybeRef<ListRoomsArgs>) => [{ filters }],
  byId: (spaceResourceId: MaybeRef<string | undefined>) => [
    { spaceResourceId },
  ],
});

export const currentInOutCountKeys = createQueryKeys(
  tags.zoolander.employeeCounts,
  {
    count: (filters: DeepMaybeRef<GetCurrentInOutCountsRequest>) => [
      { filters },
    ],
  }
);
