<script setup lang="ts">
import { ElLink } from "element-plus";
import { RouterLink, type RouteLocationRaw } from "vue-router";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";

export type ElLinkProps = InstanceType<typeof ElLink>["$props"];
export interface Props extends /* @vue-ignore */ ElLinkProps {
  route?: RouteLocationRaw;
  icon?: IconProp;
}

const props = defineProps<Props>();
</script>

<template>
  <router-link
    v-if="route"
    #default="{ navigate, href }"
    custom
    :to="route"
  >
    <el-link
      v-bind="{ ...props, ...$attrs }"
      :href="href"
      @click="navigate"
    >
      <slot />
      <template
        v-if="icon"
        #icon
      >
        <font-awesome-icon :icon="icon" />
      </template>
    </el-link>
  </router-link>
  <el-link
    v-else
    v-bind="{ ...props, ...$attrs }"
  >
    <slot />
    <template
      v-if="icon"
      #icon
    >
      <font-awesome-icon :icon="icon" />
    </template>
  </el-link>
</template>
