/* eslint-disable @typescript-eslint/no-explicit-any */
import { Auth } from "@aws-amplify/auth";
import { defineStore } from "pinia";
import { getCookie, removeCookie, setCookie } from "tiny-cookie";
import { config } from "@/config/envConfig";
import { useRootStore } from "..";
import axios from "axios";
import useApiStore from "../api";
import { endpoints } from "@/endpoints";
import router from "@/router";
import { dayjs } from "element-plus";
import { useQueryClient } from "@tanstack/vue-query";
import { computed, ref, type Ref } from "vue";
import useAccountStore from "../account";
import type { AuthUser, GetAuthUserApiResponse } from "@/api/auth/types";
import { useLocationStore } from "../location";
import type { LocationQuery } from "vue-router";
import { useAuth } from "@/composables/auth";
import { mapAuthUser } from "@/api/auth/utils";

const LOCATION_MANAGER = "Location Manager";
const COMPANY_ADMIN = "Company Admin";
const SUPER_ADMIN = "Super Admin";

const supportedRoles = [LOCATION_MANAGER, COMPANY_ADMIN, SUPER_ADMIN];

export const useAuthStore = defineStore("auth", () => {
  const queryClient = useQueryClient();
  const { isSuperAdmin } = useAuth();

  const loadedAuth = ref(false);
  const authUser = ref<AuthUser | null>(null) as Ref<AuthUser | null>;
  const mfaPreference = ref("NOMFA");
  const mfaDialogVisible = ref(false);
  const mfaSetupRequired = ref(false);
  const changePasswordDialogVisible = ref(false);

  const userEmail = computed(() => authUser.value?.email ?? "");

  const userName = computed(() =>
    authUser.value
      ? `${authUser.value.firstName} ${authUser.value.lastName}`
      : ""
  );

  const isTeamsNew = computed(
    () => typeof authUser.value?.settings.teams_is_new === "undefined"
  );

  const setMfaPreference = (value: string) => {
    mfaPreference.value = value;
  };
  const toggleMfaDialogVisible = () => {
    mfaDialogVisible.value = !mfaDialogVisible.value;
  };
  const toggleChangePasswordDialogVisible = () => {
    changePasswordDialogVisible.value = !changePasswordDialogVisible.value;
  };

  /**
   * Update the authenticated users data in the store and dispatch to redirect
   *  as required.
   *
   * If the user is a superadmin and there's a 'superadmin-query' cookie then load the company and region from
   * that cookie.
   */
  const updateAuthUser = async ({ to = null }: any) => {
    const apiStore = useApiStore();
    const rootStore = useRootStore();
    try {
      const secureCookies = process.env.NODE_ENV !== "test";

      const {
        data: { data: userData },
      } = await axios.get<GetAuthUserApiResponse>(
        apiStore.getEndpoint("rest", endpoints.authUser.get)
      );

      const user = mapAuthUser(userData);

      authUser.value = user;

      if (!supportedRoles.includes(user.roles)) {
        logout();
      }

      if (isSuperAdmin.value) {
        await loadCompanyFromSuperAdminCookie();
      } else {
        setCookie("company", user.companyPk, {
          secure: secureCookies,
          expires: "1M",
        });
        setCookie("companyId", user.companyId, {
          secure: secureCookies,
          expires: "1M",
        });
      }

      const cognitoUser = await Auth.currentAuthenticatedUser();
      loadMfaPreference(cognitoUser);
      const route = to ?? router.currentRoute;

      if (route?.value && route?.value.name === "login") {
        rootStore.setRedirectLoading(true);
        router.push({ name: "home" });
      }
    } catch (error: any) {
      rootStore.setLoading(false);
      if (!error.handledGlobally) {
        rootStore.handleException({ error });
      }
    }
  };

  const login = async ({
    email,
    password,
  }: {
    email: string;
    password?: string;
  }) => {
    removeCookies();
    const apiStore = useApiStore();
    await apiStore.setRegionConfigByEmail(email, password === undefined);

    const currentUser = await Auth.currentUserInfo();
    if (currentUser && currentUser.username !== email) {
      logout();
    }
    const user = await Auth.signIn(email.toLowerCase(), password);

    if (
      ["SOFTWARE_TOKEN_MFA", "CUSTOM_CHALLENGE"].includes(user.challengeName)
    ) {
      return user;
    }
    await updateAuthUser({});
  };

  const ssoLogin = async ({
    user,
    challengeResponse,
  }: {
    user: any;
    challengeResponse: string;
  }) => {
    await Auth.sendCustomChallengeAnswer(user, challengeResponse);
    await updateAuthUser({});
  };

  const loadCompanyFromSuperAdminCookie = async () => {
    const query = getSuperAdminCookieQuery();

    if (query) {
      const region = query["region"] as string;
      const [companyPk, companyId] = (query["admin"] as string).split(":");
      await setSuperUserToCompanyAndRegion(companyPk, companyId, region);

      router.push({
        name: "home",
        query,
      });
    } else {
      const companyPk = getCookie("company") ?? "";
      const companyId = getCookie("companyId") ?? "";
      await setSuperUserToCompanyAndRegion(companyPk, companyId);
    }
  };

  const setSuperAdminCookiesFromQuery = async (query: LocationQuery) => {
    const region = query["region"] as string;
    const [companyPk, companyId] = (query["admin"] as string).split(":");
    await setSuperUserToCompanyAndRegion(companyPk, companyId, region);
  };

  const setSuperUserToCompanyAndRegion = async (
    companyPk: string,
    companyId: string,
    region?: string
  ) => {
    if (authUser.value) {
      authUser.value.companyPk = companyPk;
      authUser.value.companyId = Number(companyId);
    }

    const secureCookieOptions = {
      secure: true,
      expires: "1M",
    };

    setCookie("company", companyPk, secureCookieOptions);
    setCookie("companyId", companyId, secureCookieOptions);

    if (region) {
      setCookie(config.REGION_COOKIE_NAME, region, { secure: true });
    }

    const apiStore = useApiStore();
    await apiStore.setRegionConfigForSuperAdmin(region);
  };

  const getSuperAdminCookieQuery = () => {
    const superAdminCookie = getCookie("superadmin-query");
    removeCookie("superadmin-query");

    return isSuperAdmin.value && superAdminCookie
      ? JSON.parse(superAdminCookie)
      : undefined;
  };

  const mfaLogin = async ({ user, mfa }: { user: any; mfa: string }) => {
    await Auth.confirmSignIn(user, mfa, "SOFTWARE_TOKEN_MFA");
    await updateAuthUser({});
  };

  const getAccessToken = async () => {
    try {
      const session = await Auth.currentSession();
      return session.getAccessToken().getJwtToken();
    } catch (error) {
      return null;
    }
  };

  const loadMfaPreference = async (user: any) => {
    setMfaPreference(await Auth.getPreferredMFA(user));
  };

  const checkLogin = async ({ to = null }: any = {}) => {
    const rootStore = useRootStore();
    const apiStore = useApiStore();
    try {
      await apiStore.setRegionConfigByEmail(getCookie("swipedon-email"));
      await updateAuthUser({ to });
    } catch (error: any) {
      if (!error.handledGlobally) {
        rootStore.handleException({ error });
      }
    } finally {
      loadedAuth.value = true;
    }
  };

  const removeCookies = () => {
    removeCookie("userTimeZone");
    if (window?.sessionStorage) {
      window.sessionStorage.removeItem("location");
      window.sessionStorage.removeItem("locationGroup");
    }
    removeCookie("companyId");
    removeCookie("company");
    removeCookie(config.REGION_COOKIE_NAME);
    removeCookie("swipedon-email");
  };

  const logout = async (globalLogout = false) => {
    const rootStore = useRootStore();
    const accountStore = useAccountStore();
    const locationStore = useLocationStore();
    const authStore = useAuthStore();

    try {
      await Auth.signOut({ global: globalLogout });
    } catch (error: any) {
      if (!error.handledGlobally) {
        rootStore.handleException({ error });
      }
    }

    if (isSuperAdmin.value) {
      removeCookie("superadmin-query");
    }

    queryClient.clear();
    authUser.value = null;
    removeCookies();
    rootStore.reset();
    accountStore.$reset();
    locationStore.$reset();
    authStore.$reset();
    rootStore.setLoading(false);

    if (localStorage) {
      localStorage.removeItem("location");
    }
  };

  const updatePassword = async (
    currentPassword: string,
    newPassword: string,
    mfaEnabled: boolean
  ): Promise<{ success: boolean; error?: any }> => {
    try {
      const currentCognitoUser = await Auth.currentAuthenticatedUser();

      await Auth.changePassword(
        currentCognitoUser,
        currentPassword,
        newPassword
      );

      await logout(true);

      if (!mfaEnabled) {
        await login({
          email: currentCognitoUser.username.toLowerCase(),
          password: newPassword,
        });
      }
      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  };

  const updateWhatsNewViewedAt = async () => {
    if (!authUser.value) return;

    const apiStore = useApiStore();
    const rootStore = useRootStore();
    const viewedAt = dayjs().format("YYYY-MM-DD HH:mm:ss");
    try {
      const payload = {
        settings: {
          whats_new_viewed_at: viewedAt,
        },
      };
      await axios.put(
        apiStore.getEndpoint(
          "platform",
          endpoints.user(String(authUser.value.id))
        ),
        payload
      );
      await updateAuthUser({});
    } catch (error: any) {
      if (!error.handledGlobally) {
        rootStore.handleException({ error });
      }
    }
  };

  const updateNewTeams = async () => {
    if (!authUser.value) return;

    const apiStore = useApiStore();

    if (isTeamsNew.value) {
      await axios.put(
        apiStore.getEndpoint(
          "platform",
          endpoints.user(String(authUser.value.id))
        ),
        {
          settings: {
            teams_is_new: false,
          },
        }
      );
    }
  };

  const setPassword = async (
    email: string,
    code: string,
    newPassword: string
  ): Promise<{ success: boolean; error?: any }> => {
    try {
      const apiStore = useApiStore();
      await apiStore.setRegionConfigByEmail(email);

      const currentUser = await Auth.currentUserInfo();
      if (currentUser && currentUser.username !== email) {
        logout();
      }

      const user = await Auth.signIn(email.toLowerCase(), code);
      if (user.challengeName != "NEW_PASSWORD_REQUIRED") {
        return { success: false, error: { code: "nonNewPasswordRequired" } };
      }

      if (!user) {
        return { success: false };
      }

      await Auth.completeNewPassword(user, newPassword);

      await login({
        email: email,
        password: newPassword,
      });

      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  };

  const resetPassword = async (
    email: string,
    code: string,
    newPassword: string
  ): Promise<{ success: boolean; mfaEnabled: boolean; error?: any }> => {
    try {
      const apiStore = useApiStore();
      await apiStore.setRegionConfigByEmail(email);

      await Auth.forgotPasswordSubmit(email, code, newPassword);
      const user = await Auth.signIn(email.toLowerCase(), newPassword);
      if (user.challengeName === "SOFTWARE_TOKEN_MFA") {
        router.push({ name: "login" });
        await logout(true);
        return { success: true, mfaEnabled: true };
      }
      await logout(true);
      await login({
        email: email,
        password: newPassword,
      });
      return { success: true, mfaEnabled: false };
    } catch (error) {
      return { success: false, mfaEnabled: false, error };
    }
  };

  const recoverPassword = async (
    email: string
  ): Promise<{ success: boolean; error?: any }> => {
    try {
      const apiStore = useApiStore();
      await apiStore.setRegionConfigByEmail(email);

      await Auth.forgotPassword(email);

      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  };

  return {
    loadedAuth,
    authUser,
    userEmail,
    userName,
    isTeamsNew,
    mfaPreference,
    mfaDialogVisible,
    changePasswordDialogVisible,
    mfaSetupRequired,
    login,
    getAccessToken,
    checkLogin,
    logout,
    updatePassword,
    updateWhatsNewViewedAt,
    updateNewTeams,
    updateAuthUser,
    removeCookies,
    loadMfaPreference,
    setMfaPreference,
    toggleMfaDialogVisible,
    toggleChangePasswordDialogVisible,
    mfaLogin,
    setPassword,
    recoverPassword,
    resetPassword,
    setSuperAdminCookiesFromQuery,
    ssoLogin,
  };
});

export default useAuthStore;
