<script setup lang="ts">
import { CONFIRM, SAVE_CONFIRM_EXPIRY_TIME } from "@/stores/constants";
import { computed, ref, watch } from "vue";

export interface Props {
  status?: string | (string | undefined)[];
  expiryTime?: number;
  large?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  status: CONFIRM.SAVED,
  expiryTime: SAVE_CONFIRM_EXPIRY_TIME,
  large: false,
});

const expired = ref(false);

const confirmation = computed(() => {
  if (typeof props.status === "string") {
    return props.status;
  }
  if (props.status.some((s) => s === CONFIRM.PENDING)) {
    return CONFIRM.PENDING;
  }
  if (props.status.some((s) => s === CONFIRM.FAILURE)) {
    return CONFIRM.FAILURE;
  }
  if (props.status.some((s) => s === CONFIRM.SUCCESS)) {
    return CONFIRM.SUCCESS;
  }
  return CONFIRM.SAVED;
});

const success = computed(() => {
  return confirmation.value === CONFIRM.SUCCESS && !expired.value;
});
const failure = computed(() => {
  return confirmation.value === CONFIRM.FAILURE;
});
const pending = computed(() => {
  return confirmation.value === CONFIRM.PENDING;
});
const saved = computed(() => {
  return confirmation.value === CONFIRM.SAVED;
});

let timer: string | number | NodeJS.Timeout | undefined;

watch(
  confirmation,
  (value) => {
    if (value === CONFIRM.SUCCESS) {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        expired.value = true;
      }, props.expiryTime);
    } else {
      expired.value = false;
    }
  },
  { immediate: true }
);
</script>

<template>
  <Transition
    v-if="!saved"
    name="fade"
  >
    <span
      v-if="success || failure || pending"
      :class="{ 'large-background': large }"
    >
      <font-awesome-icon
        v-if="success"
        :class="['success', { 'large-icon': large }]"
        :icon="['fal', 'check-circle']"
      />
      <font-awesome-icon
        v-else-if="failure"
        :class="['failure', { 'large-icon': large }]"
        :icon="['fal', 'exclamation-circle']"
      />
      <font-awesome-icon
        v-else-if="pending"
        :class="['pending', { 'large-icon': large }]"
        :icon="['fal', 'circle-notch']"
        spin
      />
    </span>
  </Transition>
</template>

<style lang="css" scoped>
.large-background {
  position: absolute;
  top: 10px;
  right: 0;
  left: 0;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 65px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.large-icon {
  width: 40px;
  height: 40px;
}

.success {
  color: var(--so-color-success);
}

.failure {
  color: var(--so-color-error);
}

.pending {
  color: var(--so-color-info);
}

.fade-leave-active {
  transition: opacity 0.8s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
