<script setup lang="ts">
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { ElButton } from "element-plus";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { RouterLink } from "vue-router";

dayjs.extend(relativeTime);
const { t, locale } = useI18n();
const currentLocale = ref(locale);

export interface Props {
  expiry: string;
  onAccountPage?: boolean;
  isExpired: boolean;
}

defineProps<Props>();

watch(locale, (newLocale) => {
  currentLocale.value = newLocale;
});
</script>

<template>
  <div class="banner">
    <i18n-t
      v-if="isExpired"
      keypath="trialBanner.expired"
      tag="span"
      scope="global"
    >
      <template #subscribeToAPlan>
        <span v-if="onAccountPage">
          {{ t("trialBanner.subscribeToAPlan") }}
        </span>
        <router-link
          v-else
          :to="{ name: 'change-plan' }"
          class="contact-link"
        >
          {{ t("trialBanner.subscribeToAPlan") }}
        </router-link>
      </template>
      <template #contactUs>
        <a
          :href="t('urls.support.generalEnquiry')"
          target="_blank"
          rel="noopener noreferrer"
          class="contact-link"
          >{{ t("trialBanner.contactUs") }}
        </a>
      </template>
    </i18n-t>
    <span v-else-if="onAccountPage">
      {{ t("trialBanner.pleaseSubscribe") }}
    </span>
    <i18n-t
      v-else
      keypath="trialBanner.expiryTimeRemaining"
      tag="span"
      scope="global"
    >
      <template #remaining>
        <strong>
          {{ dayjs(expiry).locale(currentLocale).fromNow(true) }}
        </strong>
      </template>
    </i18n-t>

    <div
      v-if="!isExpired"
      class="buttons"
    >
      <router-link
        v-if="!onAccountPage"
        :to="{ name: 'change-plan' }"
      >
        <el-button
          bordered
          class="button"
          type="primary"
          size="default"
          >{{ t("trialBanner.buyNow") }}</el-button
        >
      </router-link>
      <a
        :href="t('urls.support.bookDemo')"
        target="_blank"
        rel="noopener noreferrer"
      >
        <el-button
          bordered
          class="button"
          type="primary"
          size="default"
          >{{ t("trialBanner.bookDemo") }}</el-button
        >
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--so-bg-color-trial-banner);
  padding: 8px;
  color: var(--so-text-color-trial-banner);
  gap: 20px;

  @media screen and (max-width: $breakpoint-sm) {
    flex-direction: column;
    gap: 10px;
  }
}

.buttons {
  display: flex;
  gap: 10px;

  @media screen and (max-width: $breakpoint-xs) {
    flex-direction: column;
    width: 100%;

    a {
      width: 100%;
    }
  }
}

.button {
  border-color: var(--so-text-color-trial-banner);
  min-width: 110px;

  @media screen and (max-width: $breakpoint-xs) {
    width: 100%;
  }
}

.contact-link {
  color: var(--so-text-color-trial-banner);
}
</style>
