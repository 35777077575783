import { useQuery } from "@tanstack/vue-query";
import { ApiClient } from "@/api/zoolander";
import { ApiClient as ServerlessApi } from "@/api/serverless";
import { queryKeys } from "@/api/queryKeys";
import { tags, type DeepMaybeRef, type MaybeRef } from "../common";
import { unwrapDeepRefs } from "../common/utils";
import { computed, unref } from "vue";
import type { ListPlansArgs } from "./types";

export const useGetPlans = () => {
  return useQuery({
    queryKey: queryKeys[tags.rest.customer].getPlans().queryKey,
    queryFn: async () => new ServerlessApi().getPlans(),
  });
};

export const useListPlans = (
  filters: DeepMaybeRef<ListPlansArgs>,
  enabled?: MaybeRef<boolean>
) => {
  const isEnabled = computed(() => unref(enabled) ?? true);
  return useQuery({
    queryKey: queryKeys[tags.zoolander.plan].list(filters).queryKey,
    queryFn: async () => new ApiClient().listPlans(unwrapDeepRefs(filters)),
    select: (plans) =>
      plans.data
        .filter((plan) => plan.price !== undefined)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .sort((a, b) => a.price! - b.price!),
    enabled: isEnabled,
  });
};

export const useGetPlan = (planId: MaybeRef<string>) => {
  const enabled = computed(() => !!unref(planId));
  return useQuery({
    queryKey: queryKeys[tags.zoolander.plan].byId(planId).queryKey,
    queryFn: () => new ApiClient().getPlan({ id: unref(planId) }),
    enabled,
  });
};
