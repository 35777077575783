import { mergeQueryKeys } from "@lukemorales/query-key-factory";
import {
  bookingKeys,
  inductionsKeys,
  spaceCategoryKeys,
  spaceFeaturesKeys,
  spaceResourceRestrictionsKeys,
  spaceResourcesKeys,
  spaceZonesKeys,
  visitorInductionsKeys,
  spacesKeys,
  visitorBrandingKeys,
  subscriptionKeys,
  planKeys,
  insightsKeys,
  preregistrationInductionsKeys,
} from "./zoolander";
import {
  preregistrationsKeys,
  agreementsKeys,
  visitorFieldsKeys,
  screensaversKeys,
  visitorCategoriesKeys,
  employeeMovementsKeys,
  evacuationKeys,
  employeeArchiveKeys,
  devicesKeys,
  deviceLogoKeys,
  employeesKeys,
  visitorsKeys,
  visitsKeys,
  locationKeys,
  locationGroupKeys,
  companyKeys,
} from "./platform";
import {
  integrationStatusKeys,
  integrationLocationsKeys,
  integrationSyncKeys,
  teamsKeys,
  slackKeys,
  employeeSignInQuestionKeys,
  whatsNewKeys,
  customerKeys,
  deliveriesKeys,
  billingKeys,
  regionsKeys,
  addOnListKeys,
  companyKeys as restCompanyKeys,
} from "./rest";

export const queryKeys = mergeQueryKeys(
  agreementsKeys,
  bookingKeys,
  employeeSignInQuestionKeys,
  inductionsKeys,
  preregistrationsKeys,
  spaceCategoryKeys,
  spaceFeaturesKeys,
  spacesKeys,
  spaceResourcesKeys,
  spaceResourceRestrictionsKeys,
  visitorFieldsKeys,
  visitorBrandingKeys,
  visitorCategoriesKeys,
  screensaversKeys,
  spaceZonesKeys,
  employeeMovementsKeys,
  evacuationKeys,
  teamsKeys,
  whatsNewKeys,
  employeeArchiveKeys,
  employeesKeys,
  visitorsKeys,
  visitorInductionsKeys,
  visitsKeys,
  devicesKeys,
  integrationStatusKeys,
  integrationLocationsKeys,
  integrationSyncKeys,
  slackKeys,
  devicesKeys,
  deviceLogoKeys,
  customerKeys,
  deliveriesKeys,
  subscriptionKeys,
  billingKeys,
  locationKeys,
  regionsKeys,
  locationGroupKeys,
  planKeys,
  insightsKeys,
  preregistrationInductionsKeys,
  companyKeys,
  addOnListKeys,
  restCompanyKeys
);
