import type { GetCompanyResponse } from "@/api/companies";
import dayjs from "dayjs";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import type {
  AccountStore,
  OrderSummaryAddonArguments,
  OrderSummaryArguments,
} from "./types";
import { config } from "@/config/envConfig";
import { PLANS, PLAN_FEATURE, PROVISION_PLAN_GROUPS } from "../constants";
import type { GetSubscriptionResponse, ExpandedFeature } from "@/api/zoolander";

export const useAccountStore = defineStore("accounts", {
  state: (): AccountStore => {
    const planName = ref("");
    const expiry = ref<Date | undefined>(undefined);
    const isExpired = computed(() => dayjs(expiry.value).isBefore(dayjs()));
    const isFreeTrial = computed(() => planName.value === PLANS.FREE_TRIAL);

    return {
      alert: false,
      subscribed: false,
      orderSummary: undefined,
      subscription: {
        planName,
        expiry,
        isExpired,
        isFreeTrial,
        planGroup: ref(""),
      },
      subscriptionFeatures: [],
    };
  },
  actions: {
    setAlert(value: boolean) {
      this.alert = value;
    },
    setOrderSummary(value: OrderSummaryArguments) {
      this.orderSummary = value;
    },
    setAddonsInOrderSummary(addons: OrderSummaryAddonArguments[]) {
      if (!this.orderSummary || !addons) return;
      this.orderSummary.addons = addons;
    },
    setResourcesInOrderSummary(resources: OrderSummaryAddonArguments[]) {
      if (!this.orderSummary || !resources) return;
      this.orderSummary.resources = resources;
    },
    setSubscription(response: GetCompanyResponse) {
      this.subscription.expiry = response.subscription?.renewalAt;
      this.subscription.planName = response.subscription.plan?.name;
      this.subscription.planGroup = response.subscription.plan?.group;
    },
    setSubscriptionFeatures(response: GetSubscriptionResponse) {
      this.subscriptionFeatures = <ExpandedFeature[]>response?.features ?? [];
    },
    setPlanName(planName: string) {
      this.subscription.planName = planName;
    },
    clearOrder() {
      this.orderSummary = undefined;
    },
  },
  getters: {
    getAlert: (state) => state.alert,
    getOrderSummary: (state) => state.orderSummary,
    isOnProvisionedPlan: (state) => {
      const group = state.subscription?.planGroup || "";
      const provisionedGroups = PROVISION_PLAN_GROUPS.split(",") || [];

      return !!(group && provisionedGroups.includes(group));
    },
    isOutsideGracePeriod: (state) =>
      (state.subscription.isFreeTrial && state.subscription.isExpired) ||
      dayjs(state.subscription.expiry).diff(dayjs(), "day") <=
        -config.BILLING_GRACE_DAYS,

    isFeatureBundled: (state) => (feature: PLAN_FEATURE) =>
      state.subscriptionFeatures.some(
        ({ bundled, name }: ExpandedFeature) =>
          bundled && name?.toLowerCase() === feature.toLowerCase()
      ),
  },
});

export default useAccountStore;
export * from "./types";
