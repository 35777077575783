export const redirects = [
  {
    path: "/visitors/timeline/:id?",
    redirect: { name: "visitors-timeline" },
  },
  {
    path: "/employees/directory/:id?",
    redirect: { name: "employees-directory" },
  },
  {
    path: "/agreements",
    redirect: { name: "agreements-library" },
  },
  {
    path: "/inductions",
    redirect: { name: "inductions" },
  },
  {
    path: "/integrations",
    redirect: { name: "integrations" },
  },
  {
    path: "/integrations/users-manage",
    redirect: { name: "integrations-azure-manage" },
  },
  {
    path: "/integrations/users-manage/fields",
    redirect: { name: "integrations-azure-manage-fields" },
  },
  {
    path: "/integrations/users-manage/sync-issues",
    redirect: { name: "integrations-azure-manage-issues" },
  },
  {
    path: "/integrations/users-manage/locations",
    redirect: { name: "integrations-azure-manage-locations" },
  },
  {
    path: "/integrations/users-setup",
    redirect: { name: "integrations-azure-setup" },
  },
  {
    path: "/integrations/users-setup/connect",
    redirect: { name: "integrations-azure-setup" },
  },
  {
    path: "/integrations/users-setup/group",
    redirect: { name: "integrations-azure-setup" },
  },
  {
    path: "/integrations/users-setup/fields",
    redirect: { name: "integrations-azure-setup" },
  },
  {
    path: "/integrations/users-setup/locations",
    redirect: { name: "integrations-azure-setup" },
  },
  {
    path: "/integrations/users-setup/sync",
    redirect: { name: "integrations-azure-setup" },
  },
  {
    path: "/locations",
    redirect: { name: "locations" },
  },
  {
    path: "/locations/devices",
    redirect: { name: "devices" },
  },
  {
    path: "/locations/addons",
    redirect: { name: "locations" },
  },
  {
    path: "/locations/groups",
    redirect: { name: "locations" },
  },
  {
    path: "/settings/deliveries/setup",
    redirect: { name: "deliveries-setup-settings" },
  },
  {
    path: "/settings/deliveries/instructions",
    redirect: { name: "deliveries-instruction-settings" },
  },
  {
    path: "/settings/visitor-fields",
    redirect: { name: "visitors-fields-settings" },
  },
  {
    path: "/settings/notification-settings",
    redirect: { name: "visitors-welcome-screen-settings" },
  },
  {
    path: "/settings/branding",
    redirect: { name: "visitors-branding-and-badges-settings" },
  },
  {
    path: "/settings/id-badges",
    redirect: { name: "visitors-branding-and-badges-settings" },
  },
  {
    path: "/settings/welcome",
    redirect: { name: "visitors-welcome-screen-settings" },
  },
  {
    path: "/settings/catering",
    redirect: { name: "visitors-catering-settings" },
  },
  {
    path: "/settings/catering/menu",
    redirect: { name: "visitors-catering-settings" },
  },
  {
    path: "/settings/catering/thanks",
    redirect: { name: "visitors-catering-settings" },
  },
  {
    path: "/settings/catering/setup",
    redirect: { name: "visitors-catering-settings" },
  },
  {
    path: "/account",
    redirect: { name: "account-settings" },
  },
  {
    path: "/account/plans/enquire",
    redirect: { name: "change-plan" },
  },
  {
    path: "/account/plans/downgrade",
    redirect: { name: "change-plan" },
  },
  {
    path: "/account/plans/request-cancellation",
    redirect: { name: "cancel" },
  },
  {
    path: "/account/plans",
    redirect: { name: "change-plan" },
  },
  {
    path: "/account/history",
    redirect: { name: "account-settings" },
  },
  {
    path: "/account/addons",
    redirect: { name: "account-settings" },
  },
  {
    path: "/account/payment-method",
    redirect: { name: "account-settings" },
  },
  {
    path: "/account/addons/request-cancellation",
    redirect: { name: "account-settings" },
  },
  {
    path: "/account/settings",
    redirect: { name: "account-settings" },
  },
  {
    path: "/account/order",
    redirect: { name: "account-settings" },
  },
  {
    path: "/teams/login",
    redirect: { name: "integrations-teams-manage" },
  },
  {
    path: "/teams/locations",
    redirect: { name: "integrations-teams-manage" },
  },
];
