<script setup lang="ts">
import { ElContainer, ElMain, ElRow } from "element-plus";
import BrowserWarning from "@/pages/login/components/BrowserWarning.vue";

const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
</script>

<template>
  <el-container>
    <el-main class="anon-main">
      <el-row v-if="isIE11"><BrowserWarning /></el-row>

      <slot />
    </el-main>
  </el-container>
</template>

<style lang="scss" scoped>
.anon-main {
  padding: 0;
}
</style>
