import { createApp } from "vue";
import { ElLoading } from "element-plus";
import { createPinia } from "pinia";
import VueKonva from "vue-konva";

import App from "./App.vue";
import router from "./router";
import sentryInit from "./config/sentry";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import configureApp, { i18n, getVueQueryClient } from "./config";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";
import { VueQueryPlugin } from "@tanstack/vue-query";
import { config } from "@/config/envConfig";
import { createGtm } from "@gtm-support/vue-gtm";

configureApp().then(() => {
  const app = createApp(App)
    .component("font-awesome-icon", FontAwesomeIcon)
    .use(i18n)
    .use(createPinia())
    .use(router)
    .use(VueKonva)
    .use(ElLoading)
    .component("VueTelInput", VueTelInput);

  if (config.GTM_ID) {
    app.use(createGtm({ id: config.GTM_ID }));
  }

  app.directive("cy", (el, { value }) => {
    if (config.MODE === "production") return;

    el.setAttribute("data-cy", value);
  });

  sentryInit(app);

  VueQueryPlugin.install(app, {
    queryClient: getVueQueryClient(),
  });
  app.mount("#app");
});
