import { toRef } from "vue";
import { i18n } from "@/config";
import { config } from "@/config/envConfig";

const currentLocale = toRef(i18n.global, "locale");

export const whatsNewButtonEnabled = (
  config.WHATSNEW_SUPPORTED_LOCALES?.toLocaleLowerCase()?.split(",") || []
).includes(currentLocale.value.toLocaleLowerCase());

export default {
  whatsNewButtonEnabled,
};
