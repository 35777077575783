<script setup lang="ts">
import { useCssVar } from "@vueuse/core";
import { computed, onMounted, reactive, ref, watch } from "vue";
import {
  type FormInstance,
  ElForm,
  ElFormItem,
  ElInput,
  type FormRules,
} from "element-plus";
import CoreButton from "@/components/common/CoreButton/CoreButton.vue";
import { useI18n } from "vue-i18n";
import CoreLink from "@/components/common/CoreLink/CoreLink.vue";

export interface Props {
  isLoading: boolean;
  error?: string;
  ssoEnabled?: boolean;
}

const props = defineProps<Props>();

const buttonColour = useCssVar("--so-color-secondary");
const form = ref<FormInstance>();
const input = ref<HTMLInputElement>();
const password = ref("");
const { t } = useI18n();
const emit = defineEmits<{
  submit: [password: string];
}>();

const localError = ref(props.error);

onMounted(() => {
  if (props.ssoEnabled) return;
  input.value?.focus();
});

watch(
  () => props.error,
  (error) => {
    localError.value = error;
    if (error) {
      form.value?.validateField("password", () => null);
    }
  }
);

const formRules = computed<FormRules>(() => ({
  password: [
    {
      required: true,
      message: t("forms.login.password.required"),
      trigger: "blur",
    },
    {
      validator: (_rule, _value, callback) => {
        if (localError.value) {
          callback(localError.value);
        }
        callback();
      },
    },
  ],
}));

const submit = async () => {
  try {
    localError.value = "";
    await form.value?.validate();
  } catch (err) {
    return;
  }
  emit("submit", password.value);
};
const model = reactive({ password });
</script>
<template>
  <div>
    <el-form
      ref="form"
      hide-required-asterisk
      :model="model"
      :rules="formRules"
      label-position="top"
      @submit.prevent="submit"
    >
      <el-form-item
        prop="password"
        :label="$t('forms.login.password.label')"
      >
        <el-input
          ref="input"
          v-model="password"
          class="login-input"
          :disabled="isLoading"
          type="password"
          auto-complete="off"
          @blur="localError = ''"
        />
      </el-form-item>
      <div class="action-items">
        <CoreButton
          :loading="isLoading"
          class="login-button"
          :color="buttonColour"
          @click="submit"
        >
          {{ $t("forms.login.signIn") }}
        </CoreButton>
        <CoreLink
          :disabled="isLoading"
          :route="{ name: 'forgot' }"
          :underline="false"
        >
          {{ t("forms.login.forgotPassword.label") }}
        </CoreLink>
      </div>
    </el-form>
  </div>
</template>
<style lang="css" scoped>
.login-input :deep(.so-input__inner) {
  min-width: 360px;
}
.action-items {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}
</style>
