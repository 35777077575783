<script setup lang="ts">
import { ElRow, ElCol, ElFormItem, ElDivider } from "element-plus";
import CoreLink from "@/components/common/CoreLink/CoreLink.vue";
import { config } from "@/config/envConfig";
import { LatestBlogPost, SecurityCertifications } from "@/widgets/";
import SetPasswordForm from "@/forms/SetPasswordForm.vue";
import { useRoute } from "vue-router";
import { computed, watch, ref } from "vue";
import { ADMIN_CREATE_USER, FORGOT_PASSWORD } from "@/stores/constants";
import useAuthStore from "@/stores/auth";
import { useLogin } from "./composables/login";
import UsernameForm from "./components/UsernameForm.vue";
import PasswordForm from "./components/PasswordForm.vue";
import MfaForm from "./components/MfaForm.vue";
import CoreButton from "@/components/common/CoreButton/CoreButton.vue";
import GoogleIcon from "@/assets/images/sso/google-icon.svg";
import MicrosoftIcon from "@/assets/images/sso/microsoft-365-icon.svg";
import { useSso } from "./composables/sso";
import AppLoading from "@/AppLoading.vue";

const route = useRoute();
const authStore = useAuthStore();

const { trigger, code } = route.query;

const passwordSuccess = ref(false);

const {
  step,
  submitUsername,
  isLoading,
  submitPassword,
  submitMfaCode,
  passwordError,
  username,
  goBack,
  microsoftSsoEnabled,
  googleSsoEnabled,
  ssoEnabled,
  mfaError,
  storedUsername,
} = useLogin();

const {
  azureLogin,
  googleLogin,
  isLoading: isSsoLoading,
  error: ssoError,
} = useSso(storedUsername);

watch(ssoError, (_ssoError) => {
  if (_ssoError) goBack();
});

const formMinHeight = computed(() => {
  if (step.value === 1 && ssoEnabled.value) {
    return "400px";
  }
  return "250px";
});

const isSettingPassword = computed(() => {
  if (code && !passwordSuccess.value) {
    return ADMIN_CREATE_USER === trigger || FORGOT_PASSWORD === trigger;
  }
  return false;
});

watch(
  isSettingPassword,
  async (newValue) => {
    if (newValue && authStore.authUser) {
      await authStore.logout();
    }
  },
  { immediate: true }
);

const setPasswordSuccess = () => {
  passwordSuccess.value = true;
};
</script>

<template>
  <AppLoading v-if="isSsoLoading" />
  <div class="container">
    <div
      v-if="isSettingPassword"
      class="set-password-container"
    >
      <SetPasswordForm @on-success="setPasswordSuccess" />
    </div>
    <el-row
      v-else
      class="content"
    >
      <el-col
        class="content_news"
        :md="11"
      >
        <div class="news-tiles"><LatestBlogPost /></div>
      </el-col>
      <el-col
        class="content_login"
        :md="13"
      >
        <el-row class="logo-header">
          <CoreLink
            :href="$t('urls.marketing.root')"
            class="logo"
            :underline="false"
          >
            <img
              :alt="config.APP_PRODUCT_NAME"
              src="@theme/branding/brand_primary_logo.svg"
              class="brand-logo"
            />
          </CoreLink>
          <CoreLink
            v-if="config.SHOW_CREATE_ACCOUNT_BUTTON"
            :route="{ name: 'register' }"
            :underline="false"
          >
            {{ $t("forms.login.createAccount") }}
          </CoreLink>
        </el-row>
        <el-form-item>
          <h2
            v-text="
              step === 2
                ? $t('forms.login.mfa.title')
                : $t('forms.login.pleaseLogIn')
            "
          />
        </el-form-item>
        <div class="form-container">
          <Transition name="slide-left">
            <el-row v-if="step === 0">
              <UsernameForm
                :error="ssoError"
                :is-loading="isLoading"
                @submit="
                  (input) => {
                    ssoError = undefined;
                    submitUsername(input);
                  }
                "
              />
            </el-row>
            <div v-else-if="step === 1">
              <el-row>
                <div class="sign-in-info">
                  <p>
                    <i18n-t
                      keypath="forms.login.signingInWith"
                      tag="span"
                    >
                      <template #user>
                        <strong>{{ username }}</strong>
                      </template>
                    </i18n-t>
                    <CoreLink @click="() => goBack()">{{
                      $t("forms.login.changeUser")
                    }}</CoreLink>
                  </p>
                </div>
                <PasswordForm
                  :is-loading="isLoading"
                  :error="passwordError"
                  :sso-enabled="ssoEnabled"
                  @submit="submitPassword"
                />
              </el-row>
              <el-row
                v-if="ssoEnabled"
                :gutter="12"
              >
                <el-divider class="divider">{{
                  $t("forms.login.divider")
                }}</el-divider>
                <el-col
                  v-if="googleSsoEnabled"
                  :md="12"
                >
                  <CoreButton
                    class="sso-btn"
                    @click="googleLogin"
                    ><img
                      alt="google-sso"
                      :src="GoogleIcon"
                    />
                    {{ $t("securitySettings.sso.login.google") }}
                  </CoreButton>
                </el-col>
                <el-col
                  v-if="microsoftSsoEnabled"
                  :md="12"
                >
                  <CoreButton
                    class="sso-btn"
                    @click="azureLogin"
                  >
                    <img
                      alt="microsoft-sso"
                      :src="MicrosoftIcon"
                    />
                    {{ $t("securitySettings.sso.login.microsoft") }}
                  </CoreButton>
                </el-col>
              </el-row>
            </div>
            <div v-else-if="step === 2">
              <el-row>
                <MfaForm
                  :is-loading="isLoading"
                  :error="mfaError"
                  @submit="submitMfaCode"
                  @go-back="goBack"
                />
              </el-row>
            </div>
          </Transition>
        </div>
        <el-row>
          <SecurityCertifications />
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<style lang="scss" scoped>
/* The following CSS removes up and down arrows on the numbers input */
/* Chrome, Safari, Edge, Opera */
:deep(input::-webkit-outer-spin-button),
:deep(input::-webkit-inner-spin-button) {
  margin: 0;
  -webkit-appearance: none;
}
.sso-btn {
  width: 100%;
  margin-bottom: 1rem;
  img {
    margin-right: 1rem;
  }
}

.sso-btn {
  padding-left: 0;
  padding-right: 0;
  min-width: auto;
  &:hover {
    background-color: transparent;
    --so-button-hover-border-color: var(--so-color-primary);
    --so-button-hover-text-color: var(--so-button-text-color);
  }
}

.divider {
  margin-top: 3.5rem;
  margin-bottom: 2.5rem;
}
.sign-in-info {
  margin-bottom: 1.5rem;
  width: 100%;

  span {
    margin-right: 1rem;
  }
}
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.2s ease-out;
  position: absolute;
}

.slide-left-enter-from {
  opacity: 0;
  transform: translateX(50px);
}

.slide-left-leave-to {
  opacity: 0;
  transform: translateX(-50px);
}

.set-password-container {
  display: flex;
  min-height: 100vh;
  min-width: 100vw;
  justify-content: center;
  align-items: center;
}

.content {
  flex-direction: column-reverse;
  height: 100%;
  justify-content: center;
  min-height: 100vh;
  background: var(--so-bg-color);

  @media screen and (min-width: ($breakpoint-md + 1)) {
    justify-content: left;
    flex-direction: row;
    height: 100%;
  }
}

.content_news,
.content_login {
  padding-left: 30px;
  padding-right: 30px;

  @media screen and (min-width: ($breakpoint-md + 1)) {
    padding-top: 125px;
  }
}

.content_news {
  display: none;
  flex-grow: 1;
  background-color: var(--so-bg-color-login);

  @media screen and (min-width: ($breakpoint-md)) {
    display: block;
  }

  .news-tiles {
    justify-content: center;
    max-width: 420px;
    margin-left: auto;
    margin-right: 70px;
    width: 100%;
  }
}

.content_login {
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;

  @media all and (min-width: $breakpoint-md) {
    padding-left: 70px;
    justify-content: center;
    margin: 0;
  }
}

.logo-header {
  margin-bottom: 25px;
  text-align: center;

  a {
    color: var(--color-link);
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: var(--font-normal);
    line-height: 34px;

    &.logo {
      flex-grow: 1;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 25px;

      @media screen and (min-width: $breakpoint-xs) {
        margin-bottom: 0;
        width: auto;
      }
    }
  }

  @media screen and (min-width: $breakpoint-xs) {
    margin-bottom: 55px;
  }
}

.brand-logo {
  height: 40px;
}

.form-container {
  min-height: v-bind(formMinHeight);
  position: relative;
}
</style>
