<script lang="ts" setup>
import CoreButton from "@/components/common/CoreButton/CoreButton.vue";
import SetPasswordCard from "@/components/common/SetPasswordCard/SetPasswordCard.vue";
import { config } from "@/config/envConfig";
import { useCssVar } from "@vueuse/core";
import {
  ElForm,
  ElFormItem,
  ElInput,
  type FormInstance,
  type FormRules,
} from "element-plus";
import { computed, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import useAuthStore from "@/stores/auth";
import { useRoute } from "vue-router";
import { ADMIN_CREATE_USER, FORGOT_PASSWORD } from "@/stores/constants";

const buttonColor = useCssVar("--so-color-secondary");

const { t, rt, tm, te } = useI18n();

const form = reactive({
  password: "",
});
const emit = defineEmits<{
  (e: "onSuccess"): void;
}>();

const route = useRoute();

const formRef = ref<FormInstance>();

const disableResetPassword = ref(false);
const passwordError = ref("");

const showPasswordFormError = async (error: string) => {
  disableResetPassword.value = false;
  passwordError.value = te(`forms.login.passwordFormErrors.${error}`)
    ? t(`forms.login.passwordFormErrors.${error}`)
    : t(`forms.login.passwordFormErrors.PleaseContact`);
  try {
    await formRef.value?.validate();
  } catch (error) {
    return;
  }
};
const { trigger } = route.query;
const formError = ref();

const submitForm = async () => {
  try {
    await formRef.value?.validate();
  } catch (error) {
    return;
  }

  if (!route.query.email || !route.query.code) return;

  disableResetPassword.value = true;

  if (ADMIN_CREATE_USER === trigger) {
    const { error: setPasswordError } = await useAuthStore().setPassword(
      route.query.email?.toString(),
      route.query.code?.toString(),
      form.password
    );
    formError.value = setPasswordError;
  }

  if (FORGOT_PASSWORD === trigger) {
    const { error: resetPasswordError, mfaEnabled } =
      await useAuthStore().resetPassword(
        route.query.email?.toString(),
        route.query.code?.toString(),
        form.password
      );

    if (mfaEnabled) {
      emit("onSuccess");
      return;
    }
    formError.value = resetPasswordError;
  }

  if (formError.value) {
    switch (formError.value.code) {
      case "NotAuthorizedException":
      case "UserNotFoundException":
        showPasswordFormError("ExpiredLink");
        break;
      case "UserNotConfirmedException":
        await resendInvite();
        break;
      case "completeNewPassword":
      case "nonNewPasswordRequired":
        showPasswordFormError("PleaseContact");
        break;
      case "CodeMismatchException":
      case "ExpiredCodeException":
        showPasswordFormError(formError.value.code);
        break;
      case "TooManyFailedAttemptsException":
      case "TooManyRequestsException":
      case "LimitExceededException":
        showPasswordFormError("TooManyRequests");
        break;
      default:
        showPasswordFormError("PleaseContact");
        return false;
    }
  }
};

const showEmailSent = ref(false);

const title = computed(() =>
  showEmailSent.value ? "" : t("forms.login.setPassword.title")
);

const resendInvite = async () => {
  const { success, error } = await useAuthStore().recoverPassword(
    route.query.email?.toString() ?? ""
  );

  showEmailSent.value = success || error;
};

const rules = reactive<FormRules>({
  password: [
    {
      required: true,
      message: () => t("forms.login.password.required"),
      trigger: "blur",
    },
    {
      pattern: new RegExp(
        `^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{${config.COGNITO_MIN_PASSWORD_LENGTH},}$`,
        "g"
      ),
      message: () =>
        t("forms.login.setPassword.length", {
          length: config.COGNITO_MIN_PASSWORD_LENGTH,
        }),
      trigger: "blur",
    },
    {
      validator: (_rule, _value, callback) => {
        if (passwordError.value != "") {
          callback(passwordError.value);
          passwordError.value = "";
        }
        callback();
      },
    },
  ],
});
</script>
<template>
  <SetPasswordCard :title="title">
    <div v-if="showEmailSent">
      <h2>{{ t("forms.login.recoveredPassword.successTitle") }}</h2>
      <span>{{ t("forms.login.recoveredPassword.successSubTitle") }}</span>
    </div>
    <div v-else>
      <el-form
        ref="formRef"
        :model="form"
        :rules="rules"
        class="form"
        label-position="top"
        hide-required-asterisk
        @keydown.enter.prevent="submitForm"
      >
        <el-form-item
          :label="t('forms.login.password.label')"
          prop="password"
        >
          <div class="custom-form-item">
            <el-input
              v-model="form.password"
              type="password"
            />
            <CoreButton
              class="send-button"
              :color="buttonColor"
              :disabled="disableResetPassword"
              @click="submitForm"
            >
              {{ t("forms.login.setPassword.button") }}
            </CoreButton>
          </div>
        </el-form-item>
      </el-form>
      <p class="password-notice">
        {{ t("forms.login.strongPasswordNotice.requirements") }}
      </p>
      <ul class="password-notice-content">
        <li
          v-for="(text, index) in tm(
            'forms.login.strongPasswordNotice.conditions'
          ) as string[]"
          :key="index"
        >
          {{ rt(text) }}
        </li>
      </ul>
    </div>
  </SetPasswordCard>
</template>
<style lang="scss" scoped>
.form {
  margin-top: 25px;
}

.custom-form-item {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
}

.send-button {
  min-width: 155px;
  font-size: 1rem;
  font-weight: var(--font-semi-bold);
}

.password-notice {
  font-weight: var(--font-semi-bold);
  margin-bottom: 5px;
}

.password-notice-content {
  padding-left: 10px;
  list-style: none;
}

.password-notice-content li {
  display: flex;
  align-items: center;
  gap: 8px;

  &::before {
    content: "";
    display: inline-block;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: var(--so-text-color-primary);
  }
}

@media screen and (min-width: $breakpoint-xs) {
  .custom-form-item {
    flex-direction: row;
  }

  :deep(.so-form-item__error) {
    width: calc(100% - 161px);
  }
}

:deep(.so-input__wrapper) {
  background-color: var(--so-card-bg-color);
}
@media screen and (max-width: $breakpoint-sm) {
  :deep(.so-form-item__content:has(.so-form-item__error)) {
    padding-bottom: 2.5rem;
  }
}

:deep(.so-form-item__error) {
  margin-bottom: 8px;
  display: flex;
}
</style>
