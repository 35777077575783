import { useQuery } from "@tanstack/vue-query";
import { ApiClient } from "@/api/serverless";
import { queryKeys } from "@/api/queryKeys";
import type { MaybeRef } from "@vueuse/shared";
import { computed, unref } from "vue";
import { tags } from "../common";

export const useGetCustomer = (enabled?: MaybeRef<boolean>) => {
  return useQuery({
    queryKey: queryKeys[tags.rest.customer].get().queryKey,
    queryFn: async () => new ApiClient().getCustomer(),
    enabled,
  });
};

export const useGetBundles = (
  plan: MaybeRef<string>,
  enabled?: MaybeRef<boolean>
) => {
  return useQuery({
    queryKey: queryKeys[tags.rest.customer].getBundles(unref(plan)).queryKey,
    queryFn: () => new ApiClient().getBundles(unref(plan)),
    enabled: computed(() => unref(enabled) !== false),
  });
};
