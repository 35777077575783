/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineStore } from "pinia";
import type { RegionData, ServiceType } from "./types";
import { getCookie, setCookie } from "tiny-cookie";
import axios from "axios";
import { endpoints } from "@/endpoints";
import { config } from "@/config/envConfig";
import { Auth } from "@aws-amplify/auth";
import { i18n } from "@/config";

const REST_DOMAIN = `//${config.REST_DOMAIN}`;

const useApiStore = defineStore("api", {
  state: () => ({
    regionConfig: null as RegionData | null,
  }),
  getters: {
    getEndpoint: function () {
      return (service: ServiceType, endpointUrl = "") => {
        if (!endpointUrl) {
          return this.getServiceRegionalUrl(service);
        }

        return this.getServiceRegionalUrl(service) + endpointUrl;
      };
    },
    getServiceRegionalUrl: function (state) {
      return (service: ServiceType) => {
        if (!state.regionConfig) {
          return REST_DOMAIN;
        }

        if (service === "platform") {
          return (
            state.regionConfig.endpoints.platform?.primaryUrl ??
            state.regionConfig.endpoints.platform.url
          );
        }
        if (service === "rest") {
          return state.regionConfig.endpoints.rest.url;
        }
        if (service === "push") {
          return state.regionConfig.endpoints.push.url;
        }
        if (service === "api") {
          return state.regionConfig.endpoints.api.url;
        }
        if (service === "spaces") {
          return state.regionConfig.endpoints.spaces.url;
        }

        return "";
      };
    },
    soRegion: (state) => state.regionConfig?.region?.name ?? "",
    regionCode: (state) => state.regionConfig?.region?.code ?? "",
    formatErrorList: () => (errors: any) => {
      const errorList: any = {};
      const { t } = i18n.global;
      if (!errors.length) {
        return errorList;
      }

      errors.forEach((error: any) => {
        errorList[error.attribute] = t(`validation.api.${error.errorCode}`);
      });

      return errorList;
    },
  },
  actions: {
    async setRegionConfigForSuperAdmin(regionOverride?: string) {
      const region = regionOverride
        ? regionOverride
        : getCookie(config.REGION_COOKIE_NAME) || "-";

      const {
        data: { data: regionResponse },
      } = await axios.get(this.getEndpoint("rest", endpoints.region(region)), {
        withCredentials: false,
      });
      this.setRegionConfig(regionResponse);
    },

    async setRegionConfigByEmail(email: string | null, customAuth = false) {
      const apiStore = useApiStore();
      this.setRegionConfig(null);
      const cookies = {
        customerEmail: getCookie("swipedon-email") ?? "",
        customerRegion: getCookie(config.REGION_COOKIE_NAME) ?? "",
      };

      const customerEmail = email ?? cookies.customerEmail;
      if (!customerEmail) {
        return;
      }

      const {
        data: { data: regionResponse },
      } = await axios.get(
        apiStore.getEndpoint("rest", endpoints.region(customerEmail)),
        {
          withCredentials: false,
        } as any
      );

      if (!cookies.customerRegion) {
        setCookie(config.REGION_COOKIE_NAME, regionResponse.region.name, {
          secure: true,
        });
      }

      if (!cookies.customerEmail || cookies.customerEmail !== customerEmail) {
        setCookie("swipedon-email", customerEmail, { secure: true });
      }

      Auth.configure({
        userPoolId: regionResponse.cognito.userPool,
        userPoolWebClientId: regionResponse.cognito.dashboardClient,
        region: regionResponse.region.aws,
        mandatorySignIn: true,
        authenticationFlowType: customAuth
          ? "CUSTOM_AUTH"
          : "USER_PASSWORD_AUTH",
        cookieStorage: {
          domain: config.COGNITO_COOKIE_DOMAIN,
          path: "/",
          expires: 1,
          secure: true,
        },
      });
      this.setRegionConfig(regionResponse);
    },
    async getRegion(regionName: any) {
      const {
        data: { data: regionResponse },
      } = await axios.get(
        this.getEndpoint("rest", endpoints.region(regionName)),
        {
          withCredentials: false,
        } as any
      );

      return regionResponse;
    },
    setRegionConfig(values: any) {
      this.regionConfig = values;
    },
  },
});

export default useApiStore;
