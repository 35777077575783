<script setup lang="ts">
import { ElButton } from "element-plus";
import { onClickOutside } from "@vueuse/core";
import { ref } from "vue";
import type { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";

export type ElButtonProps = InstanceType<typeof ElButton>["$props"];

export interface Props extends /* @vue-ignore */ ElButtonProps {
  icon?: IconProp;
  iconSize?: SizeProp;
  iconRotates?: boolean;
  iconSpin?: boolean;
}

const props = defineProps<Props>();
const isRotate = ref(false);
const btnRef = ref();

onClickOutside(btnRef, () => changeRotateState(false));

const changeRotateState = (_isRotate: boolean) => {
  if (!props.iconRotates) return;
  isRotate.value = _isRotate;
};
</script>

<template>
  <el-button
    ref="btnRef"
    :class="{
      'icon-only': !$slots.default,
      'icon-rotate': props.iconRotates,
      rotate: isRotate && props.iconRotates,
    }"
    @click="changeRotateState(!isRotate)"
  >
    <slot />
    <template
      v-if="icon"
      #icon
    >
      <font-awesome-icon
        :icon="props.icon"
        :size="props.iconSize"
        :spin="props.iconSpin"
      />
    </template>
  </el-button>
</template>

<style lang="scss" scoped>
.so-button {
  min-width: 110px;

  &.icon-only {
    padding: 8px;
    min-width: auto;

    &.so-button--small {
      padding: 5px;
    }
    &.so-button--large {
      padding: 12px;
    }
  }

  &.is-plain {
    --so-button-bg-color: transparent;
  }
}

.so-button.is-text {
  padding-left: 0;
  padding-right: 0;
  min-width: auto;

  &:hover:not(.is-disabled) {
    color: var(--so-color-primary);

    &.so-button--primary {
      color: var(--so-color-primary-light-3);
    }
    &.so-button--success {
      color: var(--so-color-success-light-3);
    }
    &.so-button--info {
      color: var(--so-color-info-light-3);
    }
    &.so-button--warning {
      color: var(--so-color-warning-light-3);
    }
    &.so-button--danger {
      color: var(--so-color-danger-light-3);
    }
  }

  &:focus,
  &:hover {
    background-color: transparent;
  }
}

.so-button :deep(.so-tag) {
  min-width: 35px;
}

.so-button > :deep(.so-icon) {
  width: unset;
}

.so-button.icon-only > :deep(span) {
  display: none;
}

.so-button :deep(svg.so-icon--right) {
  transition: transform var(--so-transition-duration-fast);
}

.so-button.icon-rotate > :deep(span) {
  width: 100%;
}

.so-button.icon-rotate :deep(svg.so-icon--right) {
  margin-left: auto;
}

.so-button.icon-rotate.rotate :deep(svg.so-icon--right) {
  transform: rotate(180deg);
}

.so-button--primary {
  &:not(.is-plain):not(.is-text):not(.is-link) {
    --so-button-text-color: var(--so-button-primary-text-color);

    --so-button-hover-text-color: var(--so-button-text-color);
    --so-button-hover-bg-color: var(--so-button-primary-hover-color);
    --so-button-hover-border-color: var(--so-button-primary-hover-color);

    --so-button-disabled-text-color: var(--so-button-text-color);
  }
}

.so-button--primary.is-plain {
  --so-button-border-color: var(--so-color-primary);

  --so-button-hover-text-color: var(--so-button-text-color);
  --so-button-hover-bg-color: var(--so-button-secondary-hover-color);
  --so-button-active-text-color: var(--so-button-hover-text-color);
  --so-button-active-bg-color: var(--so-button-secondary-hover-color);
  --so-button-active-border-color: var(--so-color-primary);

  &.is-disabled {
    background-color: transparent;
  }

  &.icon-only {
    --so-button-border-color: var(--so-button-icon-border-color);
    --so-button-hover-border-color: var(--so-button-icon-border-color);
  }

  &.is-link {
    --so-button-hover-link-text-color: var(--so-button-primary-hover-color);
  }
}

.so-button--success.is-plain {
  --so-button-hover-text-color: var(--so-button-text-color);
  --so-button-hover-bg-color: var(--so-color-success-light-9);
  --so-button-hover-border-color: var(--so-color-success-light-7);

  --so-button-active-text-color: var(--so-button-hover-text-color);
  --so-button-active-bg-color: var(--so-color-success-light-9);
  --so-button-active-border-color: var(--so-color-success);
}

.so-button--info.is-plain {
  --so-button-border-color: var(--so-button-tertiary-border-color);
  --so-button-hover-text-color: var(--so-button-text-color);
  --so-button-hover-bg-color: var(--so-button-tertiary-hover-color);
  --so-button-hover-border-color: var(--so-button-border-color);

  --so-button-active-bg-color: var(--so-button-tertiary-hover-color);
  --so-button-active-border-color: var(--so-button-border-color);
  --so-button-active-text-color: var(--so-button-text-color);

  &.is-disabled {
    background-color: transparent;
  }
}

.so-button--warning.is-plain {
  --so-button-hover-text-color: var(--so-button-text-color);
  --so-button-hover-bg-color: var(--so-color-warning-light-9);
  --so-button-hover-border-color: var(--so-color-warning-light-7);

  --so-button-active-text-color: var(--so-button-hover-text-color);
  --so-button-active-bg-color: var(--so-color-warning-light-9);
  --so-button-active-border-color: var(--so-color-warning);
}

.so-button--danger.is-plain {
  --so-button-hover-text-color: var(--so-button-text-color);
  --so-button-hover-bg-color: var(--so-color-danger-light-9);
  --so-button-hover-border-color: var(--so-color-danger-light-7);

  --so-button-active-text-color: var(--so-button-hover-text-color);
  --so-button-active-bg-color: var(--so-color-danger-light-9);
  --so-button-active-border-color: var(--so-color-danger);
}
</style>
