import { tags, type MaybeRef } from "@/api/common";
import { useQuery } from "@tanstack/vue-query";
import { ApiClient } from "@/api/zoolander";
import { queryKeys } from "../queryKeys";
import useAccountStore from "@/stores/account";

export const useGetSubscription = (
  staleTime?: MaybeRef<number>,
  enabled?: MaybeRef<boolean>
) => {
  const accountStore = useAccountStore();

  return useQuery({
    queryKey: queryKeys[tags.zoolander.subscription].get().queryKey,
    queryFn: async () => {
      const response = await new ApiClient().getSubscription({
        expand: "features,plan,subscriptionResources",
      });
      accountStore.setSubscriptionFeatures(response.data);

      return response;
    },
    select: ({ data }) => data,
    staleTime,
    enabled,
  });
};
