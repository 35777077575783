import { config } from "@/config/envConfig";

export const MAX_PROFILE_IMAGE_SIZE_B = 2000000; // 2MB
export const MAX_CSV_UPLOAD_SIZE_B = 10000000; // 10MB
export const MAX_CSV_UPLOAD_SIZE_MB = MAX_CSV_UPLOAD_SIZE_B / 1000000;
export const MAX_SPACE_RESOURCE_IMAGE_SIZE_B = 10000000; // 10MB
export const MAX_SPACE_RESOURCE_MAP_IMAGE_SIZE_B = 2000000; // 2MB
export const MAX_COMPANY_IMAGE_SIZE_B = 2000000; // 2MB

export const MAX_ACTIVE_SCREENSAVERS = 10;
export const MAX_SCREENSAVER_RESOLUTION = 2048;
export const SCREENSAVER_ROTATE_INTERVAL = 10000;

export const MAX_ACTIVITY_ITEMS = 20;

export const SLACK_ADDON_NAME = "slack";
export const TEAMS_ADDON_NAME = "teams";

export const FORGOT_PASSWORD = "ForgotPassword";

export const ADMIN_CREATE_USER = "AdminCreateUser";

export enum ADDONS {
  INDUCTIONS = "inductions",
  CATERING = "catering",
  DELIVERIES = "deliveries",
  SMS = "sms",
  RESOURCES = "resources",
  ROOMS = "rooms",
}

export enum ADDON_STATUS {
  ON = "On",
  OFF = "Off",
  TRIAL = "Trial",
}

export const INTEGRATION_STATUS = {
  AVAILABLE: "available",
  INSTALLED: "installed",
};

export const DELIVERY_STATUS = {
  COLLECTED: "Collected",
  DELIVERED: "Delivered",
};

export const EVAC_USER_TYPE = {
  VISITOR: "visitor",
  EMPLOYEE: "employee",
};

export const FIELD_TYPES = {
  DOCUMENT: "document",
  MULTISELECT: "multiselect",
  VACCINE_PASS: "vaccinepass",
  STRING: "string",
  PHONE: "phone",
  EMAIL: "email",
  DOB: "dob",
  MULTICHOICE: "multichoice",
  YES_NO: "yesno",
};

export enum MODE_DRAWER {
  NEW = "NEW",
  EDIT = "EDIT",
}

export const VACCINE_PASS_TYPES = {
  EMPTY: "empty",
  PROVIDED: "provided",
  SKIPPED: "skipped",
};

export const ROLES = {
  SUPER_ADMIN: "Super Admin",
  COMPANY_ADMIN: "Company Admin",
  EMPLOYEE: "Employee",
  LOCATION_MANAGER: "Location Manager",
  GROUP_ADMIN: "Group Admin",
  COMPANY_ADMIN_ID: "2",
  EMPLOYEE_ID: "3",
  LOCATION_MANAGER_ID: "4",
  MAP: [] as string[],
  ORDER: [] as string[],
};

ROLES.MAP = [
  ROLES.SUPER_ADMIN,
  ROLES.COMPANY_ADMIN,
  ROLES.EMPLOYEE,
  ROLES.LOCATION_MANAGER,
];

ROLES.ORDER = [ROLES.COMPANY_ADMIN, ROLES.LOCATION_MANAGER, ROLES.EMPLOYEE];

export const STATUSES = {
  STATUS_PENDING: "pending",
  STATUS_DENIED: "denied",
  STATUS_DENY: "deny",
  STATUS_LAPSED: "lapsed",
  STATUS_ALLOW: "allow",
  STATUS_REMOTE: "remote",
  STATUS_OUT: "out",
  STATUS_IN: "in",
  STATUS_IN_ID: "1",
  STATUS_OUT_ID: "2",
};

export const ADMIN_ROLE = {
  COMPANY_ADMIN: "company-admin",
  LOCATION_MANAGER: "location-manager",
  EMPLOYEE: "employee",
};

export enum PLANS {
  FREE_TRIAL = "Free Trial",
  FREEMIUM = "freemium",
  LITE = "lite",
  STARTER = "starter",
  BUSINESS = "business",
  ENTERPRISE = "enterprise",
}

export const EVAC_SESSION_STORAGE_KEY = "evacuation-marked-as-safe";

export const APP_VERSION_STATUSES = {
  CURRENT: "current",
  SUPPORTED: "supported",
  INFO: "update-info",
  WARNING: "update-warning",
  DANGER: "update-danger",
  UNSUPPORTED: "unsupported",
  UNSUPPORTED_HARDWARE: "unsupported-hardware",
};

export const GOOGLE_MAPS = {
  GEOCODE_ENDPOINT: "https://maps.googleapis.com/maps/api/geocode/json",
  TIMEZONE_ENDPOINT: "https://maps.googleapis.com/maps/api/timezone/json",
};

export const VISIT_FORM = {
  MODE_NEW: "new",
  MODE_EDIT: "edit",
};

export const BREAKPOINT = {
  TABLET: 1024,
  MOBILE: 768,
};

export enum CONFIRM {
  SUCCESS = "Success",
  FAILURE = "Failure",
  PENDING = "Pending",
  SAVED = "Saved",
}

export const SAVELOADING = {
  PENDING: true,
  SUCCESS: false,
  CLEAR: undefined,
};

export const SAVE_CONFIRM_EXPIRY_TIME = 3000;

export const ALERT_EXPIRY_TIME = 3000;

export const QUERY_STALE_TIME = 20000;
export const SECOND_IN_MS = 1000;
export const MINUTE_IN_MS = 60 * SECOND_IN_MS;
export const HOUR_IN_MS = 60 * MINUTE_IN_MS;
export const MINUTES_IN_DAY = 1440;

export const SYNC_SERVICES = {
  AZURE_AD: "Azure Directory",
  ON_PREMISE_AD: "Active Directory",
};

export const MENU_NAME = {
  BEVERAGES: "Beverages",
  FOOD: "Food",
};

export const PROVISION_PLAN_GROUPS = config.ADMIN_PROVISION_PLAN_GROUPS || "";

export default {
  MAX_PROFILE_IMAGE_SIZE_B,
  MAX_PROFILE_IMAGE_SIZE_MB: MAX_PROFILE_IMAGE_SIZE_B / 1000000,
  MAX_CSV_UPLOAD_SIZE_B,
  MAX_CSV_UPLOAD_SIZE_MB,
  MAX_SPACE_RESOURCE_MAP_IMAGE_SIZE_B,
  MAX_SPACE_RESOURCE_MAP_IMAGE_SIZE_MB:
    MAX_SPACE_RESOURCE_MAP_IMAGE_SIZE_B / 1000000,
  MAX_COMPANY_IMAGE_SIZE_B,
  MAX_COMPANY_IMAGE_SIZE_MB: MAX_COMPANY_IMAGE_SIZE_B / 1000000,
  MAX_ACTIVE_SCREENSAVERS,
  MAX_SCREENSAVER_RESOLUTION,
  VISIT_FORM,
  CONFIRM,
  PROVISION_PLAN_GROUPS,
  WHATS_NEW_LINK: config.WHATS_NEW_LINK || "",
  SKIP_WHATS_NEW_UPDATE_CHECK: config.SKIP_WHATS_NEW_UPDATE_CHECK,
  BREAKPOINT,
  TIMELINES_COLUMN_SELECTOR_VISIBILITY: 491,
  STATUSES,
  ADMIN_ROLE,
  MENU_NAME,
};

export const VERIFIED_PROXIMITIES = [
  "near",
  "qr-dynamic",
  "qr-static",
  "immediate",
  "far",
  "geofence",
];

export enum RESPONSE_TYPE {
  BLOB = "blob",
}

export enum PROXIMITY {
  FAR = "far",
  GEOFENCE = "geofence",
  IMMEDIATE = "immediate",
  NEAR = "near",
  QR_DYNAMIC = "qr-dynamic",
  QR_STATIC = "qr-static",
}

export enum SOURCE {
  CONTACTLESS = "contactless",
  DASHBOARD = "dashboard",
  KIOSK = "kiosk",
  MOBILE = "mobile",
  SYSTEM = "system",
}

export enum STATUS {
  ALL = "all",
  ALLOW = "allow",
  APPROVE = "approve",
  DENIED = "denied",
  DENY = "deny",
  IN = "in",
  LAPSED = "lapsed",
  OUT = "out",
  PENDING = "pending",
  REJECT = "reject",
  REMOTE = "remote",
  ANONYMIZED = "anonymized",
}

export enum STATUS_ID {
  IN = "1",
  OUT = "2",
}

export enum STATUS_LABEL {
  APPROVE = "Approve",
  DENY = "Deny",
  IN = "In",
  OUT = "Out",
  REMOTE = "Remote",
}

export enum TINY_INT_AS_STRING {
  FALSE = "0",
  TRUE = "1",
}

export enum METHOD_ICON {
  DISPLAY = "display",
  MOBILE = "mobile",
  QRCODE = "qrcode",
  TABLET = "tablet",
}

export enum SOCKET_EVENT {
  MOVEMENT_NEW = "movement:new",
  MOVEMENT_EDIT = "movement:edit",
  VISIT_NEW = "visit:new",
  VISIT_EDIT = "visit:edit",
  VISIT_CLOSE = "visit:close",
  VISIT_REMOVE = "visit:remove",
  PREREGISTRATION_NEW = "preregistration:new",
  PREREGISTRATION_EDIT = "preregistration:edit",
  PREREGISTRATION_REMOVE = "preregistration:remove",
  DELIVERY_NEW = "delivery:new",
  DELIVERY_EDIT = "delivery:edit",
  DELIVERY_REMOVE = "delivery:remove",
  BOOKING_NEW = "spaceBooking:new",
  BOOKING_EDIT = "spaceBooking:edit",
  BOOKING_REMOVE = "spaceBooking:remove",
  SETUP_SPACES = "setup:spaces",
  SETUP_AZURE = "setup:azure",
}

export enum EMPLOYEE_REMOTE {
  None = "null",
  Enabled = "1",
  Disabled = "0",
}

export enum USER_TYPE {
  VISITOR = "visitor",
  EMPLOYEE = "employee",
}

export enum VISIT_TYPE {
  TYPE_TIMELINE = "timeline",
  TYPE_REMEMBERED = "remembered",
  TYPE_PREREGISTRATION = "preregistration",
}

export enum REVIEW_TYPE {
  VISIT = "visit",
  EMPLOYEE = "employee",
}

export enum FREQUENCY {
  MONTHLY = "monthly",
  ANNUAL = "annual",
}

export enum MAP_TYPE {
  ROOM = "room",
  ZONE = "zone",
}

export const ADDRESS_TYPE = [
  "point_of_interest",
  "park",
  "airport",
  "natural_feature",
  "subpremise",
  "premise",
  "sublocality",
  "locality",
  "intersection",
  "street_number",
  "route",
];

export enum PLAN_FEATURE {
  CONTACT_TRACING_REPORTS = "contact_tracing_reports",
  POCKET_APP = "pocket_app",
  TEAMS = "teams",
  SLACK = "slack",
  WORKING_REMOTE = "working_remote",
  ID_BADGE_PRINTING = "id_badge_printing",
  DIGITAL_AGREEMENTS = "digital_agreements",
  MULTIPLE_LOCATIONS = "multiple_locations",
  MULTIPLE_CATEGORIES = "multiple_categories",
  LIVE_CHAT = "live_chat",
  EMPLOYEE_ROAMING = "employee_roaming",
  AUTO_ANONYMISATION = "auto_anonymisation",
  ACTIVE_DIRECTORY = "active_directory",
  AZURE_AD = "azure_ad",
  VISITOR_FIELDS_BY_CATEGORY = "visitor_fields_by_category",
  PROXIMITY_VERIFICATION = "proximity_verification",
  EMPLOYEE_APPROVAL = "employee_approval",
  VISITOR_APPROVAL = "visitor_approval",
  CUSTOM_SCREENSAVERS = "custom_screensavers",
  VISITOR_PHOTOS = "visitor_photos",
  VISITOR_AGREEMENTS = "visitor_agreements",
  EMPLOYEE_VERIFICATION_PHOTOS = "employee_verification_photos",
  PREREGISTRATIONS = "preregistrations",
  SAME_DEVICES_AS_LOCATIONS = "same_devices_as_locations",
  INDUCTIONS = "inductions",
  CATERING = "catering",
  DELIVERIES = "deliveries",
  SMS = "sms",
  MFA_ENFORCEMENT = "mfa_enforcement",
  SSO = "sso",
}

export enum ResourcesVisibleField {
  Space = "space",
  Category = "category",
  Features = "features",
  Capacity = "capacity",
  TimeRange = "timeRange",
  Zones = "zones",
}

export enum PAYMENT_METHOD {
  CREDIT_CARD = "credit_card",
  MANUAL = "manual",
  REFUND = "Refund",
}

export enum API_ERROR_RESPONSE {
  FILE_TYPE = "fileType",
  FILE_SIZE = "fileSize",
}

export enum ORDER_STATUS {
  NEW = "new",
  UPGRADE = "upgrade",
  UPGRADE_RESOURCES = "upgradeResources",
  UPDATE = "update",
}

export enum CATEGORY {
  LOCATION = "location",
  ROOM = "room",
  RESOURCE = "resource",
}
