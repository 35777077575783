import { defineStore } from "pinia";
import useApiStore from "@/stores/api";
import useAuthStore from "@/stores/auth";
import { setCookie } from "tiny-cookie";
import type { RouteLocationNormalized } from "vue-router";
import { config } from "@/config/envConfig";
import { STATUS, REVIEW_TYPE } from "@/stores/constants";
import axios from "axios";
import { endpoints } from "@/endpoints";
import type { ReviewEmployeeResponse, ReviewVisitorResponse } from "./types";
import { ref } from "vue";

export const useApprovalsStore = defineStore("approvals", () => {
  const visitorResponse = ref<ReviewVisitorResponse | undefined>(undefined);
  const employeeResponse = ref<ReviewEmployeeResponse | undefined>(undefined);

  const handleAuthless = async (to: RouteLocationNormalized) => {
    const authStore = useAuthStore();
    const apiStore = useApiStore();
    const movementPk = to.params.movementPk as string;
    const reviewerPk = to.params.reviewerPk as string;
    const status = to.meta.status as STATUS.APPROVE | STATUS.REJECT;
    const type = to.meta.type as REVIEW_TYPE.EMPLOYEE | REVIEW_TYPE.VISIT;
    let name: string | undefined;

    await authStore.checkLogin({ to });

    if (!authStore.authUser) {
      const regionResponse = await apiStore.getRegion(to.query.region);
      apiStore.setRegionConfig(regionResponse);
      setCookie(config.REGION_COOKIE_NAME, regionResponse.region.name, {
        secure: true,
      });
    }

    if (type === REVIEW_TYPE.EMPLOYEE) {
      employeeResponse.value = undefined;

      const url =
        status === STATUS.APPROVE
          ? apiStore.getEndpoint(
              "platform",
              endpoints.employees.reviewApprove(movementPk)
            )
          : apiStore.getEndpoint(
              "platform",
              endpoints.employees.reviewReject(movementPk)
            );

      try {
        const response = await axios.patch<ReviewEmployeeResponse>(
          url,
          { reviewerPk },
          { withCredentials: false }
        );
        employeeResponse.value = response.data;
      } catch (error) {
        return;
      }

      name = "employees-timeline";
    }

    if (type === REVIEW_TYPE.VISIT) {
      visitorResponse.value = undefined;

      const url =
        status === STATUS.APPROVE
          ? apiStore.getEndpoint(
              "platform",
              endpoints.visitors.reviewApprove(movementPk)
            )
          : apiStore.getEndpoint(
              "platform",
              endpoints.visitors.reviewReject(movementPk)
            );

      try {
        const response = await axios.patch<ReviewVisitorResponse>(
          url,
          { reviewerPk },
          { withCredentials: false }
        );
        visitorResponse.value = response.data;
      } catch (error) {
        return;
      }

      name = "visitors-timeline";
    }

    if (authStore.authUser && name) {
      return { name, params: { id: movementPk } };
    }

    return;
  };

  return {
    visitorResponse,
    employeeResponse,
    handleAuthless,
  };
});

export default useApprovalsStore;
