import axios, { AxiosError, type InternalAxiosRequestConfig } from "axios";
import { uuid } from "short-uuid";
import * as Sentry from "@sentry/vue";
import { config } from "../envConfig";
import useAuthStore from "@/stores/auth";
import useApiStore from "@/stores/api";
import { useRootStore } from "@/stores";
import { i18n } from "..";
import { endpoints } from "@/endpoints";
import { useAuth } from "@/composables/auth";

const tracingOrigins = config.SENTRY_TRACING_ORIGINS;

const handlers = {
  async preRequest(request: InternalAxiosRequestConfig) {
    request.headers = request.headers ?? {};
    const sentryTransactionId = uuid();
    const authStore = useAuthStore();
    const { isSuperAdmin, authUser } = useAuth();

    if (tracingOrigins.some((orig) => request.url?.match(orig))) {
      request.headers["x-swipedon-transaction-id"] = sentryTransactionId;
      Sentry.setTag("transaction_id", sentryTransactionId);
    }

    if (
      typeof request.data === "undefined" &&
      !["get", "head"].includes(request.method?.toLowerCase() ?? "")
    ) {
      request.data = {};
    }

    if (config.APP_BLOG_URL && request.url?.includes(config.APP_BLOG_URL)) {
      return request;
    }

    if (
      request.withCredentials === false ||
      request.url?.toLowerCase().includes("x-amz-algorithm")
    ) {
      return request;
    }

    let accessToken = await authStore.getAccessToken();
    if (!accessToken) {
      throw new axios.Cancel("No access token");
    }
    // TODO: remove temporary HACK when platform accepts Bearer in its authorization header
    if (request.url?.startsWith("https://api")) {
      accessToken = "Bearer " + accessToken;
    }
    request.headers.Authorization = accessToken;

    const companyPk = authUser?.value?.companyPk ?? null;

    if (authUser && companyPk && isSuperAdmin.value) {
      request.headers["X-SwipedOn-CompanyPk"] = companyPk;
    }

    return request;
  },

  async errorResponse(error: AxiosError & { handledGlobally: boolean }) {
    const authStore = useAuthStore();
    if (error.message === "No access token") {
      error.handledGlobally = true;

      await authStore.logout();
    }
    // TODO: complete handler here
    if (error.response) {
      const request = error.config;
      let handledGlobally = false;
      const apiStore = useApiStore();
      const authStore = useAuthStore();
      const rootStore = useRootStore();
      const { t } = i18n.global;
      switch (error.response.status) {
        case 401: // Authentication error
          if (request?.url?.includes(endpoints.integrations.config))
            return Promise.reject(error);
          handledGlobally = true;
          if (
            request?.url?.includes(apiStore.getServiceRegionalUrl("rest")) ||
            !request?.url?.includes("//")
          ) {
            authStore.logout();
          } else {
            rootStore.handleException({ error });
          }
          break;
        case 403: // Authorisation error
          if (request?.url?.includes(endpoints.integrations.config)) return;

          // TODO: remove temporary HACK due to issue RT-1119
          if (
            request?.method === "patch" &&
            request?.url?.includes(endpoints.locations.list)
          )
            return;

          rootStore.setAlert({
            type: "error",
            message: { title: t("errors.unauthorized") },
            isNotification: true,
            isShowMessageSupport: true,
          });
          handledGlobally = true;
          break;
        case 500: // Internal server error
          rootStore.handleException({ error });
          handledGlobally = true;
          break;
        default:
      }
      error.handledGlobally = handledGlobally;
      return Promise.reject(error);
    }
    // There was no response
    error.message = error.message || "No response";
    // store.dispatch("handleException", { error });
    error.handledGlobally = true;
    return Promise.reject(error);
  },
};
export default handlers;
