<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ElAlert } from "element-plus";
import CoreLink from "@/components/common/CoreLink/CoreLink.vue";

const browseHappyUrl = "https://browsehappy.com/";
const { t } = useI18n();
</script>

<template>
  <el-alert
    type="warning"
    show-icon
    :title="t('info.browserWarning.outOfDate')"
  >
    <i18n-t
      keypath="info.browserWarning.updateYourBrowser"
      tag="span"
      scope="global"
    >
      <template #action>
        <CoreLink
          type="primary"
          :underline="false"
          :href="browseHappyUrl"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ t("info.browserWarning.updateNow") }}
        </CoreLink>
      </template>
    </i18n-t>
  </el-alert>
</template>
