import type { CompanySettingsApiResponse } from "@/api/companies";
import type { Location, LocationApiResult } from "@/api/locations";
import type { DeepPartial } from "@/types/utility";

export const enum LocationCategory {
  ALL = "ALL",
  GROUP = "GROUP",
  SINGLE = "SINGLE",
}

export type LocationGroup = {
  id: string;
  numericId: number;
  name: string;
  invisible: boolean;
  locations: Location[];
  order: number;
  visitor_auto_anonymise: boolean;
};

export type LocationGroupsApiResponse = {
  id: string;
  pk: string;
  name: string;
  invisible: "0" | "1";
  visitor_autologout: "0" | "1";
  visitor_auto_anonymise: "0" | "1";
  emp_autologout: "0" | "1";
  settings: Partial<CompanySettingsApiResponse>;
  email_logo_key: string | null;
  contactless_logo_key: string | null;
  gadget_logo_key: string | null;
  label_key: string | null;
  order: string;
  locations: LocationApiResult[];
};

export type AddLocationRequest = {
  location: {
    name: string;
    company_id: number;
    time_zone: string;
    latlng: [number, number];
    address: string;
    address_components: google.maps.GeocoderAddressComponent[];
  };
  sourceLocationGroupPk: string;
};

export interface LocationGroupsApiRequest
  extends DeepPartial<LocationGroupsApiResponse> {
  location?: AddLocationRequest;
}

export type CreateGroupRequest = {
  name: string;
  sourceLocationGroupPk: string;
};

export type AddLocationErrorResponse = {
  httpCode: number;
  success: boolean;
  errors: {
    attribute: string;
    code: number;
    details: string;
    status: number;
    title: string;
  }[];
};
