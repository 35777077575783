<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import socLightModeImage from "@/assets/images/AICPA-SOC-dark.png";
import socDarkModeImage from "@/assets/images/AICPA-SOC-light.png";
import psLightModeImage from "@/assets/images/PS-certified-dark.png";
import psDarkModeImage from "@/assets/images/PS-certified-light.png";
import DynamicImage from "@/components/common/DynamicImage/DynamicImage.vue";

const { t } = useI18n();
</script>

<template>
  <div class="security-certifications">
    <DynamicImage
      :dark-mode-image="psDarkModeImage"
      :default-image="psLightModeImage"
      :alt="t('imgAlt.iso27001')"
    />
    <DynamicImage
      :dark-mode-image="socDarkModeImage"
      :default-image="socLightModeImage"
      :alt="t('imgAlt.soc2')"
    />
  </div>
</template>

<style lang="scss" scoped>
.security-certifications {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: flex-start;

  @media all and (min-width: $breakpoint-md) {
    text-align: left;
    justify-content: flex-start;
  }

  > img {
    text-align: center;
    margin: 0 25px;

    @media all and (min-width: $breakpoint-sm) {
      margin: 0 50px 0 0;
    }
  }
}
</style>
