import { endpoints } from "@/endpoints";
import { tags } from "@/api/common";
import { queryKeys } from "@/api/queryKeys";
import useApiStore from "@/stores/api";
import { useQuery } from "@tanstack/vue-query";
import axios from "axios";
import type { WhatsNewResponse } from "@/api/whatsnew";
import constants from "@/stores/constants";
import { config } from "@/config/envConfig";

export const useGetWhatsNew = () => {
  const apiStore = useApiStore();
  return useQuery({
    queryKey: queryKeys[tags.rest.whatsNew].get().queryKey,
    queryFn: async () => {
      const result = await axios.get<WhatsNewResponse>(
        apiStore.getEndpoint("rest", endpoints.getWhatsNew)
      );
      return result.data;
    },
    select: (data) => data.updated,
    staleTime: Infinity,
    enabled:
      ["production", "staging"].includes(config.MODE.toLowerCase()) &&
      !constants.SKIP_WHATS_NEW_UPDATE_CHECK,
  });
};
