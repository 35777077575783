import { config } from "@/config/envConfig";
import useApiStore from "@/stores/api";
import { useAuth } from "../auth";
import axios from "axios";
import { endpoints } from "@/endpoints";
import useAuthStore from "@/stores/auth";
import { watch } from "vue";
import pw from "./pw.js?url";

export const useProfitwell = () => {
  const apiStore = useApiStore();
  const authStore = useAuthStore();
  const { isSuperAdmin } = useAuth();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const w = window as any;
  const PROFITWELL_API_TOKEN = config.PROFITWELL_API_TOKEN;

  const setup = async () => {
    if (!PROFITWELL_API_TOKEN) return;

    const l = function () {
      const s = document.createElement("script");
      s.setAttribute("data-pw-auth", PROFITWELL_API_TOKEN);
      s.id = "profitwell-js";
      s.src = pw;
      s.type = "text/javascript";
      s.async = true;
      const x = document.getElementsByTagName("script")[0];
      x.parentNode?.insertBefore(s, x);
    };
    if (document.readyState === "complete") {
      l();
    } else if (w.attachEvent) {
      w.attachEvent("onload", l);
    } else {
      w.addEventListener("load", l, false);
    }
  };

  const startProfitwell = async () => {
    try {
      if (isSuperAdmin.value || !PROFITWELL_API_TOKEN) return;

      const {
        data: {
          company: { billingEmail },
        },
      } = await axios.get(
        apiStore.getEndpoint("rest", endpoints.profitwellCustomer)
      );

      if (!billingEmail) return;

      w.profitwell("start", { user_email: billingEmail });
    } catch (error) {
      console.error("Failed to start Profitwell");
    }
  };

  watch(
    () => authStore.authUser,
    () => startProfitwell()
  );

  return { setup };
};
