const elementPlusLocales: Record<
  string,
  () => Promise<typeof import("element-plus/dist/locale/*.mjs")>
> = {
  af: async () => await import("element-plus/dist/locale/af.mjs"),
  ar: async () => await import("element-plus/dist/locale/ar.mjs"),
  az: async () => await import("element-plus/dist/locale/az.mjs"),
  bg: async () => await import("element-plus/dist/locale/bg.mjs"),
  bn: async () => await import("element-plus/dist/locale/bn.mjs"),
  ca: async () => await import("element-plus/dist/locale/ca.mjs"),
  cs: async () => await import("element-plus/dist/locale/cs.mjs"),
  da: async () => await import("element-plus/dist/locale/da.mjs"),
  de: async () => await import("element-plus/dist/locale/de.mjs"),
  el: async () => await import("element-plus/dist/locale/el.mjs"),
  en: async () => await import("element-plus/dist/locale/en.mjs"),
  eo: async () => await import("element-plus/dist/locale/eo.mjs"),
  es: async () => await import("element-plus/dist/locale/es.mjs"),
  et: async () => await import("element-plus/dist/locale/et.mjs"),
  eu: async () => await import("element-plus/dist/locale/eu.mjs"),
  fa: async () => await import("element-plus/dist/locale/fa.mjs"),
  fi: async () => await import("element-plus/dist/locale/fi.mjs"),
  fr: async () => await import("element-plus/dist/locale/fr.mjs"),
  he: async () => await import("element-plus/dist/locale/he.mjs"),
  hr: async () => await import("element-plus/dist/locale/hr.mjs"),
  hu: async () => await import("element-plus/dist/locale/hu.mjs"),
  "hy-am": async () => await import("element-plus/dist/locale/hy-am.mjs"),
  id: async () => await import("element-plus/dist/locale/id.mjs"),
  it: async () => await import("element-plus/dist/locale/it.mjs"),
  ja: async () => await import("element-plus/dist/locale/ja.mjs"),
  kk: async () => await import("element-plus/dist/locale/kk.mjs"),
  km: async () => await import("element-plus/dist/locale/km.mjs"),
  ko: async () => await import("element-plus/dist/locale/ko.mjs"),
  "en-test": async () => await import("element-plus/dist/locale/ar.mjs"),
  ku: async () => await import("element-plus/dist/locale/ku.mjs"),
  ky: async () => await import("element-plus/dist/locale/ky.mjs"),
  lt: async () => await import("element-plus/dist/locale/lt.mjs"),
  lv: async () => await import("element-plus/dist/locale/lv.mjs"),
  mg: async () => await import("element-plus/dist/locale/mg.mjs"),
  mn: async () => await import("element-plus/dist/locale/mn.mjs"),
  "nb-no": async () => await import("element-plus/dist/locale/nb-no.mjs"),
  nl: async () => await import("element-plus/dist/locale/nl.mjs"),
  pa: async () => await import("element-plus/dist/locale/pa.mjs"),
  pl: async () => await import("element-plus/dist/locale/pl.mjs"),
  "pt-br": async () => await import("element-plus/dist/locale/pt-br.mjs"),
  pt: async () => await import("element-plus/dist/locale/pt.mjs"),
  ro: async () => await import("element-plus/dist/locale/ro.mjs"),
  ru: async () => await import("element-plus/dist/locale/ru.mjs"),
  sk: async () => await import("element-plus/dist/locale/sk.mjs"),
  sl: async () => await import("element-plus/dist/locale/sl.mjs"),
  sr: async () => await import("element-plus/dist/locale/sr.mjs"),
  sv: async () => await import("element-plus/dist/locale/sv.mjs"),
  ta: async () => await import("element-plus/dist/locale/ta.mjs"),
  th: async () => await import("element-plus/dist/locale/th.mjs"),
  tk: async () => await import("element-plus/dist/locale/tk.mjs"),
  tr: async () => await import("element-plus/dist/locale/tr.mjs"),
  "ug-cn": async () => await import("element-plus/dist/locale/ug-cn.mjs"),
  uk: async () => await import("element-plus/dist/locale/uk.mjs"),
  "uz-uz": async () => await import("element-plus/dist/locale/uz-uz.mjs"),
  vi: async () => await import("element-plus/dist/locale/vi.mjs"),
  "zh-cn": async () => await import("element-plus/dist/locale/zh-cn.mjs"),
  "zh-tw": async () => await import("element-plus/dist/locale/zh-tw.mjs"),
};

export default elementPlusLocales;
