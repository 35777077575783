<script setup lang="ts">
import MobileNav from "./MobileNav.vue";
import DesktopNav from "./DesktopNav.vue";
import { useScreenType } from "@/composables/useScreenType";
import MfaDialog from "@/components/common/MfaDialog/MfaDialog.vue";
import ChangePasswordDialog from "@/components/common/ChangePasswordDialog/ChangePasswordDialog.vue";
import { useMenu } from "./composables/menu";

const { isMobile } = useScreenType();
const { menu } = useMenu();
</script>

<template>
  <component
    :is="isMobile ? MobileNav : DesktopNav"
    :nav-items="menu"
  />

  <MfaDialog />
  <ChangePasswordDialog />
</template>
