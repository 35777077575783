import { i18n } from "@/config";

export const parseToObject = (json: Record<string, unknown> | string) => {
  if (!json) {
    return {};
  }
  return typeof json === "string" ? JSON.parse(json) : json;
};

export const formatFullName = (
  firstName?: string,
  lastName?: string,
  fullName = true
) => {
  const names = {
    firstName: firstName ?? "",
    lastName: lastName ?? "",
  };

  return fullName
    ? i18n.global.t("format.fullName", names).trim()
    : i18n.global.t("format.shortName", names).trim();
};

export const rounded = (value: number) => {
  if (!value && value !== 0) return "";
  return (Math.round(value * 100) / 100).toString();
};

export const camelCase = (value: string) => {
  return value
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase()
    )
    .replace(/\s+/g, "");
};

export default {
  parseToObject,
  formatFullName,
  rounded,
  camelCase,
};
