<script setup lang="ts">
import { useGetCompany } from "@/api/companies";
import brandIcon from "@theme/branding/icon-negative.svg";

const { data: company } = useGetCompany(0);
</script>

<template>
  <div class="banner">
    <img
      v-cy="'swipedonIcon'"
      :src="brandIcon"
      alt=""
    />
    <i18n-t
      keypath="info.superAdminWarning"
      tag="span"
      scope="global"
    >
      <template #companyName>
        <strong>{{ company?.name }}</strong>
      </template>
    </i18n-t>
  </div>
</template>

<style lang="scss" scoped>
.banner {
  display: flex;
  justify-content: center;
  background-color: var(--so-color-secondary);
  color: var(--so-text-color-trial-banner);
  padding: 8px;
  gap: 10px;
}
</style>
