import { useListPlans } from "@/api/plans";
import { computed, type Ref } from "vue";
import type { PlanCard } from "./types";
import type { ListPlanItem } from "@/api/zoolander";
import { useGetCustomer } from "@/api/customer";
import { useSubscription } from "@/composables/subscription";
import { PLANS, FREQUENCY } from "@/stores/constants";

const annualFrequency = FREQUENCY.ANNUAL;
export const defaultTrialPlan = PLANS.BUSINESS;

export const defaultAddons = [
  { text: "catering", isIncluded: false },
  { text: "deliveries", isIncluded: false },
  { text: "sms", isIncluded: false },
  { text: "inductions", isIncluded: false },
];

export const getPlanVersion = (group: string) => {
  return Number(group?.replace(/plan|-\d+/gi, "") || 0);
};

export const usePlans = (currency: Ref<string>, recommendedPlan?: string) => {
  const { data, isLoading } = useListPlans({
    currency,
    expand: "features",
  });
  const { data: customer } = useGetCustomer();
  const { isFreeTrial, isNotLatestPlan, isBusiness, isEnterprise } =
    useSubscription();

  const groupedPlans = computed(
    () =>
      (data.value ?? []).reduce(
        (group: { [key: string]: ListPlanItem[] }, plan) => {
          if (!plan.level) return group;
          if (!group[plan.level]) {
            group[plan.level] = [];
          }

          group[plan.level].push(plan);

          return group;
        },
        {}
      ) ?? {}
  );

  const planCards = computed(() =>
    Object.keys(groupedPlans.value ?? {}).map((planName) => {
      const plan = groupedPlans.value[planName];

      const addons = defaultAddons.map((addon) => ({
        ...addon,
        isIncluded:
          plan
            .flatMap((item) => item.features ?? [])
            .find((item) => item.name?.toLowerCase() === addon.text)?.bundled ??
          false,
      }));

      const features = plan.flatMap((item) => item.features ?? []);

      const planLimits = plan.find((item) => {
        return {
          devices: item.devices,
          employees: item.employees,
        };
      });

      const annualPlan = plan.find(
        (item) => item.frequency === annualFrequency
      );

      const monthlyPlan = plan.find(
        (item) => item.frequency !== annualFrequency
      );

      return {
        planName: planName,
        employees: planLimits?.employees ?? 0,
        devices: planLimits?.devices ?? 0,
        providerIds: {
          annual: annualPlan?.providerId ?? "",
          monthly: monthlyPlan?.providerId ?? "",
        },
        ids: {
          annual: annualPlan?.id ?? "",
          monthly: monthlyPlan?.id ?? "",
        },
        price: {
          currency: currency.value,
          annual: Number(annualPlan?.price ?? 0),
          monthly: Number(monthlyPlan?.price ?? 0),
        },
        addons,
        features,
      } as PlanCard;
    })
  );

  const currentPlanMarker = computed(() => {
    if (isNotLatestPlan.value) return "";

    return customer.value?.plan?.level ?? "";
  });

  const recommendedPlanMarker = computed(() => {
    if (currentPlanMarker.value || isEnterprise.value) return "";
    if (isBusiness.value) return PLANS.ENTERPRISE;

    return defaultTrialPlan;
  });

  const plans = computed(() => {
    const filteredPlans = planCards.value.filter((planCard) => {
      if (currentPlanMarker.value === PLANS.LITE) {
        return true;
      }
      return planCard.planName !== PLANS.LITE;
    });
    return filteredPlans.map((planCard, index) => {
      const isRecommended = recommendedPlan
        ? recommendedPlan === planCard.planName
        : planCard.planName === recommendedPlanMarker.value;

      return {
        ...planCard,
        isRecommended,
        isCurrentPlan: currentPlanMarker.value === planCard.planName,
        previousPlanName: index > 0 ? filteredPlans[index - 1].planName : "",
      };
    });
  });

  const defaultPlan = computed(
    () =>
      plans.value
        .filter(
          (plan) =>
            (plan.planName == defaultTrialPlan && isFreeTrial.value) ||
            customer.value?.plan?.level === plan.planName
        )
        .map((plan) => ({
          planName: plan.planName,
          providerIds: plan.providerIds,
          ids: plan.ids,
        }))[0]
  );

  const getPlan = (providerDetected: string) =>
    plans.value
      .filter(
        (plan) =>
          plan.planName === providerDetected ||
          plan.providerIds.annual === providerDetected ||
          plan.providerIds.monthly === providerDetected
      )
      .map((plan) => ({
        planName: plan.planName,
        providerIds: plan.providerIds,
        ids: plan.ids,
      }))[0];

  return {
    isLoading,
    plans,
    defaultPlan,
    getPlan,
  };
};
