import type { FieldSettings } from "@/api/visitorFields";
import type { PhoneInputType } from "@/components/common/CorePhoneInput/types";
import type { FormItemRule } from "element-plus";
import { unref, type MaybeRef, type Ref } from "vue";
import { useI18n } from "vue-i18n";

export const useValidation = () => {
  const { t } = useI18n();

  const validateRange = (
    minChars?: number,
    maxChars?: number,
    triggers?: string[]
  ): FormItemRule => ({
    trigger: triggers?.length ? triggers : "blur",
    validator: (rule, value, callback) => {
      try {
        if (minChars !== undefined && value.trim().length < minChars) {
          callback(
            new Error(
              t("validation.minCharacters", { min: minChars }, minChars)
            )
          );
        } else if (maxChars !== undefined && value.trim().length > maxChars) {
          callback(
            new Error(
              t("validation.maxCharacters", { max: maxChars }, maxChars)
            )
          );
        }
        callback();
      } catch (error) {
        if (error instanceof Error) {
          callback(error.message || "Unexpected");
        }
      }
    },
  });

  const required = (trigger?: string): FormItemRule => ({
    required: true,
    message: () => t("validation.required"),
    trigger: trigger ?? "change",
  });

  const validateEmail = (email?: Ref<string>): FormItemRule => ({
    trigger: "blur",
    validator: (rule, value, callback) => {
      const regex = // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

      const emailValue = email?.value ?? value;

      if (emailValue?.length && !regex.test(emailValue)) {
        return callback(t("validation.invalidEmail"));
      }

      callback();
    },
  });

  const validateRequiredField = (
    value: Ref<string | unknown[] | null | undefined>
  ): FormItemRule => ({
    required: false,
    validator: (_rule, _value, callback) => {
      const isNullish = value.value === null || value.value === undefined;
      const isEmptyArr = Array.isArray(value.value) && value.value.length === 0;
      const isEmptyString = value.value === "";

      if (isNullish || isEmptyArr || isEmptyString) {
        return callback(t("validation.required"));
      }

      callback();
    },
  });

  const validatePhone = (phone?: Ref<PhoneInputType | null>): FormItemRule => ({
    validator: (_, value, callback) => {
      const phoneValue = phone?.value ?? value;

      if (phoneValue?.error) {
        callback(new Error(phoneValue.error));
        return;
      }
      callback();
    },
  });

  const validateApprovalField = (
    input: Ref<string | (string | number)[]>,
    settings: MaybeRef<FieldSettings>
  ): FormItemRule => ({
    validator: (_rule, _value, callback) => {
      const innerSettings = unref(settings);

      if (
        Array.isArray(input.value) &&
        input.value.some((val) =>
          innerSettings.reviewValues.includes(String(val))
        )
      ) {
        return callback(t("validation.requireApproval"));
      }

      if (
        typeof input.value === "string" &&
        innerSettings.reviewValues.some((val) => val === input.value)
      ) {
        return callback(t("validation.requireApproval"));
      }

      return callback();
    },
  });

  return {
    validateRange,
    validateEmail,
    required,
    validateRequiredField,
    validatePhone,
    validateApprovalField,
  };
};
