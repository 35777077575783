import { isRef, type Ref } from "vue";
import type { QueryClient, QueryKey } from "@tanstack/vue-query";

type UnwrapRef<T> = T extends Ref<infer V>
  ? UnwrapRef<V>
  : T extends Array<infer U>
  ? Array<UnwrapRef<U>>
  : T extends object
  ? { [K in keyof T]: UnwrapRef<T[K]> }
  : T;

export function unwrapDeepRefs<T, U extends UnwrapRef<T> = UnwrapRef<T>>(
  ref: T | Ref<T>
): U {
  if (ref instanceof Date) {
    return ref as unknown as U;
  }
  if (isRef(ref)) {
    return unwrapDeepRefs(ref.value) as U;
  }
  if (Array.isArray(ref)) {
    return ref.map((item) => unwrapDeepRefs(item)) as unknown as U;
  }
  if (typeof ref === "object" && ref !== null) {
    const result = {} as U;
    for (const [key, value] of Object.entries(ref)) {
      result[key as keyof U] = unwrapDeepRefs(value) as U[keyof U];
    }
    return result;
  }
  return ref as unknown as U;
}

//The mutate function of the optimistic update logic.
const optimisticArrayUpdateMutate = async <InputArray>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  newStatus: InputArray,
  indexKey: keyof typeof newStatus
) => {
  await queryClient.cancelQueries({
    queryKey,
  });

  const previousStatuses = queryClient.getQueryData<InputArray[]>(queryKey);

  if (!previousStatuses) {
    return;
  }

  const previousStatusIndex = previousStatuses.findIndex(
    (status) => status[indexKey] === newStatus[indexKey]
  );

  if (previousStatusIndex !== -1) {
    const newValues = [...previousStatuses];
    newValues[previousStatusIndex] = newStatus;
    queryClient.setQueryData(queryKey, newValues);
  }
  return previousStatuses;
};

// Optimistically update the state before performing the mutation.
// In the case that it fails, a refetch is triggered to revert them to their true state.
const optimisticArrayUpdateMutation = <ApiResponse, ErrorResponse, ApiRequest>(
  queryClient: QueryClient,
  queryKey: (request: ApiRequest) => QueryKey,
  indexKey: keyof ApiRequest
) => ({
  onSettled: (
    _data: ApiResponse | undefined,
    _error: ErrorResponse | null,
    request: ApiRequest
  ) => {
    queryClient.invalidateQueries(queryKey(request));
  },
  onMutate: (newValue: ApiRequest) => {
    return optimisticArrayUpdateMutate<ApiRequest>(
      queryClient,
      queryKey(newValue),
      newValue,
      indexKey
    );
  },
  onError: (
    _error: ErrorResponse,
    newStatus: ApiRequest,
    context: ApiRequest[] | undefined
  ) => {
    queryClient.setQueryData(queryKey(newStatus), context);
  },
});

export { optimisticArrayUpdateMutation };
