import * as Sentry from "@sentry/vue";
import { Replay } from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import useAuthStore from "@/stores/auth";
import useApiStore from "@/stores/api";
import router from "@/router";
import { config } from "./envConfig";
import type { Options, BrowserClientReplayOptions } from "@sentry/types";
import type { TracingOptions } from "@sentry/vue/types/types";
import type { App } from "vue";

const tracingOrigins = config.SENTRY_TRACING_ORIGINS;

const sentryOptions:
  | Partial<
      Omit<Options, "tracingOptions"> & {
        tracingOptions: Partial<TracingOptions>;
      } & BrowserClientReplayOptions
    >
  | undefined = {
  dsn: config.SENTRY_DSN,
  environment: config.SENTRY_ENVIRONMENT || "local",
  replaysSessionSampleRate: config.SENTRY_REPLAY_SAMPLE_RATE,
  replaysOnErrorSampleRate: config.SENTRY_REPLAY_ERROR_SAMPLE_RATE,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins,
    }),
    new Replay({
      maskAllText: config.SENTRY_REPLAY_MASK_TEXT,
    }),
  ],
  normalizeDepth: 10,
  tracesSampleRate: config.SENTRY_TRACE_SAMPLE_RATE,
  ignoreErrors: [
    "Request failed with status code 401",
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications",
    "Non-Error promise rejection captured with value: Timeout",
    /Unable to preload CSS for .*\.css/,
    "Event `Event` (type=error) captured as exception",
    "Network Error",
  ],
  beforeSend(event) {
    const authStore = useAuthStore();
    const authUser = authStore.authUser;
    const apiStore = useApiStore();

    event.tags = {
      ...event.tags,
      "company.name": authUser?.companyName ?? "—",
      "company.pk": authUser?.companyPk ?? "—",
      "location.name": authUser?.locationName ?? "—",
      "location.pk": authUser?.locationPk ?? "—",
      "user.role": authUser?.roles ?? "—",
      soRegion: apiStore.soRegion ?? "-",
    };

    event.user = {
      id: authUser?.pk ?? "—",
      role: authUser?.roles ?? "—",
      company: authUser?.companyPk ?? "—",
      location: authUser?.locationPk ?? "—",
    };

    if (event.exception && config.SENTRY_REPORTING_ENABLED) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }

    return event;
  },
};

const sentryInit = (app: App<Element>, options = sentryOptions) => {
  if (config.SENTRY_RELEASE && options) {
    options.release = config.SENTRY_RELEASE;
  }

  Sentry.init({ app, ...options });
};

export default sentryInit;
