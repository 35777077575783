<script setup lang="ts">
import { ElContainer, ElHeader } from "element-plus";
import MainNav from "./navigation/MainNav.vue";
import { productFruits } from "product-fruits";
import { config } from "@/config/envConfig";
import useAuthStore from "@/stores/auth";
import { useLanguage } from "@/composables/lang";
import { useElementSize } from "@vueuse/core";
import { computed, ref, watch } from "vue";
import { useLayoutStore } from "@/stores/layout";
import { useSubscription } from "@/composables/subscription";
import FreeTrialBanner from "@/components/common/FreeTrialBanner/FreeTrialBanner.vue";
import SuperAdminBanner from "@/components/common/SuperAdminBanner/SuperAdminBanner.vue";
import { useRoute } from "vue-router";

import { useRouterGuards } from "@/composables/route";
import { useAuth } from "@/composables/auth";
import CoreButton from "../common/CoreButton/CoreButton.vue";
import router from "@/router";
import { useGetCompanySettings } from "@/api/companies";
import AppLoading from "@/AppLoading.vue";
import { useMfaRedirect } from "@/composables/mfa";

const { selectedLanguage } = useLanguage();
const layoutStore = useLayoutStore();
const authStore = useAuthStore();
const route = useRoute();
const routerGuards = useRouterGuards();
const { isFreeTrial, expiry, isExpired } = useSubscription();
const { isSuperAdmin, isLocationManager } = useAuth();

const getCompanySettings = useGetCompanySettings({
  ignoreLocationGroups: true,
});

const mfaEnforced = computed(
  () => getCompanySettings.data.value?.mfa_enforcement
);

useMfaRedirect(mfaEnforced);

if (
  ["production", "staging"].includes(config.MODE) &&
  config.PRODUCT_FRUITS_PROJECT
) {
  productFruits.init(
    config.PRODUCT_FRUITS_PROJECT,
    (selectedLanguage.value?.code ?? "en").substring(0, 2),
    {
      username: authStore.userName,
    }
  );
}

const onAccountPage = computed(() =>
  route.matched.some((match) => match.name === "account-settings")
);

const banner = ref(null);
const { height: bannerHeight } = useElementSize(banner);
watch(bannerHeight, () => (layoutStore.bannerHeight = bannerHeight.value));

watch(
  () => layoutStore.headerHeight,
  (value) => {
    document.documentElement.style.setProperty("--navbar-offset", `${value}px`);
  }
);

routerGuards.initialiseWatches();

const logout = async () => {
  await authStore.logout();
  router.push({ name: "login" });
};
</script>

<template>
  <AppLoading v-if="getCompanySettings.isInitialLoading.value" />
  <el-container v-else>
    <el-header class="header hide-on-print">
      <div ref="banner">
        <SuperAdminBanner v-if="isSuperAdmin" />
        <FreeTrialBanner
          v-if="isFreeTrial && !isLocationManager"
          :expiry="expiry"
          :is-expired="isExpired"
          :on-account-page="onAccountPage"
        />
      </div>
      <CoreButton
        v-if="route.meta.hideNav"
        :icon="['fal', 'power-off']"
        icon-size="xl"
        class="logout-button"
        text
        @click="logout"
      >
        {{ $t("nav.logOut") }}
      </CoreButton>
      <MainNav v-else />
    </el-header>
    <slot />
  </el-container>
</template>

<style lang="css" scoped>
.header {
  --so-header-height: 80px;
  position: fixed;
  z-index: 99;
  width: 100%;
  padding: 0;
}

.logout-button {
  float: right;
  margin: 40px;
}
</style>
