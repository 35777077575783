<script lang="ts" setup>
import axios from "axios";
import { onMounted, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { config } from "@/config/envConfig";

type NewsItem = {
  title: string;
  link: string;
  image: string;
  excerpt: string;
};

const { t } = useI18n();
const newsItem = reactive<NewsItem>({
  title: "",
  link: "#",
  image: "",
  excerpt: "",
});

const getXMLUrl = () => {
  if (process.env.NODE_ENV !== "production") {
    return "/xml/blog-mock.xml";
  }

  const blogTag = config.BLOG_TAG;
  const blogUrl = t("blog.path");

  return blogTag ? `${blogUrl}/tag/${blogTag}/rss.xml` : `${blogUrl}/rss.xml`;
};

onMounted(async () => {
  const xmlFeed = await axios.get(getXMLUrl());

  const xmlParser = new window.DOMParser();
  const xml = xmlParser.parseFromString(xmlFeed.data, "application/xml");
  const item = xml.getElementsByTagName("item")[0];
  const title = item
    ?.getElementsByTagName("title")?.[0]
    ?.innerHTML.replace(/&amp;/g, "&");
  const link = item?.getElementsByTagName("link")?.[0]?.innerHTML;
  const description =
    item?.getElementsByTagName("description")?.[0]?.textContent;
  if (description === null) throw new Error("description missing");
  const htmlParser = new window.DOMParser();
  const html = htmlParser.parseFromString(description, "text/html");

  const image = html.getElementsByTagName("img")?.[0]?.src;
  const excerpt = html
    ?.getElementsByTagName("body")?.[0]
    ?.innerHTML.replace(/<[^>]+>|&nbsp;/g, "");

  newsItem.title = title;
  newsItem.link = link;
  newsItem.image = image;
  newsItem.excerpt = excerpt;
});
</script>

<template>
  <div
    v-if="newsItem.title"
    class="newstile"
  >
    <div class="whats-new-title">
      {{ t("blog.whatsNew") }}
    </div>

    <a
      :href="newsItem.link"
      target="_blank"
      rel="noopener noreferrer"
    >
      <h3 v-text="newsItem.title" />
    </a>
    <a
      :href="newsItem.link"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        :src="newsItem.image"
        :alt="t('imgAlt.blogImage')"
      />
    </a>
    <p>{{ newsItem.excerpt }}</p>

    <a
      class="more-updates-button"
      :href="t('urls.marketing.blog')"
    >
      {{ t("blog.moreUpdates") }}
      <font-awesome-icon :icon="['fal', 'chevron-right']" />
    </a>
  </div>
</template>

<style lang="scss" scoped>
.newstile {
  max-width: 500px;
  width: 100%;

  @media screen and (min-width: $breakpoint-md) {
    max-width: 420px;
    padding: 0;
  }

  .whats-new-title {
    color: var(--so-text-color-secondary);
    font-size: 1.125rem;
    font-weight: var(--font-semi-bold);
    margin-bottom: 20px;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: var(--font-semi-bold);
    margin-top: 0;
    text-decoration: none;
  }

  a {
    color: var(--so-text-color-primary);
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  img {
    width: 100%;
    margin: 20px 0;
  }

  p {
    display: block;
    font-size: 0.875rem;
    font-weight: var(--font-normal);
    line-height: 24px;
    margin-bottom: 0;
    max-height: 300px;
    overflow-y: auto;
  }

  .more-updates-button {
    color: var(--col-vermilion);
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: var(--font-semi-bold);
    margin-top: 20px;

    svg {
      padding-left: 10px;
    }
  }
}
</style>
