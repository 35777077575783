import { computed, ref } from "vue";
import { usePreferredLanguages, useStorage } from "@vueuse/core";
import {
  SUPPORTED_LOCALES,
  loadDateTimeLocale,
  loadElementPlusLocale,
  loadLocaleMessages,
  type Language,
  localeMap,
} from "../config/i18n";
import { i18n } from "../config";
import { useGetRegions } from "@/api/regions";
import { config } from "@/config/envConfig";

const elementPlusLocale = ref();

const selectedLanguage = useStorage<Language | undefined>(
  "selected-language",
  null,
  undefined,
  {
    serializer: {
      read: (v: string | undefined) => (v ? JSON.parse(v) : null),
      write: (v: Language | undefined) => JSON.stringify(v),
    },
  }
);

export const useLanguage = () => {
  const { data: regions } = useGetRegions(true);

  const setup = async () => {
    const languages = usePreferredLanguages();

    const referrerLanguageMappings: { referrer: string; language: string }[] =
      JSON.parse(config.REFERRER_LANGUAGES ?? "[]");

    const referrerMapping = referrerLanguageMappings.find(
      (mapping) => mapping.referrer === document.referrer
    );

    let defaultLanguage = referrerMapping
      ? referrerMapping.language
      : selectedLanguage.value?.code ?? languages.value?.[0];

    const exactMatch = SUPPORTED_LOCALES.find(
      ({ code }) => code === defaultLanguage
    );
    const mappedMatch = localeMap[defaultLanguage.split("-")[0]];
    const frontMatch = SUPPORTED_LOCALES.find(
      ({ code }) => code.split("-")[0] === defaultLanguage.split("-")[0]
    );

    if (exactMatch) {
      defaultLanguage = exactMatch.code;
    } else if (mappedMatch) {
      defaultLanguage = mappedMatch;
    } else if (frontMatch) {
      defaultLanguage = frontMatch.code;
    } else {
      defaultLanguage = "en-US";
    }

    await changeLanguage(defaultLanguage);
  };

  const changeLanguage = async (language: string) => {
    const supportedLocale =
      SUPPORTED_LOCALES.find((locale) => locale.code === language) ??
      SUPPORTED_LOCALES[0];

    selectedLanguage.value = { ...supportedLocale };
    await loadDateTimeLocale(supportedLocale.code);
    elementPlusLocale.value = (
      await loadElementPlusLocale(supportedLocale.code)
    )?.default;
    await loadLocaleMessages(i18n, supportedLocale.code);
  };

  const isBrowserLanguage = (lang: string) =>
    usePreferredLanguages().value?.[0].toLowerCase() === lang.toLowerCase();

  const isImperialSystem = computed(
    () => regions.value?.viewerCountry.toLowerCase() === "us"
  );

  return {
    changeLanguage,
    isImperialSystem,
    elementPlusLocale,
    setup,
    selectedLanguage,
    isBrowserLanguage,
  };
};
