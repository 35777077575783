<script setup lang="ts">
import { useColourMode } from "@/composables/colourTheme";
import { ElRadioButton, ElRadioGroup } from "element-plus";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const { store } = useColourMode();

const modeOptions = computed(() => {
  return [
    { value: "auto", label: t("nav.colorSelector.default") },
    { value: "light", label: t("nav.colorSelector.light") },
    { value: "dark", label: t("nav.colorSelector.dark") },
  ];
});
</script>

<template>
  <div class="dark-mode-toggle-container">
    <el-radio-group
      v-model="store"
      class="dark-mode-toggle"
    >
      <el-radio-button
        v-for="(mode, index) in modeOptions"
        :key="index"
        :label="mode.value"
        class="dark-mode-button"
        size="default"
      >
        {{ mode.label }}
      </el-radio-button>
    </el-radio-group>
  </div>
</template>

<style lang="css" scoped>
.dark-mode-toggle-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
}

.dark-mode-toggle {
  flex-wrap: nowrap;
  width: 100%;
}

.dark-mode-button {
  flex-basis: 33%;
}

.dark-mode-button :deep(.so-radio-button__inner) {
  width: 100%;
  padding: 8px 5px;
}
</style>
