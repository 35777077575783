/* eslint-disable @typescript-eslint/no-explicit-any */
const cognitoFix = () => {
  (window as any).global = window;
  (window as any).process = {
    env: { DEBUG: undefined },
  };
};

const configure = () => {
  cognitoFix();
};

export default { configure };
