import { loadStripe } from "@stripe/stripe-js";
import { config } from "../envConfig";

export let isLoaded = false;

export const getOptions = () => ({
  pk: config.STRIPE_PUBLISHABLE_KEY,
  apiVersion: config.STRIPE_API_VERSION,
  locale: config.STRIPE_LOCALE,
});

const retryCount = 3;

export const configureStripe = () => {
  const pk = getOptions().pk;
  if (pk) {
    loadStripeWithRetry(pk, retryCount).then(() => {
      isLoaded = true;
    });
  }
};

const loadStripeWithRetry = (pk: string, retries: number) => {
  return new Promise(function (resolve, reject) {
    loadStripe(pk)
      .then(resolve)
      .catch(function (error) {
        if (retries === 1) return reject(error);
        loadStripeWithRetry(pk, retries - 1)
          .then(resolve)
          .catch(reject);
      });
  });
};
