import { endpoints } from "@/endpoints";
import useApiStore from "@/stores/api";
import { useQuery } from "@tanstack/vue-query";
import {
  tags,
  type PlatformApiListResponse,
  type MaybeRef,
} from "@/api/common";
import axios from "axios";
import { computed, unref } from "vue";
import { queryKeys } from "@/api/queryKeys";
import { mapLocation } from "@/api/locations";

import type { LocationGroup, LocationGroupsApiResponse } from ".";
import { useAuth } from "@/composables/auth";
import useAuthStore from "@/stores/auth";
import { config } from "@/config/envConfig";

const mapGroup = (group: LocationGroupsApiResponse) => {
  return <LocationGroup>{
    id: group.pk,
    numericId: Number(group.id),
    name: group.name,
    invisible: Boolean(Number(group.invisible)),
    locations: group.locations.map((location) => mapLocation(location)),
    order: Number(group.order),
    visitor_auto_anonymise: Boolean(Number(group.visitor_auto_anonymise)),
  };
};

const cacheHeaders = config.DEV_MODE
  ? {}
  : {
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    };

export const useAllLocationGroups = () => {
  const apiStore = useApiStore();

  return useQuery({
    queryKey: queryKeys[tags.platform.locationGroups].all.queryKey,
    queryFn: async () => {
      const result = await axios.get<
        PlatformApiListResponse<LocationGroupsApiResponse>
      >(
        apiStore.getEndpoint("platform", endpoints.locationGroups.list),
        cacheHeaders
      );
      if (result.data.httpCode !== 200) {
        throw new Error("Failed to retrieve location groups");
      }
      return result.data;
    },
    select: (data) => data.list.map(mapGroup),
  });
};

export const useLocationGroups = () => {
  const apiStore = useApiStore();
  const authStore = useAuthStore();
  const { isGroupAdmin, groupAdminPks } = useAuth();

  return useQuery({
    queryKey: queryKeys[tags.platform.locationGroups].all.queryKey,
    queryFn: async () => {
      const result = await axios.get<
        PlatformApiListResponse<LocationGroupsApiResponse>
      >(
        apiStore.getEndpoint("platform", endpoints.locationGroups.list),
        cacheHeaders
      );
      if (result.data.httpCode !== 200) {
        throw new Error("Failed to retrieve location groups");
      }

      const groups = result.data;

      if (isGroupAdmin.value) {
        groups.list = groups.list.filter(({ pk }) =>
          groupAdminPks.value.includes(pk)
        );

        if (!groups.list.length) {
          authStore.logout();
        }
      }

      return groups;
    },
    select: (data) => data.list.map(mapGroup),
  });
};

export const useGetLocationGroup = (
  locationGroupPk: MaybeRef<string | undefined>
) => {
  const apiStore = useApiStore();
  const enabled = computed(() => unref(locationGroupPk) !== undefined);
  return useQuery({
    queryKey:
      queryKeys[tags.platform.locationGroups].byId(locationGroupPk).queryKey,
    queryFn: async () => {
      const result = await axios.get(
        apiStore.getEndpoint(
          "platform",
          endpoints.locationGroups.get(unref(locationGroupPk))
        )
      );
      return result.data;
    },
    enabled,
    select: (data) => mapGroup(data.locationGroup),
  });
};
