import axios from "axios";
import handlers from "@/config/axios/globalHandler";

const configure = () => {
  axios.defaults.headers.common.accept =
    "application/json, application/vnd.swipedon+json; version=2";
  axios.interceptors.request.use(handlers.preRequest);
  axios.interceptors.response.use(undefined, handlers.errorResponse);
};

export default { configure };
