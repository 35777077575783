import { endpoints } from "@/endpoints";
import { tags } from "@/api/common";
import useApiStore from "@/stores/api";
import useAuthStore from "@/stores/auth";
import { useQuery } from "@tanstack/vue-query";
import { useLocationSelector } from "@/composables/location";
import axios from "axios";
import {
  computed,
  unref,
  type ComputedRef,
  type Ref,
  type MaybeRef,
} from "vue";
import type {
  CompanySettings,
  GetCompanyApiResponse,
  CompanySettingsApiResponse,
  CateringMenu,
} from "@/api/companies";
import { ApiClient } from "@/api/serverless";
import { MENU_NAME } from "@/stores/constants";
import useBetaStore from "@/stores/beta";
import useAccountStore from "@/stores/account";
import { queryKeys } from "../queryKeys";

export const useGetCompany = (staleTime?: MaybeRef<number>) => {
  const betaStore = useBetaStore();
  const accountStore = useAccountStore();
  const authStore = useAuthStore();

  return useQuery({
    queryKey: queryKeys[tags.rest.company].me().queryKey,
    queryFn: async () => {
      const response = await new ApiClient().getCompany();
      betaStore.load(response.betas);
      accountStore.setSubscription(response);

      return response;
    },
    staleTime,
    enabled: computed(() => Boolean(authStore.authUser)),
  });
};

export const useGetCompanyById = (companyId: Ref<number | undefined>) => {
  const apiStore = useApiStore();

  return useQuery({
    queryKey: queryKeys[tags.platform.company].byId(companyId).queryKey,
    queryFn: async () => {
      const result = await axios.get(
        apiStore.getEndpoint(
          "platform",
          endpoints.getCompany(String(companyId.value))
        )
      );
      return result.data;
    },
    enabled: computed(() => Boolean(unref(companyId))),
  });
};

const mapToCompanySettings = (
  settings: CompanySettingsApiResponse
): CompanySettings => ({
  ...settings,
  agreement_append: Boolean(settings.agreement_append),
  agreement_hide: Boolean(settings.agreement_hide),
  visits_require_signature: Boolean(settings.visits_require_signature),
  host_selection_custom_field: settings.host_selection_custom_field ?? null,
  host_selection_department_field: Boolean(
    settings.host_selection_department_field
  ),
  slack_host_notifications: Boolean(settings.slack_host_notifications),
  slack_visitor_approval_notifications: Boolean(
    settings.slack_visitor_approval_notifications
  ),
  employee_message_required: Boolean(settings.employee_message_required),
  employee_remote: Boolean(settings.employee_remote),
  employee_roaming: Boolean(settings.employee_roaming),
  emp_autologout: Boolean(Number(settings.emp_autologout)),
  profile_capture_e: Boolean(settings.profile_capture_e),
  photo_capture_e: Boolean(settings.photo_capture_e),
  photo_capture_v: Boolean(settings.photo_capture_v),
  alert_notifications_enabled: Boolean(settings.alert_notifications_enabled),
  email_global_cc: Boolean(settings.email_global_cc),
  hide_inactive_fields: Boolean(settings.hide_inactive_fields),
  host_approval_notifications: Boolean(settings.host_approval_notifications),
  host_notifications_show_fields: Boolean(
    settings.host_notifications_show_fields
  ),
  host_notifications: Boolean(settings.host_notifications),
  show_employee_list: Boolean(settings.show_employee_list),
  show_out_state: Boolean(settings.show_out_state),
  visit_review_show_fields: Boolean(settings.visit_review_show_fields),
  visiting_employee_toggle: Boolean(settings.visiting_employee_toggle),
  visitor_auto_suggest: Boolean(settings.visitor_auto_suggest),
  visitor_remember_me: Boolean(settings.visitor_remember_me),
  employee_secure_mode: Boolean(settings.employee_secure_mode),
  show_employee_in_out_timestamp: Boolean(
    settings.show_employee_in_out_timestamp
  ),
  mobile: {
    movements: Boolean(settings.mobile.movements),
    beacon_notifications: Boolean(settings.mobile.beacon_notifications),
    proximity_verification: Boolean(settings.mobile.proximity_verification),
    proximity_out_verification: Boolean(
      settings.mobile.proximity_out_verification
    ),
    proximity_movements: Boolean(settings.mobile.proximity_movements),
    proximity_distance: settings.mobile.proximity_distance,
    preregistrations: Boolean(settings.mobile.preregistrations),
  },
  color_accent: settings.color_accent ?? null,
  print_label_number: settings.print_label_number ?? null,
  visitor_enable_label_scan: Boolean(settings.visitor_enable_label_scan),
  visit_duration_notification: Boolean(settings.visit_duration_notification),
  visit_duration_notification_hours: Number(
    settings.visit_duration_notification_hours
  ),
  visit_duration_host_notification: Boolean(
    settings.visit_duration_host_notification
  ),
  visit_out_notification: Boolean(settings.visit_out_notification),
  visit_out_host_notification: Boolean(settings.visit_out_host_notification),
  visitor_autologout: Boolean(Number(settings.visitor_autologout)),
  auto_anonymise_days_visitor: Number(settings.auto_anonymise_days_visitor),
  contactless: {
    gadget_enabled: Boolean(settings.contactless.gadget_enabled),
    static_host_selection: Boolean(settings.contactless.static_host_selection),
  },
  addons: {
    catering: {
      settings: {
        thankYou: {
          title: settings.addons.catering.settings.thankYou.title,
          message: settings.addons.catering.settings.thankYou.message,
        },
        hostReceivesOrder: Boolean(
          settings.addons.catering.settings.hostReceivesOrder
        ),
        orderContacts: settings.addons.catering.settings.orderContacts?.map(
          (contact) => ({
            name: contact.name,
            email: contact.email,
            phone: contact.phone,
            image:
              contact.image === "/img/placeholders/avatars/avatar_default.png"
                ? ""
                : contact.image,
            id: contact.id,
            isGeneralContact: Boolean(contact.isGeneralContact),
            locationName: contact.locationName ?? "",
            locationId: contact.locationId ?? "",
            firstName: contact.firstName,
            lastName: contact.lastName,
          })
        ),
      },
      menu: settings.addons.catering.menu?.map((menuItem) => ({
        name: menuItem.name,
        enabled: Boolean(menuItem.enabled),
        notes: menuItem.notes,
        options: menuItem.options.map((option) => {
          if (menuItem.name === MENU_NAME.FOOD) {
            return {
              enabled: Boolean(option.enabled),
              image: option.image,
              imageName: option.imageName,
              name: option.name,
              notes: option.notes,
              price: option.price,
            };
          }
          return {
            enabled: Boolean(option.enabled),
            image: option.image,
            name: option.name,
            quantity: option.quantity,
            options: option.options?.map((beverageOption) => ({
              enabled: Boolean(beverageOption.enabled),
              multiple: Boolean(beverageOption.multiple),
              required: Boolean(beverageOption.required),
              name: beverageOption.name,
              options: beverageOption.options?.map((beverageOption2) => ({
                enabled: Boolean(beverageOption2.enabled),
                name: beverageOption2.name,
                color: beverageOption2.color,
                price: beverageOption2.price,
                quantity: beverageOption2.quantity,
              })),
            })),
          };
        }),
      })) as CateringMenu[],
    },
    deliveries: {
      scanLabel: Boolean(settings.addons.deliveries?.scanLabel),
      generalDeliveries: {
        enabled: Boolean(
          settings.addons.deliveries?.generalDeliveries?.enabled
        ),
        contacts: settings.addons.deliveries?.generalDeliveries?.contacts.map(
          (contact) => ({
            name: contact.name,
            email: contact.email,
            phone: contact.phone,
            image:
              contact.image === "/img/placeholders/avatars/avatar_default.png"
                ? ""
                : contact.image,
            id: contact.id,
            isGeneralContact: Boolean(contact.isGeneralContact),
            locationName: contact.locationName ?? "",
            locationId: contact.locationId ?? "",
            firstName: contact.firstName,
            lastName: contact.lastName,
          })
        ),
      },
      instructions: {
        general: settings.addons.deliveries?.instructions.general?.map(
          (item) => ({ text: item.text })
        ),
        recipient: settings.addons.deliveries?.instructions.recipient?.map(
          (item) => ({ text: item.text })
        ),
      },
      imageKey: settings.addons.deliveries?.imageKey,
    },
  },
  ipad_radius: Number(settings.ipad_radius),
  iPad_deliveries_btn_txt: settings.iPad_deliveries_btn_txt,
  iPad_emp_swipe_btn_txt: settings.iPad_emp_swipe_btn_txt,
  iPad_vis_in_txt: settings.iPad_vis_in_txt,
  iPad_vis_out_txt: settings.iPad_vis_out_txt,
  color_ipad_button_text: settings.color_ipad_button_text,
  color_buttons: settings.color_buttons,
  teams_host_notifications: Boolean(settings.teams_host_notifications),
  teams_visitor_approval_notifications: Boolean(
    settings.teams_visitor_approval_notifications
  ),
  categories_heading_enabled: Boolean(settings.categories_heading_enabled),
  categories_heading: settings.categories_heading,
  mfa_enforcement: Boolean(settings.mfa_enforcement),
  sso_microsoft: Boolean(settings.sso_microsoft),
  sso_google: Boolean(settings.sso_google),
});

export const useGetCompanySettings = (options?: {
  locationGroupId?: ComputedRef<string>;
  ignoreLocationGroups?: boolean;
}) => {
  const apiStore = useApiStore();
  const authStore = useAuthStore();
  const { selectedLocationGroupPk, isLocationsLoaded } = useLocationSelector();

  const selectedLocationGroup = computed(
    () => options?.locationGroupId?.value ?? selectedLocationGroupPk.value
  );

  return useQuery<GetCompanyApiResponse, unknown, CompanySettings>({
    queryKey: queryKeys[tags.platform.company].byLocationGroupId(
      options?.ignoreLocationGroups ? undefined : selectedLocationGroup
    ).queryKey,
    queryFn: async () => {
      const response = await axios.get<GetCompanyApiResponse>(
        apiStore.getEndpoint(
          "platform",
          endpoints.getCompany(authStore.authUser?.companyPk ?? "")
        ),
        { params: { locationGroupPk: selectedLocationGroup.value } }
      );
      return response.data;
    },
    select: (data) => {
      const { settings } = data.company;
      return mapToCompanySettings(settings);
    },
    enabled: computed(
      () =>
        (options?.ignoreLocationGroups ?? unref(isLocationsLoaded)) &&
        Boolean(authStore.authUser)
    ),
    refetchOnWindowFocus: false,
  });
};
