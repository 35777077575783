<script setup lang="ts">
import { ElConfigProvider } from "element-plus";
import { computed, onMounted, ref } from "vue";
import { RouterView, useRoute } from "vue-router";
import AuthenticatedLayout from "./components/layout/AuthenticatedLayout.vue";
import AnonymousLayout from "./components/layout/AnonymousLayout.vue";
import AppLoading from "./AppLoading.vue";
import { useDark } from "@vueuse/core";
import { useLanguage } from "./composables/lang";
import useAuthStore from "@/stores/auth";
import Login from "@/pages/login/index.vue";
import { useIntercom } from "./composables/intercom";
import { useProfitwell } from "./composables/profitwell";
import { useSentryUserTracking } from "./composables/useSentryTracking";
import { useGetCompany } from "./api/companies";

const languageLoaded = ref(false);
const route = useRoute();
const authStore = useAuthStore();

useDark();
useSentryUserTracking();
const { setup: setupIntercom } = useIntercom();
const { setup, elementPlusLocale } = useLanguage();
const { setup: setupProfitwell } = useProfitwell();
const { isLoading: isCompanyLoading } = useGetCompany();

onMounted(async () => {
  await setup();
  await setupProfitwell();
  setupIntercom();
  languageLoaded.value = true;
});

const isLoading = computed(
  () =>
    !route.name ||
    !authStore.loadedAuth ||
    !languageLoaded.value ||
    (isCompanyLoading.value && Boolean(authStore.authUser))
);
</script>

<template>
  <el-config-provider
    namespace="so"
    size="large"
    :locale="elementPlusLocale"
  >
    <AppLoading v-if="isLoading" />

    <template v-else>
      <AnonymousLayout v-if="route.meta.allowAnonymous">
        <RouterView />
      </AnonymousLayout>

      <AnonymousLayout v-else-if="!authStore.authUser">
        <Login />
      </AnonymousLayout>

      <AuthenticatedLayout v-else>
        <RouterView />
      </AuthenticatedLayout>
    </template>
  </el-config-provider>
</template>

<style lang="scss">
@import "element-plus/theme-chalk/src/button-group.scss";
@import "@/assets/styles/_reset.scss";
@import "@/assets/styles/_print.scss";
@import "@/assets/css/element.css";
@import "@/assets/css/styles.css";
</style>
