import { useQuery } from "@tanstack/vue-query";
import { endpoints } from "@/endpoints";
import { tags } from "@/api/common";
import useApiStore from "@/stores/api";
import axios from "axios";
import type { RegionsReponse } from "./types";
import { useI18n } from "vue-i18n";
import { queryKeys } from "../queryKeys";
import type { MaybeRef } from "@vueuse/shared";

export const useGetRegions = (enabled?: MaybeRef<boolean>) => {
  const apiStore = useApiStore();
  const { t } = useI18n();

  return useQuery({
    queryKey: queryKeys[tags.rest.regions].list().queryKey,
    queryFn: async () => {
      const result = await axios.get<RegionsReponse>(
        apiStore.getEndpoint("rest", endpoints.getRegions),
        {
          withCredentials: false,
        }
      );
      return {
        regions: result.data.data
          .map((region) => ({
            nameFormatted: t(`forms.regions.list.${region.region.name}`),
            name: region.region.name,
            endpoint: region.endpoints.rest.url,
            value: region.region.name,
          }))
          .sort((a, b) => (a.nameFormatted > b.nameFormatted ? 1 : -1)),
        viewerCountry: result.data.viewerCountry,
      };
    },
    enabled,
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};
