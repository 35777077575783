<script setup lang="ts">
import { ElDivider, ElSubMenu, ElMenuItem } from "element-plus";
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useIsRouteActive } from "@/composables/route";
import { useAuth } from "@/composables/auth";

import type { NavItem } from "../types";

export interface Props {
  navItem: NavItem;
}
const props = defineProps<Props>();
const subNavItems = computed(() => props.navItem.subNavItems);
const { t } = useI18n();
const settingsItemsCol1 = ref([]);
const settingsItemsCol2 = ref([]);

const { isSuperAdmin } = useAuth();

const settingsItemClick = (index: string | undefined) => {
  if (index === undefined) return;
  const settingsItems = [
    ...settingsItemsCol1.value,
    ...settingsItemsCol2.value,
  ];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const menuItem: any = settingsItems.find(
    (menuItem: typeof ElMenuItem) => menuItem.index === index
  );
  if (menuItem) {
    menuItem.$el.click();
  }
};
</script>

<template>
  <el-divider direction="vertical" />
  <el-sub-menu
    v-cy="navItem.titleKey"
    :class="['settings-menu', { active: useIsRouteActive(navItem) }]"
    index="visitors"
    popper-class="settings-submenu"
    :teleported="false"
  >
    <template #title>{{ t(navItem.titleKey) }}</template>
    <div class="settings-dropdown">
      <div class="settings-dropdown-column">
        <h4>{{ t("nav.location") }}</h4>
        <div
          v-for="(subNavItem, index) in subNavItems?.group1"
          :key="index"
          class="settings-dropdown-item"
          @click="settingsItemClick(subNavItem.route)"
          @keyup.enter="settingsItemClick(subNavItem.route)"
        >
          <el-menu-item
            ref="settingsItemsCol1"
            v-cy="subNavItem.titleKey"
            :index="subNavItem.route"
            :route="{ name: subNavItem.route }"
            :class="[
              'settings-dropdown-item-heading',
              { 'is-active': useIsRouteActive(subNavItem) },
            ]"
            :disabled="subNavItem.disabled"
          >
            <font-awesome-icon :icon="subNavItem.icon" />
            <span class="setting-title">{{ t(subNavItem.titleKey) }}</span>
          </el-menu-item>
          <span
            v-if="subNavItem.bodyKey"
            class="info"
            >{{ t(subNavItem.bodyKey) }}</span
          >
        </div>
      </div>
      <div class="settings-dropdown-column setting-organization">
        <h4>{{ t("nav.organization") }}</h4>
        <div
          v-for="(subNavItem, index) in subNavItems?.group2"
          :key="index"
          :class="[
            'settings-dropdown-item',
            { hidden: isSuperAdmin && subNavItem.route === 'account-settings' },
          ]"
          @click="settingsItemClick(subNavItem.route)"
          @keyup.enter="settingsItemClick(subNavItem.route)"
        >
          <el-menu-item
            ref="settingsItemsCol2"
            v-cy="subNavItem.titleKey"
            :index="subNavItem.route"
            :route="{ name: subNavItem.route }"
            :class="[
              'settings-dropdown-item-heading settings-dropdown-item-organization',
              { 'is-active': useIsRouteActive(subNavItem) },
            ]"
            :disabled="subNavItem.disabled"
          >
            <font-awesome-icon :icon="subNavItem.icon" />
            <span class="setting-title">{{ t(subNavItem.titleKey) }}</span>
          </el-menu-item>
          <span
            v-if="subNavItem.bodyKey"
            class="info"
            >{{ t(subNavItem.bodyKey) }}</span
          >
        </div>
      </div>
    </div>
  </el-sub-menu>
  <el-divider direction="vertical" />
</template>

<style lang="scss" scoped>
.settings-menu {
  height: 100%;
}

.settings-menu.active :deep(.so-sub-menu__title) {
  border-bottom-color: var(--so-menu-active-color) !important;
  color: var(--so-menu-active-color) !important;
}

:deep(.so-popper.settings-submenu) .so-menu--popup {
  padding: 0;
}

.settings-menu > :deep(.so-sub-menu__title),
.settings-dropdown-item-heading {
  font-family: var(--font-family-headings);
  font-weight: var(--font-bold);
}

:deep(.so-popper.settings-submenu) {
  @media screen and (min-width: 1250px) {
    transform: translate(calc(-50% + 56px), 0);
  }
}

.settings-dropdown {
  display: flex;
  height: 100%;

  > .so-divider--vertical {
    height: 100%;
  }
}

.settings-dropdown-column {
  display: flex;
  flex-direction: column;
  padding: 30px 35px;
  width: 310px;
}

.setting-organization {
  background-color: var(--so-fill-color-light);
}

.hidden {
  display: none;
}

.settings-dropdown-item {
  margin-bottom: 20px;
  text-decoration: none;
  cursor: pointer;

  &:hover .settings-dropdown-item-heading.is-disabled {
    color: var(--so-text-color-primary);
  }

  &:hover .settings-dropdown-item-heading:not(.is-disabled) {
    color: var(--so-menu-hover-text-color);
  }

  .settings-dropdown-item-heading {
    /* stylelint-disable-next-line declaration-no-important */
    --so-menu-hover-text-color: var(--so-menu-active-color) !important;
    height: 20px;
    line-height: 1.5rem;
    padding-left: 0;
    gap: 5px;

    &.is-active {
      color: var(--so-menu-active-color);
    }

    & svg {
      width: 16px;
      height: 16px;
    }
  }

  .settings-dropdown-item-organization {
    background-color: var(--so-fill-color-light);
  }
}

.setting-title {
  padding-top: 2px;
}

h4 {
  line-height: 2rem;
  font-size: 0.75rem;
  color: var(--so-text-color-secondary);
  text-transform: uppercase;
}
</style>
