import { defineStore } from "pinia";
import { i18n } from "@/config";
import { LocationCategory, type LocationGroup } from "@/api/locationGroups";

import type { Location } from "@/api/locations";

export interface LocationState {
  selectedLocationGroup: LocationGroup | null;
  selectedLocation: Location | { name: string };
  selectedLocationType: LocationCategory | null;
}

export const useLocationStore = defineStore("locations", {
  state: (): LocationState => ({
    selectedLocationGroup: null,
    selectedLocation: {
      name: i18n.global.t("locationSelector.allLocations"),
    },
    selectedLocationType: null,
  }),
  actions: {
    setSelectedLocation: function (payload: Location) {
      this.selectedLocation = payload;
    },
    setSelectedLocationType: function (type: LocationCategory) {
      this.selectedLocationType = type;
    },
    setSelectedLocationGroup: function (payload: LocationGroup) {
      this.selectedLocationGroup = payload;
    },
    setAllLocationsSelected: function () {
      this.selectedLocationType = LocationCategory.ALL;
    },
  },
});
