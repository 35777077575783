<script setup lang="ts">
import useAuthStore from "@/stores/auth";
import ConfirmDialog from "@/components/common/ConfirmDialog/ConfirmDialog.vue";
import CoreSaveConfirm from "@/components/common/CoreSaveConfirm/CoreSaveConfirm.vue";
import CoreButton from "@/components/common/CoreButton/CoreButton.vue";
import { CONFIRM } from "@/stores/constants";
import {
  ElRadio,
  ElRadioGroup,
  ElForm,
  ElInput,
  ElFormItem,
  type FormRules,
  type FormInstance,
} from "element-plus";
import { useI18n } from "vue-i18n";
import { Auth } from "@aws-amplify/auth";
import QRCode from "qrcode";
import { ref, reactive, watch, computed } from "vue";
import { useGetCompanySettings } from "@/api/companies";

const getCompanySettings = useGetCompanySettings();

const mfa_enforcement = computed(
  () => getCompanySettings.data.value?.mfa_enforcement ?? false
);

const mfa_preference = computed(() =>
  mfa_enforcement.value ? "SOFTWARE_TOKEN_MFA" : authStore.mfaPreference
);

const emit = defineEmits<{
  (e: "closed"): void;
}>();
const formRef = ref<FormInstance>();
const { t } = useI18n();
const authStore = useAuthStore();
const qrImgString = ref("");
const selectedMfa = ref(mfa_preference.value);
const currentUser = ref();
const mfaSetupCode = ref("");
const state = reactive({
  form: {
    mfaCode: "",
  },
});
const loading = ref(false);
const mfaCodeError = ref("");
const mfaCode = ref("");
const mfaErrorCodes = [
  "ExpiredCodeException",
  "NotAuthorizedException",
  "CodeMismatchException",
  "EnableSoftwareTokenMFAException",
  "InvalidParameterException",
];

const mfaOptions = computed(() => [
  {
    label: t("nav.profile.mutliFactorAuth.options.off"),
    code: "NOMFA",
    enabled: !mfa_enforcement.value,
  },
  {
    label: t("nav.profile.mutliFactorAuth.options.totp"),
    code: "SOFTWARE_TOKEN_MFA",
    enabled: true,
  },
]);

const saveConfirms = reactive({
  mfaConfirmed: CONFIRM.SAVED,
});

watch(
  () => authStore.mfaPreference,
  (newVal) => (selectedMfa.value = newVal)
);

const handleClose = (done: () => void) => {
  resetAndCloseDialog();
  done();
};
const resetAndCloseDialog = () => {
  authStore.mfaSetupRequired = false;
  authStore.mfaDialogVisible = false;
  formRef.value?.clearValidate("mfaCode");
  mfaCodeError.value = "";
  state.form.mfaCode = "";
  selectedMfa.value = authStore.mfaPreference;
  qrImgString.value = "";
  saveConfirms.mfaConfirmed = CONFIRM.SAVED;
};

watch(
  () => authStore.mfaSetupRequired,
  async (newVal) => {
    if (newVal) {
      currentUser.value = await Auth.currentAuthenticatedUser();
      selectedMfa.value = "SOFTWARE_TOKEN_MFA";
      await generateQR();
    }
  }
);

const generateQR = async () => {
  mfaSetupCode.value = await Auth.setupTOTP(currentUser.value);
  const qrurl = `otpauth://totp/SwipedOn?secret=${mfaSetupCode.value}&issuer=SwipedOn`;
  qrImgString.value = await QRCode.toDataURL(qrurl, {
    width: 200,
    margin: 2,
    type: "image/png",
  });
};

const setUpMfa = async () => {
  mfaCodeError.value = "";
  if (authStore.mfaPreference !== "NOMFA") return;
  currentUser.value = await Auth.currentAuthenticatedUser();
  qrImgString.value = "";
  if (selectedMfa.value === "SOFTWARE_TOKEN_MFA") {
    await generateQR();
  }
};
const submitChallenge = async () => {
  await Auth.verifyTotpToken(currentUser.value, state.form.mfaCode);
  await Auth.setPreferredMFA(currentUser.value, "SOFTWARE_TOKEN_MFA");
  authStore.setMfaPreference("SOFTWARE_TOKEN_MFA");
};

const handleMfaCodeError = (error: Error) => {
  saveConfirms.mfaConfirmed = CONFIRM.SAVED;
  state.form.mfaCode = "";
  mfaCodeError.value = error.name;
  if (mfaErrorCodes.includes(error.name)) {
    mfaCodeError.value = t(`multiFactorDialog.errors.${error.name}`);
    formRef.value?.validateField("mfaCode", () => null);
  }
};

const confirmAction = async () => {
  loading.value = true;
  saveConfirms.mfaConfirmed = CONFIRM.PENDING;
  formRef.value?.validateField("mfaCode", () => null);

  currentUser.value = await Auth.currentAuthenticatedUser();
  if (selectedMfa.value === "SOFTWARE_TOKEN_MFA" && state.form.mfaCode == "") {
    saveConfirms.mfaConfirmed = CONFIRM.SAVED;
    loading.value = false;
    return;
  }
  if (selectedMfa.value === "SOFTWARE_TOKEN_MFA" && state.form.mfaCode != "") {
    try {
      await submitChallenge();
    } catch (err) {
      if (!(err instanceof Error)) return;
      handleMfaCodeError(err);
      return;
    }

    saveConfirms.mfaConfirmed = CONFIRM.SUCCESS;
  } else {
    qrImgString.value = "";
    await Auth.setPreferredMFA(currentUser.value, "NOMFA");
    authStore.setMfaPreference("NOMFA");
    saveConfirms.mfaConfirmed = CONFIRM.SUCCESS;
  }

  setTimeout(() => {
    loading.value = false;
    resetAndCloseDialog();
  }, 1000);
};

const cancelAction = () => {
  resetAndCloseDialog();
  emit("closed");
};

const disableMfaVerify = computed(() => {
  return mfa_enforcement.value || authStore.mfaPreference == selectedMfa.value;
});

const rules = reactive<FormRules>({
  mfaCode: [
    {
      required: true,
      max: 6,
      message: () => t("multiFactorDialog.errors.InvalidParameterException"),
      trigger: ["change", "blur"],
    },
    {
      validator: (rule, value, callback) => {
        if (mfaCodeError.value) {
          callback(mfaCodeError.value);
        }
        callback();
      },
    },
  ],
});
</script>

<template>
  <ConfirmDialog
    :visible="authStore.mfaDialogVisible"
    :title="t('multiFactorDialog.title')"
    @before-close="handleClose"
    @close="emit('closed')"
  >
    <div>
      <p>
        {{ t("multiFactorDialog.subtitle") }}
      </p>
      <el-radio-group
        v-if="!authStore.mfaSetupRequired"
        v-model="selectedMfa"
      >
        <el-radio
          v-for="(mfaoption, index) in mfaOptions"
          :key="index"
          :label="mfaoption.code"
          :disabled="!mfaoption.enabled"
          size="large"
          @change="setUpMfa"
        >
          {{ mfaoption.label }}
        </el-radio>
      </el-radio-group>
      <div v-if="qrImgString !== ''">
        <p>{{ t("multiFactorDialog.step1") }}</p>
        <p>{{ t("multiFactorDialog.step2") }}</p>
        <img
          :src="qrImgString"
          alt=""
        />
        <p>{{ t("multiFactorDialog.step3") }}</p>
        <el-form
          ref="formRef"
          :rules="rules"
          :model="state.form"
          label-position="top"
          @submit.prevent="confirmAction"
        >
          <el-form-item
            ref="mfaCode"
            prop="mfaCode"
          >
            <el-input
              v-model="state.form.mfaCode"
              type="number"
              :placeholder="t('multiFactorDialog.placeholder')"
            />
          </el-form-item>
        </el-form>
      </div>
    </div>
    <template #actions>
      <CoreButton
        type="default"
        @click="cancelAction"
      >
        {{ t("multiFactorDialog.cancel") }}
      </CoreButton>
      <CoreButton
        type="primary"
        :disabled="disableMfaVerify"
        @click="confirmAction"
      >
        {{ t("multiFactorDialog.confirm") }}
        <CoreSaveConfirm
          class="so-icon--right"
          :status="saveConfirms.mfaConfirmed"
        />
      </CoreButton>
    </template>
  </ConfirmDialog>
</template>

<style scoped lang="css">
.dialog-actions {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media only screen and (min-width: 480px) {
  .dialog-actions {
    flex-direction: row;
    justify-content: right;
  }
}

p {
  padding: 9px 0;
}

:deep(.success),
:deep(.pending) {
  color: var(--so-button-text-color);
}
/* The following CSS removes up and down arrows on the numbers input */
/* Chrome, Safari, Edge, Opera */
:deep(input::-webkit-outer-spin-button),
:deep(input::-webkit-inner-spin-button) {
  margin: 0;
  -webkit-appearance: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
