<script setup lang="ts">
import { ElTooltip, ElMenuItem, ElBadge } from "element-plus";
import { useI18n } from "vue-i18n";
import type { NavItem } from "../types";
import { config } from "@/config/envConfig";
import useAuthStore from "@/stores/auth";

export interface Props {
  navItem: NavItem;
}

const { t } = useI18n();
const props = defineProps<Props>();

const authStore = useAuthStore();

const openLink = async () => {
  if (props.navItem.address) window.open(props.navItem.address, "_blank");

  if (props.navItem.address === config.WHATS_NEW_LINK)
    await authStore.updateWhatsNewViewedAt();
};
</script>

<template>
  <el-tooltip
    :content="t(props.navItem.titleKey)"
    :offset="-18"
    :hide-after="0"
    :disabled="props.navItem.disabled"
  >
    <el-menu-item
      :index="props.navItem.route"
      :route="{ name: props.navItem.route }"
      :disabled="props.navItem.disabled"
      @click="openLink"
    >
      <el-badge
        :hidden="!props.navItem.showDotBadge"
        is-dot
      >
        <font-awesome-icon
          class="nav-icon"
          :icon="props.navItem.icon"
        />
      </el-badge>
    </el-menu-item>
  </el-tooltip>
</template>

<style lang="css" scoped>
.nav-icon {
  font-size: 20px;
}
</style>
