import shortUuid from "short-uuid";

const uuidTranslator = shortUuid("0123456789abcdefghijklmnopqrstuvwxyz");

export const useUuid = () => {
  const generateUuid = () => {
    return uuidTranslator.new();
  };

  return { generateUuid };
};
