import { createQueryKeys } from "@lukemorales/query-key-factory";
import type { OrderPreviewRequest } from "../billing";
import { tags, type MaybeRef, type DeepMaybeRef } from "../common";
import type { DeliveriesParams } from "../deliveries";
import type {
  IntegrationsLocationsTriggerRequest,
  IntegrationsSyncTriggerRequest,
} from "../integrations";

export const addOnListKeys = createQueryKeys(tags.rest.addonList, {
  list: () => [{}],
});

export const companyKeys = createQueryKeys(tags.rest.company, {
  me: () => ["me"],
});

export const employeeSignInQuestionKeys = createQueryKeys(
  tags.rest.employeeQuestions,
  {
    list: (selectedLocationGroupPk?: MaybeRef<string | undefined>) => [
      [selectedLocationGroupPk],
    ],
  }
);

export const integrationStatusKeys = createQueryKeys(
  tags.rest.integrationStatus,
  {
    all: null,
  }
);

export const integrationLocationsKeys = createQueryKeys(
  tags.rest.integrationLocations,
  {
    list: (apiArgs?: IntegrationsLocationsTriggerRequest) => [{ apiArgs }],
  }
);

export const integrationSyncKeys = createQueryKeys(tags.rest.integrationSync, {
  list: (apiArgs?: IntegrationsSyncTriggerRequest) => [{ apiArgs }],
});

export const teamsKeys = createQueryKeys(tags.rest.teams, {
  all: null,
  list: null,
});

export const slackKeys = createQueryKeys(tags.rest.slack, {
  all: null,
});

export const whatsNewKeys = createQueryKeys(tags.rest.whatsNew, {
  get: () => [{}],
});

export const deliveriesKeys = createQueryKeys(tags.rest.deliveries, {
  list: (apiArgs?: DeepMaybeRef<DeliveriesParams>) => [{ apiArgs }],
  byId: (deliveryId: MaybeRef<string>) => [{ deliveryId }],
});

export const customerKeys = createQueryKeys(tags.rest.customer, {
  get: () => [{}],
  getPlans: () => [{}],
  getBundles: (plan: string) => [{ plan }],
});

export const billingKeys = createQueryKeys(tags.rest.billing, {
  get: () => [{}],
  getPreview: (payload?: OrderPreviewRequest) => [{ payload }],
});

export const regionsKeys = createQueryKeys(tags.rest.regions, {
  list: () => [{}],
});
