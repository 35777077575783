{
  "addons": {
    "resources": {
      "title": "Bookable resources",
      "promoDescription": "Keep track of your resources and allow employees to book what they need"
    },
    "rooms": {
      "title": "Meeting rooms",
      "promoDescription": "Make it easy to view real time availability and to find and book the right room"
    },
    "deliveries": {
      "title": "Deliveries",
      "promoDescription": "Speed up the drop off process with label scanning"
    },
    "catering": {
      "title": "Catering",
      "promoDescription": "Offer your visitors food or a beverage on arrival",
      "getStartedDialog": {
        "startTrial": "Start your {trial} today!",
        "freeTrial": "14 day free trial",
        "body": "Offer your visitors coffee, other drinks, or food items as they sign in. Automatically send their order to the right place, and easily track costs."
      },
      "trialStartedDialog": {
        "body": "To get started, create a preboarding flow and assign it to a visitor category",
        "header": "Great! Your 14 day free trial has now started.",
        "later": "Do It Later",
        "learnMore": "Learn more >"
      },
      "trialEndedDialog": {
        "body": "Your 14 day free trial of Catering has finished. If you would like to continue using Catering, purchase the AddOn for each location you require.",
        "subscribe": "Purchase AddOn",
        "not": "Not Now"
      }
    },
    "inductions": {
      "title": "Preboarding",
      "promoDescription": "Ensure visitors are prepared and informed",
      "getStartedDialog": {
        "startTrial": "Start your {trial} today!",
        "freeTrial": "14 day free trial",
        "body": "Ensure visitors are fully informed and prepared for their visit with bespoke preboarding for your workplace. Create custom preboarding tailored to your unique compliance needs."
      },
      "trialStartedDialog": {
        "body": "To get started, create a preboarding flow and assign it to a visitor category",
        "header": "Great! Your 14 day free trial has now started. {more}",
        "create": "Get started",
        "later": "Do It Later",
        "learnMore": "Learn more >"
      },
      "trialEndedDialog": {
        "body": "Your 14 day free trial of Visitor Preboarding has finished. If you would like to continue using this feature, please purchase the AddOn for each location you require",
        "subscribe": "Purchase AddOn",
        "not": "Not Now"
      }
    },
    "dialog": {
      "getStarted": {
        "startTrial": "Start your {trial} today!",
        "freeTrial": "14 day free trial",
        "body": "Ensure visitors are fully informed and prepared for their visit with bespoke preboarding for your workplace. Create custom preboarding tailored to your unique compliance needs.",
        "confirm": "Get Started",
        "cancel": "Not now"
      }
    },
    "smsLabel": "SMS",
    "sms": {
      "title": "Unlimited SMS",
      "promoDescription": "Notify employees when a visitor or delivery arrives"
    },
    "howToPurchase": {
      "title": "How to purchase additional addons",
      "subTitle": "Additional AddOns can be easily purchased, for each location, once your plan is setup"
    }
  },
  "billing": {
    "confirmation": {
      "new": {
        "title": "Congrats! Your order has been confirmed",
        "description": "Before you get started, check your location details are correct",
        "button": "Check Location Details"
      },
      "upgrade": {
        "title": "Our plans are now even better value with selected AddOns included",
        "description": "You are currently on a legacy plan. Upgrade to the latest version to get the most out of SwipedOn including access to bookable resources"
      },
      "spacesUpgrade": {
        "title": "Upgrade your plan to access bookable resources",
        "description": "You are currently on a legacy plan. You will need to upgrade to our newest version to access this feature, plus loads of other benefits including bundled AddOns",
        "viewPlans": "View Plans"
      },
      "resourceUpgrade": {
        "title": "Congrats! Your order has been confirmed",
        "description": "Now let's add your bookable resources",
        "button": "Add Resources"
      },
      "update": {
        "title": "Congrats! Your order has been confirmed",
        "description": "Now, let's assign your AddOns to the locations you require them at",
        "button": "Assign AddOns"
      }
    },
    "cost": "{symbol}{cost}",
    "paymentDetails": {
      "title": "Billing details",
      "billingContact": "Billing contact",
      "paymentDetails": "Payment details",
      "useSavedCreditCard": "Use my saved Visa card ending in {last4digits}",
      "useNewCreditCard": "Checkout with a different credit card"
    },
    "chargeUnits": {
      "monthly": {
        "perPeriod": " / month",
        "perLocation": " / month / location",
        "perLocationPeriod": "per location / month"
      },
      "annual": {
        "perPeriod": " / year",
        "perLocation": " / year / location"
      }
    },
    "billed": {
      "monthly": "billed monthly",
      "annual": "billed annually"
    },
    "buttons": {
      "select": "Select",
      "next": "Next",
      "back": "Back",
      "contact": "Contact Us"
    },
    "location": "location",
    "room": "meeting room",
    "resource": "resource",
    "monthlyPrice": "Or {price} per month",
    "perMonth": "per month",
    "monthlyPricePerLocation": "Or {price} per location billed monthly",
    "monthlyResourcePrice": "{price} / {resource} / month",
    "annualResourcePrice": "{price} / {resource} / year",
    "paymentMethods": {
      "updateCC": "Update your credit card",
      "Note": "Note",
      "updateCCReplace": "Updating your card will replace the saved card",
      "updateBtn": "Update Card",
      "savedCard": "Saved card",
      "futurePayments": "Future payments will use this card",
      "endingIn": "{brand} ending in {last4digits}",
      "expires": "Expires {month}/{year}",
      "noSavedCard": "No card saved",
      "cardHolderName": "Card holder name",
      "cardDetails": "Card details",
      "validation": {
        "incomplete_creditcard_name": "Your card name is incomplete",
        "incomplete_number": "Your card number is incomplete",
        "invalid_number": "Your card number is invalid",
        "invalid_expiry_year_past": "The card’s expiration year is in the past",
        "invalid_expiry_month_past": "The card’s expiration month is in the past",
        "invalid_card": "Your card is invalid",
        "generic_decline": "Your card was declined",
        "insufficient_funds": "Your card has insufficient funds",
        "stolen_card": "Your card was declined",
        "expired_card": "Your card has expired",
        "processing_error": "Please check with your card issuer or retry",
        "incorrect_cvc": "Your card’s security code is incorrect",
        "payment_error_title": "There was a problem processing your payment",
        "authentication_required": "The card was declined because the transaction requires authentication such as 3D Secure",
        "account_error_country_change_requires_additional_steps": "Your card was declined because the country in which it was issued is different from your account country, please contact customer support",
        "amount_too_large": "Your card was declined because the amount is too large",
        "amount_too_small": "Your card was declined because the amount is too small",
        "application_fees_not_allowed": "Your card was declined because it includes an application fee",
        "balance_insufficient": "Your card was declined because your account does not have a sufficient balance",
        "card_not_supported": "Your card does not support this type of purchase",
        "card_velocity_exceeded": "Your card was declined because we have detected unusual activity on this account",
        "approve_with_id": "The payment can’t be authorized.",
        "currency_not_supported": "Your card was declined because the currency is not supported",
        "requires_action": "Please check with your card issuer or retry"
      }
    },
    "orderSummary": {
      "title": "Order summary",
      "nLocationPlan": "{planName} Plan | {quantity} x Location {planName} Plan | {quantity} x Location {planName} Plan",
      "nResourcesPlan": "{quantity} x Resource | {quantity} x Resources",
      "nRoomsPlan": "{quantity} x Meeting Room | {quantity} x Meeting Rooms",
      "nItem": "{item} | {quantity} x {item} | {quantity} x {item}",
      "nAddonsPlan": "{quantity} x {addonName} AddOn",
      "addonsPlan": "{addonName} AddOn | {addonName} AddOns",
      "resource": "Resource | Resources",
      "room": "Meeting Room | Meeting Rooms",
      "creditTitle": "Credit",
      "credit": "{planName} Plan Credit",
      "discount": "Promo code applied",
      "amountDue": "Amount due",
      "addOnCredit": "{addOn} AddOn",
      "payNow": "Pay {amount}",
      "confirm": "Confirm",
      "accountBalance": "Applied account balance",
      "breakdown": "Price breakdown",
      "breakdownDesc": "Credit applied for unused time on existing subscriptions after {date}",
      "lineItemCredit": "{item} Credit",
      "newBalance": "New balance {amount}",
      "subTotal": "Sub total {amount}"
    },
    "couponCode": {
      "expandTitle": "Have a promo code?",
      "invalid": "Invalid code",
      "placeholder": "Enter your code",
      "submit": "Apply"
    },
    "planCard": {
      "recommendedForYou": "Recommended for you",
      "currentPlan": "Current",
      "perMonth": "per month",
      "includedInBundle": "Included",
      "addonTitle": "AddOns",
      "featuresTitle": "{planName} features",
      "plusPreviousPlanFeatures": "All in {planName}, plus:",
      "newFeatureTag": "NEW",
      "exceededLimit": "Your account has too many {limitType} for a Lite plan. Our Standard plan may suit you better",
      "exceededLimitEmployee": "employees",
      "exceededLimitDevice": "devices",
      "exceededLimitBoth": "employees and devices"
    },
    "selectPlanForm": {
      "titles": {
        "trial": "Select your preferred plan",
        "trialEnded": "Your trial has ended, select a plan to continue",
        "update": "Subscription details"
      },
      "fields": {
        "currency": "Currency",
        "paymentFrequency": {
          "label": "Payment frequency",
          "values": {
            "annual": "Pay Annually",
            "monthly": "Pay Monthly"
          }
        },
        "quantity": "Total number of locations",
        "planType": "Plan type"
      },
      "categoryUnlinkWarning": {
        "title": "Different fields per category is an Enterprise plan feature",
        "description": "Please note by subscribing to a {plan} plan, all visitor fields will be visible for all categories"
      }
    },
    "plans": {
      "fallback": {
        "basic": {
          "name": "QR Only"
        },
        "starter": {
          "name": "Starter Plan",
          "shortName": "Standard",
          "features": [
            "Unlimited employees",
            "Visitor photos",
            "ID badge printing",
            "Digital agreements",
            "Slack & Teams notifications",
            "Sync multiple locations",
            "Live chat support",
            "1 bookable resource included"
          ],
          "newFeatures": [
            "Unlimited employees",
            "Sync multiple locations",
            "1 bookable resource included"
          ]
        },
        "business": {
          "name": "Business Plan",
          "shortName": "Premium",
          "features": [
            "Unlimited devices or QR codes",
            "Location verified sign in/out",
            "Visitor approvals",
            "Remote working",
            "Employee roaming",
            "Live display of staff in/out",
            "Customer success onboarding",
            "3 bookable resources included"
          ],
          "newFeatures": [
            "3 bookable resources included"
          ]
        },
        "enterprise": {
          "name": "Enterprise Plan",
          "shortName": "Enterprise",
          "features": [
            "Customize multiple visitor flows",
            "Record multiple agreements",
            "Employee directory integrations",
            "Auto anonymize visitor data",
            "Priority support",
            "Dedicated account manager",
            "Bespoke onboarding",
            "5 bookable resources included",
            "Require Multi-Factor Authentication"
          ],
          "newFeatures": [
            "5 bookable resources included"
          ]
        },
        "premium": {
          "name": "Premium Plan",
          "shortName": "Premium"
        },
        "custom": {
          "name": "Enterprise (Custom) Plan"
        },
        "freemium": {
          "name": "Free Plan"
        },
        "lite": {
          "name": "Lite Plan",
          "shortName": "Lite",
          "features": [
            "One device or QR code",
            "Up to 10 employees",
            "Contactless sign in",
            "Unlimited visitors",
            "1 bookable resource included"
          ],
          "newFeatures": [
            "1 bookable resource included"
          ]
        }
      }
    },
    "currencies": {
      "usd": {
        "symbol": "$",
        "format": "{symbol}{amount}"
      },
      "nzd": {
        "symbol": "$",
        "format": "{symbol}{amount}"
      },
      "cad": {
        "symbol": "$",
        "format": "{symbol}{amount}"
      },
      "gbp": {
        "symbol": "£",
        "format": "{symbol}{amount}"
      },
      "eur": {
        "symbol": "€",
        "format": "{symbol}{amount}"
      },
      "aud": {
        "symbol": "$",
        "format": "{symbol}{amount}"
      },
      "krw": {
        "symbol": "₩",
        "format": "{symbol}{amount}"
      },
      "cny": {
        "symbol": "¥",
        "format": "{symbol}{amount}"
      },
      "sgd": {
        "symbol": "$",
        "format": "{symbol}{amount}"
      },
      "twd": {
        "symbol": "NT$",
        "format": "{symbol}{amount}"
      },
      "hkd": {
        "symbol": "HK$",
        "format": "{symbol}{amount}"
      },
      "brl": {
        "symbol": "R$",
        "format": "{symbol} {amount}"
      },
      "sek": {
        "symbol": "kr",
        "format": "{amount} {symbol}"
      },
      "dkk": {
        "symbol": "kr.",
        "format": "{symbol} {amount}"
      },
      "nok": {
        "symbol": "kr",
        "format": "{symbol} {amount}"
      },
      "php": {
        "symbol": "₱",
        "format": "{symbol} {amount}"
      },
      "myr": {
        "symbol": "RM",
        "format": "{symbol} {amount}"
      },
      "aed": {
        "symbol": "AED",
        "format": "{amount} {symbol}"
      },
      "mxn": {
        "symbol": "$",
        "format": "{symbol} {amount}"
      }
    },
    "requestAddonsForm": {
      "title": "AddOn request",
      "subtitle": "Select what AddOns you'd  like to add and our team will be in touch to process this request",
      "formSentAlertTitle": "Your request has been sent",
      "formSentAlertDescription": "Someone from our team will be in touch soon",
      "company": "Company name",
      "billingEmail": "Billing email",
      "billingAddress": "Billing Address",
      "purchaseOrderNumber": "Purchase order number",
      "addonsToAdd": "What would you like to add to your subscription?",
      "sms": "Unlimited SMS",
      "deliveries": "Deliveries",
      "catering": "Catering",
      "inductions": "Preboarding",
      "includedInPlan": "{addon} (Included in plan)",
      "withPrice": "{addon} {price} / location",
      "numRequired": "Number required",
      "message": "Message",
      "disclaimer": "Please note a change to your subscription will incur a manual processing fee",
      "anyQuestions": "Have a question? Please contact your local sales rep directly",
      "buttons": {
        "back": "Back",
        "submit": "Send Request"
      }
    },
    "requestResourcesForm": {
      "title": "Resources request",
      "subtitle": "Select how many resources you would like to add to your Account. Once confirmed, this will automatically be processed via a manual invoice.",
      "subtitle2": "If you'd prefer to speak to your local rep first, please {contactUs}",
      "contactUs": "contact us",
      "formSentAlertTitle": "Your request has been sent",
      "formSentAlertDescription": "Someone from our team will be in touch soon",
      "company": "Company name",
      "billingEmail": "Billing email",
      "billingAddress": "Billing Address",
      "purchaseOrderNumber": "Purchase order number",
      "resourcesRequired": "Number of resources required",
      "message": "Message",
      "disclaimer": "Please note a change to your subscription will incur a manual processing fee",
      "anyQuestions": "Have a question? Please contact your local sales rep directly",
      "buttons": {
        "back": "Back",
        "submit": "Send Request"
      }
    },
    "requestLocationsForm": {
      "title": "Locations request",
      "subtitle": "Select how many locations you require and our team will be in touch to process this request",
      "formSentAlertTitle": "Your request has been sent",
      "formSentAlertDescription": "Someone from our team will be in touch soon",
      "company": "Company name",
      "billingEmail": "Billing email",
      "billingAddress": "Billing Address",
      "purchaseOrderNumber": "Purchase order number",
      "locationsRequired": "Number of extra locations required",
      "message": "Message",
      "disclaimer": "Please note a change to your subscription will incur a manual processing fee.",
      "anyQuestions": "Have a question? Please contact your local sales rep directly.",
      "buttons": {
        "back": "Back",
        "submit": "Send Request"
      }
    },
    "downgradePlanForm": {
      "title": "Plan downgrade",
      "subtitle": "Please contact us by filling out the form below and one of our account managers will be in touch with you as soon as possible",
      "formSentAlertTitle": "Your request has been sent",
      "formSentAlertDescription": "Someone from our team will be in touch soon",
      "name": "Name",
      "email": "Email",
      "company": "Company name",
      "downgradeRequest": "Downgrade request",
      "downgradeRequestOptions": {
        "annualToMonthly": "Annual billing to monthly",
        "removeLocations": "Remove locations",
        "downgradePlan": "Downgrade plan"
      },
      "explanation": "Please provide details of your required plan change",
      "buttons": {
        "back": "Back",
        "submit": "Send"
      }
    },
    "unsubscribeForm": {
      "title": "Cancel plan request",
      "subtitle": "Please fill in your details and our support team will contact you as soon as possible to assist",
      "formSentAlertTitle": "Your request has been sent",
      "formSentAlertDescription": "Someone from our team will be in touch soon",
      "name": "Name",
      "email": "Email",
      "company": "Company name",
      "cancellationReason": "Why are you cancelling your subscription?",
      "cancellationOptions": {
        "noValue": "I did not get sufficient value",
        "noFeatures": "It does not have the features I need",
        "priceHigh": "Price is too expensive",
        "other": "We like SwipedOn, we’re cancelling for other reasons"
      },
      "insufficientValueLabel": "What best describes your reason for cancelling?",
      "insufficientValueOptions": {
        "notUsed": "Didn’t use it as much as we thought",
        "itResources": "Couldn’t get enough IT resources to set it up",
        "other": "Other"
      },
      "moreFeaturesLabel": "Please share more information about the features you specifically needed to help us improve",
      "otherReasonOptionsLabel": "Which of the following best describes your reason for cancelling?",
      "pleaseShareLabel": "Please share more information to help us improve",
      "otherReasonOptions": {
        "change": "Our business needs changed (such as a project finished, or business closed)",
        "covid": "COVID-19 has impacted our business",
        "cost": "Our business is cutting costs",
        "shortUse": "Only intended for short term use",
        "different": "Subscribed to another SwipedOn plan",
        "other": "Other"
      },
      "moreInfoPlease": "Please share more information to help us improve",
      "moreFeaturesPlease": "Please share more information about the features you specifically needed to help us improve",
      "switchQuestion": "Are you switching to an alternative system? If so, which one?",
      "switchQuestionCompetitor": "Which alternative system are you switching to?",
      "switchOptions": {
        "yes": "Yes",
        "no": "No",
        "unknown": "Don’t know"
      },
      "alternatives": [
        "Envoy",
        "EVA Receptionist",
        "Greetly",
        "iLobby",
        "iOffice",
        "LobbyGuard",
        "Proxyclick",
        "Raptor",
        "Sign in App",
        "Sine",
        "Team Go",
        "Teem",
        "The Receptionist",
        "Traction Guest",
        "Visitly",
        "VisitUs Reception",
        "Vizito",
        "Who’s On Location",
        "Other"
      ],
      "buttons": {
        "back": "Back",
        "submit": "Send"
      }
    },
    "cancelAddonForm": {
      "title": "Cancel AddOn request",
      "subtitle": "Please fill in your details and our support team will contact you as soon as possible to assist",
      "formSentAlertTitle": "Your request has been sent",
      "formSentAlertDescription": "Someone from our team will be in touch soon",
      "cancellationReason": "Reason for cancelation request",
      "addon": "AddOn",
      "buttons": {
        "back": "Back",
        "submit": "Send Request"
      }
    },
    "forms": {
      "errors": {
        "unexpected": "An unexpected error occurred",
        "exceeds_resources": {
          "employees": "Number of employees exceeds what is afforded by the selected plan"
        }
      }
    }
  },
  "info": {
    "noEmployeesFound": "No employees found",
    "browserWarning": {
      "outOfDate": "Your web browser is out of date",
      "updateYourBrowser": "Update your browser for more security, speed and the best experience on this website. {action}",
      "updateNow": "Update now"
    },
    "superAdminWarning": "You are viewing {companyName} as a Super Admin"
  },
  "helperText": {
    "dragTooltip": "Click and hold to reorder"
  },
  "nav": {
    "home": "Home",
    "visitors": "Visitors",
    "employees": "Employees",
    "bookings": "Spaces",
    "deliveries": "Deliveries",
    "devices": "Devices",
    "settings": "Settings",
    "evacuation": "Evacuation",
    "whatsNew": "What’s New",
    "support": "Support",
    "location": "Location",
    "organization": "Organization",
    "settingsItems": {
      "visitorSettings": {
        "name": "Visitor Settings",
        "description": "Customize your visitor sign-in flow",
        "tabs": {
          "welcome-screen": "Welcome Screen",
          "branding-and-badges": "Branding & Badges",
          "categories": "Categories",
          "fields": "Fields",
          "advanced": "Advanced Settings",
          "catering": "Catering"
        }
      },
      "employeesSettings": {
        "name": "Employee Settings",
        "description": "In and out settings, sign-in questions, profile fields and sign-out messages",
        "tabs": {
          "general": "General",
          "pocket-app": "SwipedOn Pocket",
          "profile-fields": "Profile fields",
          "sign-in-questions": "Sign in questions",
          "sign-out-messages": "Sign out messages"
        }
      },
      "configureSpaces": {
        "name": "Spaces Settings",
        "description": "Manage your bookable resources",
        "tabs": {
          "spaces": "Spaces",
          "resources": "Resources",
          "categories": "Categories"
        }
      },
      "deliveriesSettings": {
        "name": "Deliveries Settings",
        "description": "Configure delivery messaging and contacts",
        "tabs": {
          "setup": "Setup",
          "delivery-instructions": "Delivery Instructions"
        }
      },
      "devices": {
        "name": "Devices",
        "description": "Set up SwipedOn for iPads and Android tablets"
      },
      "locations": {
        "name": "Locations & AddOns",
        "description": "Manage locations, groups, AddOns and download QR codes"
      },
      "agreementsLibrary": {
        "name": "Agreements Library",
        "description": "Add and manage company wide agreements"
      },
      "inductions": {
        "name": "Preboarding",
        "description": "Create and manage visitor preboarding"
      },
      "integrations": {
        "name": "Integrations",
        "description": "Connect with your existing workplace tools"
      },
      "accountSettings": {
        "name": "Account",
        "description": "Plans and payment details"
      },
      "securitySettings": {
        "name": "Security",
        "description": "Manage your security settings"
      }
    },
    "colorSelector": {
      "default": "Default",
      "light": "Light",
      "dark": "Dark"
    },
    "logOut": "Log Out",
    "profile": {
      "viewProfile": "View Profile",
      "securityHeader": "Security",
      "buttons": {
        "changePassword": "Password",
        "updatePassword": "Update Password",
        "language": "Language",
        "mfa": "Multi-Factor Authentication"
      },
      "fields": {
        "sessionEndWarning": "Changing your password will log you out from SwipedOn Pocket",
        "sessionEndWarningMfa": "Changing your password will log you out from SwipedOn and SwipedOn Pocket. You will be required to log back in to continue using SwipedOn",
        "currentPassword": "Current password",
        "newPassword": "New password",
        "mfaCodeLabel": "Multi-Factor Authentication is enabled for this account. Please enter a code to change your password",
        "mfaCode": "Authentication Code",
        "changePasswordHeader": "Change Password"
      },
      "mutliFactorAuth": {
        "options": {
          "totp": "On",
          "off": "Off"
        }
      }
    }
  },
  "multiFactorDialog": {
    "title": "Multi-Factor Authentication (MFA)",
    "subtitle": "MFA can help protect your account by requiring a security code when signing into the SwipedOn Web Dashboard",
    "step1": "1. Install an Authenticator app on your phone",
    "step2": "2. Scan the QR code using your Authenticator app",
    "step3": "3. Enter the next authentication code",
    "placeholder": "Enter 6 digit code",
    "cancel": "Cancel",
    "confirm": "Confirm",
    "errors": {
      "EnableSoftwareTokenMFAException": "Please enter a valid code",
      "InvalidParameterException": "Please enter a valid code"
    }
  },
  "multiFactorSetup": {
    "title": "Multi-Factor Authentication (MFA)",
    "subtitle": "MFA is now required. Follow the steps below to complete the process.",
    "steps": [
      "Install an Authenticator app on your phone {learnMore}",
      "Scan the QR code using your Authenticator app",
      "Enter the next authentication code"
    ],
    "learnMore": "learn more",
    "confirm": "Confirm"
  },
  "locationSelector": {
    "main": "{title} {location}",
    "titleAt": "{title} {at}",
    "at": "at",
    "allLocations": "All Locations",
    "enterLocation": "Enter a location",
    "manageGroup": "Manage Group",
    "manageLocationsAndGroups": "Manage Locations and Groups"
  },
  "employeeSelector": {
    "title": "Select an employee",
    "collapseTitle": "Or add a General Contact",
    "emailPlaceholder": "Email",
    "phonePlaceholder": "Mobile number",
    "locationPlaceholder": "Location to be notified about",
    "search": "Search",
    "add": "Add",
    "remove": "Remove",
    "generalContact": "General Contact",
    "generalCatering": "General Catering"
  },
  "home": {
    "latestActivity": {
      "header": "Latest activity",
      "visitorTimeline": "Visitor Timeline",
      "employeeTimeline": "Employee Timeline",
      "activitiesCount": "Showing latest {count} activities",
      "event": "{name} {remotely}{signedInOut} {location} {source}",
      "remotely": "remotely ",
      "signedInTo": "signed in to",
      "signedUpToTrial": "{name} started a SwipedOn trial",
      "signedOutOf": "signed out of",
      "onTheIpad": "on the iPad",
      "onTheWebApp": "on the Web dashboard",
      "onContactless": "on the Contactless app",
      "onPocket": "on SwipedOn Pocket",
      "automatically": "automatically",
      "andIs": " and is {awaitingApproval}",
      "awaitingApproval": "awaiting approval",
      "types": {
        "employee": "Employee",
        "visitor": "Visitor"
      },
      "noActivities": {
        "title": "There has been no visitor or employee activity",
        "subtitle": "Activity displays here when a visitor or employee signs in or out"
      },
      "viewVisitorTimeline": "View visitor timeline",
      "viewEmployeeTimeline": "View employee timeline"
    },
    "mainTitle": {
      "header": "Hi {name}",
      "subHeader": "{title} {location}",
      "title": "Here’s the latest at"
    },
    "whatsHappening": {
      "awaitingApproval": "You have {people} awaiting approval",
      "visitors": "0 visitors | 1 visitor | {n} visitors",
      "employees": "0 employees | 1 employee | {n} employees"
    },
    "metrics": {
      "enable": "Enable",
      "getStarted": "Get Started",
      "past7days": "PAST 7 DAYS",
      "visitorsIn": {
        "description": "Visitors In",
        "label": "Automate and modernize the visitor sign in"
      },
      "expectedVisitors": {
        "description": "Expected Visitors Today",
        "label": "Fast track the sign in experience and send an invite"
      },
      "employeesIn": {
        "description": "Employees In",
        "label": "Offer your staff the simplest way to sign in and out"
      },
      "employeesRemote": {
        "description": "Employees Remote",
        "label": "Simplify the hybrid workplace by knowing who’s working remotely"
      },
      "bookings": {
        "description": "Bookings Today",
        "label": "Make better use of your space and keep track of your resources",
        "setUpSpaces": "Set Up Spaces"
      },
      "deliveries": {
        "description1": "Deliveries",
        "label1": "Manage deliveries to the workplace & automatically notify recipients",
        "description2": "Deliveries Today",
        "label2": "LAST DELIVERY",
        "noDeliveries": "{learn} how Deliveries can help streamline your front desk",
        "learn": "Learn",
        "trialDeliveries": "Trial Deliveries",
        "addNow": "Add Now"
      },
      "nextDue": "NEXT DUE",
      "noExpectedVisitors": "You currently have no expected visitors",
      "noDeliveries": "No deliveries yet received",
      "generalDelivery": "General Delivery",
      "at": "At {locationName}",
      "visiting": "Visiting {name}",
      "deliveryFor": "for {name}"
    },
    "versionWarning": {
      "title": {
        "infoSingle": "Your SwipedOn app needs updating",
        "infoPlural": "Your SwipedOn apps need updating",
        "warningSingle": "Your version of the SwipedOn app will soon be unsupported",
        "warningPlural": "SwipedOn will soon be unsupported on a number of your devices",
        "dangerSingle": "Please update the SwipedOn app on {device}",
        "dangerPlural": "Please update the SwipedOn app",
        "unsupportedSingle": "The SwipedOn app on {device} is out of date",
        "unsupportedPlural": "You are running out of date versions of the SwipedOn app"
      },
      "description": {
        "infoSingle": "Don’t miss the latest features and ensure your app continues to run correctly. Update {device} with the latest version.",
        "infoPlural": "Don’t miss the latest features & ensure your apps continue to run correctly. Go to {page} in the web dashboard to see which devices require an update.",
        "warningSingle": "The SwipedOn app on {device} requires an update to ensure it continues to run correctly.",
        "warningPlural": "Keep your system running smoothly with the latest version. Go to {page} to find out what needs updating.",
        "dangerSingle": "The version of the SwipedOn app on {device} will soon be unsupported. Please update to the latest version.",
        "dangerPlural": "The SwipedOn App, on a number of your devices, will soon be unsupported. Go to {page} to see which ones require an update.",
        "unsupportedSingle": "You are running an older, unsupported version of the SwipedOn app that will not function correctly. Please update to the latest version.",
        "unsupportedPlural": "A number of your devices are running an older version of the SwipedOn app that will not function correctly. Go to {page} to see which ones require an update."
      },
      "learnMore": "Learn more",
      "devices": "Devices"
    }
  },
  "visitors": {
    "tabs": {
      "timeline": "Timeline",
      "rememberedVisitors": "Remembered Visitors",
      "preregistration": "Preregistration"
    },
    "timeline": {
      "confirm": {
        "signOutVisitors": "You are about to sign out {count} visitor | You are about to sign out {count} visitors",
        "disclaimer": "This cannot be undone. Do you want to continue?",
        "removeVisitors": "You are about to remove {count} remembered visitor from the list | You are about to remove {count} remembered visitors from the list",
        "removeDisclaimer": "Do you want to continue?",
        "anonymizeVisits": "You are about to anonymize {count} visit | You are about to anonymize {count} visits",
        "signOutVisits": "You are about to sign out {count} visit | You are about to sign out {count} visits"
      },
      "title": "Visitor Timeline",
      "in": "In",
      "newVisit": "New Visit",
      "signedInAt": "Signed in at {datetime}",
      "signedOutAt": "Signed out at {datetime}",
      "columns": {
        "status": "Status",
        "photo": "Photo",
        "visitor": "Visitor",
        "host": "Host",
        "category": "Category",
        "location": "Location",
        "signedIn": "Signed In",
        "signedOut": "Signed Out",
        "duration": "Duration",
        "customFields": "Custom Fields"
      },
      "fullName": "Full name",
      "namePlaceholder": "Enter visitor name",
      "location": "Location",
      "locationAndMethod": "{location} {method}",
      "category": "What type of visitor are you?",
      "host": "Who are you visiting today?",
      "visitingHost": "Visiting (Host)",
      "inAndOut": "In and out",
      "signedIn": "Signed in",
      "signedOut": "Signed out",
      "totalTime": "Total time",
      "randomisedAlert": "This visitor has been selected for randomized approval",
      "methods": {
        "kiosk": "on the iPad",
        "contactless": "on the Contactless App",
        "dashboard": "on the Web Dashboard",
        "mobile": "on SwipedOn Pocket",
        "system": "automatically"
      },
      "actions": {
        "export": "Export",
        "exportAll": "Export All",
        "signOut": "Sign Out",
        "signOutAll": "Sign Out All",
        "anonymize": "Anonymize",
        "newVisitButton": "New Visit",
        "signInVisitor": "Sign in a visitor",
        "signIn": "Sign In",
        "fullName": "Full name",
        "location": "Location",
        "category": "Category",
        "filters": {
          "status": "Status",
          "category": "Category"
        },
        "searchPlaceholder": "Search"
      },
      "emptyState": {
        "heading": "There is no visitor activity",
        "action": "Activity displays here when a visitor signs in using {ipadTabletLink} or {qrCodeLink}",
        "ipadTablet": "iPad, tablet",
        "qrCode": "QR code",
        "addNewVisit": "Add A New Visit",
        "noMatchingResults": "No matching results",
        "trial": {
          "heading": "There is no visitor activity yet",
          "subheading": "Get started by completing your very first sign in",
          "deviceId": "Device ID: {deviceId}",
          "description": "Install the ‘SwipedOn Visitor Management’ app on your iPad or Tablet",
          "action": "and enter your {device}",
          "orQrCode": "Or scan the QR code"
        }
      }
    },
    "preregistration": {
      "title": "Preregistration",
      "expected": "Expected",
      "searchPlaceholder": "Search",
      "delete": "Delete",
      "addVisitorButton": "Add Visitor",
      "importCSV": "Import CSV",
      "preregisterVisitor": "Preregister a visitor",
      "fullName": "Full name",
      "startDate": "Start date",
      "endDate": "End date",
      "startTime": "Start time",
      "location": "Location",
      "address": "Address",
      "visitorCategory": "Visitor category",
      "noHost": "No host",
      "visitingToday": "Host",
      "visitingEmployee": "Visiting an Employee (host)",
      "visitingHost": "Visiting (host)",
      "hostPlaceholder": "Search for an employee",
      "invitation": "Invitation",
      "sendEmailInvite": "Send email invite",
      "visitorEmail": "Visitor email",
      "inviteMessage": "Invite message",
      "inviteMessageUpgrade": "Invite messages are unavailable on a free trial.",
      "inviteMessageUpgradeDescription": "This feature is included on all paid plans. {upgradeLink}",
      "inviteMessageUpgradeLink": "Upgrade now",
      "sendTestInvite": "Send yourself a test invite",
      "sendUpdatedInvite": "Send updated invite",
      "includeInductionLink": "Include Visitor preboarding link",
      "upgradeTooltip": "Upgrade your plan to preregister visitors",
      "add": "Preregister Visit",
      "filters": {
        "category": "Category",
        "allCategories": "All"
      },
      "tableHeadings": {
        "visitor": "Visitor",
        "category": "Category",
        "date": "Date",
        "time": "Time",
        "host": "Host",
        "location": "Location",
        "customFields": "Custom Fields"
      },
      "emptyState": {
        "heading": "There are no preregistered visitors",
        "message": "Fast track the sign in experience by preregistering visitor details and sending an email invite",
        "action": "Add Visitor",
        "noMatchingResults": "No matching results",
        "upgrade": "Upgrade now"
      },
      "deleteDialog": {
        "titleSingle": "You are about to delete the preregistration details for {name}",
        "titleMulti": "You are about to delete {number} preregistered visitors",
        "descrition": "If your visitor has been sent an invite, they will receive a cancellation message. This action can not be undone. Are you sure you want to continue?",
        "cancel": "Cancel",
        "delete": "Delete"
      }
    },
    "rememberedVisitors": {
      "title": "Remembered Visitors",
      "searchPlaceholder": "Search",
      "signedInAt": "Signed in at {datetime}",
      "signedOutAt": "Signed out at {datetime}",
      "columns": {
        "visitor": "Visitor",
        "status": "Status",
        "photo": "Photo",
        "lastVisit": "Last Visit",
        "host": "Host",
        "category": "Category",
        "location": "Location",
        "customFields": "Custom Fields"
      },
      "anonymizedVisitor": "Anonymized Visitor",
      "anonymized": "Anonymized",
      "form": {
        "fullName": "Full name",
        "location": "Location",
        "whatType": "What type of visitor are you?",
        "visitToday": "Who are you visiting today?",
        "noHost": "No host",
        "visitingEmployee": "Visiting an Employee (host)",
        "visitingHost": "Visiting (host)",
        "downloadVisitorAgreement": "Download Visitor Agreement",
        "yourNotes": "Your notes",
        "visitorAgreementFileName": "Visitor Agreement {name} {datetime}",
        "contactTraceFileName": "SwipedOn Contact Tracing {name} {datetime}",
        "expires": "Expires {date}",
        "expired": "{name} expired {date}",
        "completed": "{name} completed {date}",
        "requiredField": "Required field",
        "contactTrace": "Contact Trace"
      },
      "filters": {
        "status": "Status",
        "category": "Category",
        "all": "All"
      },
      "emptyState": {
        "heading": "There are no remembered visitors",
        "message": "Returning visitors can speed up sign in by saving their details for next time",
        "action": "Enable this feature in {link}",
        "visitorSettings": "Visitor Settings",
        "noMatchingResults": "No matching results"
      },
      "actions": {
        "export": "Export",
        "exportAll": "Export All",
        "signOut": "Sign Out",
        "signOutAll": "Sign Out All",
        "remove": "Remove"
      }
    },
    "fields": {
      "yes": "Yes",
      "no": "No",
      "yesNoSelectedYesWarning": "This response requires approval when your visitor signs in",
      "documentUploadNotSupported": "Document upload is not supported in the SwipedOn web dashboard. If required, your visitor will need to sign in themselves",
      "fieldNotSupported": "This field type is not supported",
      "disabledField": "This field is disabled",
      "documentUploaded": "Document uploaded",
      "downloadDocument": "Download Document",
      "documentUploadFileName": "Document Upload {name} {datetime}",
      "noDocumentProvided": "No document provided",
      "passValidated": "Pass validated",
      "passNotProvided": "Pass not provided",
      "passInvalid": "Pass invalid"
    },
    "settings": {
      "downloadVisitorAgreement": "Download Visitor Agreement",
      "induction": {
        "expired": "{name} expired {date}",
        "expiredAt": "Expires {date}",
        "noExpiry": "No expiry",
        "completed": "{name} completed {date}"
      },
      "yourNotes": "Your notes",
      "contactTrace": "Contact Trace",
      "startTyping": "Start typing…"
    }
  },
  "authless": {
    "approvedBy": "Approved by {name}",
    "declinedBy": "Declined by {name}",
    "arrivedAt": "{name} arrived at {location} on {datetime}",
    "employee": {
      "declined": "Employee declined",
      "approved": "Employee approved",
      "alreadyDeclined": "Employee has already been declined",
      "alreadyApproved": "Employee has already been approved",
      "viewEmployee": "View Employee",
      "pleaseInform": "Please inform accordingly",
      "approvalLapsed": "Employee approval no longer required"
    },
    "visit": {
      "declined": "Visit declined",
      "approved": "Visit approved",
      "alreadyDeclined": "Visit has already been declined",
      "alreadyApproved": "Visit has already been approved",
      "visiting": "Visiting {name}",
      "viewVisit": "View Visit",
      "pleaseInform": "Please inform your visitor accordingly",
      "approvalLapsed": "Visitor approval no longer required"
    }
  },
  "mobileWelcomeScreen": {
    "noImage": "No image",
    "subTitle": "Update button shape, text and color",
    "heading": {
      "overlay": "Overlay",
      "signIn": "Sign in button",
      "other": "Other buttons & text",
      "signOut": "Visitor sign out",
      "deliveries": "Deliveries",
      "qrScan": "QR scan",
      "employeeTitle": "Employee & sign out buttons"
    },
    "button": {
      "shape": "Button shape",
      "color": "Button color",
      "text": "Button text",
      "textColor": "Button text color"
    },
    "employee": {
      "text": "Employee button text",
      "signOut": "Visitor sign out button text"
    }
  },
  "welcomeScreen": {
    "title": "Customize your welcome screen",
    "subTitle": "Update button shape, text and color by clicking on the welcome screen below",
    "ipadPreview": {
      "changeOrientation": "Change device orientation",
      "changeOverlay": "Change overlay",
      "overlayBack": "Back",
      "defaults": {
        "employees": "Employees",
        "scan": "Scan to visit",
        "scanToSignIn": "Scan to sign in",
        "visitorIn": "Visitor In",
        "deliveries": "Deliveries",
        "visitorOut": "Visitor Out"
      },
      "ipadScanCaption": "Ipad Scan Caption",
      "ipadSwipedonLogo": "Swipedon Logo",
      "ipadIcon": "Ipad Icon"
    },
    "backgroundImageHeading": "Background image",
    "backgroundImageInfo": "Upload backgrounds, select multiple images to create a slideshow",
    "contactlessSignInSettings": {
      "title": "Contactless sign in",
      "signInOnIpadTablet": "Contactless sign in on iPad and Tablet",
      "descriptionLine1": "Displays a dynamic QR code in lower right corner of the screen, for visitor and employees to sign in on their own device",
      "descriptionLine2": "Don’t have an iPad or tablet? Generate",
      "staticQRCodeLinkText": "static QR code",
      "displayIpadQrLabel": "Display a QR code for contactless sign in",
      "scanLabel": "Scan QR text",
      "scanDefault": "Scan to visit"
    },
    "learnMore": "{link} about configuring your welcome screen",
    "learnMoreLinkText": "Learn more",
    "screensaverAlt": "screensaver {id}",
    "customScreensaversNotEnabled": "Upgrade your plan to add custom backgrounds",
    "confirmationScreen": {
      "title": "Confirmation screen",
      "description": "Customize the message your visitors see when they complete their sign-in",
      "inputTitle": "Title",
      "enterTitle": "Enter a title",
      "message": "Message",
      "enterMessage": "Enter a message"
    },
    "updateCta": {
      "upgradeCtaAlt": "Lightbulb Icon",
      "title": "Provide a custom sign-in message",
      "upgradeNow": "Upgrade now",
      "description": "Customize the message your visitors see once they complete their sign in {link}"
    }
  },
  "employees": {
    "anonymizedEmployee": "Anonymized Employee",
    "tabs": {
      "directory": "Directory",
      "timeline": "Timeline",
      "archive": "Archive"
    },
    "timeline": {
      "title": "Employee Timeline",
      "remote": "Remote",
      "in": "In",
      "columns": {
        "status": "Status",
        "photo": "Photo",
        "employee": "Employee",
        "signedIn": "Signed In",
        "signedOut": "Signed Out",
        "duration": "Duration",
        "location": "Location",
        "signInQuestions": "Sign In Questions",
        "statusMessage": "Status Message"
      },
      "emptyState": {
        "heading": "There is no employee activity",
        "description": "Offer a simple, verifiable, way to sign in and out using the {device} or {pocket}",
        "device": "iPad/tablet",
        "pocket": "SwipedOn Pocket",
        "noMatchingResults": "No matching results",
        "trial": {
          "heading": "There is no employee activity yet",
          "body": "Activity displays here when your staff sign in and out",
          "directory": "add your employees to the directory",
          "description": "You'll need to {directory} to make use of this feature",
          "pocket": "SwipedOn Pocket",
          "action": "Give it a whirl yourself, sign in with {pocket}"
        }
      },
      "signOutDialog": {
        "title": "You are about to sign out {count} employee | You are about to sign out {count} employees",
        "disclaimer": "This can not be undone. Do you want to continue?",
        "confirm": "Sign Out Employees",
        "cancel": "Cancel"
      },
      "actions": {
        "export": "Export",
        "exportAll": "Export All",
        "signOut": "Sign Out",
        "signOutAll": "Sign Out All",
        "searchPlaceholder": "Search"
      },
      "employeeMovementDrawer": {
        "email": "Email",
        "phone": "Phone",
        "location": "Location",
        "viewProfile": "View Profile",
        "viewMovements": "View Timeline For {name}",
        "inAndOut": "In and out",
        "signedIn": "Signed in",
        "signedOut": "Signed out",
        "totalTime": "Total time",
        "methods": {
          "kiosk": "on the iPad",
          "contactless": "on the Contactless App",
          "dashboard": "on the Web Dashboard",
          "mobile": "on SwipedOn Pocket",
          "system": "automatically"
        },
        "locationAndMethod": "{location} {method}",
        "signInQuestions": "Sign in questions",
        "contactTrace": "Contact Trace"
      },
      "filters": {
        "status": "Status"
      }
    },
    "archive": {
      "title": "Employee Archive",
      "columns": {
        "name": "Name",
        "dateArchived": "Date Archived"
      },
      "actions": {
        "searchPlaceholder": "Search",
        "actions": "Actions",
        "restore": "Restore",
        "anonymize": "Anonymize",
        "disclaimer": "This can not be undone. Do you want to continue?",
        "showAnonymizeEmployees": "You are about to anonymize {count} employee | You are about to anonymize {count} employees",
        "anonymizeEmployees": "Anonymize Employee | {n} Anonymize Employees",
        "cancel": "Cancel"
      },
      "emptyState": {
        "heading": "You have no archived employees yet",
        "message": "Keep your records up to date by archiving past employees from the {directory} Data remains in the archive until you choose to anonymize it",
        "directory": "Directory",
        "noMatchingResults": "No matching results"
      }
    },
    "directory": {
      "title": "Employee Directory",
      "signedInAt": "Signed in at {datetime}",
      "signedOutAt": "Signed out at {datetime}",
      "columns": {
        "status": "Status",
        "photo": "Photo",
        "employee": "Employee",
        "signedIn": "In",
        "signedOut": "Out",
        "adminRole": "Admin Role",
        "remote": "Remote",
        "email": "Email",
        "phone": "Phone",
        "department": "Department",
        "statusMessage": "Status Message",
        "latestActivity": "Latest Activity",
        "location": "Location"
      },
      "actions": {
        "searchPlaceholder": "Search",
        "export": "Export",
        "exportAll": "Export All",
        "signOut": "Sign Out",
        "signOutAll": "Sign Out All",
        "archive": "Archive",
        "add": "Add",
        "addEmployee": "Add Employee",
        "importCSV": "Import CSV",
        "confirmUpload": "Upload Photo",
        "integrations": " Directory Integrations",
        "cancel": "Cancel",
        "updatePlanToAdd": "Upgrade your plan to add more employees"
      },
      "signOutDialog": {
        "title": "You are about to sign out {count} employee | You are about to sign out {count} employees",
        "disclaimer": "This can not be undone. Do you want to continue?",
        "confirm": "Sign Out Employees",
        "cancel": "Cancel"
      },
      "filters": {
        "status": "Status",
        "adminRole": "Admin Role",
        "department": "Department",
        "all": "All",
        "select": "Select",
        "noDepartmentWarning": {
          "line1": "No departments added",
          "line2": "Go to Employee Settings > Profile fields"
        }
      },
      "emptyState": {
        "heading": "There are no employees in this directory",
        "messageLine1": "Add employees for greater visibility on who is where",
        "messageLine2": "Add via CSV upload, directory integrations, or individually",
        "noMatchingResults": "No matching results"
      }
    },
    "form": {
      "workingRemotelyOptions": {
        "followLocation": "Default to location setting",
        "enabled": "Always allowed",
        "disabled": "Never allowed",
        "manage": "Manage your employees’ permissions to sign in as working remotely"
      },
      "firstName": "First name",
      "lastName": "Last name",
      "email": "Email",
      "phone": "Phone",
      "phonePlaceholder": "000 0000",
      "department": "Department",
      "location": "Location",
      "emailCc": "Assistant email (cc)",
      "phoneCc": "Assistant phone (sms)",
      "employeeRemote": "Working remotely",
      "adminRole": "Admin role",
      "locationGroup": "Location/Group",
      "cancel": "Cancel",
      "create": "Create",
      "autoCompletePlaceholder": "Enter keywords…",
      "adminRoles": {
        "companyAdmin": "Company Admin",
        "locationManager": "Location Manager",
        "groupAdmin": "Group Admin",
        "employee": "Employee",
        "superAdmin": "Super Admin"
      },
      "inviteSent": "Invite Sent",
      "sendingInvite": "Sending Invite...",
      "resendInvite": "Resend Invite",
      "locationGroupsGrouping": "Location Groups",
      "singleLocationsGrouping": "Single Locations",
      "drawerHeading": "Add Employee",
      "notificationSettings": "Notification Settings",
      "sms": "SMS",
      "adIntegrationCheckboxLabel": "Sync via {platform}",
      "addEmail": "Add Email",
      "manage": "Manage"
    },
    "errors": {
      "fetchMovementFailed": "Failed to fetch employee movement",
      "fetchMovementsFailed": "Failed to fetch employee movements",
      "updateEmployeeFailed": "Failed to update employee",
      "signOutEmployeeFailed": "Failed to sign out employee",
      "signOutEmployeesFailed": "Failed to sign out employees",
      "signOutAllFailed": "Failed to sign out all employees & visitors",
      "archiveEmployeeFailed": "Failed to archive employee",
      "archiveEmployeesFailed": "Failed to archive employees",
      "anonymizeEmployeeFailed": "Failed to anonymize employee",
      "anonymizeEmployeesFailed": "Failed to anonymize employees",
      "restoreEmployeeFailed": "Failed to restore employee",
      "restoreEmployeesFailed": "Failed to restore employees",
      "exportEmployeesFailed": "Failed to export employee data",
      "exportMovementsFailed": "Failed to export employee movement data",
      "exportInductionsFailed": "Failed to export preboarding data"
    }
  },
  "deliveries": {
    "title": "Deliveries",
    "markAsCollected": "Mark As Collected",
    "searchPlaceholder": "Search",
    "actions": {
      "addDelivery": "Add Delivery",
      "viewAddons": "View AddOns"
    },
    "assignToMarkCollected": "Assign a recipient to mark as collected",
    "filters": {
      "deliveryStatus": "Delivery Status"
    },
    "column": {
      "recipient": "Recipient",
      "delivered": "Delivered",
      "collected": "Collected",
      "signature": "Signature"
    },
    "form": {
      "drawerHeading": "Add Delivery",
      "dateDelivered": "Date Delivered",
      "recipient": "Recipient",
      "recipientPlaceholder": "Search or select an employee",
      "notificationNote": "Add note to notification email",
      "signedInAt": "Signed in at {datetime}",
      "signedOutAt": "Signed out at {datetime}",
      "deliveredDate": "Date delivered",
      "signatureRequired": "Signature required",
      "signatureNotRequired": "Signature not required",
      "notificationSent": "Notification sent",
      "resend": "Resend",
      "sent": "Sent",
      "collected": "Collected ({datetime})",
      "changeRecipient": "Change Recipient",
      "newRecipient": "New recipient",
      "notifyRecipient": "Notify Recipient",
      "deleteDelivery": "Delete Delivery",
      "markAsCollected": "Mark As Collected",
      "subtitle": "Assign a recipient to this delivery"
    },
    "required": "Required",
    "generalDelivery": "General Delivery",
    "emptyState": {
      "title": "There have been no deliveries",
      "description": {
        "firstLine": "Keep track of delivered items effortlessly and instantly send reminders for employees to collect items",
        "secondLine": "Deliveries will be logged when scanned using the {device} or can be added manually"
      },
      "device": "iPad/tablet",
      "titleDisabled": "The Deliveries AddOn is not enabled at this location",
      "noMatchingResults": "No matching results"
    },
    "tooltipDisable": {
      "disabled": "Deliveries not enabled.",
      "locations": "Go to Locations to manage",
      "addOns": "AddOns"
    }
  },
  "evacuation": {
    "title": "Evacuation",
    "timelines": {
      "columns": {
        "safe": "Safe",
        "name": "Name",
        "contactDetail": "Contact Details",
        "status": "Status",
        "type": "Type"
      },
      "filters": {
        "type": "Type",
        "status": "Status",
        "category": "Category",
        "date": "Date",
        "dateArchived": "Date Archived",
        "in": "Signed In",
        "remote": "Remote",
        "out": "Signed Out",
        "pending": "Pending",
        "denied": "Denied",
        "rejected": "Denied",
        "all": "All",
        "today": "Today",
        "last7days": "Last 7 days",
        "next7days": "Next 7 days",
        "last30days": "Last 30 days",
        "next30days": "Next 30 days",
        "over30days": "Over 30 days",
        "custom": "Custom",
        "employees": "Employees",
        "visitors": "Visitors"
      },
      "emptyState": {
        "title": "Know who is where in case of an emergency",
        "description": {
          "firstLine": "Quickly and easily mark visitors and employees as safe ",
          "secondLine": "Export and print logs for auditing purposes"
        },
        "noMatchingResults": "No matching results"
      }
    },
    "hostPhone": "Host: {phone}",
    "visiting": "Visiting {name}",
    "type": {
      "employee": "Employee",
      "visitor": "Visitor"
    },
    "yesterday": "Yesterday",
    "today": "Today",
    "pendingType": "Pending {type}",
    "actions": {
      "searchPlaceholder": "Search",
      "filters": "Filters",
      "actions": "Actions",
      "refresh": "Refresh List",
      "lastRefreshed": "Refreshed {time}",
      "export": "Export all {count}",
      "print": "Print all {count}"
    },
    "in": "In"
  },
  "spaces": {
    "tabs": {
      "bookings": "Bookings",
      "resources": "Resource Availability"
    },
    "title": "Bookings",
    "actions": {
      "newBooking": "New Booking",
      "showAvailableResources": "0 Available | Show {count} Available | Show {count} Available",
      "searchPlaceholder": "Search by employee or resource",
      "timePlaceholder": "All Day",
      "filters": "Filters",
      "actions": "Actions",
      "export": "Export",
      "cancel": "Cancel",
      "counterAction": "{label} | {label} (1) | {label} ({count})"
    },
    "bookings": {
      "newBooking": "New Booking",
      "newRepeatBooking": "New repeat booking",
      "setupYourSpaces": "Set Up Your Spaces",
      "noBookingsHeading": "There are no bookings",
      "noBookingsSubHeading": "Keep track of who’s got what, for when",
      "noBookingsNoSpacesHeading": "There are no bookings yet",
      "noBookingsNoSpacesSubHeading": "Manage your workplace and its resources by allowing staff to book what they need",
      "noMatchingResults": "No matching results",
      "info": "{location}, {dateTimeRange}",
      "anyLocation": "Any Location",
      "who": "Who is this booking for?",
      "what": "What are you booking?",
      "title": "Meeting title",
      "location": "Location",
      "locationPlaceholder": "Select a Location",
      "space": "Space",
      "spacePlaceholder": "Select a Space",
      "startDate": "Start date",
      "endDate": "End date",
      "startTime": "Start time",
      "endTime": "End time",
      "any": "Any",
      "select": {
        "desk": "Select a desk",
        "room": "Select a meeting room",
        "equipment": "Select equipment",
        "vehicle": "Select a vehicle",
        "carpark": "Select a car park",
        "custom": "Select a {name}"
      },
      "restrictionsNotMet": "Please note, the selected employee does not meet the restrictions in place for this resource",
      "notYetEditable": "This booking is not yet editable, as it exceeds how far in advance this category can be booked",
      "pending": "This booking is pending. Check back later",
      "updateRepeat": "Update repeat",
      "allOccurrences": "All occurrences",
      "thisOccurrence": "This occurrence only",
      "modifySearch": "Modify search",
      "back": "Back",
      "bookNow": "Book Now",
      "repeatBooking": "Repeat Booking",
      "repeatBookingForm": {
        "frequency": "Repeat frequency",
        "daily": "Daily",
        "weekdays": "Weekdays",
        "weekly": "Weekly",
        "fortnightly": "Fortnightly",
        "monthly": "Monthly",
        "doesNotRepeat": "Does not repeat",
        "end": "End repeat",
        "after": "After",
        "on": "On",
        "never": "Never",
        "occurrences": "Occurrences",
        "pickDate": "Pick a date"
      },
      "conflictsAlert": {
        "title": "{count} of your {total} bookings have conflicts",
        "description1": "The following dates are not available and will not be included in your booking",
        "description2": "The following dates are not available and will not be included in your booking. This is up to the first two years of your booking only",
        "bookingConflicts": "Booking conflicts",
        "noDates": "No available dates"
      },
      "required": "This field is required",
      "requiredSelectResource": "A resource must be selected",
      "durationUnavailabilityError": "Not available for bookings longer than 24 hours",
      "durationError": "Repeat bookings cannot exceed 24 hours",
      "spaceAndLocation": "{space}, {location}",
      "checkedIn": "Checked in",
      "cancelBooking": "Cancel Booking",
      "saveChanges": "Save Changes",
      "deleteBooking": {
        "header": "You are about to cancel a booking",
        "organiserNotified": "The booking organizer will be notified",
        "areYouSure": "This action can not be undone, are you sure you want to continue?",
        "cancel": "Cancel",
        "cancelThisOnly": "Cancel this booking only",
        "delete": "Cancel Booking"
      },
      "deleteRepeatBooking": {
        "header": "You are about to cancel a repeat booking",
        "message": "By taking this action you will be cancelling {0} as part of this repeat series. | By taking this action you will be cancelling {0} as part of this repeat series.",
        "booking": "{count} booking | {count} bookings",
        "organiserNotified": "The booking organizer will be notified.",
        "cancel": "Cancel {count} Booking | Cancel {count} Bookings"
      },
      "features": "Features",
      "unavailableError": {
        "title": "Resource Unavailable",
        "message": "This resource is already booked on this date/time"
      },
      "endTimeError": "Invalid End Time",
      "checkIn": {
        "title": "Check in",
        "checkedIn": "Checked in",
        "ended": "Ended"
      },
      "checkInButton": "Check In",
      "endBookingButton": "End Booking",
      "notBegun": "Booking hasn’t begun yet",
      "cannotAddRoomBooking": "Cannot make meeting room booking",
      "cannotUpdateRoomBooking": "Cannot update meeting room booking",
      "notValid365User": "The selected employee is not a valid Microsoft 365 user",
      "notValidLoggedIn365User": "You are not a valid Microsoft 365 user"
    },
    "categories": {
      "desk": "Desk",
      "room": "Meeting Room",
      "equipment": "Equipment",
      "vehicle": "Vehicle",
      "carpark": "Car Park"
    },
    "table": {
      "employee": "Employee",
      "plusOthers": "+ { count } other | + { count } others",
      "category": "Category",
      "resource": "Resource",
      "start": "Start",
      "end": "End",
      "location": "Location",
      "space": "Space",
      "checkedIn": "Checked-in",
      "repeat": "Repeat"
    },
    "features": {
      "delete": "Delete feature",
      "deleteFeature": {
        "header": "Delete this feature",
        "info": "Deleting this feature deletes it from all resources at all locations and can not be undone",
        "cancel": "Cancel",
        "delete": "Delete"
      }
    },
    "spacesSettings": {
      "searchPlaceholder": "Search by space"
    },
    "resourcesSettings": {
      "searchPlaceholder": "Search by resource"
    },
    "resources": {
      "title": "Resources",
      "table": {
        "name": "Resource",
        "available": "Available",
        "bookedAllDay": "All day",
        "booked": "Booked",
        "plusOthers": "+ 1 other booking |+ {count} other bookings",
        "noResources": "There are no resources yet",
        "noResourcesSubHeading": "Make it easy for employees to book your workplace resources",
        "noMatchingResults": "No matching results",
        "setupButton": "Setup Your Spaces",
        "searchPlaceholder": "Search by resource",
        "dateRangeFormat": "{from} - {to}"
      },
      "weekCalendar": {
        "timeRange": "{from}-{to}"
      },
      "activityByDay": {
        "allDay": "All day",
        "bookingLink": "View"
      },
      "resourceBooking": {
        "title": "Book this resource",
        "zone": "Zones",
        "restrictions": "Restrictions",
        "restrictedTo": "Restricted to {restrictedTo}",
        "features": "Features",
        "notes": "Notes",
        "editResource": "Edit this resource",
        "availabilityTab": "Availability",
        "detailsTab": "Details"
      }
    }
  },
  "deliveriesLog": {
    "errorRetrievingDeliveries": "Error loading deliveries"
  },
  "timelines": {
    "contactTraceFileName": "SwipedOn Contact Tracing {name} {datetime}",
    "visitorAgreementFileName": "Visitor Agreement {name} {datetime}",
    "movementsCsvFileName": "SwipedOn Employee Movements {datetime}",
    "signedInAt": "Signed in at {dateTime}",
    "signedOutAt": "Signed out at {dateTime}",
    "pendingAt": "Pending at {dateTime}",
    "deniedByAt": "Denied by {name} at {dateTime}",
    "deniedAt": "Denied at {dateTime}",
    "approvedByAt": "Approved by {name} at {dateTime}",
    "approvedAt": "Approved at {dateTime}",
    "employeesCsvFileName": "SwipedOn Employees {datetime}",
    "visitorsCsvFileName": "SwipedOn Visitors {datetime}",
    "visitsCsvFileName": "SwipedOn Visits {datetime}",
    "statuses": {
      "in": "In",
      "out": "Out",
      "remote": "Remote",
      "pending": "Pending",
      "denied": "Denied",
      "approve": "Approve",
      "reject": "Reject"
    },
    "actions": "Actions",
    "counterAction": "{label} | {label} (1) | {label} ({count})",
    "evacuationCsvFileName": "SwipedOn Evacuation {datetime}"
  },
  "accountSettings": {
    "details": {
      "addPlan": "Add {planName}",
      "changePlan": "Change Plan",
      "planDetails": {
        "title": "Plan details",
        "planType": "Plan type",
        "locations": "Locations",
        "resources": "Resources",
        "addons": "AddOns",
        "locationCount": "0 Locations | 1 Location | {n} Locations",
        "resourceCount": "0 Resources | 1 Resource | {n} Resources",
        "addonCount": "0 AddOns | 1 AddOn | {n} AddOns",
        "monthlyPayment": "Monthly payment",
        "annualPayment": "Annual payment",
        "manual": "Manual",
        "billingMethod": "Billing method",
        "paymentDue": "Payment due",
        "joined": "Joined",
        "creditCard": "Credit Card",
        "none": "None",
        "buttons": {
          "cancelPlan": "Cancel Subscription"
        },
        "regions": {
          "list": {
            "australia": "Australia",
            "canada": "Canada",
            "england": "United Kingdom",
            "germany": "Germany",
            "singapore": "Singapore",
            "steris": "Steris",
            "us": "US",
            "usa": "USA"
          }
        },
        "hostingRegion": "Data hosting region",
        "learnMore": "Learn more",
        "lookAfterData": "{link} about how we look after your data"
      },
      "accountDetails": {
        "title": "Account details",
        "accountName": "Account name",
        "invoiceTo": "Invoice billing name",
        "email": "Email",
        "phoneNumber": "Phone Number",
        "invoiceAddress": "Invoice address",
        "savedCard": "Saved card",
        "cardHolderName": "Card holder name",
        "cardDetails": "Card Details",
        "phone": "Phone number",
        "buttons": {
          "editDetails": "Edit Details"
        },
        "country": "Country",
        "streetAddress": "Street address",
        "state": "State/Region",
        "city": "City",
        "zip": "Zip/Postal code",
        "invalidAddress": "Invalid address"
      },
      "paymentHistory": {
        "title": "Payment history",
        "date": "Date",
        "description": "Description",
        "transactionStatus": "Transaction Status",
        "total": "Total",
        "dateTime": "Date/time:",
        "response": "Response:",
        "responseCode": "Response Code:",
        "cardNumber": "Card Number:",
        "type": "Type:",
        "id": "ID:",
        "downloadReceipt": "Download Receipt"
      }
    },
    "additionalPurchase": {
      "title": "Would you like to include any Resources and AddOns?",
      "resourcesQtyLabel": "How many resources?",
      "roomsQtyLabel": "How many rooms?",
      "addonQtyLabel": "For how many locations?",
      "overResourceBudget": "You have {n} {resource} set up",
      "resource": "Resource | Resources",
      "room": "Meeting room | Meeting rooms",
      "availableEverywhere": "Available at all locations",
      "perResourcePerMonth": "{price} per {resource} / month",
      "perLocationPerMonth": "{price} per location / month",
      "nAlreadyPurchased": "{n} purchased",
      "nAddonAlreadyPurchased": "{n} already purchased",
      "includedInPlan": "Included in plan",
      "nIncludedInPlan": "{n} included",
      "inParentheses": "({message})",
      "includedAndPurchased": "{included}, {purchased}"
    }
  },
  "employeeSettings": {
    "general": {
      "title": "General settings",
      "tabletTitle": "iPad and tablet settings",
      "workingRemote": "Employees can sign in as working remotely",
      "workingRemoteDesc": "This can also be set individually in employee profiles {link}",
      "autoSignOut": "Sign out all employees at a specific time",
      "updateProfilePhoto": "Allow employees to update their profile photo on iPad, tablet and SwipedOn Pocket",
      "updateProfilePhotoDesc": "If disabled, photos can only be updated from the web dashboard {link}",
      "verificationPhoto": "Take a verification photo during sign in & sign out",
      "employeeSectionPrivate": "Make the employee section private",
      "employeeSectionPrivateDesc": "For added privacy, access this section by triple tapping the employee button {link}",
      "displayTimestamp": "Display sign in & out timestamp",
      "learnMoreLinkText": "Learn More",
      "upgrade": "Upgrade now"
    },
    "profileFields": {
      "title": "Employee profile fields",
      "description": "Capture information against an employee’s profile such as department, floor or role. Employee fields are the same across all of your locations",
      "addField": "Add Field",
      "fieldName": "Field name",
      "addFieldCancel": "Cancel",
      "deleteField": "Delete",
      "deleteFieldConfirm": {
        "header": "You are about to delete {field}",
        "body": [
          "Deleting this field will also delete any associated content in the employee profiles",
          "This action can not be undone, are you sure you want to continue?"
        ],
        "cancel": "Cancel",
        "confirm": "Delete field"
      },
      "banner": {
        "header": "Add departments to your employee profiles",
        "learnMore": "Learn more",
        "body": {
          "bullet1": "Add and manage your company wide departments by syncing with your current directory tools or add manually",
          "bullet2": "Limit resources or zones to specific departments {learnMore}",
          "bullet3": "Filter by department to easily see who’s on and off site"
        }
      },
      "defaultFields": {
        "name": "First and last name",
        "email": "Email",
        "phone": "Phone number",
        "assistant-details": "Assistant phone and email",
        "department": "Department"
      },
      "options": "OPTIONS",
      "addDepartmentButton": "Add department",
      "deleteDepartmentConfirm": {
        "header": "You are about to delete {department}",
        "body": [
          "Deleting this department will remove it from any assigned employee profiles",
          "This action can not be undone, are you sure you want to continue?"
        ],
        "cancel": "Cancel",
        "confirm": "Delete Department"
      },
      "hostSelection": {
        "title": "Include a profile field in host selection",
        "description": "Help visitors search during sign in, by choosing to include a profile field on the host selection screen {learnMore}",
        "learnMore": "Learn more"
      },
      "syncedTooltip": "Synced by AD",
      "adLogoAltText": "Azure active directory logo",
      "selectOptionDepartment": "Department",
      "selectOptionNone": "None"
    },
    "signInQuestions": {
      "title": "Sign in questions",
      "description": "Set questions for employees to answer when they sign in",
      "questionType": {
        "text": "Text",
        "yesNo": "Yes / No"
      },
      "questionLabel": "Question",
      "required": "Required",
      "requireApproval": "Require approval if:",
      "yes": "Yes",
      "no": "No",
      "add": "Add Question",
      "cancel": "Cancel",
      "delete": "Delete"
    },
    "signOutMessages": {
      "title": "Sign out messages",
      "description": "Heading offsite or picking up the kids? Employees can leave a custom status or select from a default list set here",
      "requireMessage": "Require employees to leave a sign out message",
      "addMessage": "Add Message",
      "addMessageCancel": "Cancel",
      "removeMessage": "Remove",
      "message": "Message"
    },
    "pocket": {
      "title": "SwipedOn Pocket settings",
      "description": "Set up how you want your employees to sign in and out using SwipedOn Pocket",
      "signedInOut": "Allow employees to sign in and out using SwipedOn Pocket",
      "stepsApplyToPocketInfo": "The following settings only apply to those that have installed SwipedOn Pocket via the Apple or Google app stores",
      "signedInOutReminder": "Send sign-in & sign-out reminders",
      "signedInOutReminderDesc": "Employees will receive reminders when they arrive or leave the location",
      "requireLocationVerification": "Require location verification",
      "requireLocationVerificationDesc": "Ensures employees sign in within proximity of the location",
      "aboutTitle": "More about SwipedOn Pocket",
      "aboutDescription": "SwipedOn Pocket has been designed exclusively for employees and makes signing in and out easier than ever. It can be installed as an app on employees’ phones or accessed on the web. SwipedOn Pocket records the time of activity as well as answers to any questions you wish to ask your team when they sign in.",
      "platformDetails": [
        "SwipedOn Pocket is available from both the Apple and Google app stores. Installing the app allows for location based features such as reminders and auto sign in and out.",
        "Alternatively SwipedOn Pocket can be accessed via a browser. Employees simply visit {link} and register using their SwipedOn email address."
      ],
      "platformDetailsLinkText": "my.swipedon.com",
      "installInstruction": "Send employees instructions on how to install and use SwipedOn Pocket {link}",
      "installInstructionLinkText": "Download PDF",
      "learnMore": "Employees can sign in as working remotely or from a different office, receive notifications, change their profile picture and more {link}",
      "learnMoreLinkText": "Learn More",
      "whatShouldEmployeeEnterLeaveWorkspace": "What should the mobile Pocket app do when employees enter and leave the workplace?",
      "whenRequireVerifyAtWorkspace": "When do you require an employee to verify they are at your workplace?",
      "listLeaveAction": {
        "sendSignInOutReminder": "Send a sign in and sign out reminder",
        "autoSignInOut": "Automatically sign employees in and out",
        "doNothing": "Do nothing"
      },
      "listVerificationAction": {
        "whenSignIn": "When signing in",
        "whenSignOut": "When signing out",
        "whenSignInOut": "When both signing in and signing out",
        "verifyNotRequired": "Verification not required"
      },
      "preregistrations": "Allow employees to preregister visitors in SwipedOn Pocket",
      "proximitySettings": "Proximity Settings",
      "proximitySettingsSubText": "Set a virtual perimeter around your workplace for location based Pocket settings",
      "upgradeCtaTitle": "Customize your proximity settings",
      "upgradeCtaAlt": "Lightbulb Icon",
      "upgradeCtaBody": "Send reminders, automatically sign employees in and out, or require verification. {link}",
      "upgradeCtaLink": "Upgrade now",
      "warningAlert": {
        "headingSingle": "Please enter an accurate address for this location",
        "headingGroup": "A location in this group does not have an accurate address",
        "updateAddress": "Update address",
        "info": "The precision of geo-fencing relies on an accurate location address {location}"
      }
    },
    "tooltips": {
      "enableIpadEmployeeInOut": "Employee in/out must be enabled from the Devices page",
      "enablePocketOrIpadEmployeeInOut": "Employee in/out must be enabled on Pocket or iPad and tablet"
    }
  },
  "spacesSettings": {
    "banner": {
      "header": "Set up Spaces to manage almost anything in your workplace",
      "bullet1": "Desks, car parks, lockers, laptops or vehicles can all be booked in an instant",
      "bullet2": "Simply create a space and add your resources",
      "bullet3": "Employees can book what the need for when they need it, all from their phone",
      "bullet4": "{count} resource included in your subscription, {pricePerResource} thereafter | {count} resources included in your subscription, {pricePerResource} thereafter",
      "pricePerResource": "{price} per resource / month",
      "learnMore": "Learn More",
      "learnMoreLink": "https://www.swipedon.com/space-management-software"
    },
    "spaces": {
      "emptyState": {
        "heading": "There are no spaces at this location",
        "messageLine2": "Once a space is created you can start adding resources",
        "action": "Add Space"
      },
      "noMatchingSpacesFound": {
        "heading": "No matching spaces found",
        "messageLine1": "Try modifying your search text"
      },
      "tableHeadings": {
        "name": "Name of Space",
        "location": "Location",
        "totalResources": "Total Resources"
      },
      "title": "A space defines an open plan area such as a level, site or building",
      "addSpace": "Add Space",
      "selectLocationPlaceholder": "Select location",
      "addResources": "Add Resources",
      "manageResources": "Manage Resources",
      "addNewSpace": "Add new space",
      "viewMap": "View Map",
      "addMap": "Add Map",
      "resources": "{count} resources {link} | 1 resource {link} | {count} resources {link}",
      "resourcesManage": "Manage",
      "resourcesAdd": "Add",
      "addSpaceForm": {
        "locationLabel": "Location",
        "nameLabel": "Name of Space",
        "nameInfo": "This should be something your employees will recognize when making a booking",
        "add": "Add",
        "adding": "Adding…",
        "validation": {
          "required": "Required field",
          "name": {
            "unique": "A space with this name already exists"
          }
        }
      },
      "editSpaceForm": {
        "location": "Location: {location}",
        "status": "Status: {status}",
        "statusActive": "Active",
        "statusInactive": "Inactive",
        "nameLabel": "Name of Space",
        "resources": "In this Space",
        "zones": "Zones: {count}",
        "desks": "Desks: {countTotal}",
        "equipment": "Equipment: {countTotal}",
        "rooms": "Rooms: {countTotal}",
        "vehicles": "Vehicles: {countTotal}",
        "carParks": "Car Parks: {countTotal}",
        "countTotal": "{total} Total",
        "delete": "Delete Space",
        "downloadAllQRCodes": "Download All QR Codes For {name}"
      },
      "deleteSpace": {
        "header": "You are about to delete {space}",
        "actionConsequence": "By taking this action you will be:",
        "consequences": {
          "spaces": "Deleting {count} resource | Deleting {count} resources",
          "bookings": "Cancelling {count} booking | Cancelling {count} bookings",
          "futureBookings": "Preventing employees from making a booking in this space in the future"
        },
        "organiserNotified": "Booking organizers will be notified, unless the booking is currently in progress",
        "areYouSure": "This action can not be undone, are you sure you want to continue?",
        "cancel": "Cancel",
        "delete": "Delete this space"
      },
      "upgradePlanTooltip": "You need to Upgrade your plan from the Account page"
    },
    "resources": {
      "addResource": "Add Resource",
      "addSpaceResourceDialog": {
        "title": "Add new resources?",
        "description": "You have selected to add {resources}. We’ll charge your account for the remaining period on your subscription",
        "oneResource": "1 new resource",
        "cancel": "Cancel",
        "confirmAndAdd": "Confirm & Add Another",
        "confirm": "Confirm"
      },
      "addResourceTooltip": "You must first add a space",
      "addResourceDropdownMenu": "Import CSV",
      "list": "List",
      "map": "Map",
      "mapView": {
        "upload": "Drag & drop to upload map or floorplan",
        "uploadToReplace": "Drag & drop to replace map",
        "orChooseFile": "or choose file",
        "maxSize": "(max 2mb)",
        "errorMaxSize": "Your file size exceeds {size}MB",
        "clearAllPins": "Clear all pins",
        "clearAllPinsDialogMessage": [
          "By taking this action employees will no longer be able to see where these resources are placed",
          "This action can not be undone. Are you sure you want to continue?"
        ],
        "clearAllPinsConfirmationTitle": "You are about to clear all pins from {spaceName}",
        "clearAllPinsApprove": "Clear pins",
        "clearAllPinsCancel": "Cancel",
        "replaceMap": "Replace map",
        "dragTooltip": "Click and hold to drag",
        "clickAnywhere": "Click anywhere on the map to add a new resource",
        "selectResource": "Drag and drop to place on map",
        "selectRoom": "Select a meeting room to draw on the map",
        "placed": "PLACED",
        "noResources": "There are no resources to place",
        "resourcesTooltip": "Resources",
        "roomsTooltip": "Meeting Rooms",
        "contextMenu": {
          "editShape": "Edit Shape",
          "editDetails": "Edit Details",
          "cancel": "Cancel",
          "done": "Done"
        },
        "removeZone": {
          "removeThisZone": "Delete",
          "header": "You are about to remove {zone}",
          "areYouSure": "This action can not be undone, are you sure you want to continue?",
          "cancel": "Cancel",
          "delete": "Remove this zone"
        }
      },
      "newZoneForm": {
        "newZone": "New zone",
        "nameOfZone": "Name of zone",
        "nameHint": "Hint: Ensure this is something your employees will understand when making a booking",
        "colorOnMap": "Color on map",
        "availableTo": "Available to",
        "restrictedStatement": "Everyone",
        "unRestrictedStatement": "Selected employees or departments",
        "add": "Add",
        "fieldCharacterRange": "Zone name must be between 1 and 128 characters",
        "inThisZone": "In this Zone",
        "desks": "Desks: {countTotal}",
        "equipment": "Equipment: {countTotal}",
        "vehicles": "Vehicles: {countTotal}",
        "carParks": "Car Parks: {countTotal}",
        "rooms": "Rooms: {countTotal}",
        "custom": "{category}: {countTotal}",
        "countTotal": "{total} Total"
      },
      "addZone": "Add Zone",
      "zones": "Zones",
      "title": "Manage your bookable resources such as desks, equipment, vehicles or carparks",
      "emptyState": {
        "heading": "There are no resources",
        "messageLine1": "Add bookable desks, rooms, vehicles, car parks or equipment",
        "noSpaceDesciption": "To start adding resources you must first {addSpace}",
        "addSpace": "add a space"
      },
      "noMatchingResults": "No matching results",
      "tableHeadings": {
        "name": "Name",
        "category": "Category",
        "capacity": "Capacity",
        "location": "Location",
        "space": "Space"
      },
      "filters": {
        "space": "Space",
        "category": "Category",
        "features": "Features",
        "timeRange": "Time range",
        "startTimePlaceholder": "Start time",
        "endTimePlaceholder": "End time",
        "zones": "Zones",
        "capacity": "Minimum capacity"
      },
      "actions": "Actions",
      "delete": "Delete",
      "counterAction": "{label} | {label} (1) | {label} ({count})",
      "downloadQRCode": "Download QR code",
      "downloadQRModal": {
        "title": "Select your preferred format",
        "content": "QR code format",
        "placeholder": "Please select",
        "download": "Download",
        "usLetter": "US Letter",
        "avery94106": "1.5\" x 1.5\", 20 per sheet (Avery 94106)",
        "avery94107": "2\" x 2\", 12 per sheet (Avery 94107)",
        "avery94237": "2\" x 3\", 8 per sheet (Avery 94237)",
        "avery94207": " 2\" x 4\", 10 per sheet (Avery 94207)",
        "a4": "A4",
        "averyL7120": "35mm x 35mm, 35 per sheet (Avery L7120)",
        "averyL7160": "63mm x 38mm, 21 per sheet (Avery L7160)",
        "averyL7159": "63mm x 33mm, 24 per sheet (Avery L7159)"
      },
      "spaceResourceForm": {
        "downloadQRCode": "Download QR code",
        "drawTitle": "Add new resource",
        "categoryLabel": "Resource Category",
        "availableToLabel": "Available to",
        "selectCategoryPlaceholder": "Select category",
        "nameLabel": "{resourceCategory} Name",
        "levelLabel": "Space",
        "selectSpacePlaceholder": "Select space",
        "capacityLabel": "Max capacity",
        "add": "Add",
        "adding": "Adding…",
        "required": "Required field",
        "delete": "Delete resource",
        "removeFromMap": "Remove from map",
        "remove": "Remove",
        "notes": "Notes will be visible to all employees when booking a resource",
        "unRestrictedStatement": "Everyone",
        "restrictedStatement": "Selected employees or departments",
        "searchPlaceholder": "Search",
        "searchEmployeeLabel": "Employees",
        "searchEmployeePlaceholder": "Search employees",
        "searchFeaturesPlaceholder": "Type to select or create feature",
        "createNew": "Create new {name}",
        "searchDepartmentLabel": "Departments",
        "searchDepartmentPlaceholder": "Search departments",
        "restrictedTooltipMessage": "Maximum of {amount} restrictions allowed",
        "restrictedSearchNoneFoundMessage": "No results found",
        "member": "{text} ({count} member) | {text} ({count} members)",
        "imageSizeExceeded": "Please upload an image smaller than 10MB",
        "name": {
          "unique": "A resource with this name already exists"
        },
        "features": "Features",
        "photo": "Photo",
        "notesLabel": "Notes",
        "purchaseFirst": "Please {contactUs} to add resources",
        "contactUs": "get in touch"
      },
      "deleteSpaceResource": {
        "headerSingle": "You are about to delete {spaceResource}",
        "headerPlural": "You are about to delete {count} resources",
        "actionConsequence": "By taking this action you will be:",
        "consequences": {
          "bookings": "Cancelling 1 booking | Cancelling {count} bookings",
          "futureBookings": "Preventing employees from being able to book this resource in the future | Preventing employees from being able to book these resources in the future"
        },
        "areYouSure": "This action can not be undone, are you sure you want to continue?",
        "cancel": "Cancel",
        "delete": "Delete this resource | Delete these resources"
      },
      "editRoomForm": {
        "roomName": "Meeting Room name",
        "notes": "Notes will be visible to all employees when booking a resource",
        "photo": "Photo",
        "notesLabel": "Notes",
        "remove": "Remove",
        "required": "Required field",
        "imageSizeExceeded": "Please upload an image smaller than 10MB",
        "requireApproval": "Require approval to book this meeting room",
        "conjuctionBookingAvailable": "Available to be booked in conjunction with another meeting room (co-joined)",
        "bookIndiviudal": "Can still be booked individually",
        "bookingSettings": "Booking settings",
        "bookingTabDetails": "Details",
        "bookingTabDisplayPanel": "Display Panel",
        "panelName": "Panel name",
        "deviceId": "Device Id",
        "deviceStatus": "Status",
        "addPanel": "Add Panel",
        "displayPanelSettings": "Display panel settings",
        "panelBackgroundImage": "Background image",
        "panelShowOrganiser": "Show organiser",
        "panelShowMeetingName": "Show meeting name",
        "panelAllowPeopleOptions": "Allow people to book, end, check-in and extend on screen",
        "panelNotSetup": "Not set up",
        "category": "Category",
        "location": "Location",
        "space": "Space",
        "capacity": "Capacity"
      },
      "deleteRoom": {
        "headerSingle": "You are about to remove the sync for {room}",
        "headerPlural": "You are about to remove the sync for {count} rooms",
        "areYouSure": "This action can not be undone. Are you sure you want to continue?",
        "cancel": "Cancel",
        "removeSync": "Remove Sync",
        "action": "By taking this action, employees will no longer be able to book or manage existing bookings for this room via SwipedOn"
      }
    },
    "categories": {
      "tableHeadings": {
        "category": "Category",
        "spaceResourcesCount": "Resources"
      },
      "heading": "Categories",
      "title": "Configure the controls and features for your resource categories",
      "resourcesCount": "{count} Resource | {count} Resources",
      "emptyState": {
        "heading": "You don’t have any categories yet",
        "messageLine1": "A category is a type of resource, for example desk, vehicle, car park or equipment",
        "messageLine2": "To start configuring categories you must first {0}",
        "addASpace": "add a space"
      },
      "addCategory": "Add Category",
      "addCategoryTooltip": "You must first add a space",
      "editCategoryForm": {
        "features": "Features",
        "featuresDesc": "Create features and assign to resources to help people find what they’re after",
        "addFeature": "Add feature",
        "bookingSettings": "Booking settings",
        "recurringBookings": "Allow repeat bookings",
        "recurringBookingsAlert": "Changes made will only apply to new bookings",
        "advanceBookingDays": "How many days in advance can people book?",
        "delete": "Delete",
        "deleteCategory": "Delete category",
        "validation": {
          "required": "Required field",
          "name": {
            "unique": "A feature with this name already exists"
          }
        }
      },
      "editRoomCategoryForm": {
        "features": "Features",
        "featuresDesc": "Create features and assign to resources to help people find what they’re after",
        "addFeature": "Add feature",
        "bookingSettings": "Booking settings",
        "recurringBookings": "Allow repeat bookings",
        "recurringBookingsAlert": "Changes made will only apply to new bookings",
        "advanceBookingDays": "How many days in advance can people book?",
        "advanceBookingDaysAlert": "This change will only apply to new bookings",
        "delete": "Delete",
        "deleteCategory": "Delete category",
        "validation": {
          "required": "Required field",
          "name": {
            "unique": "A feature with this name already exists"
          }
        },
        "freeUpRoom": "Free up rooms if not checked into after a period of time",
        "autoReleasTimeType": "Minutes",
        "displayPanelSettings": "Display panel settings",
        "conserveBattery": "Conserve battery between set hours",
        "displayLanguage": "Display language",
        "timeFormat": "Time format",
        "timeFormatHourDisplay": "{timeFormat} hour"
      },
      "addCategoryForm": {
        "name": "Category name",
        "icon": "Icon",
        "add": "Add new category",
        "validation": {
          "required": "Required field",
          "name": {
            "invalidRange": "Name must be between 1 and 128 characters",
            "unique": "A category with this name already exists"
          }
        }
      },
      "deleteCategory": {
        "header": "You are about to delete {category}",
        "actionConsequence": "By taking this action you will be:",
        "consequences": {
          "resources": "Deleting all resources in this category",
          "bookings": "Cancelling all bookings in this category"
        },
        "bookingAlert": "Booking organizers will be notified, unless the booking is currently in progress",
        "areYouSure": "This action can not be undone. Are you sure you want to continue?",
        "cancel": "Cancel",
        "delete": "Delete"
      },
      "deleteFeature": {
        "header": "You are about to delete {feature}",
        "actionConsequence": "By taking this action you will remove it from any resource that has this feature",
        "areYouSure": "This action can not be undone. Are you sure you want to continue?",
        "cancel": "Cancel",
        "delete": "Delete"
      }
    },
    "rooms": {
      "title": "Make it easy to view real time availability and find and book the right meeting room",
      "subTitle": "Assign and sync meeting rooms",
      "tableHeadings": {
        "name": "Name",
        "email": "Email",
        "physicalAddress": "Address",
        "location": "Location",
        "level": "Space",
        "synced": "Synced"
      },
      "emptyState": {
        "heading": "There are no meeting rooms to import",
        "messageLine1": "",
        "noMatchingResults": "No matching results"
      },
      "searchPlaceholder": "Search by meeting room",
      "importButton": "Import",
      "removeSync": "Remove",
      "syncBtn": "Sync Meeting Room | Sync Meeting Rooms",
      "importRooms": "Import Meeting Rooms",
      "selectLevel": "Select space",
      "selectLocationFirst": "Location required",
      "selectLocation": "Select location",
      "addRoomDialog": {
        "title": "Add Meeting Room | Add Meeting Rooms",
        "description": "You have selected to sync {resources}.",
        "addAllToSubscription": "We’ll charge your account for the remaining period on your subscription",
        "addRoomsToSubscription": "This will add {nRooms} to your subscription and will be included in the next invoice.",
        "nRooms": "{n} Meeting Room | {n} Meeting Rooms",
        "cancel": "Cancel",
        "confirm": "Confirm"
      },
      "validation": {
        "required": "Required field"
      }
    }
  },
  "securitySettings": {
    "title": "Security",
    "subtitle": "Manage your SwipedOn security settings",
    "learnMore": "Learn more",
    "upgradeNow": "Upgrade now",
    "mfa": {
      "require": {
        "label": "Require MFA for all Company Admins and Location Managers",
        "description": "If enabled, Company Admins and Location Managers will be required to set up MFA"
      },
      "warning": {
        "title": "You do not have MFA set up",
        "description": "Please {activateMFA} to enable the mandatory MFA setting",
        "activateMFA": "activate MFA"
      }
    },
    "sso": {
      "require": {
        "label": "Allow employees to sign-in using Google or Microsoft 365",
        "description": "If enabled, Company Admins and Location Managers can use secure sign on services. MFA is not required when signing in with these services"
      },
      "microsoft": "Microsoft",
      "google": "Google",
      "login": {
        "google": "Sign in with Google",
        "microsoft": "Sign in with Microsoft 365"
      }
    },
    "information": {
      "title": "More about SwipedOn Security",
      "description": "If you'd like to know more about security related matters, please {getInTouch} with our team or visit our {faq} page.",
      "getInTouch": "get in touch",
      "faq": "FAQ"
    }
  },
  "devicesSettings": {
    "addDevice": "Add Device",
    "subtitle": "Manage your iPads, Android tablets, or if using the employee in and out board feature, a smart TV",
    "appVersionTooltipWarning": "Update available",
    "appVersionTooltipDanger": "Unsupported version",
    "ioBoard": "IO Board",
    "tableHeadings": {
      "name": "Device Name",
      "deviceId": "Device ID",
      "location": "Location",
      "appVersion": "App Version",
      "lastActivity": "Last Activity"
    },
    "emptyState": {
      "heading": "There are no devices at this location",
      "messageLine1": "Add and connect a device to the SwipedOn App",
      "messageLine2": "Click the ‘{0}’ button above to get started"
    },
    "editDeviceForm": {
      "name": "Device name",
      "deviceId": "Device ID",
      "info": "Device Information",
      "notSetUp": "Device not set up",
      "installApp": "{learnMore} about installing the SwipedOn app",
      "appVersion": "App version",
      "appName": "SwipedOn",
      "osVersion": "OS version",
      "device": "Device",
      "lastActivity": "Last activity",
      "options": "Select which options you’d like enabled on this device",
      "employeeInOut": "Employee sign in and out",
      "visitorInOut": "Visitor sign in and out",
      "deliveries": "Scan deliveries",
      "catering": "Provide catering",
      "evacuation": "Evacuation mode",
      "remove": "Remove Device",
      "cannotRemove": "This device cannot be removed. An account will show one device at a minimum. Logging out and deleting the app will ensure the remaining device ID is no longer used",
      "appVersionAlert": {
        "outOfDate": "Your SwipedOn app is out of date",
        "updateInfo": "App update available",
        "updateWarning": "Your app needs updating",
        "updateDanger": "Please update your app",
        "unsupported": "Your app is unsupported",
        "unsupportedHardware": "Please update your device",
        "updateApp": "Please update to the latest version. {learnHow}",
        "learnHow": "Learn how"
      },
      "validation": {
        "required": "Required field",
        "name": {
          "invalidRange": "Name must be between 1 and 255 characters"
        }
      }
    },
    "learnMore": "Learn more",
    "setupIOBoard": "{link} about setting up a digital employee in/out board",
    "upgradeToolTip": "Upgrade your plan to add more devices",
    "addDeviceForm": {
      "name": "Device name",
      "title": "Add device",
      "addButton": "Add Device",
      "location": "Location",
      "options": "Select which options you’d like enabled on this device",
      "employeeInOut": "Employee sign in and out",
      "visitorInOut": "Visitor sign in and out",
      "deliveries": "Scan deliveries",
      "catering": "Provide catering",
      "evacuation": "Evacuation mode",
      "selectLocationPlaceholder": "Select location",
      "resourceLimitExceeded": "Devices limit exceeded",
      "validation": {
        "required": "Required field",
        "name": {
          "invalidRange": "Name must be between 1 and 255 characters"
        },
        "limitExceeded": {
          "title": "Devices limit exceeded",
          "message": "Upgrade your plan to add more devices"
        }
      }
    }
  },
  "agreementsLibrary": {
    "subtitle": "Your central hub for managing company wide agreements. Add an agreement to the visitor sign-in flow from {link}",
    "pdf": "PDF",
    "default": "Text",
    "searchPlaceholder": "Search agreements",
    "addNew": "Add New",
    "cancel": "Cancel",
    "deleteDocument": "Delete Document",
    "lastUpdated": "Last updated",
    "newDocument": "New document",
    "documentType": "Document type",
    "documentTitle": "Document title",
    "addDocument": "Add Document",
    "cancelTitle": "You have unsaved changes",
    "cancelMessage": "You will lose these changes. Are you sure you want to continue?",
    "continue": "Continue",
    "replacePDF": "Replace PDF",
    "editorPlaceholder": "Start typing or paste content here",
    "dragText": "Drag & drop to upload PDF",
    "agreementNotFound": "Agreement not found",
    "refreshBrowser": "Please refresh your browser window",
    "pdfFileSize": "Your file size is too large. Please keep it to within 10MB",
    "failedToSaveAgreement": "Failed to save agreement",
    "error": {
      "saveAgreementFailed": "Failed to save agreement",
      "loadAgreementsFailed": "Failed to load agreements",
      "loadAgreementFailed": "Failed to load specified agreement",
      "setAgreementAssignmentFailed": "Failed to set agreement assignment"
    },
    "noMatchingResults": "No matching results",
    "ctaTitle": "Capture signed agreements during sign in",
    "ctaBody": "Assign an agreement to your visitor sign in flow and store a digital record. {link}",
    "ctaLink": "Upgrade now"
  },
  "azureSettings": {
    "title": "Azure Active Directory",
    "subtitle": "Connect to your Azure Active Directory account",
    "updateSuccess": "Your Azure Active Directory Sync configuration has been updated.",
    "updateError": "There was a problem updating your Azure Active Directory Sync configuration. If this problem persists please contact support",
    "authErrorTitle": "Authentication error connecting to Azure",
    "authErrorDescription": "Please remove sync below, check your Azure settings & reconfigure your connection. For help please read {link}",
    "howToConnect": "How to connect to your Azure account",
    "status": {
      "title": "Status",
      "active": "Active",
      "paused": "Paused",
      "lastSync": "Last sync:",
      "reviewIssues": "Review issues",
      "buttons": {
        "pause": "Pause Sync",
        "enable": "Enable Sync",
        "remove": "Remove Sync",
        "cancel": "Cancel"
      },
      "removeModal": {
        "title": "Are you sure you want to remove Azure Sync?",
        "content": "This action will remove all connection data and you will need to re-enter all information to sync again"
      },
      "removeAlertSuccess": "Your Azure Active Directory Sync configuration has been deleted"
    },
    "issues": {
      "back": "Back",
      "syncIssues": "Sync Issues"
    },
    "group": {
      "title": "Azure Group",
      "placeHolder": "Select…",
      "manualPlaceHolder": "Enter Group Id",
      "noGroup": "No group is set up",
      "error": {
        "authTitle": "Authentication error connecting to Azure",
        "authDescription": "Check your credentials are correct",
        "invalid": "Unknown Group",
        "updating": "Error updating group"
      },
      "buttons": {
        "edit": "Edit",
        "cancel": "Cancel",
        "update": "Update Group"
      },
      "required": "Required field"
    },
    "fields": {
      "title": "Employee Custom Fields",
      "description": "Map your SwipedOn employee custom fields to your Azure categories",
      "names": {
        "firstName": "First name",
        "lastName": "Last name",
        "userName": "User name",
        "mail": "Mail",
        "office": "Office",
        "officePhone": "Office phone",
        "mobilePhone": "Mobile phone",
        "displayName": "Display name",
        "jobTitle": "Job Title",
        "department": "Department",
        "streetAddress": "Street address",
        "state": "State",
        "country": "Country",
        "city": "City",
        "postalCode": "Postal code",
        "email": "Email",
        "mobile": "Mobile",
        "phone": "Phone"
      },
      "update": "Update Fields",
      "cancel": "Cancel",
      "map": "Map",
      "table": {
        "columns": {
          "swipedOnCategories": "SwipedOn Categories",
          "azureCategories": "Azure Categories"
        }
      }
    },
    "locations": {
      "title": "Locations",
      "description": "Map your SwipedOn locations to Azure",
      "updateLocations": "Update Locations",
      "defaultLocation": "Assign employees without a mapped office to a location",
      "location": "Location",
      "localLocations": "SwipedOn Offices",
      "remoteLocations": "Azure Locations"
    },
    "setup": {
      "connect": {
        "title": "Connect",
        "tenantId": "Tenant ID",
        "clientId": "Client ID",
        "clientSecret": "Client Secret",
        "environment": {
          "title": "Environment",
          "azure": "Azure",
          "gccHigh": "GCC High (US Government)"
        }
      },
      "group": {
        "title": "Group",
        "azureGroup": "Azure Group"
      },
      "fields": {
        "title": "Fields"
      },
      "locations": {
        "title": "Locations",
        "subtitle": "Map your Azure offices to your SwipedOn locations"
      },
      "conflicts": {
        "title": "There is 1 employee in SwipedOn who doesn’t have a matching record in Azure | There are {count} employees in SwipedOn who don’t have a matching record in Azure",
        "syncIssues": "Sync Issues",
        "removeAction": "Remove this 1 employee from SwipedOn | Remove these {count} employees from SwipedOn",
        "ignoreAction": "Ignore this 1 employee when synchronizing | Ignore these {count} employees when synchronizing"
      },
      "summary": {
        "title": "Summary",
        "entries": "Entries"
      },
      "results": {
        "title": "Results",
        "syncedLocations": "{count} Location synced successfully | {count} Locations synced successfully",
        "syncedFields": "{count} Field synced successfully | {count} Fields synced successfully"
      },
      "actions": {
        "next": "Next",
        "back": "Back",
        "map": "Map",
        "skip": "Skip",
        "sync": "Sync",
        "done": "Done"
      }
    }
  },
  "locationsSettings": {
    "title": "Locations & AddOns",
    "subtitle": "Manage your locations and their AddOns. Create a group to apply the same settings across multiple locations",
    "employeeRoaming": {
      "label": "Allow employee roaming",
      "description": "Employees can sign in at locations they are not assigned to"
    },
    "createGroup": "Create Group",
    "addLocation": "Add location details (1 location) | Add location details ({count} locations)",
    "buyNewLocation": "Add New Location",
    "devicesCount": "0 Device | 1 Device | {n} Devices",
    "png": "PNG",
    "svg": "SVG",
    "newGroup": "New Group",
    "groupName": "Group",
    "copySettingsFrom": "Copy settings from",
    "locationGroups": "Location Groups",
    "singleLocations": "Single Locations",
    "selectGroupLocation": "Select a group or location",
    "selectGroup": "Select a group",
    "noGroupsCreated": "No groups created",
    "none": "None (Single location)",
    "remove": "Remove Group",
    "available": "Available",
    "includedInPlan": "Included in plan",
    "learnMoreAddons": "Learn more about AddOns",
    "learnMoreURL": "https://www.swipedon.com/addons",
    "upgradeToolTip": "Upgrade your plan to add more locations",
    "newLocation": {
      "next": "Next",
      "back": "Back",
      "add": "Add",
      "buyNow": "Buy Now",
      "addLocationDetails": "Add location details",
      "newLocation": "New location",
      "locationName": "Location name",
      "address": "Address",
      "moveToGroup": "Move to group",
      "likeToIncludeAddOns": "Would you like to include any AddOns?"
    },
    "buyLocationDialog": {
      "buyLocation": "Buy location?",
      "buyAddOns": "Buy AddOn? | Buy AddOns?",
      "buyDetails": "You have selected to purchase {item}. We’ll charge your account for the remaining period on your subscription",
      "newLocation": "a new location",
      "a": "a",
      "newAddOns": "new AddOn | new AddOns",
      "newLocationAddOns": "a new location with an AddOn | a new location with AddOns",
      "cancel": "Cancel",
      "confirm": "Confirm"
    },
    "form": {
      "labelName": "Location name",
      "address": "Address",
      "timezone": "Timezone",
      "addToGroup": "Group",
      "inThisGroup": "In this Group",
      "removeFromGroup": "Remove from group",
      "addons": "AddOns",
      "includedInPlan": "(Included in plan)",
      "addonLabel": "{addon} {suffix}",
      "addonFreeTrialAlert": "AddOns are available on any SwipedOn plan. Some plans have AddOns included. {viewPlans}",
      "viewPlans": "View plans",
      "devices": "Devices",
      "labelRemove": "Remove Location",
      "deleteLocation": "delete a location",
      "cancelAddon": "cancel an AddOn",
      "contactUs": "Please contact us to {location}",
      "orAddon": " or {addon}",
      "checklist": {
        "title": "To remove a location, the following must be completed",
        "resources": {
          "devices": "Devices",
          "companyAdminsLocationManagers": "Company Admins and Location managers",
          "activeEmployees": "employees"
        },
        "removeAllResources": "Remove all {resources} from {location}",
        "archiveAllResources": "Archive all active {resources} from {location}",
        "refresh": "Refresh List"
      },
      "confirmRemoveFromGroup": {
        "title": "Are you sure you want to remove {name} from group?",
        "description2": "This action can not be undone, are you sure you want to continue?"
      },
      "download": {
        "title": "Download data for this location before it’s removed",
        "visitors": "Visitors",
        "visits": "Visits",
        "employees": "Archived Employees",
        "movements": "Employee Movements",
        "label": "I want to remove {location}"
      },
      "dialog": {
        "header": "You are about to remove {location}",
        "areYouSure": "This action can not be undone, are you sure you want to continue?",
        "includedInPurchase": "The {addonName} trial is currently active at {locationsWithAddon} locations and will be included in this purchase.",
        "includedInPurchaseOther": "The {addonName} trial is currently active at another location and will be included in this purchase. | The {addonName} trial is currently active at {locationsWithAddon} other locations and will be included in this purchase.",
        "includedInPurchaseInstructions": "Ensure you have turned it off at any locations you don't want to include.",
        "cancel": "Cancel",
        "confirm": "Confirm"
      },
      "trialAddon": {
        "trialLabel": "{trial} left in trial or {link}",
        "buyNow": "buy now"
      },
      "buyNowDialog": {
        "header": "Buy {addOnName}",
        "description": "You have selected to purchase the {addOnName} {addOn}. We’ll charge your account for the remaining period on your subscription",
        "addOn": "AddOn"
      }
    },
    "table": {
      "location": "Location",
      "address": "Address",
      "devices": "Devices",
      "qrcode": "QR Code",
      "addons": "AddOns",
      "noData": "There are no locations in this group. Open a location to move it to this group"
    }
  },
  "visitorSettings": {
    "upgradeLink": "Upgrade now",
    "branding": {
      "uploader": {
        "text": "Drop logo here or {link}",
        "linkText": "click to upload",
        "infoText": "Must be PNG or JPG format, under 2MB in size"
      },
      "company": {
        "title": "Company logo",
        "description": "Add a logo and select where you’d like it to display",
        "idBadge": "ID Badge",
        "email": "Email",
        "contactless": "Contactless",
        "displaysOn": "Displays on",
        "errorMaxSize": "Your file size exceeds {size}MB",
        "removeConfirm": {
          "header": "You are about to delete this logo",
          "body": [
            "By taking this action you will be removing it from all locations it is currently displaying at",
            "This action can not be undone. Are you sure you want to continue?"
          ],
          "cancel": "Cancel",
          "delete": "Delete"
        }
      },
      "idBadge": {
        "title": "Visitor ID badges",
        "description": "Print ID badges by connecting a compatible label printer {link}",
        "learnMoreLinkText": "Learn more",
        "scanBadgeTitle": "Allow scanning of badge to sign out",
        "scanBadgeDesc": "Visitors can hold their badge up to the iPad or tablet camera to sign out",
        "standard": "Standard",
        "photo": "Photo",
        "simple": "Simple"
      },
      "brandColor": {
        "title": "Brand color",
        "description": "Choose an accent color for headings, buttons and highlights",
        "accentColor": "Accent color",
        "welcome": "Welcome",
        "brandLight": "Light mode preview",
        "brandDark": "Dark mode preview",
        "androidTabletOnly": "(Android tablet only)",
        "learnMore": "Learn more",
        "applyCompanyBrand": "{link} about applying your company brand to SwipedOn"
      }
    },
    "agreements": {
      "learnMore": "Learn more",
      "manageVisitorFields": "{link} about managing visitor fields",
      "upgradeCtaTitle": "Create custom sign-in flows per category",
      "upgradeCtaBody": "Collect different information per visitor category on our Enterprise plan. {link}",
      "upgradeCtaAlt": "Lightbulb Icon"
    },
    "fields": {
      "cancel": "Cancel",
      "add": "Add Field",
      "fieldLabel": "Field",
      "visitorFields": {
        "title": "Visitor fields",
        "description": "Create new visitor fields and show or hide existing ones. Set which are required and need approval",
        "hideInactiveFields": "Hide inactive fields",
        "addField": "Add Field"
      },
      "agreements": {
        "title": "Agreements",
        "description": "Select an agreement for your visitors to accept. A copy will be stored for every visit",
        "learnMore": "Learn more",
        "requireSignature": "Require Signature",
        "manageAgreements": "Manage",
        "replaceAgreement": "Replace Agreement",
        "selectAgreement": "Select Agreement",
        "noAgreement": "No agreement selected",
        "ctaTitle": "Capture signed agreements",
        "ctaBody": "Have visitors sign legal documents and store a digital record. {link}",
        "ctaLinkText": "Upgrade now"
      },
      "inductions": {
        "title": "Preboarding",
        "description": "Select a preboarding flow for visitors to view on sign-in",
        "manage": "Manage",
        "replace": "Replace preboarding",
        "select": "Select",
        "noneSelected": "No preboarding flow selected",
        "error": "Failed to set preboarding assignment",
        "purchasedFor": "The Preboarding AddOn has been purchased for {count} of {total} locations in this location group.",
        "tryCtaTitle": "Ensure visitors are fully informed and prepared for their visit",
        "tryCtaBody": "Create custom visitor preboarding flows tailored to your unique compliance needs. {link}",
        "Try": "Try it now",
        "purchase": "Purchase AddOn",
        "getStartedDialog": {
          "startTrial": "Start your {trial} today!",
          "freeTrial": "14 day free trial",
          "body": "Ensure visitors are fully informed and prepared for their visit with bespoke preboarding for your workplace. Create custom preboarding tailored to your unique compliance needs.",
          "confirm": "Get Started",
          "cancel": "Not now"
        },
        "trialStartedDialog": {
          "body": "To get started, create a preboarding flow and assign it to a visitor category",
          "header": "Great! Your 14 day free trial has now started. {more}",
          "create": "Get started",
          "later": "Do It Later",
          "learnMore": "Learn more >"
        },
        "trialEndedDialog": {
          "body": "Your 14 day free trial of Visitor Preboarding has finished. If you would like to continue using this feature, please purchase the AddOn for each location you require",
          "subscribe": "Purchase AddOn",
          "not": "Not Now"
        }
      },
      "fieldType": {
        "fullName": "Full Name",
        "email": "Email",
        "phone": "Phone",
        "text": "Text",
        "string": "Text",
        "yesno": "Yes / No",
        "dob": "Date of Birth",
        "multichoice": "Multi-choice",
        "multiselect": "Checkboxes",
        "document": "Document",
        "vaccinepass": "Vaccine"
      },
      "removeConfirm": {
        "header": "Are you sure?",
        "body": [
          "This field will be removed from all locations and answers will be permanently deleted. This action can not be undone",
          "To keep the data, you can hide it from your locations using the switch button"
        ]
      },
      "minOptions": "At least 1 option is required | At least {number} options are required",
      "options": "Options",
      "approvalRequired": "Approval Required",
      "addOption": "Add Option",
      "required": "Required",
      "clearResponse": "Clear response after each visit (saved visitors)",
      "delete": "Delete",
      "documentInstructions": "Add any specific instructions (optional)",
      "documentIntro": "Visitors will be able to take a photo, or upload a document (phone only), of any information you require",
      "yes": "Yes",
      "no": "No",
      "requireApproval": "Require approval if:",
      "upgradeToAddApprovals": "Upgrade your plan to add approvals",
      "categorySelect": "Select a category",
      "duplicateOption": "This option already exists"
    },
    "catering": {
      "actions": {
        "addItem": "Add Item",
        "price": "Price",
        "notesPlaceholder": "E.g. gluten free",
        "add": "Add",
        "cancel": "Cancel",
        "delete": "Delete",
        "remove": "Remove",
        "itemName": "Item name",
        "notes": "Notes"
      },
      "beverages": {
        "title": "Beverages menu",
        "description": "Keep track of costs by adding prices to items. This won’t be visible to visitors"
      },
      "food": {
        "title": "Food menu",
        "foodEmpty": "Your {type} is currently empty. Click the {action} button to build your menu"
      },
      "cateringContacts": {
        "title": "Catering contacts",
        "description": "Choose an employee or add a contact for catering orders",
        "hostReceivesOrder": "Send catering order to the host",
        "contactExists": "An order contact already exists with the details given"
      },
      "report": {
        "title": "Report",
        "description": "Export a report showing the orders and total cost over a period of time",
        "period": "Select a reporting period",
        "generate": "Generate report",
        "to": "To"
      }
    },
    "advancedSettings": {
      "validation": {
        "emailInvalid": "Invalid email",
        "emailAlreadyExists": "Email already exists",
        "emailMaxRecipients": "Maximum of 3 recipients allowed",
        "hostApprovalToolTip": "Select a host must be enabled"
      },
      "emailListComponent": {
        "formItemLabel": "Email",
        "inputPlaceHolder": "Email address",
        "addButton": "Add",
        "removeButton": "Remove"
      },
      "visitorPhoto": {
        "title": "Visitor photo",
        "checkboxLabel": "Take photo of the visitor during sign in"
      },
      "selectHost": {
        "title": "Select a host",
        "requireVisitorSelectLabel": "Require visitors to select who they’re meeting with",
        "requireVisitorSelectDesc": "Hosts will be notified of visitor arrivals",
        "showHostListLabel": "Show host list",
        "showHostListDesc": "Visitors can see a list of employees to choose from",
        "displayHostStatusLabel": "Display the host signed in/out status to visitors",
        "includeVisitorResponseLabel": "Include all visitor responses in host notification email",
        "allowHostSelectionWithStaticQr": "Allow host selection when scanning a static QR code"
      },
      "returningVisitors": {
        "title": "Returning visitors",
        "allowVisitorSaveDetailLabel": "Allow visitors to save their details for their next visit",
        "displayNameMatchesLabel": "Display name matches as visitors enter their details",
        "disableRememberedVisitors": {
          "header": "Are you sure you want to disable Remembered Visitors?",
          "infoSectionTop": "Disabling this setting will remove existing remembered visitors from {locationName}. Remembered visitors who are currently signed in will be signed out. Existing visit data will not be removed",
          "infoSectionBottom": "For more information regarding Remembered Visitors please see the {helpArticle}",
          "helpArticle": "help article",
          "cancel": "Cancel",
          "disable": "Disable Remembered Visitors"
        }
      },
      "approvals": {
        "title": "Approvals",
        "includeAllVisitorResponsesLabel": "Include all visitor responses in approval emails",
        "allowHostApprovalLabel": "Allow hosts to approve visitors ",
        "sendApprovalAlertsLabel": "Send approval alerts to specific recipients",
        "sendApprovalAlertsDesc": "Add up to three recipients that you want to be alerted of an approval. Note these recipients do not have access to approve or deny",
        "phoneInput": "Mobile number"
      },
      "additionalRecipients": {
        "title": "Additional recipients for sign in notifications",
        "checkboxLabel": "Send visitor sign in notifications to specific recipients",
        "checkboxDesc": "Add up to three recipients"
      },
      "signOutSettings": {
        "title": "Sign out settings",
        "hour": "Hour | Hours",
        "day": "Day | Days",
        "notifyVisitorNotSignedOut": "Send a notification if a visitor hasn’t signed out after a period of time",
        "notifyVisitorNotSignedOutDesc": "Notification will be sent to local Company Admins and Location Managers",
        "notifyHost": "Also notify host",
        "notifyVisitorSignOut": "Send a notification when a visitor signs out",
        "notifyVisitorSignOutDesc": "Notification will be sent to local Company Admins and Location Managers",
        "autoSignOutLabel": "Automatically sign visitors out at a specific time",
        "autoAnonLabel": "Automatically anonymize signed out visitors after a period of time",
        "autoAnonDesc": "This means the information is permanently deleted from the SwipedOn system"
      },
      "generateStaticQr": {
        "title": "Generate a static QR code",
        "description": "Display QR codes to facilitate contactless sign in. SVG files are suitable for large use such as a TV screen",
        "selectLocation": "Select location",
        "downloadPNG": "Download PNG",
        "downloadSVG": "Download SVG"
      },
      "tipsEnhancedPrivacy": {
        "title": "Tips for enhanced privacy",
        "tipList": [
          "Disable the ability for visitors to save their details on iPad",
          "Don’t display visitor name matches. Instead, require visitors to enter their full name and company name",
          "Disable the employee list being shown during host selection, instead requiring the visitor to type in the host name",
          "Disable the ability to show host selection when scanning a static QR code"
        ],
        "summary": "These tips are provided for guidance purposes only. You will need to make your own assessment as to whether your use of SwipedOn complies with the GDPR (if applicable) and other applicable privacy and data protection laws."
      }
    },
    "categories": {
      "title": "Visitor categories",
      "subtitle": "Know who your visitors are by creating categories for them to select from (Visitor, cleaner, contractor)",
      "newCategory": "New category",
      "categoryName": "Category name",
      "addCategory": "Add Category",
      "add": "Add",
      "on": "On",
      "off": "Off",
      "catering": "Catering",
      "hostSelection": "Host selection",
      "requireApproval": "Require approval for a selection of visitors",
      "selectionsRandomized": "Selections will be randomized",
      "oneIn": "One in",
      "visitors": "Visitors",
      "alertEditingCategory": "Changing this category name will make all historic visit records in this category read-only",
      "alertCannotRemoveCategory": "This category cannot be removed. Every location must have an active category",
      "deleteCategory": "Delete Category",
      "cancel": "Cancel",
      "delete": "Delete",
      "deleteConfirm": {
        "header": "Are you sure?",
        "body": [
          "This category will be removed from all locations. This action can not be undone",
          "To hide it at just this location, click the switch button"
        ]
      },
      "screenHeading": {
        "title": "Category screen heading",
        "subtitle": "Create a heading for your category screen (for example, ‘What type of visitor are you?’) or leave disabled to use the default ‘Category’",
        "checkboxLabel": "Use a custom heading",
        "inputPlaceholder": "Enter heading",
        "learnMore": "{link} about what visitor categories are used for and how to set these up",
        "learnMoreLinkText": "Learn more",
        "mustHaveValue": "Must have a value"
      }
    }
  },
  "deliverySettings": {
    "setup": {
      "generalSetting": "General settings",
      "scanDelivery": "Scan delivery label to find employee match",
      "allowDelivery": "Allow deliveries without a specified recipient",
      "allowDeliveryDesc": "Notifications will be sent to the nominated contact",
      "deliveryContacts": "Delivery contacts",
      "deliveryContactsDesc": "Select an existing employee or enter the details you would like to use as the deliveries contact",
      "contactExists": "A general delivery contact already exists with the details given"
    },
    "instructions": {
      "title": "Instructions",
      "description": "Provide instructions for the delivery person when a package has a specified recipient or when it’s a general delivery. 150 characters max",
      "preview": "Preview",
      "thankYou": "Thank You",
      "backgroundImageHeading": "Background image",
      "titles": {
        "recipient": "Delivered with recipients",
        "general": "General deliveries"
      },
      "instructionDefaults": {
        "recipientNotified": "The recipient has been notified",
        "recipientWillCollect": "Please wait – the recipient will collect the delivery from you shortly",
        "recipientTeamWillCollect": "Please wait – a member of our team will collect the delivery from you shortly",
        "generalNotified": "Our team has been notified",
        "generalTeamWillCollect": "Please wait – a member of our team will collect the delivery from you shortly"
      },
      "instructionsTitles": {
        "recipient": {
          "noSignatureRequired": "No signature required",
          "signatureRequired": "Signature required",
          "signatureRequiredRecipientSignedOut": "Signature required, but recipient signed out"
        },
        "general": {
          "noSignatureRequired": "No signature required",
          "signatureRequired": "Signature required"
        }
      }
    }
  },
  "teamsSideLoadDetails": {
    "heading": {
      "intro": "Follow the below steps to complete your setup",
      "tryAgain": "We couldn’t detect the SwipedOn Teams app. Please try downloading again from step 1"
    },
    "step": {
      "1": "1. Download the SwipedOn app",
      "2": "2. Open Microsoft Teams and go to the App tab. Select ‘Manage your apps’ and ‘Upload an app’",
      "3": "3. Select ‘Upload an app to your organization’s app catalog’ and open your downloaded SwipedOn .zip file",
      "4": "4. You will see the below if the app has been successfully uploaded",
      "5": "5. Completed the above? Great! You’re ready to configure the notifications you want to receive"
    },
    "button": {
      "download": "Download Zip",
      "configure": "Configure Notifications",
      "cancel": "Cancel Setup"
    }
  },
  "forms": {
    "filters": "Filters",
    "bulkImport": {
      "modalHeadingResources": "Import Resources",
      "modalHeadingEmployees": "Employees Import",
      "modalHeadingVisitors": "Import Visitors",
      "resource": "Resource",
      "resources": "Resources",
      "visitor": "Visitor",
      "visitors": "Visitors",
      "invite": "Invite",
      "invites": "Invites",
      "employee": "Employee",
      "employees": "Employees",
      "actionButtons": {
        "next": "Next",
        "back": "Back",
        "done": "Done",
        "reupload": "Re-upload file",
        "confirm": "Confirm"
      },
      "csvErrors": {
        "notFound": "A {column} with the name {name} was not found",
        "invalid": "{column} is invalid",
        "required": "A {column} is required",
        "unique": "A resource with the name {name} already exists in a different category",
        "general": "An error occurred while uploading. If this problem persists please contact support",
        "outOfRange": "{column} range is invalid",
        "additionalErrors": "there are {count} additional errors"
      },
      "stepPrepare": {
        "title": "Prepare",
        "heading": "Select a location and visitor category",
        "alert": "If you have visitors across multiple categories please upload a separate CSV"
      },
      "stepDownload": {
        "title": "Download",
        "heading": "Please download our CSV template",
        "description": "Or click ‘Next’ if you’ve prepared one already",
        "button": "Download CSV Template",
        "employeesCSVTemplateFileName": "SwipedOn Employees CSV Template",
        "resourcesCSVTemplateFileName": "SwipedOn Resources CSV Template",
        "preregCSVTemplateFileName": "SwipedOn Preregistration CSV Template"
      },
      "stepInvite": {
        "title": "Invite",
        "heading": "Would you like to send an email invite?",
        "yes": "Yes",
        "no": "No",
        "message": "Invite message (optional)",
        "includeInduction": "Include Visitor preboarding",
        "alert": "Ensure visitor email addresses are included in your CSV if choosing to send an invite. Invites can also be sent individually once visitors are imported"
      },
      "stepLocation": {
        "title": "Location",
        "heading": "Choose a location",
        "locationPlaceholder": "Select a Location",
        "noSpacesExistWarning": "No spaces exist at this location. Please {0} first",
        "noSpacesExistLinkText": "add a space"
      },
      "stepUpload": {
        "title": "Upload",
        "heading": "Upload your CSV file",
        "uploadButton": "Choose file",
        "descriptionOne": "We’ll handle your upload in the following way",
        "descriptionTwoEmployees": "Any rows containing new email addresses will be added and any rows containing existing email addresses will be updated",
        "descriptionTwoResources": "Any rows containing new resources will be added and any rows containing existing resources will be updated",
        "mediaUploadTypeError": "Unsupported file type",
        "mediaUploadSizeError": "The file size must be less than {size}MB",
        "dragText": "Drag & drop to upload CSV",
        "uploadFailed": "The CSV you have uploaded is not the correct format"
      },
      "stepConfirm": {
        "title": "Confirm",
        "heading": "Confirm file import",
        "createdEntity": "{count} {entityPlural} to be created|{count} {entity} to be created|{count} {entityPlural} to be created",
        "updatedEntity": "{count} {entityPlural} to be updated|{count} {entity} to be updated|{count} {entityPlural} to be updated",
        "issues": "{count} Issues detected|{count} Issue detected|{count} Issues detected",
        "fixDescription": "To fix these issues you can download the CSV file below, edit and re-upload",
        "issuesFileName": "SwipedOn Resources Import Issues",
        "button": "Download issues",
        "nNew": "{count} new {entityPlural}|{count} new {entity}|{count} new {entityPlural}",
        "purchaseConfirm": "You have selected to purchase {purchase}. We’ll charge your account for the remaining period on your subscription",
        "price": "Each new resource will cost {price} per month",
        "exceededLimit": "You are unable to continue as import exeeds current limit by {exceededLimitBy}.",
        "exceededLimitBy": "{count} {entityPlural}|{count} {entity}|{count} {entityPlural}",
        "purchaseMoreResources": "In order to purchase more resources, please {contactUs}",
        "contactUs": "get in touch"
      },
      "stepResults": {
        "title": "Results",
        "heading": "Results",
        "createdEntity": "{count} {entityPlural} imported successfully|{count} {entity} imported successfully|{count} {entityPlural} imported successfully",
        "updatedEntity": "{count} {entityPlural} updated successfully|{count} {entity} updated successfully|{count} {entityPlural} updated successfully",
        "sentEntity": "{count} {entityPlural} sent|{count} {entity} sent|{count} {entityPlural} sent",
        "issues": "{count} Issues detected|{count} Issue detected|{count} Issues detected",
        "fixDescription": "To fix these issues you can download the CSV file below, edit and re-upload",
        "issuesFileName": "SwipedOn Resources Import Issues",
        "preregistrationIssuesFileName": "SwipedOn Preregistration Import Issues",
        "button": "Download issues"
      }
    },
    "regions": {
      "label": "REGION",
      "regionSelector": {
        "label": "Choose where your cloud-hosted data is stored. This can’t be changed after you create your account. We recommend the closest region to your location.",
        "required": "Please select a region"
      },
      "list": {
        "australia": "AU",
        "canada": "CA",
        "england": "UK",
        "germany": "EU",
        "ireland": "IE",
        "japan": "JP",
        "singapore": "SG",
        "usa": "US",
        "us": "US"
      }
    },
    "login": {
      "certifications": {
        "number": "Cert no. 122117"
      },
      "backAction": "Back",
      "mfa": {
        "title": "Multi-Factor Authentication",
        "subtitle": "Authentication code",
        "verify": "Verify",
        "placeholder": "Enter 6 digit code",
        "challengeErrors": {
          "ExpiredCodeException": "Expired code",
          "NotAuthorizedException": "Please login again",
          "CodeMismatchException": "Please enter valid code"
        }
      },
      "email": {
        "label": "Email",
        "required": "Your email address is required",
        "invalid": "Please use a valid email address"
      },
      "password": {
        "label": "Password",
        "required": "Your password is required"
      },
      "forgotPassword": {
        "label": "Reset your password",
        "resend": "RESEND",
        "emailSent": "Please check your email ({email})"
      },
      "setPassword": {
        "title": "Set a password",
        "placeholderSet": "Enter a new password",
        "length": "Password must be at least {length} characters long and contain upper case, lower case and numbers",
        "button": "Set Password",
        "sendButton": "Send"
      },
      "recoveredPassword": {
        "title": "Password recovery",
        "subtitle": "Enter your email address and receive a link to reset your password",
        "label": "Account email address",
        "successTitle": "Email sent",
        "successSubTitle": "Please check your inbox to complete the password reset process."
      },
      "passwordFormErrors": {
        "PleaseContact": "An error occurred. Please contact your administrator",
        "TooManyRequests": "Too many requests. Please wait a while before trying again",
        "CodeMismatchException": "The link you are using has expired. Please contact your admin to request another invite",
        "ExpiredCodeException": "The link you are using has expired. Please contact your admin to request another invite",
        "ExpiredLink": "The link you are using has expired. Please contact your admin to request another invite",
        "UserNotConfirmedException": "Please confirm your account",
        "PasswordResetRequiredException": "Please reset your password",
        "NotAuthorizedException": "Incorrect login or password",
        "UserNotFoundException": "Incorrect login or password"
      },
      "strongPasswordNotice": {
        "requirements": "Your password must:",
        "conditions": [
          "Be at least 8 characters long",
          "Include numbers, upper case and lower case letters"
        ]
      },
      "next": "Next",
      "signIn": "Sign in",
      "submit": "Log in to Dashboard",
      "profile": "Go to Profile",
      "createAccount": "Create Account",
      "pleaseLogIn": "Please sign in",
      "signingInWith": "Signing in with {user}",
      "ssoUserMismatch": "The email address you entered does not match the one you signed up with",
      "changeUser": "Change",
      "divider": "or"
    },
    "register": {
      "logoAlt": "Logo",
      "stepOne": "Step 1",
      "stepTwo": "Step 2",
      "startTrial": "Start your free 14 day trial",
      "easySetup": [
        "Quick and easy setup.",
        "No credit card required.",
        "No commitment."
      ],
      "letsGetPersonal": "Let’s get personal",
      "findOutWhyCustomersTrustUs": "See why thousands of businesses choose SwipedOn for their workplace sign in solution",
      "firstName": {
        "label": "First name",
        "required": "Please enter your first name"
      },
      "lastName": {
        "label": "Last name",
        "required": "Please enter your last name"
      },
      "email": {
        "label": "Email",
        "conflict": "Email already exists",
        "blocked": "Please enter a different email address. This form does not accept addresses from {email}"
      },
      "phone": "Phone number",
      "phonePlaceholder": "000 0000",
      "howHeard": {
        "label": "How did you hear about SwipedOn?",
        "required": "Please tell us how you heard about SwipedOn"
      },
      "back": "Back",
      "next": "Next",
      "companyName": {
        "label": "Company",
        "required": "This field is required"
      },
      "address": {
        "label": "Street address",
        "required": "This field is required"
      },
      "register": "Start my trial",
      "terms": {
        "label": "TERMS",
        "placeholder": "I agree to the {termsOfService} and {privacyPolicy}",
        "tos": "Terms of Service",
        "privacy": "Privacy Policy",
        "required": "Please agree to our Terms of Service and Privacy Policy"
      },
      "recaptcha": {
        "label": "This site is protected by reCAPTCHA and the Google {privacyPolicy} and {tos} apply.",
        "privacyPolicy": "Privacy Policy",
        "tos": "Terms of Service"
      }
    }
  },
  "core": {
    "counterPill": "{count} {label}"
  },
  "imageUpload": {
    "imageSizeRestrictionError": "Please limit your file size to {size}MB",
    "dragAndDrop": {
      "dragText": "Drag & drop to upload image",
      "orChooseText": "or choose file",
      "uploadFile": "Upload file",
      "chooseFile": "Choose file"
    },
    "view": "View",
    "typeError": " Unsupported type.",
    "uploadImage": "Upload Image",
    "imageSizeRestrictionWarning": "Must be PNG or JPG format, under {size}{unit} in size",
    "defaultImageName": "No photo uploaded"
  },
  "errors": {
    "unexpected": "An unexpected error occurred",
    "unauthorized": "You aren’t authorized to perform this action",
    "fetchTrendsFailed": "Failed to load data",
    "changeRoleFail": "Failed to change user role",
    "fetchContactTraceFail": "Failed to fetch contact tracing information",
    "enabledScreensaverMax": "Can not have more than {limit} screensavers enabled",
    "enabledScreensaverMin": "Must have at least one screensaver enabled",
    "enabledScreensaverAtOtherLocation": "Cannot delete a screensaver enabled at another location",
    "roleInviteFail": "Invite failed to send. Please contact support for help",
    "exportVisitsFailed": "Failed to export visit data",
    "fetchVisitorsFailed": "Failed to fetch visitors",
    "fetchVisitsFailed": "Failed to fetch visits",
    "fetchVisitorFailed": "Failed to fetch visitor",
    "fetchVisitFailed": "Failed to fetch visit",
    "updateVisitorFailed": "Failed to update visitor",
    "signOutVisitsFailed": "Failed to sign out visitors",
    "signOutVisitFailed": "Failed to sign out visitor",
    "anonymizeVisitsFailed": "Failed to anonymize visits",
    "anonymizeVisitFailed": "Failed to anonymize visit",
    "anonymizeVisitorsFailed": "Failed to anonymize visitors",
    "anonymizeVisitorFailed": "Failed to anonymize visitor",
    "fetchPreRegistrationsFailed": "Failed to fetch pre-registered visitors",
    "fetchPreRegistrationFailed": "Failed to fetch pre-registered visitor",
    "exportVisitorsFailed": "Failed to export visitor data",
    "unableToPreviewPDF": "Unable to display PDF file",
    "marketplaceTokenInvalid": "Your account has already been created. Please sign in",
    "marketplaceTokenUnknown": "This session has expired. Please start again",
    "forbidden": "Something went wrong, please contact support",
    "contactSupport": [
      "If this problem persists please ",
      "contact support"
    ]
  },
  "imgAlt": {
    "defaultProfilePhoto": "Default profile photo",
    "soc2": "SOC 2 certification",
    "iso27001": "ISO 27001 certification",
    "blogImage": "Latest Blog Image",
    "appStoreDownload": "App Store",
    "googlePlayDownload": "Google Play",
    "webAppDownload": "Web App"
  },
  "validation": {
    "required": "Required",
    "passwordLimit": "Password update request limit has been reached. Please try again later",
    "passwordFormat": "Password should contain mixed case and numeric characters",
    "currentPasswordError": "The password you entered does not match our records",
    "api": {
      "email_exists": "Email already exists",
      "emailCc_invalid": "Invalid email address",
      "phone_invalid": "Invalid phone number",
      "phoneCc_invalid": "Invalid phone number"
    },
    "requiredField": "This field is required",
    "maxCharacters": "Maximum of 1 character allowed | Maximum of {max} characters allowed",
    "minCharacters": "Must have a minimum of 1 character | Must have a minimum of {min} characters",
    "duplicateZone": "A zone of the same name already exists",
    "duplicateField": "A field of the same name already exists",
    "duplicateMessage": "A message of the same name already exists",
    "duplicateQuestion": "A question of the same name already exists",
    "duplicateFieldWithType": "A field of the same name and type already exists",
    "duplicateAgreement": "An agreement with this name already exists",
    "duplicateGroup": "A group with this name already exists",
    "duplicateLocation": "A location with this name already exists",
    "invalidPhone": "Invalid phone number",
    "invalidRange": "Field name must be between 1 and 128 characters",
    "invalidEmail": "Invalid email",
    "invalidNumber": "Invalid number",
    "requiredLocation": "Please enter a valid location address",
    "requireApproval": "This response requires approval when your visitor signs in",
    "invalidAddress": "Invalid address"
  },
  "urls": {
    "pocket": "https://my.swipedon.com",
    "marketing": {
      "root": "https://www.swipedon.com",
      "blog": "https://www.swipedon.com/blog"
    },
    "support": {
      "azure": "https://www.swipedon.com/support/how-to-connect-your-azure-account",
      "bookDemo": "https://www.swipedon.com/book-swipedon-demo",
      "generalEnquiry": "https://www.swipedon.com/contact-us",
      "branding": "https://www.swipedon.com/support/welcome-screen-settings",
      "ioBoard": "https://www.swipedon.com/support/how-to-set-up-the-employee-in/out-board",
      "deliveries": "https://www.swipedon.com/support/how-to-set-up-deliveries",
      "employees": "https://www.swipedon.com/support/how-to-add-an-employee",
      "printers": "https://www.swipedon.com/support/how-to-connect-the-ipad-and-printer",
      "visitorFields": "https://www.swipedon.com/support/visitor-fields",
      "visitorCategories": "https://www.swipedon.com/support/visitor-categories",
      "workingRemote": "https://www.swipedon.com/support/working-remotely-for-employees",
      "updatingEmployeePhoto": "https://www.swipedon.com/support/how-to-take-set-an-employee-profile-photo",
      "lockEmployeeList": "https://www.swipedon.com/support/lock-employee-list",
      "customFieldHostSelection": "https://www.swipedon.com/support/show-field-in-host-selection",
      "pocket": "https://www.swipedon.com/employee-sign-in-app",
      "rememberMe": "https://www.swipedon.com/support/remember-visitors",
      "brandingSettings": "https://www.swipedon.com/support/branding-settings",
      "supportCentre": "https://www.swipedon.com/support",
      "installApp": "https://www.swipedon.com/support/how-to-install-the-swipedon-app",
      "updateApp": "https://www.swipedon.com/support/how-to-update-the-swipedon-ios-app",
      "microsoftTeamsIntegration": "https://www.swipedon.com/support/integration-for-teams",
      "slackIntegration": "https://www.swipedon.com/support/integration-for-slack",
      "createZone": "https://www.swipedon.com/support/how-to-create-a-zone",
      "faq": "https://www.swipedon.com/frequently-asked-questions",
      "learnMoreMfaUrl": "https://www.swipedon.com/support/how-to-set-up-mfa-enforcement",
      "learnMoreSsoUrl": "https://www.swipedon.com/support/single-sign-on",
      "securityFaq": "https://www.swipedon.com/frequently-asked-questions#safety-security",
      "securityDps": "https://www.swipedon.com/support/data-protection-security"
    },
    "downloads": {
      "pocketGuidePdf": "https://www.swipedon.com/hubfs/SwipedOn-Pocket-Guide-2023.pdf",
      "androidPocket": "https://play.google.com/store/apps/details?id=com.swipedon.mobile",
      "iosPocket": "https://apps.apple.com/us/app/id1518500176"
    },
    "terms": {
      "termUrl": "https://www.swipedon.com/terms-of-service",
      "privacyUrl": "https://www.swipedon.com/privacy-policy"
    },
    "registerThankYou": "https://www.swipedon.com/register-thank-you"
  },
  "inductions": {
    "title": "Preboarding",
    "subtitle": "Manage your preboarding flows. These can be assigned to different visitor categories in Settings > Visitor fields",
    "days": "{n} day | {n} days",
    "visitors": "{n} Visitor | {n} Visitors",
    "exportCsvFilename": "Preboarding completed {datetime}",
    "actions": {
      "actions": "Actions",
      "resetInduction": "Reset",
      "delete": "Delete",
      "exportCompleted": "Export completed",
      "newInduction": "Add New",
      "counterAction": "{label} | {label} (1) | {label} ({count})"
    },
    "timelines": {
      "label": {
        "name": "Name",
        "valid": "Valid",
        "created": "Created",
        "lastEdited": "Last edited",
        "completed": "Completed"
      }
    },
    "modal": {
      "resetInduction": {
        "content": [
          "Resetting will change the preboarding status of any saved visitors that have completed this preboarding flow previously. Are you sure you want to reset? | Resetting will change the preboarding status of any saved visitors that have completed these preboarding flows previously. Are you sure you want to reset?",
          "This action can not be undone"
        ],
        "titleSingle": "Are you sure you want to reset the visitors for {name}?",
        "titleMultiple": "Are you sure you want to reset the visitors for these preboarding flows?",
        "cancel": "Cancel",
        "resetInduction": "Reset"
      },
      "deleteInduction": {
        "content": "Deleting this preboarding flow will remove it from any assigned visitor categories and visitor data. This action can not be undone. | Deleting these preboarding flows will remove them from any assigned visitor categories and visitor data. This action can not be undone.",
        "titleSingle": "Are you sure you want to delete {name}?",
        "titleMultiple": "Are you sure you want to delete these preboarding flows?",
        "cancel": "Cancel",
        "deleteInduction": "Delete"
      }
    },
    "editInduction": {
      "name": "Preboarding Name",
      "newInduction": "New Preboarding flow",
      "expiry": "Expires (days)",
      "media": "Media",
      "mediaUploadText": "Drag & drop to upload a document, video or image",
      "mediaUploadFileChoose": "or choose file",
      "mediaUploadFileChooseMobile": "Choose file",
      "mediaUploadLimitError": "Your file exceeds the {limit}{unit} limit",
      "mediaUploadTypeError": "Unsupported file type",
      "view": "View",
      "questions": "Questions",
      "addQuestion": "Add Question",
      "questionsEmptyCaption": "You don’t have any questions yet",
      "questionsEmptyText": "Click the Add Question button to get started",
      "question": "Question",
      "answer": "Answer",
      "correct": "Correct",
      "addAnswer": "Add answer",
      "deleteQuestion": "Delete question",
      "save": "Save",
      "requiredCorrectAnswer": "At least one answer must be correct",
      "duplicateAnswer": "This answer already exists",
      "requiredField": "Required field",
      "warningMessage": "You must create a question or upload media to create a preboarding flow",
      "supportFileTypes": "Supported file types: pdf, png, jpg, mp4",
      "limitFileSize": "Maximum file size: 100MB"
    },
    "emptyState": {
      "heading": "You don’t have any preboarding flows yet",
      "messageLine1": "Add preboarding flows and assign them to your visitor categories",
      "messageLine2": "Click the ‘{0}’ button above to get started",
      "action": "Add new"
    }
  },
  "integrations": {
    "options": {
      "all": "All",
      "calendar": "Calendar"
    },
    "manage": "Manage",
    "setUp": "Set Up",
    "download": "Download",
    "upgrade": "Upgrade",
    "trial": "Trial",
    "cancel": "Cancel",
    "remove": "Remove",
    "availableOnEnterprise": "Available on an Enterprise plan",
    "availableOnStandardAndAbove": "Available on Standard and above",
    "learnMore": "Learn More",
    "azureAuthError": "Authentication error connecting to Azure",
    "msTeamsDescription": "Automatically post employee movements to your channels",
    "msActiveDirectoryDescription": "Download the sync agent and configure it on your on-premise Active Directory server",
    "azureActiveDirectoryDescription": "Sync your SwipedOn employees list with Azure Active Directory",
    "teams": {
      "employeeInOutTitle": "Employee in & out",
      "employeeInOutDesc": "Post employee in and out movements to a Teams channel",
      "form": {
        "channels": "{teamName} / {channelName}"
      },
      "location": "Location",
      "teamsChannels": "Channels",
      "selectAChannel": "Select a channel",
      "description": "Keep tabs on what’s happening in the workplace with Microsoft Teams",
      "teams_host_notifications": {
        "title": "Visitor arrivals",
        "label": "Notify host",
        "description": "Hosts will be sent a direct message when their visitor arrives"
      },
      "teams_visitor_approval_notifications": {
        "title": "Approvals",
        "label": "Notify approvers",
        "description": "A direct message will be sent to local Company Admins and Location Managers"
      },
      "tips": {
        "title": "Tips for integrating with Microsoft Teams",
        "description": [
          "Employee in and out notifications can only be sent to Public channels in Teams. Private channels cannot be selected",
          "Employee email addresses must be an exact match between SwipedOn and Teams in order to receive visitor arrival and approval notifications",
          "For further help please read {link}"
        ],
        "linkDescription": "How to set up the MS Teams integration"
      },
      "confirmRemoveTitle": "Are you sure you want to remove the Microsoft Teams integration?",
      "confirmRemoveDescription": "This will remove the SwipedOn app from Microsoft Teams. This action can not be undone",
      "alreadyInstalledTitle": "The Microsoft account you selected is already associated with a SwipedOn account",
      "alreadyInstalledDescription": "Please select a different Microsoft account or remove the MS Teams integration from your other SwipedOn account",
      "supportText": "Show me how"
    },
    "slack": {
      "description": "Keep tabs on what’s happening in the workplace with Slack",
      "notifyHostTitle": "Visitor arrivals",
      "notifyHost": "Notify host",
      "notifyHostDesc": "Hosts will be sent a direct message when their visitor arrives",
      "notifyApproversTitle": "Approvals",
      "notifyApprovers": "Notify approvers",
      "notifyApproversDesc": "A direct message will be sent to local Company Admins and Location Managers",
      "employeeInOutTitle": "Employee in & out",
      "employeeInOutDesc": "Post employee in and out movements to a Slack channel",
      "location": "Location",
      "slackChannels": "Slack Channels",
      "selectAChannel": "Select a channel",
      "tipTitle": "Tips for integrating with Slack",
      "tipNotification": "Employee in and out notifications can only be sent to Public channels in Slack. Private channels can not be selected",
      "tipEmail": "Employee email addresses must be an exact match between SwipedOn and Slack in order to receive visitor arrival and approval notifications",
      "tipLearnMore": "For further help please read {link}",
      "learnMore": "How to set up the Slack integration",
      "removeIntegration": "Remove Integration",
      "confirmRemoveDesc": "This will remove the SwipedOn app from Slack, this action can not be undone",
      "confirmRemoveTitle": "Are you sure you want to remove the Slack integration?",
      "alreadyInstalledTitle": "The SwipedOn app is already installed in this Slack workspace",
      "alreadyInstalledDescription": "Please remove the existing integration or use a different Slack workspace",
      "supportText": "Show me how"
    },
    "msCalendar": {
      "connect": "Connect",
      "manage": "Manage",
      "disconnect": {
        "removeIntegration": "Remove Integration",
        "confirmTitle": "You are about to remove the Microsoft 365 integration",
        "confirmMessage": "By taking this action, employees will no longer be able to book or manage existing room bookings via SwipedOn.",
        "confirmQuestion": "This action can not be undone. Are you sure you want to continue?",
        "confirm": "Remove Integration",
        "cancel": "Cancel"
      },
      "description": "Integrate SwipedOn with your meeting room calendar environment in real time. View availability and make bookings from SwipedOn pocket or display panel",
      "errors": {
        "tenantId": {
          "title": "Microsoft Integration error",
          "message": "Connect with a Microsoft account that contains your SwipedOn user email"
        }
      }
    },
    "removeIntegration": "Remove Integration",
    "description": " Connect SwipedOn with your business tools to keep your workplace running smoothly"
  },
  "blog": {
    "path": "/blog",
    "whatsNew": "What’s new",
    "moreUpdates": "More updates"
  },
  "saveDialog": {
    "save": "Save",
    "saved": "Saved",
    "cancel": "Cancel"
  },
  "emptyValue": "—",
  "dateRange": "{start}{divider}{end}",
  "dateRangeAllDay": "{start}{divider}{end}, All day",
  "dateRangeDivider": " - ",
  "dateFormat": "D MMM YYYY",
  "timeFormat": "hh:mm a",
  "nameFormat": "{firstName} {lastName}",
  "spaceCategories": {
    "carpark": "Car Park",
    "desk": "Desk",
    "equipment": "Equipment",
    "vehicle": "Vehicle",
    "room": "Meeting Room"
  },
  "drawerTooltips": {
    "previous": "Previous",
    "next": "Next",
    "close": "Close"
  },
  "format": {
    "fullName": "{firstName} {lastName}",
    "shortName": "{firstName}",
    "timezone": "{timezone} (GMT{timezoneOffset})",
    "timeRange": "{from} - {to}",
    "withTimeZone": "{range} {timeZoneSuffix}",
    "timeZoneSuffix": "({timeZone})"
  },
  "statusFilters": {
    "all": "All",
    "viewAll": "View All",
    "signedIn": "Signed In",
    "remote": "Remote",
    "signedOut": "Signed Out",
    "pending": "Pending",
    "denied": "Denied",
    "collected": "Collected",
    "delivered": "Delivered"
  },
  "adminRoles": {
    "all": "All",
    "companyAdmin": "Company Admin",
    "locationManager": "Location Manager",
    "groupAdmin": "Group Admin",
    "employee": "Employee",
    "superAdmin": "Super Admin",
    "Company Admin": "Company Admin",
    "Location Manager": "Location Manager",
    "Group Admin": "Group Admin",
    "Employee": "Employee",
    "Super Admin": "Super Admin"
  },
  "datePresets": {
    "all": "All",
    "custom": "Custom",
    "today": "Today",
    "tomorrow": "Tomorrow",
    "yesterday": "Yesterday",
    "last7days": "Last 7 days",
    "next7days": "Next 7 days",
    "last30days": "Last 30 days",
    "next30days": "Next 30 days"
  },
  "duration": {
    "yearMonths": "{years}y {months}m",
    "monthDays": "{months}m {days}d",
    "dayHours": "{days}d {hours}h",
    "hourMinutes": "{hours}h {minutes}m",
    "minuteSeconds": "{minutes}m {seconds}s",
    "seconds": "{seconds}s"
  },
  "times": {
    "0000": "12:00am",
    "0100": "1:00am",
    "0200": "2:00am",
    "0300": "3:00am",
    "0400": "4:00am",
    "0500": "5:00am",
    "0600": "6:00am",
    "0700": "7:00am",
    "0800": "8:00am",
    "0900": "9:00am",
    "1000": "10:00am",
    "1100": "11:00am",
    "1200": "12:00pm",
    "1300": "1:00pm",
    "1400": "2:00pm",
    "1500": "3:00pm",
    "1600": "4:00pm",
    "1700": "5:00pm",
    "1800": "6:00pm",
    "1900": "7:00pm",
    "2000": "8:00pm",
    "2100": "9:00pm",
    "2200": "10:00pm",
    "2300": "11:00pm"
  },
  "daysInWeek": {
    "0": {
      "char": "S",
      "short": "Sun",
      "long": "Sunday"
    },
    "1": {
      "char": "M",
      "short": "Mon",
      "long": "Monday"
    },
    "2": {
      "char": "T",
      "short": "Tue",
      "long": "Tuesday"
    },
    "3": {
      "char": "W",
      "short": "Wed",
      "long": "Wednesday"
    },
    "4": {
      "char": "T",
      "short": "Thu",
      "long": "Thursday"
    },
    "5": {
      "char": "F",
      "short": "Fri",
      "long": "Friday"
    },
    "6": {
      "char": "S",
      "short": "Sat",
      "long": "Saturday"
    }
  },
  "upload": {
    "dragAndDrop": "Drag & drop to upload image",
    "orChooseFile": "or choose file"
  },
  "components": {
    "removableEntityList": {
      "remove": "Remove",
      "edit": "Edit"
    }
  },
  "noExpiry": "No expiry",
  "buttons": {
    "cancel": "Cancel",
    "signOutVisitors": "Sign Out Visitors",
    "removeVisitors": "Remove Visitors",
    "remove": "Remove",
    "anonymizeVisits": "Anonymize Visits",
    "signOutVisits": "Sign Out Visits"
  },
  "trialBanner": {
    "expiryTimeRemaining": "You have {remaining} remaining in your SwipedOn trial",
    "expired": "Your trial has expired. Please {subscribeToAPlan} or for any questions {contactUs}",
    "subscribeToAPlan": "subscribe to a plan",
    "contactUs": "contact us",
    "pleaseSubscribe": "Please subscribe to a plan below or",
    "buyNow": "Buy Now",
    "bookDemo": "Book A Demo"
  },
  "countrySelect": {
    "placeholder": "Select",
    "noMatch": "No matching data"
  },
  "inOutImage": {
    "in": "In",
    "out": "Out"
  },
  "methods": {
    "kiosk": "iPad",
    "contactless": "Contactless App",
    "dashboard": "Web Dashboard",
    "mobile": "SwipedOn Pocket",
    "system": "System"
  },
  "authPocket": {
    "description": "Please input the following authentication code into SwipedOn Pocket to complete the sign-in process.",
    "brandLogoAlt": "brand logo",
    "phoneImageAlt": "phone image"
  }
}