<script lang="ts" setup>
import { type AvatarProps, ElAvatar } from "element-plus";
import { computed } from "vue";
import defaultAvatarLight from "@/assets/images/avatar-default-light.svg";
import defaultAvatarDark from "@/assets/images/avatar-default-dark.svg";
import { useColourMode } from "@/composables/colourTheme";
import CoreButton from "@/components/common/CoreButton/CoreButton.vue";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";

export type Props = {
  text?: string;
  icon?: IconProp;
  changeable?: boolean;
  isGreyscale?: boolean;
  isAnonymized?: boolean;
  isArchived?: boolean;
} & Partial<AvatarProps>;

const emit = defineEmits<{
  (e: "delete-photo"): void;
}>();

const props = withDefaults(defineProps<Props>(), {
  changeable: false,
  icon: undefined,
});

const avatarProps = computed<Partial<AvatarProps>>(() => ({
  alt: props.alt,
  fit: props.fit,
  shape: props.shape,
  size: props.size,
}));

const { isDark } = useColourMode();

const source = computed<string | undefined>(() =>
  props.icon || props.text
    ? undefined
    : props.src || (isDark.value ? defaultAvatarDark : defaultAvatarLight)
);
</script>

<template>
  <div :class="['avatar-container', { changeable }]">
    <div
      v-if="changeable"
      class="changeable-overlay"
    >
      <font-awesome-icon :icon="['fas', 'plus']" />
    </div>
    <CoreButton
      v-if="src && changeable"
      circle
      size="small"
      class="btn-remove-photo"
      :icon="['fal', 'trash-alt']"
      icon-size="lg"
      @click.stop="emit('delete-photo')"
    />
    <el-avatar
      v-bind="{ ...avatarProps, ...$attrs, src: source }"
      :class="[
        {
          anonymized: isAnonymized,
          greyscale: isGreyscale,
          archived: isArchived,
        },
      ]"
    >
      <font-awesome-icon
        v-if="icon"
        class="avatar-icon"
        :icon="icon"
      />
      <template v-else>
        {{ text }}
      </template>
    </el-avatar>
  </div>
</template>

<style scoped lang="css">
.so-avatar {
  --so-avatar-text-color: var(--so-avatar-text-color-custom);
  --so-avatar-bg-color: var(--so-avatar-bg-color-custom);
  --so-avatar-text-size: 1.25rem;
  --so-avatar-size: 65px;
}

.so-avatar--small {
  --so-avatar-size: 40px;
}

.so-avatar--large {
  --so-avatar-size: 100px;
}

.so-avatar .avatar-icon {
  font-size: 1.372em;
}

.so-avatar--small .avatar-icon {
  font-size: 0.858em;
}

.so-avatar--large .avatar-icon {
  font-size: 2.11em;
}

.avatar-container {
  position: relative;
  display: inline-flex;
  border-radius: 100%;
}

.avatar-container.changeable .changeable-overlay {
  display: none;
}

.avatar-container.changeable:hover .changeable-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
  background-color: var(--so-overlay-color-lighter);
  border-radius: 100%;
}

.btn-remove-photo {
  position: absolute;
  bottom: -10px;
  left: calc(50% - 12px);
}

.btn-remove-photo:hover {
  transform: scale(1.2);
}

.so-avatar.anonymized {
  background-color: var(--so-avatar-bg-anonymized);
}

.so-avatar.anonymized .avatar-icon {
  color: var(--so-text-color-secondary);
}

:deep(.so-avatar.greyscale img) {
  filter: grayscale(1);
}

:deep(.so-avatar.archived img) {
  opacity: 0.4;
}
</style>
