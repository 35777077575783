import { useColorMode } from "@vueuse/core";
import { computed } from "vue";

const mode = useColorMode({
  attribute: "theme",
});

const myColorMode = computed(() =>
  mode.store.value === "auto" ? mode.system.value : mode.store.value
);

const isDark = computed(() => myColorMode.value === "dark");
const isLight = computed(() => myColorMode.value === "light");

export const useColourMode = () => {
  return {
    isDark,
    isLight,
    mode,
    store: mode.store,
  };
};
