import { useRegionConfig, useSsoConfig } from "@/api/auth/queries";
import { config } from "@/config/envConfig";
import useAuthStore from "@/stores/auth";
import Auth from "@aws-amplify/auth";
import { useLocalStorage } from "@vueuse/core";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const loginErrorCodes = [
  "UserNotConfirmedException",
  "PasswordResetRequiredException",
  "NotAuthorizedException",
  "UserNotFoundException",
  "LimitExceededException",
  "TooManyFailedAttemptsException",
  "TooManyRequestsException",
];

const mfaErrorCodes = [
  "ExpiredCodeException",
  "NotAuthorizedException",
  "CodeMismatchException",
];

export const useLogin = () => {
  const step = ref(0);
  const passwordError = ref("");
  const mfaError = ref("");
  const challengeUser = ref();
  const isSubmitting = ref(false);
  const username = ref<string>();
  const storedUsername = useLocalStorage<string | undefined>(
    "username",
    undefined
  );

  const authStore = useAuthStore();
  const { t } = useI18n();

  const {
    data: regionConfig,
    isInitialLoading: isRegionConfigLoading,
    isError: regionConfigError,
  } = useRegionConfig(username);

  const {
    data: ssoProviders,
    isInitialLoading: isSsoConfigLoading,
    isError: ssoConfigError,
    isSuccess,
  } = useSsoConfig(
    username,
    computed(() => !isRegionConfigLoading.value)
  );

  watch(isSuccess, (_isSuccess) => {
    if (_isSuccess) step.value = 1;
  });

  watch(
    [regionConfigError, ssoConfigError],
    ([_regionConfigError, _ssoConfigError]) => {
      if (_regionConfigError || _ssoConfigError) username.value = undefined;
    }
  );

  watch(regionConfig, (_regionConfig) => {
    if (_regionConfig === undefined) return;
    Auth.configure({
      userPoolId: _regionConfig.cognito.userPool,
      userPoolWebClientId: _regionConfig.cognito.dashboardClient,
      region: _regionConfig.region.aws,
      mandatorySignIn: true,
      authenticationFlowType: "USER_PASSWORD_AUTH",
      cookieStorage: {
        domain: config.COGNITO_COOKIE_DOMAIN,
        path: "/",
        expires: 1,
        secure: true,
      },
    });
  });

  const submitUsername = (_username: string) => {
    username.value = _username;
    storedUsername.value = _username;
  };

  const submitPassword = async (_password: string) => {
    passwordError.value = "";
    if (username.value === undefined) return;
    isSubmitting.value = true;
    try {
      challengeUser.value = await authStore.login({
        email: username.value,
        password: _password,
      });
      if (challengeUser.value !== undefined && challengeUser.value) {
        step.value = 2;
      }
    } catch (error) {
      if (error instanceof Error && loginErrorCodes.includes(error.name)) {
        passwordError.value = t(`forms.login.passwordFormErrors.${error.name}`);
      }
    } finally {
      isSubmitting.value = false;
    }
  };

  const submitMfaCode = async (code: string) => {
    mfaError.value = "";
    isSubmitting.value = true;
    try {
      await authStore.mfaLogin({
        user: challengeUser.value,
        mfa: code,
      });
    } catch (err) {
      if (!(err instanceof Error)) return;
      if (mfaErrorCodes.includes(err.name)) {
        mfaError.value = t(`forms.login.mfa.challengeErrors.${err.name}`);
      }
    } finally {
      isSubmitting.value = false;
    }
  };

  const goBack = () => {
    step.value = 0;
    username.value = undefined;
    passwordError.value = "";
  };

  const microsoftSsoEnabled = computed(() => ssoProviders.value?.microsoft);
  const googleSsoEnabled = computed(() => ssoProviders.value?.google);
  const ssoEnabled = computed(
    () => microsoftSsoEnabled.value || googleSsoEnabled.value
  );
  const isLoading = computed(
    () =>
      isRegionConfigLoading.value ||
      isSsoConfigLoading.value ||
      isSubmitting.value
  );

  return {
    submitUsername,
    submitPassword,
    submitMfaCode,
    microsoftSsoEnabled,
    googleSsoEnabled,
    ssoEnabled,
    isLoading,
    step,
    passwordError,
    mfaError,
    username,
    goBack,
    storedUsername,
  };
};
