import {
  useLocationGroups,
  LocationCategory,
  type LocationGroup,
} from "@/api/locationGroups";
import { computed, watch } from "vue";
import { useLocationStore } from "@/stores/location";
import useAuthStore from "@/stores/auth";
import { useLocalStorage } from "@vueuse/core";

import type { Location } from "@/api/locations";
import { useAuth } from "./auth";

export { LocationCategory };

export function useLocationSelector() {
  const { data: locationGroupData } = useLocationGroups();
  const { isGroupAdmin, isSuperAdmin } = useAuth();

  const locationStore = useLocationStore();
  const authStore = useAuthStore();
  const { isLocationManager } = useAuth();

  const locationGroups = computed(() => locationGroupData.value ?? []);

  watch(locationGroups, (newGroups) => {
    const userLocationId = authStore.authUser?.locationPk;
    const usersLocationGroup = newGroups.find(({ locations }) =>
      locations.find(({ id }) => id === userLocationId)
    );
    const fallbackLocationId = newGroups.filter(
      (group) => group.locations.length > 0
    )[0].locations[0].id;

    if (locationStorage.value.location) {
      if (!usersLocationGroup) {
        locationStorage.value.location =
          isGroupAdmin.value || isSuperAdmin.value
            ? fallbackLocationId
            : userLocationId ?? fallbackLocationId;
      }
    } else if (
      (isGroupAdmin.value || isSuperAdmin.value) &&
      !usersLocationGroup
    ) {
      fallbackLocationId;
    } else {
      locationStorage.value.location = userLocationId ?? fallbackLocationId;
    }
  });

  const locationStorage = useLocalStorage<{
    location?: string;
    company?: string;
    allLocations: boolean;
  }>("location", {
    company: authStore.authUser?.companyPk,
    allLocations: false,
  });

  const locationLocalStorage = computed(() => locationStorage.value);

  const selectedLocation = computed(
    () => (locationStore.selectedLocation as Location) ?? undefined
  );

  const selectedLocationPk = computed(() =>
    locationStore.selectedLocationType === LocationCategory.SINGLE
      ? selectedLocation.value.id ?? undefined
      : undefined
  );

  const selectedLocationGroup = computed<LocationGroup | undefined>(
    () => locationStore.selectedLocationGroup ?? undefined
  );

  const selectedLocationGroupPk = computed(() =>
    locationStore.selectedLocationType !== LocationCategory.ALL
      ? locationStore.selectedLocationGroup?.id ?? undefined
      : undefined
  );

  const isLocationGroupsDataLoaded = computed(
    () => locationGroups.value.length > 0
  );

  const isLocationsLoaded = computed(
    () => !!locationStore.selectedLocationType
  );

  const isAllLocationsSelected = computed(
    () => locationStore.selectedLocationType === LocationCategory.ALL
  );

  const isSingleLocation = computed(
    () => locationStore.selectedLocationType === LocationCategory.SINGLE
  );

  const isGroupLocation = computed(
    () => locationStore.selectedLocationType === LocationCategory.GROUP
  );

  const getLocationByPk = (id?: string): Location | undefined => {
    let locationMatch: Location | undefined;

    locationGroups.value.some(
      (locGroup: { id: string; locations: Location[] }) => {
        const match = locGroup.locations.find((loc: Location) => loc.id === id);
        if (match) {
          locationMatch = {
            ...match,
            locationGroupPk: locGroup.id,
          };
          return true;
        }
      }
    );

    return locationMatch;
  };

  const getLocationById = (id?: number): Location | undefined => {
    let locationMatch: Location | undefined;

    locationGroups.value.some(
      (locGroup: { id: string; locations: Location[] }) => {
        const match = locGroup.locations.find(
          (loc: Location) => loc.numericId === id
        );
        if (match) {
          locationMatch = {
            ...match,
            locationGroupPk: locGroup.id,
          };
          return true;
        }
      }
    );

    return locationMatch;
  };

  const getLocationOrGroupByPk = (id?: string) => {
    const locationGroup = locationGroups.value.find(
      (locGroups: { id: string }) => id === locGroups.id
    );

    if (locationGroup) {
      return locationGroup;
    }

    return getLocationByPk(id);
  };

  const getLocationGroupByLocationId = (
    locationId?: number | string
  ): LocationGroup | undefined => {
    if (!locationId) return;
    return locationGroups.value.find((group) =>
      group.locations.find((location) =>
        typeof locationId === "string"
          ? location.id === locationId
          : location.numericId === locationId
      )
    );
  };

  const locationParams = computed(() => {
    switch (selectedLocationType.value) {
      case LocationCategory.GROUP:
        return { locationGroupPk: selectedLocationGroup.value?.id };
      case LocationCategory.SINGLE:
        return { locationPk: selectedLocation.value.id ?? undefined };
      case LocationCategory.ALL:
      default:
        return {};
    }
  });

  const locationParamZoolander = computed(() => {
    switch (selectedLocationType.value) {
      case LocationCategory.GROUP:
        return { locationGroupId: selectedLocationGroup.value?.id };
      case LocationCategory.SINGLE:
        return { locationId: selectedLocation.value.id ?? undefined };
      case LocationCategory.ALL:
      default:
        return {};
    }
  });

  const selectAllLocations = () => {
    locationStore.setAllLocationsSelected();
    locationStorage.value.allLocations = true;
    locationStorage.value.company = authStore.authUser?.companyPk;
  };

  const selectSingleLocation = (location: Location) => {
    locationStore.setSelectedLocation(location);
    if (!isLocationManager.value) {
      locationStore.setSelectedLocationGroup(
        getLocationOrGroupByPk(location.locationGroupPk) as LocationGroup
      );
    }
    locationStore.setSelectedLocationType(LocationCategory.SINGLE);
  };

  const selectLocationGroup = (locationGroup: LocationGroup) => {
    const location = getLocationByPk(
      locationGroup.locations?.[0]?.id ?? undefined
    );
    if (location) {
      locationStore.setSelectedLocation(location);
    }
    locationStore.setSelectedLocationGroup(
      getLocationOrGroupByPk(locationGroup.id ?? undefined) as LocationGroup
    );
    locationStore.setSelectedLocationType(LocationCategory.GROUP);
  };

  const isLocationGroup = (
    locationOrGroup: Location | LocationGroup
  ): locationOrGroup is LocationGroup => {
    return "locations" in locationOrGroup;
  };

  const selectLocation = (pk?: string) => {
    if (pk === undefined) {
      selectAllLocations();
      return;
    }

    const locationOrGroup = getLocationOrGroupByPk(pk);
    if (!locationOrGroup) {
      return;
    }

    if (isLocationGroup(locationOrGroup)) {
      selectLocationGroup(locationOrGroup);
    } else {
      selectSingleLocation(locationOrGroup);
    }
    locationStorage.value.location = locationOrGroup.id;
    locationStorage.value.company = authStore.authUser?.companyPk;
    locationStorage.value.allLocations = false;
  };

  const selectedLocationType = computed(
    () => locationStore.selectedLocationType
  );

  const companyLocations = computed(() => {
    return locationGroups.value.reduce(
      (locations, group) => locations.concat(group.locations),
      [] as Location[]
    );
  });

  const isUsingMultipleTimezones = computed(() => {
    if (isSingleLocation.value) {
      return false;
    }
    if (isGroupLocation.value) {
      const groupLocations = selectedLocationGroup.value?.locations;
      if (groupLocations) {
        const firstTimezone = groupLocations[0]?.timeZone;
        return groupLocations.some(
          (location) => location.timeZone !== firstTimezone
        );
      }
      return false;
    }

    const firstTimezone = companyLocations.value?.[0]?.timeZone;
    return (
      companyLocations.value?.some(
        (location) => location.timeZone !== firstTimezone
      ) ?? false
    );
  });

  return {
    locationGroups,
    locationParams,
    locationParamZoolander,
    locationLocalStorage,
    companyLocations,
    selectedLocationPk,
    selectedLocation,
    selectedLocationGroup,
    selectedLocationGroupPk,
    selectedLocationType,
    isLocationGroupsDataLoaded,
    isLocationsLoaded,
    isAllLocationsSelected,
    isSingleLocation,
    isGroupLocation,
    isUsingMultipleTimezones,
    getLocationByPk,
    getLocationById,
    getLocationOrGroupByPk,
    getLocationGroupByLocationId,
    selectLocation,
    isLocationGroup,
  };
}
