import useBetaStore from "@/stores/beta";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useAuth } from "@/composables/auth";
import { useSubscription } from "@/composables/subscription";
import { useWhatsNew } from "@/composables/whatsNew";
import { config } from "@/config/envConfig";
import { whatsNewButtonEnabled } from "@/config/featureFlags";
import type { NavItem } from "../types";
import {
  settingsPages,
  locationManagerSettingsPages,
  groupAdminSettingsPages,
  spacesTabs,
  employeesTabs,
  visitorsTabs,
} from "../data";

const removeBetaMenuItems = (
  disabledRoutes: string[],
  navItems: NavItem[]
): NavItem[] => {
  return navItems
    .filter((item) => removeSpacesRoutes(disabledRoutes, item))
    .map((item) => {
      const navItem = { ...item };
      if (item.subNavItems) {
        navItem.subNavItems = {
          group1:
            item.subNavItems?.group1.filter((grp) =>
              removeSpacesRoutes(disabledRoutes, grp)
            ) ?? [],
          group2:
            item.subNavItems?.group2.filter((grp) =>
              removeSpacesRoutes(disabledRoutes, grp)
            ) ?? [],
        };
      }
      return navItem;
    });
};

const removeSpacesRoutes = (routes: string[], item: NavItem) =>
  !routes.includes(item.route ?? "");

export const useMenu = () => {
  const { t } = useI18n();

  const beta = useBetaStore();
  const { isGroupAdmin, isLocationManager, isSuperAdmin } = useAuth();
  const { isProvisioned } = useSubscription();
  const { whatsNewActive } = useWhatsNew();

  const showWhatsNew = computed(
    () =>
      !isSuperAdmin.value &&
      !isProvisioned.value &&
      config.WHATS_NEW_LINK &&
      whatsNewButtonEnabled
  );

  const navItems = computed(() => {
    const navItems: NavItem[] = [
      { titleKey: "nav.home", route: "home", mobileOnly: true },
      {
        titleKey: "nav.visitors",
        route: "visitors",
        tabNavItems: visitorsTabs,
      },
      {
        titleKey: "nav.employees",
        route: "employees",
        tabNavItems: employeesTabs,
      },
      {
        titleKey: "nav.bookings",
        route: "spaces",
        tabNavItems: spacesTabs,
      },
      { titleKey: "nav.deliveries", route: "deliveries" },
    ];

    if (isLocationManager.value) {
      navItems.push({ titleKey: "nav.devices", route: "devices" });
    } else if (isGroupAdmin.value) {
      navItems.push({
        titleKey: "nav.settings",
        route: "settings",
        subNavItems: groupAdminSettingsPages,
      });
    } else {
      navItems.push({
        titleKey: "nav.settings",
        route: "settings",
        subNavItems: settingsPages,
      });
    }

    navItems.push({
      titleKey: "nav.evacuation",
      route: "evacuation",
      icon: ["fal", "siren-on"],
    });

    if (showWhatsNew.value) {
      navItems.push({
        titleKey: "nav.whatsNew",
        icon: ["fal", "bell"],
        address: config.WHATS_NEW_LINK,
        rightAligned: true,
        showDotBadge: whatsNewActive.value,
      });
    }

    navItems.push(
      {
        titleKey: "nav.support",
        icon: ["fal", "circle-info"],
        address: t("urls.support.supportCentre"),
        rightAligned: !showWhatsNew.value,
      },
      {
        titleKey: "nav.logOut",
        route: "logout",
        icon: ["fal", "power-off"],
        mobileOnly: true,
      }
    );

    return navItems;
  });

  const items = computed(() => {
    return removeBetaMenuItems(beta.disabled.routes, navItems.value);
  });

  return {
    menu: items,
  };
};

export const useSubNav = () => {
  const beta = useBetaStore();
  const { isGroupAdmin, isLocationManager } = useAuth();

  const pages = computed(() => {
    if (isLocationManager.value) return locationManagerSettingsPages;
    if (isGroupAdmin.value) return groupAdminSettingsPages;
    return settingsPages;
  });

  const items = computed(() => {
    return {
      ...pages.value,
      group1: removeBetaMenuItems(beta.disabled.routes, pages.value.group1),
      group2: removeBetaMenuItems(beta.disabled.routes, pages.value.group2),
    };
  });

  return {
    subMenu: items,
  };
};
