import useAuthStore from "@/stores/auth";
import { computed } from "vue";
import { useAuth } from "./auth";
import { useGetWhatsNew } from "@/api/whatsnew";

export const useWhatsNew = () => {
  const authStore = useAuthStore();
  const { isSuperAdmin } = useAuth();

  const { data: whatsNewData } = useGetWhatsNew();

  const lastViewedAt = computed(() =>
    isSuperAdmin.value ? null : authStore.authUser?.settings.whats_new_viewed_at
  );

  const whatsNewActive = computed(
    () =>
      !lastViewedAt.value ||
      new Date(lastViewedAt.value) < new Date(whatsNewData.value as string)
  );

  return { whatsNewActive };
};
