import type { MaybeRef } from "@/api/common";
import { useGetSubscription } from "@/api/subscription";
import type { ExpandedFeature } from "@/api/zoolander";
import { computed } from "vue";
import useAccountStore from "@/stores/account";
import type { Location } from "@/api/locations";
import { useGetCompany } from "@/api/companies";
import Constants, {
  PLANS,
  ADDONS,
  ADDON_STATUS,
  PLAN_FEATURE,
} from "@/stores/constants";
import dayjs from "dayjs";
import { getPlanVersion } from "./plans";
import { useRoute } from "vue-router";

export type Feature = {
  bundled: boolean;
  isAddOn: boolean;
  name: string;
};

export const latestPlan = "plan6-0";

export const useSubscription = (staleTime?: MaybeRef<number>) => {
  const { isLoading: isCompanyLoading, data: companyData } = useGetCompany();
  const { isLoading: isSubscriptionLoading, data: subscription } =
    useGetSubscription(staleTime);

  const accountStore = useAccountStore();
  const route = useRoute();

  const plan = computed(() => companyData.value?.subscription?.plan);
  const addOns = computed(() => companyData.value?.addons);
  const isFreeTrial = computed(() => accountStore.subscription.isFreeTrial);
  const isFreemium = computed(() => plan.value?.level === PLANS.FREEMIUM);
  const isLite = computed(() => plan.value?.level === PLANS.LITE);
  const isStarter = computed(() => plan.value?.level === PLANS.STARTER);
  const isBusiness = computed(() => plan.value?.level === PLANS.BUSINESS);
  const isEnterprise = computed(() => plan.value?.level === PLANS.ENTERPRISE);
  const expiry = computed(() => `${accountStore.subscription.expiry}`);
  const isProvisioned = computed(() => {
    const group = plan.value?.group || "";
    const provisionedGroups = Constants.PROVISION_PLAN_GROUPS.split(",") || [];

    return !!(group && provisionedGroups.includes(group));
  });
  const isManualCustomer = computed(
    () => !!companyData.value?.subscription.isManualCustomer
  );

  const addonInductions = computed(() => {
    return addOns.value?.find(
      (addOn) => addOn.name.toLowerCase() === ADDONS.INDUCTIONS
    );
  });

  const addonCatering = computed(() => {
    return addOns.value?.find(
      (addOn) => addOn.name.toLowerCase() === ADDONS.CATERING
    );
  });

  const hasExpiredInductionTrial = computed(() => {
    const subscription = addonInductions.value?.subscription;
    if (!subscription) return false;

    return (
      subscription.status === ADDON_STATUS.TRIAL &&
      dayjs(subscription.trialEndAt).isBefore(dayjs.utc())
    );
  });

  const hasNotUsedInductions = computed(
    () => !isCompanyLoading.value && !addonInductions.value?.subscription
  );

  const hasExpiredCateringTrial = computed(() => {
    const subscription = addonCatering.value?.subscription;
    if (!subscription) return false;

    return (
      subscription.status === ADDON_STATUS.TRIAL &&
      dayjs(subscription.trialEndAt).isBefore(dayjs.utc())
    );
  });

  const currentPlanVersion = computed(() => {
    return Number(
      companyData.value?.subscription.plan.group?.replace(/plan|-\d+/gi, "") ||
        0
    );
  });

  const isNotLatestPlan = computed(
    () =>
      !isFreeTrial.value &&
      currentPlanVersion.value < getPlanVersion(latestPlan)
  );

  const subscriptionFeatures = computed<ExpandedFeature[]>(
    () => subscription.value?.features ?? []
  );

  const resourcesInUse = computed(
    () =>
      (subscription.value?.subscriptionResources?.carParks ?? 0) +
      (subscription.value?.subscriptionResources?.desks ?? 0) +
      (subscription.value?.subscriptionResources?.equipment ?? 0) +
      (subscription.value?.subscriptionResources?.vehicles ?? 0) +
      (subscription.value?.subscriptionResources?.custom ?? 0)
  );

  const roomsInUse = computed(
    () => subscription.value?.subscriptionResources?.rooms ?? 0
  );

  const isFeatureEnabled = (feature: PLAN_FEATURE) => {
    return subscriptionFeatures.value?.some(
      (subFeature) => subFeature.name?.toLowerCase() === feature.toLowerCase()
    );
  };

  const isInductionsEnabled = computed(
    () =>
      subscriptionFeatures.value?.some(
        (feature) => feature.name?.toLowerCase() === ADDONS.INDUCTIONS
      )
  );
  const isCateringEnabled = computed(
    () =>
      subscriptionFeatures.value?.some(
        (feature) => feature.name?.toLowerCase() === ADDONS.CATERING
      )
  );
  const isFieldsByCategoryEnabled = computed(
    () =>
      subscriptionFeatures.value?.some(
        (feature) =>
          feature.name?.toLowerCase() ===
          PLAN_FEATURE.VISITOR_FIELDS_BY_CATEGORY
      )
  );

  const isAgreementsByCategoryEnabled = computed(() => isEnterprise.value);

  const isInductionssByCategoryEnabled = computed(() => isEnterprise.value);

  const isDeliveriesEnabled = computed(
    () =>
      subscriptionFeatures.value?.some(
        (feature) => feature.name?.toLowerCase() === ADDONS.DELIVERIES
      ) || isFreeTrial.value
  );

  const isPurchasingPlanFromTrial = computed(() => {
    const listSelectionPages = [
      "change-plan",
      "payment-details",
      "purchase-addons",
    ];
    const listTrialAccountPages = [
      "account-settings",
      "account-settings-details",
      "purchase-addons",
    ];
    const userPlan =
      isFreeTrial.value && listTrialAccountPages.includes(route.name as string);
    return listSelectionPages.includes(route.name as string) || userPlan;
  });

  const isFeatureBundled = (feature: PLAN_FEATURE) =>
    subscriptionFeatures.value.some(
      ({ bundled, name }: ExpandedFeature) =>
        bundled && name?.toLowerCase() === feature.toLowerCase()
    );

  const totalAddonQuantity = (addonType?: string) => {
    const addOn = subscriptionFeatures.value.filter(
      ({ bundled, isAddOn, name }: ExpandedFeature) =>
        !bundled &&
        isAddOn &&
        (!addonType || name?.toLowerCase() === addonType?.toLowerCase())
    );

    if (addOn.length == 0) return 0;

    return subscriptionFeatures.value
      .filter(
        ({ bundled, isAddOn, name }: ExpandedFeature) =>
          !bundled &&
          isAddOn &&
          (!addonType || name?.toLowerCase() === addonType?.toLowerCase())
      )
      .map((features) => features.quantity ?? 0)
      .reduce((total, feature) => total + feature);
  };

  const isDeliveriesEnabledAtLocation = (location: Location) =>
    isFreeTrial.value || !!location.settings?.deliveries;

  return {
    subscription,
    isFeatureEnabled,
    isFeatureBundled,
    resourcesInUse,
    roomsInUse,
    isInductionsEnabled,
    isCateringEnabled,
    isFieldsByCategoryEnabled,
    isDeliveriesEnabled,
    isPurchasingPlanFromTrial,
    totalAddonQuantity,
    isDeliveriesEnabledAtLocation,
    isLoading: computed(
      () => isCompanyLoading.value || isSubscriptionLoading.value
    ),
    isFreeTrial,
    isFreemium,
    isLite,
    isStarter,
    isBusiness,
    isEnterprise,
    isProvisioned,
    isManualCustomer,
    addOns,
    hasExpiredInductionTrial,
    hasExpiredCateringTrial,
    hasNotUsedInductions,
    isInductionssByCategoryEnabled,
    isAgreementsByCategoryEnabled,
    expiry,
    isExpired: computed(() => accountStore.subscription.isExpired),
    isNotLatestPlan,
  };
};
