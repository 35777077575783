<script setup lang="ts">
import { ElMenu, ElMenuItem, ElDivider } from "element-plus";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

import desktopLogo from "@theme/branding/brand_primary_logo.svg";
import tabletLogo from "@theme/branding/brand_short_logo.svg";
import { useCssVar, useElementSize } from "@vueuse/core";
import { useIsRouteActive } from "@/composables/route";
import { useScreenType } from "@/composables/useScreenType";
import NavItemSettings from "./components/NavItemSettings.vue";
import NavItemIcon from "./components/NavItemIcon.vue";
import NavAvatar from "./components/NavAvatar.vue";
import type { NavItem } from "./types";
import { useLayoutStore } from "@/stores/layout";

export interface Props {
  navItems: NavItem[];
}
const props = defineProps<Props>();

const navColor = useCssVar("--so-color-secondary");

const { t } = useI18n();

const layoutStore = useLayoutStore();
const menu = ref(null);
const { height: menuHeight } = useElementSize(menu);
watch(menuHeight, () => (layoutStore.navbarHeight = menuHeight.value));

const desktopNavItems = computed(() =>
  props.navItems.filter((navItem) => !navItem.mobileOnly)
);

const route = useRoute();
const { isTablet } = useScreenType();

const productLogo = computed(() => (isTablet.value ? tabletLogo : desktopLogo));
</script>

<template>
  <el-menu
    ref="menu"
    router
    class="menu"
    mode="horizontal"
    :default-active="route.name?.toString()"
    :active-text-color="navColor"
    :ellipsis="false"
  >
    <el-menu-item
      :class="{ 'home-menu-option': route.name !== 'home' }"
      index=""
    >
      <router-link
        to="/"
        class="empty-link"
      >
        <img
          v-cy="'swipedonLogo'"
          class="logo"
          :src="productLogo"
          alt=""
        />
        <font-awesome-icon
          size="lg"
          class="home-icon"
          :icon="['fas', 'house']"
        />
      </router-link>
    </el-menu-item>
    <template
      v-for="(navItem, index) in desktopNavItems"
      :key="index"
    >
      <div
        v-if="navItem.rightAligned"
        class="flex-grow"
      />
      <NavItemSettings
        v-if="navItem.subNavItems"
        :nav-item="navItem"
      />
      <NavItemIcon
        v-else-if="navItem.icon"
        :nav-item="navItem"
      />
      <el-menu-item
        v-else
        v-cy="navItem.titleKey"
        :index="navItem.route"
        :route="{ name: navItem.route }"
        :class="[{ 'is-active': useIsRouteActive(navItem) }]"
        :disabled="navItem.disabled"
      >
        {{ t(navItem.titleKey) }}
      </el-menu-item>
      <el-divider
        v-if="navItem.route === 'devices'"
        direction="vertical"
      />
    </template>
    <NavAvatar />
  </el-menu>
</template>

<style scoped lang="scss">
.flex-grow {
  flex-grow: 1;
}

.logo {
  z-index: 1;
  min-width: 140px;
  max-height: 43px;
  opacity: 1;
  transition: var(--so-transition-fade-linear);
  width: 100%;

  @media screen and (max-width: $breakpoint-lg) {
    min-width: auto;
  }
}

.home-menu-option:hover .logo {
  opacity: 0.1;
}
.dark .home-menu-option:hover .logo {
  opacity: 0.15;
}

.home-icon {
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  color: var(--so-menu-active-color);
  opacity: 0;
  transition: var(--so-transition-fade-linear);
}

.home-menu-option:hover .home-icon {
  opacity: 1;
}

.menu {
  /* stylelint-disable-next-line declaration-no-important */
  --so-menu-hover-text-color: var(--so-menu-active-color) !important;
  z-index: 1;
  align-items: center;
  height: 80px;
  border: none;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);

  @media screen and (max-width: 926px) {
    --so-menu-base-level-padding: 10px;
  }
}

.so-menu {
  --so-menu-bg-color: var(--so-color-nav-bg);
}
.so-menu-item {
  font-family: var(--font-family-headings);
  font-weight: 700;
}

.empty-link {
  display: flex;
  align-items: center;
  height: 100%;
}
</style>
