import type { Language } from "./i18n";

const envConfig = {
  BUILD: import.meta.env.VITE_BUILD,
  PRODUCT_NAME: import.meta.env.VITE_PRODUCT_NAME,
  GTM_ID: import.meta.env.VITE_GTM_ID,
  COGNITO_MIN_PASSWORD_LENGTH: import.meta.env.VITE_COGNITO_MIN_PASSWORD_LENGTH,
  SHOW_CREATE_ACCOUNT_BUTTON: import.meta.env.VITE_SHOW_CREATE_ACCOUNT_BUTTON,
  REGISTER_URL: import.meta.env.VITE_REGISTER_URL,
  BLOG_TAG: import.meta.env.VITE_BLOG_TAG,
  THEME: import.meta.env.VITE_THEME,
  REGION_COOKIE_NAME: import.meta.env.VITE_REGION_COOKIE_NAME,
  REST_DOMAIN: import.meta.env.VITE_REST_DOMAIN,
  SENTRY_TRACING_ORIGINS: import.meta.env.VITE_SENTRY_TRACING_ORIGINS,
  BILLING_GRACE_DAYS: import.meta.env.VITE_BILLING_GRACE_DAYS,
  HUBSPOT_POST_FORM_URL: import.meta.env.VITE_HUBSPOT_POST_FORM_URL,
  HUBSPOT_PORTAL_ID: import.meta.env.VITE_HUBSPOT_PORTAL_ID,
  HUBSPOT_FORM_GUID: import.meta.env.VITE_HUBSPOT_FORM_GUID,
  HUBSPOT_FORM_DOWNGRADE: import.meta.env.VITE_HUBSPOT_FORM_DOWNGRADE,
  HUBSPOT_FORM_CANCEL_PLAN: import.meta.env.VITE_HUBSPOT_FORM_CANCEL_PLAN,
  HUBSPOT_FORM_CANCEL_ADDON: import.meta.env.VITE_HUBSPOT_FORM_CANCEL_ADDON,
  HUBSPOT_FORM_REQUEST_LOCATIONS: import.meta.env
    .VITE_HUBSPOT_FORM_REQUEST_LOCATIONS,
  HUBSPOT_FORM_REQUEST_RESOURCES: import.meta.env
    .VITE_HUBSPOT_FORM_REQUEST_RESOURCES,
  HUBSPOT_FORM_REQUEST_ADDONS: import.meta.env.VITE_HUBSPOT_FORM_REQUEST_ADDONS,
  HUBSPOT_WEBSITE_ID: import.meta.env.VITE_HUBSPOT_WEBSITE_ID,
  PARTNERSTACK: import.meta.env.VITE_PARTNERSTACK,
  COGNITO_COOKIE_DOMAIN: import.meta.env.VITE_COGNITO_COOKIE_DOMAIN,
  GOOGLE_MAPS_API_KEY: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  GOOGLE_MAPS_TIMEZONE_API_KEY: import.meta.env
    .VITE_GOOGLE_MAPS_TIMEZONE_API_KEY,
  WEBSOCKET_RECONNECTION_ATTEMPTS: import.meta.env
    .VITE_WEBSOCKET_RECONNECTION_ATTEMPTS,
  WEBSOCKET_RECONNECTION_DELAY: import.meta.env
    .VITE_WEBSOCKET_RECONNECTION_DELAY,
  DISABLE_WEBSOCKETS: import.meta.env.VITE_DISABLE_WEBSOCKETS,
  APP_BLOG_URL: import.meta.env.VITE_APP_BLOG_URL,
  ADMIN_PROVISION_PLAN_GROUPS: import.meta.env.VITE_ADMIN_PROVISION_PLAN_GROUPS,
  DARK_MODE: import.meta.env.VITE_DARK_MODE,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  SENTRY_ENVIRONMENT: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  SENTRY_REPLAY_SAMPLE_RATE: import.meta.env.VITE_SENTRY_REPLAY_SAMPLE_RATE,
  SENTRY_REPLAY_ERROR_SAMPLE_RATE: import.meta.env
    .VITE_SENTRY_REPLAY_ERROR_SAMPLE_RATE,
  SENTRY_REPLAY_MASK_TEXT: import.meta.env.VITE_SENTRY_REPLAY_MASK_TEXT,
  SENTRY_TRACE_SAMPLE_RATE: import.meta.env.VITE_SENTRY_TRACE_SAMPLE_RATE,
  SENTRY_REPORTING_ENABLED: import.meta.env.VITE_SENTRY_REPORTING_ENABLED,
  SENTRY_RELEASE: import.meta.env.VITE_SENTRY_RELEASE,
  MARKER_PROJECT: import.meta.env.VITE_MARKER_PROJECT,
  PRODUCT_FRUITS_PROJECT: import.meta.env.VITE_PRODUCT_FRUITS_PROJECT,
  SUPPORTED_LANGUAGES: import.meta.env.VITE_SUPPORTED_LANGUAGES,
  TEAMS_CLIENT_ID: import.meta.env.VITE_TEAMS_CLIENT_ID,
  TEAMS_TENANT: import.meta.env.VITE_TEAMS_TENANT,
  SLACK_OAUTH_SCOPES: import.meta.env.VITE_SLACK_OAUTH_SCOPES,
  SLACK_CLIENT_ID: import.meta.env.VITE_SLACK_CLIENT_ID,
  STRIPE_PUBLISHABLE_KEY: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
  STRIPE_ACCOUNT: import.meta.env.VITE_STRIPE_ACCOUNT,
  STRIPE_API_VERSION: import.meta.env.VITE_STRIPE_API_VERSION,
  STRIPE_LOCALE: import.meta.env.VITE_STRIPE_LOCALE,
  WHATS_NEW_LINK: import.meta.env.VITE_WHATS_NEW_LINK,
  WHATSNEW_SUPPORTED_LOCALES: import.meta.env.VITE_WHATSNEW_SUPPORTED_LOCALES,
  SKIP_WHATS_NEW_UPDATE_CHECK: import.meta.env.VITE_SKIP_WHATS_NEW_UPDATE_CHECK,
  SHOW_MANAGE_GROUPS_LINKS: import.meta.env.VITE_SHOW_MANAGE_GROUPS_LINKS,
  SWIPEDON_TEAMS_APP_LINK: import.meta.env.VITE_SWIPEDON_TEAMS_APP_LINK,
  INTERCOM_ID: import.meta.env.VITE_INTERCOM_ID,
  INTERCOM_UNSUPPORTED_LOCALES: import.meta.env
    .VITE_INTERCOM_UNSUPPORTED_LOCALES,
  BLACKLISTED_EMAILS: import.meta.env.VITE_BLACKLISTED_EMAILS,
  HIDE_REGION_SELECTOR: import.meta.env.VITE_HIDE_REGION_SELECTOR,
  HUBSPOT_FORM_COMPLETION_URL: import.meta.env.VITE_HUBSPOT_FORM_COMPLETION_URL,
  SUPPORTED_CURRENCIES: import.meta.env.VITE_SUPPORTED_CURRENCIES,
  HIDE_RESOURCES_TAB: import.meta.env.VITE_HIDE_RESOURCES_TAB,
  PROFITWELL_API_TOKEN: import.meta.env.VITE_PROFITWELL_API_TOKEN,
  SPACES_RECURRING_BOOKINGS_HIDDEN: import.meta.env
    .VITE_SPACES_RECURRING_BOOKINGS_HIDDEN,
  VISITOR_BULK_PREREG_HIDDEN: import.meta.env.VITE_VISITOR_BULK_PREREG_HIDDEN,
  MS_ROOMS_CLIENTID: import.meta.env.VITE_MS_ROOMS_CLIENTID,
  CHURNKEY_APP_ID: import.meta.env.VITE_CHURNKEY_APP_ID,
  CHURNKEY_API_KEY: import.meta.env.VITE_CHURNKEY_API_KEY,
  CHURNKEY_MODE: import.meta.env.VITE_CHURNKEY_MODE,
  REFERRER_LANGUAGES: import.meta.env.VITE_REFERRER_LANGUAGES,
  BYPASS_PLAN_RESTRICTIONS: import.meta.env.VITE_BYPASS_PLAN_RESTRICTIONS,
  SSO_ENABLED: import.meta.env.VITE_SSO_ENABLED,
  CAPTCHA_SITEKEY: import.meta.env.VITE_CAPTCHA_SITEKEY,
  MICROSOFT_SSO_CLIENT_ID: import.meta.env.VITE_MICROSOFT_SSO_CLIENT_ID,
  GOOGLE_SSO_CLIENT_ID: import.meta.env.VITE_GOOGLE_SSO_CLIENT_ID,
  POCKET_PREREG_ENABLED: import.meta.env.VITE_POCKET_PREREG_ENABLED,
  EVACUATION_SETTING_HIDDEN: import.meta.env.VITE_EVACUATION_SETTING_HIDDEN,
  VISITOR_RANDOM_SELECTION_HIDDEN: import.meta.env
    .VITE_VISITOR_RANDOM_SELECTION_HIDDEN,
};

export const config = {
  get MS_ROOMS_CLIENTID() {
    return getVal("MS_ROOMS_CLIENTID");
  },
  get MICROSOFT_SSO_CLIENT_ID() {
    return getVal("MICROSOFT_SSO_CLIENT_ID");
  },
  get GOOGLE_SSO_CLIENT_ID() {
    return getVal("GOOGLE_SSO_CLIENT_ID");
  },
  get SSO_REDIRECT_URI() {
    return `${window.location.origin}/login`;
  },
  get STRIPE_PUBLISHABLE_KEY() {
    return getVal("STRIPE_PUBLISHABLE_KEY");
  },
  get CAPTCHA_SITEKEY() {
    return getVal("CAPTCHA_SITEKEY");
  },
  get GTM_ID() {
    return getVal("GTM_ID");
  },
  get INTERCOM_ID() {
    return getVal("INTERCOM_ID");
  },
  get INTERCOM_UNSUPPORTED_LOCALES() {
    return getVal("INTERCOM_UNSUPPORTED_LOCALES") ?? "";
  },
  get STRIPE_ACCOUNT() {
    return getVal("STRIPE_ACCOUNT");
  },
  get STRIPE_API_VERSION() {
    return getVal("STRIPE_API_VERSION") ?? "2022-11-15";
  },
  get STRIPE_LOCALE() {
    return getVal("STRIPE_LOCALE") ?? "auto";
  },
  get MODE() {
    return import.meta.env.MODE;
  },
  get MARKER_PROJECT() {
    return getVal("MARKER_PROJECT");
  },
  get PRODUCT_FRUITS_PROJECT() {
    return getVal("PRODUCT_FRUITS_PROJECT");
  },
  get DEV_MODE() {
    return import.meta.env.DEV;
  },
  get BUILD() {
    return getVal("BUILD", (build) => Number.parseInt(build)) ?? 1;
  },
  get APP_PRODUCT_NAME() {
    return getVal("PRODUCT_NAME") ?? "SwipedOn";
  },
  get COGNITO_MIN_PASSWORD_LENGTH() {
    return getVal("COGNITO_MIN_PASSWORD_LENGTH") ?? 8;
  },
  get SHOW_CREATE_ACCOUNT_BUTTON() {
    return getVal("SHOW_CREATE_ACCOUNT_BUTTON", (val) => val === "true");
  },
  get REGISTER_URL() {
    return getVal("REGISTER_URL") ?? "";
  },
  get BLOG_TAG() {
    return getVal("BLOG_TAG") ?? "";
  },
  get THEME() {
    return getVal("THEME");
  },
  get REGION_COOKIE_NAME() {
    return getVal("REGION_COOKIE_NAME") ?? "swipedon-region";
  },
  get REST_DOMAIN() {
    return getVal("REST_DOMAIN");
  },
  get SENTRY_TRACING_ORIGINS() {
    return (
      getVal("SENTRY_TRACING_ORIGINS", (val) =>
        val.split(",").map((el) => new RegExp(el))
      ) ?? []
    );
  },
  get BILLING_GRACE_DAYS() {
    return getVal("BILLING_GRACE_DAYS", (val) => Number.parseInt(val)) ?? 7;
  },
  get HUBSPOT_POST_FORM_URL() {
    return getVal("HUBSPOT_POST_FORM_URL");
  },
  get HUBSPOT_PORTAL_ID() {
    return getVal("HUBSPOT_PORTAL_ID");
  },
  get HUBSPOT_FORM_GUID() {
    return getVal("HUBSPOT_FORM_GUID");
  },
  get HUBSPOT_FORM_DOWNGRADE() {
    return getVal("HUBSPOT_FORM_DOWNGRADE");
  },
  get HUBSPOT_FORM_CANCEL_PLAN() {
    return getVal("HUBSPOT_FORM_CANCEL_PLAN");
  },
  get HUBSPOT_FORM_CANCEL_ADDON() {
    return getVal("HUBSPOT_FORM_CANCEL_ADDON");
  },
  get HUBSPOT_FORM_REQUEST_LOCATIONS() {
    return getVal("HUBSPOT_FORM_REQUEST_LOCATIONS");
  },
  get HUBSPOT_FORM_REQUEST_RESOURCES() {
    return getVal("HUBSPOT_FORM_REQUEST_RESOURCES");
  },
  get HUBSPOT_FORM_REQUEST_ADDONS() {
    return getVal("HUBSPOT_FORM_REQUEST_ADDONS");
  },
  get HUBSPOT_WEBSITE_ID() {
    return getVal("HUBSPOT_WEBSITE_ID");
  },
  get PARTNERSTACK() {
    return getVal("PARTNERSTACK");
  },
  get COGNITO_COOKIE_DOMAIN() {
    return getVal("COGNITO_COOKIE_DOMAIN");
  },
  get GOOGLE_MAPS_API_KEY() {
    return getVal("GOOGLE_MAPS_API_KEY");
  },
  get WEBSOCKET_RECONNECTION_ATTEMPTS() {
    return getVal("WEBSOCKET_RECONNECTION_ATTEMPTS") ?? 3;
  },
  get WEBSOCKET_RECONNECTION_DELAY() {
    return (
      getVal("WEBSOCKET_RECONNECTION_DELAY", (val) => Number.parseInt(val)) ??
      1000
    );
  },
  get DISABLE_WEBSOCKETS() {
    return getVal("DISABLE_WEBSOCKETS", (val) => val === "true");
  },
  get APP_BLOG_URL() {
    return getVal("APP_BLOG_URL");
  },
  get ADMIN_PROVISION_PLAN_GROUPS() {
    return getVal("ADMIN_PROVISION_PLAN_GROUPS");
  },
  get SENTRY_DSN() {
    return getVal("SENTRY_DSN");
  },
  get SENTRY_ENVIRONMENT() {
    return getVal("SENTRY_ENVIRONMENT");
  },
  get SENTRY_REPLAY_SAMPLE_RATE() {
    return (
      getVal("SENTRY_REPLAY_SAMPLE_RATE", (val) => Number.parseInt(val)) ?? 0
    );
  },
  get SENTRY_TRACE_SAMPLE_RATE() {
    return (
      getVal("SENTRY_TRACE_SAMPLE_RATE", (val) => Number.parseInt(val)) ?? 0
    );
  },
  get SENTRY_REPLAY_ERROR_SAMPLE_RATE() {
    return (
      getVal("SENTRY_REPLAY_ERROR_SAMPLE_RATE", (val) =>
        Number.parseInt(val)
      ) ?? 1
    );
  },
  get SENTRY_REPORTING_ENABLED() {
    return getVal("SENTRY_REPORTING_ENABLED", (val) => val === "true");
  },
  get SENTRY_RELEASE() {
    return getVal("SENTRY_RELEASE");
  },
  get SENTRY_REPLAY_MASK_TEXT() {
    return getVal("SENTRY_REPLAY_MASK_TEXT", (val) => val === "true");
  },
  get SUPPORTED_LANGUAGES() {
    const DEFAULT_LANGUAGES = [{ code: "en-US", description: "US English" }];
    try {
      return (
        getVal("SUPPORTED_LANGUAGES", (val) => {
          return JSON.parse(val).map((lang: Language) => {
            lang.code = lang.code.replace(/-([a-z]+)/g, (code) =>
              code.toUpperCase()
            );
            return lang;
          }) as Language[];
        }) ?? DEFAULT_LANGUAGES
      );
    } catch (err) {
      return DEFAULT_LANGUAGES;
    }
  },
  get TEAMS_CLIENT_ID() {
    return getVal("TEAMS_CLIENT_ID");
  },
  get TEAMS_TENANT() {
    return getVal("TEAMS_TENANT");
  },
  get SLACK_OAUTH_SCOPES() {
    return getVal("SLACK_OAUTH_SCOPES");
  },
  get SLACK_CLIENT_ID() {
    return getVal("SLACK_CLIENT_ID");
  },
  get WHATS_NEW_LINK() {
    return getVal("WHATS_NEW_LINK");
  },
  get WHATSNEW_SUPPORTED_LOCALES() {
    return getVal("WHATSNEW_SUPPORTED_LOCALES");
  },
  get SWIPEDON_TEAMS_APP_LINK() {
    return getVal("SWIPEDON_TEAMS_APP_LINK");
  },
  get SKIP_WHATS_NEW_UPDATE_CHECK() {
    return getVal("SKIP_WHATS_NEW_UPDATE_CHECK", (val) => val === "true");
  },
  get SHOW_MANAGE_GROUPS_LINKS() {
    const value = getVal("SHOW_MANAGE_GROUPS_LINKS") ?? "";
    return {
      groups: value.includes("groups"),
      all: value.includes("all"),
    };
  },
  get BLACKLISTED_EMAILS() {
    return getVal("BLACKLISTED_EMAILS");
  },

  get HIDE_REGION_SELECTOR() {
    return getVal("HIDE_REGION_SELECTOR", (val) => val === "true");
  },

  get HUBSPOT_FORM_COMPLETION_URL() {
    return getVal("HUBSPOT_FORM_COMPLETION_URL");
  },

  get GOOGLE_MAPS_TIMEZONE_API_KEY() {
    return getVal("GOOGLE_MAPS_TIMEZONE_API_KEY");
  },

  get SUPPORTED_CURRENCIES() {
    return getVal("SUPPORTED_CURRENCIES", (val) => val.split(",")) ?? [];
  },

  get HIDE_RESOURCES_TAB() {
    return getVal("HIDE_RESOURCES_TAB", (val) => val === "true");
  },

  get PROFITWELL_API_TOKEN() {
    return getVal("PROFITWELL_API_TOKEN");
  },

  get SPACES_RECURRING_BOOKINGS_HIDDEN() {
    return getVal("SPACES_RECURRING_BOOKINGS_HIDDEN", (val) => val === "true");
  },

  get VISITOR_BULK_PREREG_HIDDEN() {
    return getVal("VISITOR_BULK_PREREG_HIDDEN", (val) => val === "true");
  },

  get CHURNKEY_APP_ID() {
    return getVal("CHURNKEY_APP_ID");
  },

  get CHURNKEY_API_KEY() {
    return getVal("CHURNKEY_API_KEY");
  },

  get CHURNKEY_MODE() {
    return getVal("CHURNKEY_MODE") ?? "test";
  },

  get REFERRER_LANGUAGES() {
    return getVal("REFERRER_LANGUAGES");
  },

  get BYPASS_PLAN_RESTRICTIONS() {
    return getVal("BYPASS_PLAN_RESTRICTIONS", (val) => val === "true");
  },
  get SSO_ENABLED() {
    return getVal("SSO_ENABLED", (val) => val === "true");
  },
  get POCKET_PREREG_ENABLED() {
    return getVal("POCKET_PREREG_ENABLED", (val) => val === "true");
  },
  get EVACUATION_SETTING_HIDDEN() {
    return getVal("EVACUATION_SETTING_HIDDEN", (val) => val === "true");
  },
  get VISITOR_RANDOM_SELECTION_HIDDEN() {
    return getVal("VISITOR_RANDOM_SELECTION_HIDDEN", (val) => val === "true");
  },
};

const getVal = <T = string>(
  key: keyof typeof envConfig,
  transform?: (val: string) => T
): T | undefined => {
  const val = envConfig[key];
  if (val) {
    if (transform) {
      return transform(val);
    }
    return val;
  }
  configWarning(key);
};

const configWarning = (configKey: keyof typeof envConfig) => {
  if (config.MODE !== "production") {
    console.warn(`Unable to retrieve ${configKey} configuration value`);
  }
};
