<script setup lang="ts">
const emit = defineEmits<{
  (e: "transition-finished"): void;
}>();

const enter = (el: Element) => {
  const element = el as HTMLElement;
  element.style.setProperty("height", "auto");
  const { height } = getComputedStyle(element);
  element.style.setProperty("height", "0");

  // Force repaint to make sure the animation is triggered correctly.
  getComputedStyle(element).height;

  requestAnimationFrame(() => {
    element.style.setProperty("height", height);
  });
};

const afterEnter = (el: Element) => {
  const element = el as HTMLElement;
  element.style.setProperty("height", "auto");
  emit("transition-finished");
};

const beforeLeave = (el: Element) => {
  const element = el as HTMLElement;
  const { height } = getComputedStyle(element);
  element.style.setProperty("height", height);
};

const leave = (el: Element) => {
  const element = el as HTMLElement;
  requestAnimationFrame(() => {
    element.style.setProperty("height", "0");
  });
};
</script>

<template>
  <Transition
    v-bind="$attrs"
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @leave="leave"
  >
    <slot />
  </Transition>
</template>

<style lang="scss" scoped>
// If we need this to work for a TransitionGroup then check out swipedon-dashboard for the magic
.v-enter-active,
.v-leave-active:not(.v-over) {
  transition-duration: 0.5s;
  transition-timing-function: ease;
}

.v-enter-to,
.v-leave-from {
  overflow: hidden;
}

.v-enter-from,
.v-leave-to {
  height: 0;
  overflow: hidden;
}
</style>
