<script setup lang="ts">
import useAuthStore from "@/stores/auth";
import ConfirmDialog from "@/components/common/ConfirmDialog/ConfirmDialog.vue";
import CoreButton from "@/components/common/CoreButton/CoreButton.vue";
import CoreSaveConfirm from "@/components/common/CoreSaveConfirm/CoreSaveConfirm.vue";
import { useRootStore } from "@/stores";
import { useValidation } from "@/composables/validation";
import { useI18n } from "vue-i18n";
import { reactive, ref, computed } from "vue";
import { CONFIRM } from "@/stores/constants";
import {
  ElAlert,
  ElForm,
  ElFormItem,
  ElInput,
  type FormRules,
  type FormInstance,
} from "element-plus";

const emit = defineEmits<{
  (e: "closed"): void;
}>();

const rootStore = useRootStore();
const { t } = useI18n();
const authStore = useAuthStore();
const mfaRequired = computed(() => authStore.mfaPreference !== "NOMFA");

const updatePasswordLoading = ref(false);
const passwordFormRef = ref<FormInstance>();
const { validateRange } = useValidation();

const password = reactive({
  currentPassword: "",
  newPassword: "",
});

const errors = reactive({
  passwordMatch: false,
  currentPasswordLimit: false,
});

const saveConfirms = reactive({
  passwordConfirmed: CONFIRM.SAVED,
});

const resetAndCloseDialog = () => {
  authStore.changePasswordDialogVisible = false;
  passwordFormRef.value?.clearValidate();
  password.currentPassword = "";
  password.newPassword = "";
  saveConfirms.passwordConfirmed = CONFIRM.SAVED;
};

const handleClose = (done: () => void) => {
  resetAndCloseDialog();
  done();
};

const cancelAction = () => {
  resetAndCloseDialog();
  emit("closed");
};

const resetErrors = () => {
  errors.passwordMatch = false;
  errors.currentPasswordLimit = false;
  passwordFormRef.value?.clearValidate();
};

const passwordRules = reactive<FormRules>({
  currentPassword: [
    {
      required: true,
      message: () => t("validation.required"),
      trigger: "blur",
    },
    {
      validator: (_rule, _value, callback) => {
        if (errors.passwordMatch) {
          resetErrors();
          callback(t("validation.currentPasswordError"));
        }
        callback();
      },
    },
    {
      validator: (_rule, _value, callback) => {
        if (errors.currentPasswordLimit) {
          resetErrors();
          callback(t("validation.passwordLimit"));
        }
        callback();
      },
    },
  ],
  newPassword: [
    {
      required: true,
      message: () => t("validation.required"),
      trigger: "blur",
    },
    validateRange(8, 30),
    {
      pattern: /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/,
      message: () => t("validation.passwordFormat"),
      trigger: "blur",
    },
  ],
});

const updatePassword = async () => {
  await passwordFormRef.value?.clearValidate();
  updatePasswordLoading.value = true;
  saveConfirms.passwordConfirmed = CONFIRM.PENDING;

  const { success, error } = await authStore.updatePassword(
    password.currentPassword,
    password.newPassword,
    mfaRequired.value
  );

  updatePasswordLoading.value = false;

  if (success) {
    saveConfirms.passwordConfirmed = CONFIRM.SUCCESS;
    password.currentPassword = "";
    password.newPassword = "";

    resetAndCloseDialog();
    if (mfaRequired.value) {
      authStore.logout();
    }

    return;
  }

  if (error) {
    saveConfirms.passwordConfirmed = CONFIRM.FAILURE;
    switch (error.code) {
      case "LimitExceededException":
        errors.currentPasswordLimit = true;
        passwordFormRef.value?.validate().catch(() => {
          return;
        });
        break;
      case "NotAuthorizedException":
        errors.passwordMatch = true;
        passwordFormRef.value?.validate().catch(() => {
          return;
        });
        break;
      case "InvalidParameterException":
        if (!error.message.includes("previousPassword")) {
          rootStore.handleException({ error });
        }
        break;
      default:
        if (!error.handledGlobally) {
          rootStore.handleException({ error });
        }
    }
  }
};

const disablePasswordUpdate = computed(
  () =>
    password.currentPassword.length === 0 || password.newPassword.length === 0
);
</script>

<template>
  <ConfirmDialog
    :visible="authStore.changePasswordDialogVisible"
    :title="t('nav.profile.fields.changePasswordHeader')"
    @before-close="handleClose"
    @close="emit('closed')"
  >
    <el-form
      ref="passwordFormRef"
      :model="password"
      :rules="passwordRules"
      label-width="120px"
      label-position="top"
      hide-required-asterisk
      @submit.prevent
    >
      <el-form-item
        prop="currentPassword"
        :label="t('nav.profile.fields.currentPassword')"
      >
        <el-input
          v-model="password.currentPassword"
          show-password
        />
      </el-form-item>

      <el-form-item
        prop="newPassword"
        :label="t('nav.profile.fields.newPassword')"
      >
        <el-input
          v-model="password.newPassword"
          show-password
          maxlength="30"
        />
      </el-form-item>
      <el-form-item>
        <b>{{ t("forms.login.strongPasswordNotice.requirements") }}</b>
        <p>- {{ t("forms.login.strongPasswordNotice.conditions[0]") }}</p>
        <p>- {{ t("forms.login.strongPasswordNotice.conditions[1]") }}</p>
      </el-form-item>
      <el-form-item v-if="mfaRequired">
        <el-alert
          show-icon
          type="info"
          class="info"
          :closable="false"
          :title="t('nav.profile.fields.sessionEndWarningMfa')"
        />
      </el-form-item>
      <el-form-item v-else>
        <el-alert
          show-icon
          type="info"
          class="info"
          :closable="false"
          :title="t('nav.profile.fields.sessionEndWarning')"
        />
      </el-form-item>
    </el-form>
    <template #actions>
      <CoreButton
        type="default"
        @click="cancelAction"
      >
        {{ t("multiFactorDialog.cancel") }}
      </CoreButton>
      <CoreButton
        type="primary"
        class="password-button"
        :disabled="disablePasswordUpdate"
        @click="updatePassword"
      >
        {{ t("nav.profile.buttons.updatePassword") }}
        <CoreSaveConfirm
          class="so-icon--right"
          :status="saveConfirms.passwordConfirmed"
        />
      </CoreButton>
    </template>
  </ConfirmDialog>
</template>

<style scoped lang="css">
.dialog-actions {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media only screen and (min-width: 480px) {
  .dialog-actions {
    flex-direction: row;
    justify-content: right;
  }
}

.popover-section {
  padding: 7px 0;
  border-top: 1px solid var(--so-border-color);
}

.popover-section + .popover-section {
  border-top-width: 0;
}
</style>
