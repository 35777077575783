import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { i18n } from "@/config";
import { SUPPORTED_LOCALES } from "@/config/i18n";
import { MINUTE_IN_MS } from "@/stores/constants";

dayjs.extend(relativeTime);

export type DateRange = {
  sd: Date;
  ed: Date;
  st: Date;
  et: Date;
};

export const dateStringFormats: Record<string, Record<string, string>> = {
  "en-us": {
    SHORT_FORMAT: "h:mma, MMM DD",
    LONG_FORMAT: "h:mma, MMM DD YYYY",
  },
};

const defaultDatetimeFormat = {
  SHORT_FORMAT: {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  },
  LONG_FORMAT: {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  },
  SHORT_FORMAT_WITHOUT_TIME: {
    month: "short",
    day: "numeric",
  },
  LONG_FORMAT_WITHOUT_TIME: {
    year: "numeric",
    month: "short",
    day: "numeric",
  },
  TIME_ONLY: {
    hour: "numeric",
    minute: "numeric",
  },
};

export const datetimeFormats = SUPPORTED_LOCALES.reduce(
  (result, item) => ({ ...result, [item.code]: defaultDatetimeFormat }),
  {}
);

export const parseDateString = (
  dateString?: string | Date,
  convertToTz = false
) => {
  const processedDateString = convertToTz
    ? dayjs(dateString)
    : dayjs.tz(dateString);

  return processedDateString.tz("utc").toDate();
};

export const formatDate = (date: string | undefined) => {
  if (!date) return;
  return dayjs(date).format("LL");
};

export const createDateFromParts = (date: Date, time: Date) => {
  const dayjsTime = dayjs(time);
  return dayjs(date)
    .set("hour", dayjsTime.hour())
    .set("minute", dayjsTime.minute())
    .set("second", 0)
    .set("millisecond", 0);
};

export const currentDateInTimezone = (
  timezone?: string,
  isStartOfDay = true
) => {
  if (!timezone || timezone == "") {
    timezone = "utc";
  }
  const localDate = isStartOfDay
    ? dayjs().tz(timezone).startOf("day")
    : dayjs().tz(timezone);
  return new Date(localDate.year(), localDate.month(), localDate.date());
};

export const currentDateTimeInTimezone = (timezone?: string) => {
  if (!timezone || timezone == "") {
    timezone = "utc";
  }
  return dayjs().tz(timezone).toDate();
};

export const convert12HourTimeToDate = (time: string) =>
  dayjs(`${new Date().toDateString()} ${time}`).toDate();

export const formatExpiredDate = (expiredAt: string, timezone: string) =>
  dayjs(expiredAt).tz(timezone).format("D MMMM, YYYY");

export const convertDateTimeToRelativeTimeFromNow = (datetime: string) => {
  const utcDate = dayjs(`${datetime.replace(" ", "T")}Z`);

  return dayjs(utcDate).fromNow();
};

export const formatTimezone = (timezone: string) => {
  let timezoneOffset;
  try {
    timezoneOffset = dayjs().tz(timezone).format("ZZ");
  } catch {
    timezoneOffset = "";
  }

  return i18n.global.t("format.timezone", {
    timezone: timezone.replaceAll("_", " "),
    timezoneOffset,
  });
};

export const getTimeZoneShortCode = (timeZone: string) => {
  const fmt = new Intl.DateTimeFormat(i18n.global.locale, {
    timeZone,
    timeZoneName: "short",
  });

  return (
    fmt.formatToParts(new Date()).find((dt) => dt.type === "timeZoneName")
      ?.value ?? ""
  );
};

export const localeFormatTimeRange = (
  start: Date,
  end: Date,
  locale: string,
  timeZone: string,
  shiftZone = false
) => {
  const formatOptions =
    defaultDatetimeFormat.TIME_ONLY as Intl.DateTimeFormatOptions;

  const startTimeString = new Intl.DateTimeFormat(locale, {
    ...formatOptions,
  }).format(shiftZone ? shiftTimezone(start, timeZone) : start);

  const endTimeString = new Intl.DateTimeFormat(locale, {
    ...formatOptions,
  }).format(shiftZone ? shiftTimezone(end, timeZone) : end);

  const timeRange = i18n.global.t("format.timeRange", {
    from: startTimeString,
    to: endTimeString,
  });

  return timeZone
    ? i18n.global.t("format.withTimeZone", {
        range: timeRange,
        timezone: getTimeZoneShortCode(timeZone),
      })
    : timeRange;
};

export const getTimezoneOffsetHours = (timezone: string) => {
  try {
    return dayjs().tz(timezone).utcOffset() / 60;
  } catch (error) {
    return undefined;
  }
};

export const getComputerTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const shiftTimezone = (dateTime: Date, timezone: string) =>
  dayjs(dateTime).tz(timezone).tz(getComputerTimezone(), true).toDate();

export const revertTimezone = (shiftedDateTime: Date, timezone: string) =>
  dayjs(shiftedDateTime).tz(timezone, true).toDate();

export const roundUpMinutes = (date: Date, minutes: number) => {
  const ms = minutes * MINUTE_IN_MS;
  return new Date(Math.ceil(date.getTime() / ms) * ms);
};

export const nowRoundedFiveMinutes = () =>
  roundUpMinutes(dayjs().set("seconds", 0).set("milliseconds", 0).toDate(), 5);
