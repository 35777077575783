import { watch } from "vue";
import * as Sentry from "@sentry/vue";
import { useAuth } from "./auth";

export function useSentryUserTracking() {
  const { authUser } = useAuth();

  watch(authUser, (user) => {
    if (!user) {
      Sentry.setUser(null);
      return;
    }
    if (user.pk) {
      Sentry.setTag("user", user.pk);
      Sentry.setUser({ id: user.pk });
    }
    if (user.companyName) {
      Sentry.setTag("company.name", user.companyName);
    }
    if (user.companyPk) {
      Sentry.setTag("company.pk", user.companyPk);
    }
    if (user.locationName) {
      Sentry.setTag("location.name", user.locationName);
    }
    if (user.locationPk) {
      Sentry.setTag("location.pk", user.locationPk);
    }
    if (user.assignedRoles?.length) {
      Sentry.setTag("user.role", user.assignedRoles.join(","));
    }
  });
}
