import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowDown,
  faArrowLeft,
  faArrowRightFromBracket,
  faArrowUp,
  faAt,
  faBatteryBolt,
  faBell,
  faBicycle,
  faBox,
  faBoxTaped,
  faCabinetFiling,
  faCamcorder,
  faCars,
  faCarBuilding,
  faChairOffice,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faCircleInfo,
  faCirclePlus,
  faCircleNotch,
  faCircleQuestion,
  faCircleParking,
  faClipboardList,
  faCouch,
  faDownload,
  faEdit,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faEyeSlash,
  faGarage,
  faGear,
  faIdCardClip,
  faInfoCircle,
  faKey,
  faLaptop,
  faList,
  faLocationPlus,
  faLock,
  faMagnifyingGlass,
  faMapLocation,
  faMobile,
  faMobileButton,
  faMotorcycle,
  faPalette,
  faPauseCircle,
  faPencilSquare,
  faPowerOff,
  faScrewdriverWrench,
  faSirenOn,
  faSliders,
  faSquareCheck,
  faTablet,
  faTags,
  faTennisBall,
  faText,
  faTimes,
  faTimesCircle,
  faTrashCan,
  faTruckMoving,
  faUpload,
  faUser,
  faUserClock,
  faUserCheck,
  faUsers,
  faUserPlus,
  faDrawSquare,
  faRectangleList,
  faLocationDot,
  faCircleArrowLeft,
  faCircleArrowRight,
  faLocationPin,
  faFileLines,
  faFileCheck,
  faFileImage,
  faFileVideo,
  faXmark,
  faQrcode,
  faClipboardListCheck,
  faChevronUp,
  faCarSide,
  faCalendarCheck,
  faBirthdayCake,
  faListCheck,
  faSuitcaseMedical,
  faRotateRight,
  faBoxArchive,
  faDisplay,
  faAngleLeft,
  faAngleRight,
  faImage,
  faCog,
  faEye,
  faHouseChimney,
  faBoxOpen,
  faMugSaucer,
  faPencil,
  faAddressBook,
  faClipboardCheck,
  faMessageLines,
  faArrowRightToBracket,
  faSignature,
  faDiagramNext,
  faCircleUser,
  faShieldCheck,
} from "@fortawesome/pro-light-svg-icons";

import {
  faText as faThinText,
  faFilePdf,
} from "@fortawesome/pro-thin-svg-icons";

import {
  faBars,
  faChevronDown as faChevronDownRegular,
  faCreditCard,
  faXmarkLarge,
} from "@fortawesome/pro-regular-svg-icons";

import {
  faCheck as faCheckSolid,
  faCircleCheck as faCircleCheckSolid,
  faGripDots,
  faHouse,
  faUserGroup as faUserGroupSolid,
  faCircleInfo as faCircleInfoSolid,
  faPlus as faPlusSolid,
  faMinus as faMinusSolid,
  faLocationPlus as faLocationPlusSolid,
  faMap,
  faMapMarkerCheck,
  faLockKeyhole,
  faLockKeyholeOpen,
} from "@fortawesome/pro-solid-svg-icons";

const configure = () => {
  library.add(
    faArrowDown,
    faArrowLeft,
    faArrowRightFromBracket,
    faArrowUp,
    faAt,
    faBatteryBolt,
    faBars,
    faBell,
    faBicycle,
    faBirthdayCake,
    faBox,
    faBoxTaped,
    faCabinetFiling,
    faCamcorder,
    faCars,
    faCarBuilding,
    faCarSide,
    faChairOffice,
    faCheck,
    faCheckCircle,
    faCheckSolid,
    faCirclePlus,
    faChevronDown,
    faCircleCheckSolid,
    faChevronDownRegular,
    faChevronRight,
    faChevronLeft,
    faCircleInfo,
    faCircleInfoSolid,
    faCircleNotch,
    faCircleQuestion,
    faCirclePlus,
    faCircleParking,
    faClipboardList,
    faCouch,
    faCreditCard,
    faDownload,
    faEdit,
    faEnvelope,
    faExclamationCircle,
    faExclamationTriangle,
    faEyeSlash,
    faGarage,
    faGear,
    faGripDots,
    faHouse,
    faIdCardClip,
    faInfoCircle,
    faKey,
    faList,
    faListCheck,
    faMagnifyingGlass,
    faMapLocation,
    faMobileButton,
    faLaptop,
    faLocationPlus,
    faLocationPlusSolid,
    faLock,
    faMobile,
    faMotorcycle,
    faPalette,
    faPauseCircle,
    faPencilSquare,
    faPowerOff,
    faScrewdriverWrench,
    faSirenOn,
    faSliders,
    faSquareCheck,
    faSuitcaseMedical,
    faTablet,
    faTags,
    faTennisBall,
    faText,
    faThinText,
    faTimes,
    faTimesCircle,
    faTrashCan,
    faTruckMoving,
    faUpload,
    faUser,
    faUserClock,
    faUserCheck,
    faUsers,
    faUserGroupSolid,
    faUserPlus,
    faXmarkLarge,
    faDrawSquare,
    faRectangleList,
    faLocationDot,
    faCircleArrowLeft,
    faCircleArrowRight,
    faLocationPin,
    faPlusSolid,
    faMinusSolid,
    faFileLines,
    faFileCheck,
    faFileImage,
    faImage,
    faFilePdf,
    faFileVideo,
    faXmark,
    faQrcode,
    faClipboardListCheck,
    faQrcode,
    faChevronUp,
    faMap,
    faCalendarCheck,
    faRotateRight,
    faBoxArchive,
    faMapMarkerCheck,
    faDisplay,
    faAngleLeft,
    faAngleRight,
    faCog,
    faEye,
    faHouseChimney,
    faBoxOpen,
    faMugSaucer,
    faPencil,
    faAddressBook,
    faClipboardCheck,
    faMessageLines,
    faLockKeyhole,
    faLockKeyholeOpen,
    faArrowRightToBracket,
    faSignature,
    faDiagramNext,
    faCircleUser,
    faShieldCheck
  );
};

export default { configure };
