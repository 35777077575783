import useAuthStore from "@/stores/auth";
import { watch, type Ref } from "vue";
import { useRouter } from "vue-router";

export const useMfaRedirect = (mfaEnforced: Ref<boolean | undefined>) => {
  const router = useRouter();
  const authStore = useAuthStore();

  const removeGuard = router.beforeEach((guard) => {
    if (
      guard.name !== "mfa-setup" &&
      mfaEnforced.value &&
      authStore.mfaPreference === "NOMFA"
    ) {
      router.push({ name: "mfa-setup" });
    }
  });

  watch(
    mfaEnforced,
    (_mfaEnforced) => {
      if (_mfaEnforced === false) {
        removeGuard();
        return;
      }
      if (_mfaEnforced && authStore.mfaPreference === "NOMFA") {
        router.push({ name: "mfa-setup" });
      }
    },
    { immediate: true }
  );
};
