import polyfills from "@/polyfills";
import axios from "./axios/setup";
import fontAwesome from "./fontAwesome";
import { setupI18n } from "./i18n";
import { QueryClient } from "@tanstack/vue-query";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjsRelativeTime from "dayjs/plugin/relativeTime";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import duration from "dayjs/plugin/duration";
import markerSDK from "@marker.io/browser";
import { config } from "./envConfig";
import { configureStripe } from "./stripe/init";
import en from "@/locales/en-US.json";
import { datetimeFormats } from "@/utils/dateTimeFormats";

const configureAxios = () => {
  axios.configure();
};

const configureFontAwesome = () => {
  fontAwesome.configure();
};

const configurePolyfills = () => {
  polyfills.configure();
};

export const i18n = setupI18n({
  legacy: false,
  locale: window.navigator.language,
  fallbackLocale: "en",
  messages: {
    en,
  },
  fallbackWarn: false,
  missingWarn: false,
  datetimeFormats,
});

export const getVueQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        retry: 2,
        staleTime: 20000,
      },
    },
  });
};

const configureDayJs = () => {
  dayjs.extend(localeData);
  dayjs.extend(localizedFormat);
  dayjs.extend(customParseFormat);
  dayjs.extend(dayjsRelativeTime);
  dayjs.extend(duration);
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(isSameOrBefore);
};

const configureMarkerIo = async () => {
  if (config.MODE === "production" && config.MARKER_PROJECT) {
    await markerSDK.loadWidget({
      project: config.MARKER_PROJECT,
    });
  }
};

const configureApp = async () => {
  configurePolyfills();
  configureAxios();
  configureFontAwesome();
  configureDayJs();
  await configureMarkerIo();
  await configureStripe();
};

export default configureApp;
