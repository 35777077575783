export const tags = {
  zoolander: {
    spaces: "spaces",
    bookings: "bookings",
    recurringBookings: "recurringBookings",
    spaceRecurringAvailability: "spaceRecurringAvailability",
    insights: "insights",
    spaceResources: "spaceResources",
    spaceResourceRestrictions: "spaceResourceRestrictions",
    spaceCategories: "spaceCategories",
    roomCategories: "roomCategories",
    features: "features",
    inductions: "inductions",
    departments: "departments",
    customer: "customer",
    zones: "zones",
    visitorInductions: "visitorInductions",
    subscription: "subscription",
    plan: "plan",
    preregistrationInductions: "preregistrationInductions",
    rooms: "rooms",
    calendarIntegration: "calendarIntegration",
    movements: "movements",
    movementFields: "movementFields",
    visits: "visits",
    visitFields: "visitFields",
    employeeCounts: "employeeCounts",
  },
  platform: {
    agreements: "agreements",
    locations: "locations",
    locationGroups: "locationGroups",
    employees: "employees",
    employeeMovements: "employeeMovements",
    company: "platformCompany",
    customFields: "customFields",
    screensavers: "screensavers",
    statuses: "statuses",
    devices: "devices",
    deviceLogos: "deviceLogos",
    preregistrations: "preregistrations",
    visitorCategories: "visitorCategories",
    branding: "branding",
    visitorFields: "visitorFields",
    visitors: "visitors",
    visits: "visits", //done
    evacuation: "evacuation",
    employeeArchive: "employeeArchive",
  },
  rest: {
    company: "restCompany",
    addonList: "addonList",
    integrationConfig: "integrationConfig",
    integrationGroups: "integrationGroups",
    integrationStatus: "integrationStatus",
    integrationLocations: "integrationLocations",
    integrationSync: "integrationSync",
    slack: "slack",
    employeeQuestions: "employeeQuestions",
    customer: "customer",
    transaction: "transaction",
    teams: "teams",
    whatsNew: "whatsNew",
    deliveries: "deliveries",
    billing: "billing",
    regions: "regions",
  },
};
