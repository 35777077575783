const dateLocales: Record<string, () => Promise<ILocale>> = {
  af: async () => await import("dayjs/locale/af"),
  am: async () => await import("dayjs/locale/am"),
  "ar-dz": async () => await import("dayjs/locale/ar-dz"),
  "ar-iq": async () => await import("dayjs/locale/ar-iq"),
  "ar-kw": async () => await import("dayjs/locale/ar-kw"),
  "ar-ly": async () => await import("dayjs/locale/ar-ly"),
  "ar-ma": async () => await import("dayjs/locale/ar-ma"),
  "ar-sa": async () => await import("dayjs/locale/ar-sa"),
  "ar-tn": async () => await import("dayjs/locale/ar-tn"),
  ar: async () => await import("dayjs/locale/ar"),
  az: async () => await import("dayjs/locale/az"),
  be: async () => await import("dayjs/locale/be"),
  bg: async () => await import("dayjs/locale/bg"),
  bi: async () => await import("dayjs/locale/bi"),
  bm: async () => await import("dayjs/locale/bm"),
  "bn-bd": async () => await import("dayjs/locale/bn-bd"),
  bn: async () => await import("dayjs/locale/bn"),
  bo: async () => await import("dayjs/locale/bo"),
  br: async () => await import("dayjs/locale/br"),
  bs: async () => await import("dayjs/locale/bs"),
  ca: async () => await import("dayjs/locale/ca"),
  cs: async () => await import("dayjs/locale/cs"),
  cv: async () => await import("dayjs/locale/cv"),
  cy: async () => await import("dayjs/locale/cy"),
  da: async () => await import("dayjs/locale/da"),
  "de-at": async () => await import("dayjs/locale/de-at"),
  "de-ch": async () => await import("dayjs/locale/de-ch"),
  de: async () => await import("dayjs/locale/de"),
  dv: async () => await import("dayjs/locale/dv"),
  el: async () => await import("dayjs/locale/el"),
  "en-au": async () => await import("dayjs/locale/en-au"),
  "en-ca": async () => await import("dayjs/locale/en-ca"),
  "en-gb": async () => await import("dayjs/locale/en-gb"),
  "en-ie": async () => await import("dayjs/locale/en-ie"),
  "en-il": async () => await import("dayjs/locale/en-il"),
  "en-in": async () => await import("dayjs/locale/en-in"),
  "en-nz": async () => await import("dayjs/locale/en-nz"),
  "en-sg": async () => await import("dayjs/locale/en-sg"),
  "en-tt": async () => await import("dayjs/locale/en-tt"),
  en: async () => await import("dayjs/locale/en"),
  eo: async () => await import("dayjs/locale/eo"),
  "es-do": async () => await import("dayjs/locale/es-do"),
  es: async () => await import("dayjs/locale/es"),
  et: async () => await import("dayjs/locale/et"),
  eu: async () => await import("dayjs/locale/eu"),
  fa: async () => await import("dayjs/locale/fa"),
  fi: async () => await import("dayjs/locale/fi"),
  fo: async () => await import("dayjs/locale/fo"),
  "fr-ca": async () => await import("dayjs/locale/fr-ca"),
  "fr-ch": async () => await import("dayjs/locale/fr-ch"),
  fr: async () => await import("dayjs/locale/fr"),
  fy: async () => await import("dayjs/locale/fy"),
  ga: async () => await import("dayjs/locale/ga"),
  gd: async () => await import("dayjs/locale/gd"),
  gl: async () => await import("dayjs/locale/gl"),
  "gom-latn": async () => await import("dayjs/locale/gom-latn"),
  gu: async () => await import("dayjs/locale/gu"),
  he: async () => await import("dayjs/locale/he"),
  hi: async () => await import("dayjs/locale/hi"),
  hr: async () => await import("dayjs/locale/hr"),
  ht: async () => await import("dayjs/locale/ht"),
  hu: async () => await import("dayjs/locale/hu"),
  "hy-am": async () => await import("dayjs/locale/hy-am"),
  id: async () => await import("dayjs/locale/id"),
  is: async () => await import("dayjs/locale/is"),
  "it-ch": async () => await import("dayjs/locale/it-ch"),
  it: async () => await import("dayjs/locale/it"),
  ja: async () => await import("dayjs/locale/ja"),
  jv: async () => await import("dayjs/locale/jv"),
  ka: async () => await import("dayjs/locale/ka"),
  kk: async () => await import("dayjs/locale/kk"),
  km: async () => await import("dayjs/locale/km"),
  kn: async () => await import("dayjs/locale/kn"),
  ko: async () => await import("dayjs/locale/ko"),
  ku: async () => await import("dayjs/locale/ku"),
  ky: async () => await import("dayjs/locale/ky"),
  lb: async () => await import("dayjs/locale/lb"),
  lo: async () => await import("dayjs/locale/lo"),
  lt: async () => await import("dayjs/locale/lt"),
  lv: async () => await import("dayjs/locale/lv"),
  me: async () => await import("dayjs/locale/me"),
  mi: async () => await import("dayjs/locale/mi"),
  mk: async () => await import("dayjs/locale/mk"),
  ml: async () => await import("dayjs/locale/ml"),
  mn: async () => await import("dayjs/locale/mn"),
  mr: async () => await import("dayjs/locale/mr"),
  "ms-my": async () => await import("dayjs/locale/ms-my"),
  ms: async () => await import("dayjs/locale/ms"),
  mt: async () => await import("dayjs/locale/mt"),
  my: async () => await import("dayjs/locale/my"),
  nb: async () => await import("dayjs/locale/nb"),
  ne: async () => await import("dayjs/locale/ne"),
  "nl-be": async () => await import("dayjs/locale/nl-be"),
  nl: async () => await import("dayjs/locale/nl"),
  nn: async () => await import("dayjs/locale/nn"),
  "oc-lnc": async () => await import("dayjs/locale/oc-lnc"),
  "pa-in": async () => await import("dayjs/locale/pa-in"),
  pl: async () => await import("dayjs/locale/pl"),
  "pt-br": async () => await import("dayjs/locale/pt-br"),
  pt: async () => await import("dayjs/locale/pt"),
  rn: async () => await import("dayjs/locale/rn"),
  ro: async () => await import("dayjs/locale/ro"),
  ru: async () => await import("dayjs/locale/ru"),
  rw: async () => await import("dayjs/locale/rw"),
  sd: async () => await import("dayjs/locale/sd"),
  se: async () => await import("dayjs/locale/se"),
  si: async () => await import("dayjs/locale/si"),
  sk: async () => await import("dayjs/locale/sk"),
  sl: async () => await import("dayjs/locale/sl"),
  sq: async () => await import("dayjs/locale/sq"),
  "sr-cyrl": async () => await import("dayjs/locale/sr-cyrl"),
  sr: async () => await import("dayjs/locale/sr"),
  ss: async () => await import("dayjs/locale/ss"),
  "sv-fi": async () => await import("dayjs/locale/sv-fi"),
  sv: async () => await import("dayjs/locale/sv"),
  sw: async () => await import("dayjs/locale/sw"),
  ta: async () => await import("dayjs/locale/ta"),
  te: async () => await import("dayjs/locale/te"),
  tet: async () => await import("dayjs/locale/tet"),
  tg: async () => await import("dayjs/locale/tg"),
  th: async () => await import("dayjs/locale/th"),
  tk: async () => await import("dayjs/locale/tk"),
  "tl-ph": async () => await import("dayjs/locale/tl-ph"),
  tlh: async () => await import("dayjs/locale/tlh"),
  tr: async () => await import("dayjs/locale/tr"),
  tzl: async () => await import("dayjs/locale/tzl"),
  "tzm-latn": async () => await import("dayjs/locale/tzm-latn"),
  tzm: async () => await import("dayjs/locale/tzm"),
  "ug-cn": async () => await import("dayjs/locale/ug-cn"),
  uk: async () => await import("dayjs/locale/uk"),
  ur: async () => await import("dayjs/locale/ur"),
  "uz-latn": async () => await import("dayjs/locale/uz-latn"),
  uz: async () => await import("dayjs/locale/uz"),
  vi: async () => await import("dayjs/locale/vi"),
  "x-pseudo": async () => await import("dayjs/locale/x-pseudo"),
  yo: async () => await import("dayjs/locale/yo"),
  "zh-cn": async () => await import("dayjs/locale/zh-cn"),
  "zh-hk": async () => await import("dayjs/locale/zh-hk"),
  "zh-tw": async () => await import("dayjs/locale/zh-tw"),
  zh: async () => await import("dayjs/locale/zh"),
  "es-mx": async () => await import("dayjs/locale/es-mx"),
  "es-pr": async () => await import("dayjs/locale/es-pr"),
  "es-us": async () => await import("dayjs/locale/es-us"),
};

export default dateLocales;
