import type { AuthUser, AuthUserApiResponse } from "./types";

export const mapAuthUser = (userResponse: AuthUserApiResponse) => {
  const userSettings = JSON.parse(userResponse.settings);

  const user: AuthUser = {
    ...userResponse,
    settings: { whats_new_viewed_at: userSettings.whats_new_viewed_at },
  };

  if (userSettings.employee_remote !== undefined)
    user.settings.employee_remote =
      userSettings.employee_remote === null
        ? null
        : Boolean(userSettings.employee_remote);
  if (userSettings.lg_admin_location_group_pks !== undefined)
    user.settings.lg_admin_location_group_pks =
      userSettings.lg_admin_location_group_pks;
  if (userSettings.sms_notifications !== undefined)
    user.settings.sms_notifications = Boolean(userSettings.sms_notifications);
  if (userSettings.email_notifications !== undefined)
    user.settings.email_notifications = Boolean(
      userSettings.email_notifications
    );
  if (userSettings.slack_is_new !== undefined)
    user.settings.slack_is_new = userSettings.slack_is_new;
  if (userSettings.teams_is_new !== undefined)
    user.settings.teams_is_new = userSettings.teams_is_new;
  if (userSettings.employee_remote_follow !== undefined)
    user.settings.employee_remote_follow = userSettings.employee_remote_follow;

  return user;
};
