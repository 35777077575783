<template>
  <div
    v-loading="true"
    class="authentication-wrapper"
    element-loading-background="transparent"
  />
</template>

<style lang="scss" scoped>
.authentication-wrapper {
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--so-color-secondary-light);
}
</style>
